import React from "react";
import { SearchBar } from "../../../";
import HeroText from "../../../assets/homepage/HeroSection/HeroText.svg";

const Hero = () => {
  return (
    <div className="w-all-sections-width mx-auto flex flex-col items-center pb-10">
      {/* Centered SVG image on top */}
      <img src={HeroText} alt="Hero Text" className="mx-auto mb-3" />

      {/* Heading text remains left-aligned */}
      <h2 className="h1 font-bold mb-5 text-left w-full">
        {/* For mobile, sm, md: display as one line */}
        <div className="block lg:hidden">
          <p className="leading-[35px]">
            <span className="text-brandGold">Discover the best packages</span>
            <span className="text-white"> from leading operators & service providers.</span>
          </p>
        </div>
        {/* For large devices: break text into two lines */}
        <div className="hidden lg:block space-y-2">
          <p className="text-brandGold leading-[30px] md:leading-none">
            Discover the best packages
          </p>
          <p className="text-white leading-[30px] md:leading-none">
            from leading operators & service providers.
          </p>
        </div>
      </h2>

      <SearchBar />
    </div>
  );
};

export default Hero;
