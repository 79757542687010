import React, { useEffect } from "react";
import Banner from "../../HeroSection/Banner";
import HeaderFooter from "../../../../ZiyaratArticlesPages/HeaderFooter";
import Slider from "../../MadinahPlaces/Wadijin/Slider";
import Page from "./Page";
import ArruddafParkImg1 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/arruddafPark/image1.avif";
import ArruddafParkImg2 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/arruddafPark/image2.jpeg";
import ArruddafParkImg3 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/arruddafPark/image3.jpeg";
import ArruddafParkImg4 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/arruddafPark/image4.jpg";
import ArruddafParkImg5 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/arruddafPark/image5.jpg";
const ArruddafPark = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const images = [
    ArruddafParkImg2,
    ArruddafParkImg3,
    ArruddafParkImg4,
    ArruddafParkImg4,
    ArruddafParkImg5,
  ];

  return (
    <div>
      <HeaderFooter>
        <Banner image={ArruddafParkImg1} title={"Arruddaf Park"} />
        <Page />
        <Slider images={images} />
      </HeaderFooter>
    </div>
  );
};

export default ArruddafPark;
