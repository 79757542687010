import React, { useState, useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Header,
  AppDownload,
  Footer,
  SortingDiv,
  FiltersComponent,
  PackageListing,
} from "../../";
import { getShortPackageDetails } from "../../api/apiService";

const ListingPage = () => {
  const [packages, setPackages] = useState([]);
  const [filteredPackages, setFilteredPackages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [sortOrder, setSortOrder] = useState("earliest-to-latest");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);
  const [explicitDepartureDate, setExplicitDepartureDate] = useState(false);
  const pageSize = 5;
  const [searchParams] = useSearchParams();

  const handlePageChange = (direction) => {
    setCurrentPage((prevPage) => {
      if (direction === "next") return prevPage + 1;
      if (direction === "prev") return Math.max(prevPage - 1, 1);
      return prevPage;
    });
  };

  const formatDate = useCallback((dateString) => {
    if (!dateString) return null;
    return new Date(dateString).toISOString().split("T")[0];
  }, []);

  useEffect(() => {
    const departureCityQuery = searchParams.get("departureLocation") || "";
    const departureDateQuery = searchParams.get("departureDate") || "";
    setExplicitDepartureDate(departureDateQuery !== "");
    setFilters({
      departureCity: departureCityQuery,
      departureDate:
        departureCityQuery && !departureDateQuery
          ? new Date().toISOString().split("T")[0]
          : departureDateQuery,
    });
  }, [searchParams]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const departureCity =
          filters.departureCity || searchParams.get("departureLocation") || "";
        const departureDateQuery =
          filters.departureDate || searchParams.get("departureDate") || "";
        let start_date = departureDateQuery;
        if (departureCity && !departureDateQuery) {
          start_date = new Date().toISOString().split("T")[0];
        }
        const params = {
          package_type: searchParams.get("type") || "umrah",
          start_date,
          flight_from: departureCity,
        };

        const result = await getShortPackageDetails({
          package_type: params.package_type,
          start_date: params.start_date,
          flight_from: params.flight_from,
        });

        setPackages(result || []);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [filters, searchParams, formatDate]);

  useEffect(() => {
    const processPackages = () => {
      let result = [...packages];

      if (filters.umrahDuration) {
        const duration = parseInt(filters.umrahDuration, 10);
        result = result.filter(
          (p) => p.mecca_nights + p.madinah_nights === duration
        );
      }

      if (filters.airline) {
        result = result.filter((p) =>
          p.airline_detail?.some((a) => a.airline_name === filters.airline)
        );
      }

      if (filters.destinationCity) {
        result = result.filter((p) =>
          p.airline_detail?.some((a) => a.flight_to === filters.destinationCity)
        );
      }

      if (filters.departureCity) {
        result = result.filter((p) =>
          p.airline_detail?.some((a) => a.flight_from === filters.departureCity)
        );
      }

      if (explicitDepartureDate && filters.departureDate) {
        const selectedDate = new Date(filters.departureDate);
        result = result.filter((p) => new Date(p.start_date) >= selectedDate);
      }

      ["makkah", "madinah"].forEach((city) => {
        const filterKey = `${city}HotelDistance`;
        if (filters[filterKey]) {
          result = result.filter((p) => {
            const hotel = p.hotel_detail?.find(
              (h) => h.hotel_city.toLowerCase() === city
            );
            const distance = parseFloat(hotel?.hotel_distance || 0);
            return distance <= filters[filterKey];
          });
        }
      });

      if (filters.mealsIncluded) {
        result = result.filter(
          (p) =>
            p.is_breakfast_included ||
            p.is_lunch_included ||
            p.is_dinner_included
        );
      }

      if (filters.hasZiyarah) {
        result = result.filter((p) =>
          p.ziyarah_detail?.some((z) => z.ziyarah_list?.trim() !== "")
        );
      }

      if (sortOrder === "low-to-high") {
        result.sort((a, b) => a.cost_for_sharing - b.cost_for_sharing);
      } else if (sortOrder === "high-to-low") {
        result.sort((a, b) => b.cost_for_sharing - a.cost_for_sharing);
      } else if (sortOrder === "Top Reviewed") {
        result.sort(
          (a, b) => b.rating_count.rating_count - a.rating_count.rating_count
        );
      } else if (sortOrder === "earliest-to-latest") {
        result.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
      }

      return result;
    };

    setFilteredPackages(processPackages());
    setCurrentPage(1);
  }, [packages, filters, sortOrder, explicitDepartureDate, formatDate]);

  const paginatedPackages = filteredPackages.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <div>
      <Header />
      <main className="w-all-sections-width mx-auto mb-s10 md:mb-m10 xl:mb-l14">
        <SortingDiv sortOrder={sortOrder} setSortOrder={setSortOrder} />
        <div className="flex flex-col xl:flex-row gap-6 mt-6">
          <button
            onClick={() => setIsFiltersVisible(!isFiltersVisible)}
            className="xl:hidden px-4 py-2 bg-brandGold text-white rounded-md text-sm"
          >
            {isFiltersVisible ? "Close Filters" : "Show Filters"}
          </button>
          {isFiltersVisible && (
            <aside className="w-full xl:hidden">
              <FiltersComponent
                packages={packages}
                filters={filters}
                onFilterChange={setFilters}
                clearFilters={() => setFilters({})}
              />
            </aside>
          )}
          <aside className="hidden xl:block xl:w-[35%]">
            <FiltersComponent
              packages={packages}
              filters={filters}
              onFilterChange={setFilters}
              clearFilters={() => setFilters({})}
            />
          </aside>
          <PackageListing
            packages={paginatedPackages}
            loading={loading}
            error={error}
            currentPage={currentPage}
            pageSize={pageSize}
            totalItems={filteredPackages.length}
            onPageChange={handlePageChange}
          />
        </div>
      </main>
      <AppDownload />
      <Footer />
    </div>
  );
};

export default ListingPage;
