import React, { useEffect } from "react";
import Banner from "../../HeroSection/Banner";
import HeaderFooter from "../../../../ZiyaratArticlesPages/HeaderFooter";
import Slider from "../Wadijin/Slider";
import Page from "./Page";
import MasjidIjabahImg1 from "../../../../../assets/ZiyaratPlaces/Madinah/MasjidEjabah/ejabah4.jpg";
import MasjidIjabahImg2 from "../../../../../assets/ZiyaratPlaces/Madinah/MasjidEjabah/ejabah6.jpeg";
import MasjidIjabahImg3 from "../../../../../assets/ZiyaratPlaces/Madinah/MasjidEjabah/ejabah7.jpg";
import MasjidIjabahImg4 from "../../../../../assets/ZiyaratPlaces/Madinah/MasjidEjabah/ejabah8.jpg";
import bg from "../../../../../assets/ZiyaratPlaces/Madinah/MasjidEjabah/ejabah2.svg"
const MasjidIjabah = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const images = [
    MasjidIjabahImg1,
    MasjidIjabahImg2,
    MasjidIjabahImg3,
    MasjidIjabahImg4,
  ];

  return (
    <div className="">
      <HeaderFooter>
        <Banner
          image={bg}
          title={"Masjid Ijabah"}
        />
        <Page />
        <Slider images={images} />
      </HeaderFooter>
    </div>
  );
};

export default MasjidIjabah;
