import React, { useState, useEffect, useCallback } from "react";
import { getAllComplaintsByUser } from "../../api/apiService";
import { Header, Footer, Loader } from "../../";
import { format } from "date-fns";
import banner from "../../assets/Detail-Page/banner.png";

const ComplaintsOverviewPage = () => {
  const [complaints, setComplaints] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(null);

  const userProfile = JSON.parse(
    localStorage.getItem("Login-User-Profile") || "{}"
  );
  const sessionToken = userProfile.session_token || "";

  useEffect(() => {
    const fetchComplaints = async () => {
      if (sessionToken) {
        try {
          const res = await getAllComplaintsByUser(sessionToken);
          if (res.success) {
            setComplaints(res.data);
            setSelected(res.data[0] || null);
          }
        } catch (err) {
          console.error("Error fetching complaints:", err);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };
    fetchComplaints();
  }, [sessionToken]);

  const handleSelect = useCallback((complaint) => setSelected(complaint), []);

  const renderComplaintItem = useCallback(
    (complaint) => (
      <div
        key={complaint.complaint_id}
        className={`p-6 rounded-lg shadow-sm cursor-pointer transition-all duration-300 transform hover:scale-105 hover:shadow-lg ${
          selected?.complaint_id === complaint.complaint_id
            ? "bg-brandGold text-white shadow-lg"
            : "bg-white border border-gray-200 text-gray-800 hover:bg-gray-100"
        }`}
        onClick={() => handleSelect(complaint)}
      >
        <h3 className="font-semibold text-lg truncate">
          {complaint.complaint_title}
        </h3>
        <p className="text-sm mt-1 truncate">{complaint.complaint_message}</p>
        <div className="text-xs mt-2 text-gray-400">
          {format(new Date(complaint.complaint_time), "PPpp")}
        </div>
      </div>
    ),
    [selected, handleSelect]
  );

  const renderDetails = useCallback(() => {
    if (!selected)
      return (
        <div className="p-8 text-center text-gray-500 animate-fade-in">
          Select a complaint to view details.
        </div>
      );

    const audioSrc =
      selected.audio_message &&
      (selected.audio_message.startsWith("http")
        ? selected.audio_message
        : `${process.env.REACT_APP_API_BASE_URL}${selected.audio_message}`);

    return (
      <div className="p-8 bg-white shadow-lg rounded-lg border border-gray-200 transform transition-all duration-300 animate-fade-in">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">
          {selected.complaint_title}
        </h2>
        <p className="text-gray-700 mb-6 leading-relaxed">
          {selected.complaint_message}
        </p>
        {audioSrc && (
          <div className="mb-6">
            <audio controls className="w-full rounded-md">
              <source src={audioSrc} type="audio/wav" />
              Your browser does not support the audio element.
            </audio>
          </div>
        )}
        <div className="text-sm space-y-2">
          <div className="flex items-center gap-2">
            <span className="font-semibold">Status:</span>
            <span
              className={`px-3 py-1 rounded-full text-xs font-medium ${
                selected.complaint_status === "Resolved"
                  ? "bg-green-100 text-green-800"
                  : "bg-yellow-100 text-yellow-800"
              }`}
            >
              {selected.complaint_status}
            </span>
          </div>
          <div>
            <span className="font-semibold">Time:</span>{" "}
            {format(new Date(selected.complaint_time), "PPpp")}
          </div>
          {selected.response_message && (
            <div>
              <span className="font-semibold">Response:</span>{" "}
              {selected.response_message}
            </div>
          )}
        </div>
      </div>
    );
  }, [selected]);

  if (loading)
    return (
      <div className="bg-white flex items-center justify-center h-screen">
        <Loader />
      </div>
    );

  return (
    <div className="min-h-screen flex flex-col bg-gray-100">
      <Header />
      <div className=" w-all-sections-width mx-auto mb-10">
        <img
          src={banner}
          alt="banner"
          className="w-full rounded-lg shadow-md mb-8"
        />
        <h2 className="h2">My Complaints</h2>
        <div className="flex flex-col md:flex-row flex-1 overflow-hidden gap-6">
          <div className="w-full md:w-1/3 bg-white p-4 rounded-lg shadow-md overflow-auto space-y-4 animate-slide-in-left">
            {complaints.length ? (
              complaints.map(renderComplaintItem)
            ) : (
              <div className="p-6 text-gray-500">No complaints found.</div>
            )}
          </div>
          <div className="w-full md:w-2/3 overflow-auto animate-slide-in-right">
            {renderDetails()}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ComplaintsOverviewPage;
