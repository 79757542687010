import React, { useEffect } from "react";
import Banner from "../../HeroSection/Banner";
import HeaderFooter from "../../../../ZiyaratArticlesPages/HeaderFooter";
import Slider from "../Wadijin/Slider";
import Page from "./Page";
import MasjidAbuBakarImg1 from "../../../../../assets/ZiyaratPlaces/Madinah/masjidabubakar/slider1.jpg";
import MasjidAbuBakarImg2 from "../../../../../assets/ZiyaratPlaces/Madinah/masjidabubakar/slider2.jpg";
import MasjidAbuBakarImg3 from "../../../../../assets/ZiyaratPlaces/Madinah/masjidabubakar/abubakar4.jpg";
import MasjidAbuBakarImg4 from "../../../../../assets/ZiyaratPlaces/Madinah/masjidabubakar/slider4.png";
import bg from "../../../../../assets/ZiyaratPlaces/Madinah/masjidabubakar/abubakar1.svg"
const MasjidAbuBakar = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const images = [
    MasjidAbuBakarImg1,
    MasjidAbuBakarImg2,
    MasjidAbuBakarImg3,
    MasjidAbuBakarImg4,
  ];

  return (
    <div className="">
      <HeaderFooter>
        <Banner
          image={bg}
          title={"Masjid Abu Bakr"}
        />
        <Page />
        <Slider images={images} />
      </HeaderFooter>
    </div>
  );
};

export default MasjidAbuBakar;
