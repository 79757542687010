import React from "react";
import HeroSection from "../../HeroSection/HeroSection";
import { MasjidNimra } from "../../../../../Utilities/data";
import Page from "./Page";
import HeaderFooter from "../../../../ZiyaratArticlesPages/HeaderFooter";

const MasjidNimrah = () => {
  return (
    <div>
      <HeaderFooter>
        <HeroSection
          herobg={MasjidNimra.hero.image}
          heading={MasjidNimra.hero.heading}
          paragraph={MasjidNimra.hero.description}
        />
        <Page />
      </HeaderFooter>
    </div>
  );
};

export default MasjidNimrah;
