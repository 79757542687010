import React from "react";
import { Calendar } from "lucide-react";
import { NumericFormat } from "react-number-format";
import { useCurrency } from "../../../../context/CurrencyContext";
import { useNavigate, useLocation } from "react-router-dom";

const BookingDetail = ({ bookingResponse, travellers = 0, roomBreakDown = [] }) => {
  const { start_date = "", total_price = 0, payment_detail = [] } = bookingResponse;
  const navigate = useNavigate();
  const location = useLocation();

  const totalApprovedPaid =
    payment_detail
      ?.filter((payment) => payment.payment_status !== null)
      .reduce((acc, payment) => acc + (payment.transaction_amount || 0), 0) || 0;
  const remainingAmount = total_price - totalApprovedPaid;
  const { currency, convert } = useCurrency();

  return (
    <div className="w-full bg-white rounded-lg border border-lightGray p-6 space-y-6">
      <div className="space-y-4">
        <div>
          <h2 className="text-lg font-semibold text-brandGold">
            <NumericFormat
              value={convert(total_price, "PKR")}
              displayType={"text"}
              thousandSeparator
              prefix={`${currency} `}
              decimalScale={0}
              fixedDecimalScale={true}
            />
          </h2>
          <p className="text-lg font-semibold">{travellers} TRAVELLERS</p>
        </div>
        <div className="border-b border-lightGray" />
      </div>

      <div className="space-y-4">
        <div className="space-y-2">
          <label className="text-sm font-medium text-blueForDate">
            Departure date
          </label>
          <div className="flex items-center gap-2 p-3 border border-lightGray rounded-md bg-white/70">
            <Calendar className="w-4 h-4 text-[#6B7280]" />
            <input
              type="text"
              value={
                start_date
                  ? new Date(start_date).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })
                  : "N/A"
              }
              className="bg-transparent text-sm outline-none text-[#9CA3AF]"
              readOnly
            />
          </div>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-sm font-medium text-blueForDate">
          Departs from
        </label>
        <div className="flex items-center gap-2 p-3 border border-lightGray rounded-md bg-white/70">
          <input
            type="text"
            value={`${bookingResponse?.airline_detail?.[0]?.flight_from || ""}`}
            className="bg-transparent text-sm outline-none text-[#9CA3AF]"
            readOnly
          />
        </div>
      </div>

      <div className="space-y-6">
        {roomBreakDown && roomBreakDown.length > 0 ? (
          roomBreakDown.map((room, index) => (
            <div key={index} className="space-y-2">
              <h3 className="text-sm font-semibold text-blueForDate">
                {room.type}
              </h3>
              <div className="flex justify-between text-sm">
                <span>
                  {room.count} {room.count > 1 ? "Beds" : "Bed"}
                </span>
                <span className="text-black font-semibold">
                  <NumericFormat
                    value={convert(room.price * room.count, "PKR")}
                    displayType={"text"}
                    thousandSeparator
                    prefix={`${currency} `}
                    decimalScale={0}
                    fixedDecimalScale={true}
                  />
                </span>
              </div>
              <p className="text-xs text-primaryGray">
                <NumericFormat
                  value={convert(room.price, "PKR")}
                  displayType={"text"}
                  thousandSeparator
                  prefix={`${currency} `}
                  decimalScale={0}
                  fixedDecimalScale={true}
                />{" "}
                x {room.count} {room.count > 1 ? "Persons" : "Person"}
              </p>
            </div>
          ))
        ) : (
          <p className="text-gray-500 text-sm">No rooms selected.</p>
        )}
      </div>

      {bookingResponse.booking_status !== "Objection" && location.pathname !== "/document-detail" && (
        <div className="space-y-4">
          <div className="border-b border-lightGray" />
          <div className="space-y-1">
            <div className="flex justify-between text-sm font-bold">
              <span>TOTAL PAYABLE</span>
              <NumericFormat
                value={convert(total_price, "PKR")}
                displayType={"text"}
                thousandSeparator
                prefix={`${currency} `}
                decimalScale={0}
                fixedDecimalScale={true}
              />
            </div>
            {bookingResponse.booking_status !== "Paid" && (
              <>
                <div className="flex justify-between text-sm font-bold text-[#00936c]">
                  <span>Already Paid</span>
                  <NumericFormat
                    value={convert(totalApprovedPaid, "PKR")}
                    displayType={"text"}
                    thousandSeparator
                    prefix={`${currency} `}
                    decimalScale={0}
                    fixedDecimalScale={true}
                  />
                </div>
                <div className="flex justify-between text-sm font-bold text-redIcon">
                  <span>Payment due now</span>
                  <NumericFormat
                    value={convert(remainingAmount, "PKR")}
                    displayType={"text"}
                    thousandSeparator
                    prefix={`${currency} `}
                    decimalScale={0}
                    fixedDecimalScale={true}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {location.pathname === "/document-detail" && (
        <button
          onClick={() => navigate(-1)}
          className="px-4 py-2 w-full bg-brandGold text-white rounded hover:bg-yellow-600"
        >
          Back
        </button>
      )}
    </div>
  );
};

export default BookingDetail;
