import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { submitBookingRequest, getAllRequestsByUser } from "../../../api/apiService";
import { Footer, Header, PackageHeader, BookingDetail } from "../../../";
import banner from "../../../assets/Detail-Page/banner.png";

const brandGold = "rgba(209, 165, 84, 1)";
const gray400 = "rgba(223, 228, 234, 1)";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? brandGold : provided.borderColor,
    boxShadow: state.isFocused ? `0 0 0 1px ${brandGold}` : provided.boxShadow,
    "&:hover": { borderColor: gray400 }
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? brandGold
      : state.isFocused
      ? gray400
      : provided.backgroundColor,
    color: state.isSelected ? "white" : provided.color
  })
};

const RequestPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { booking, packageDetails } = state || {};
  const { user_session_token, booking_number } = booking || {};

  const requestTitles = [
    "Medical assistance",
    "Concierge assistance",
    "Financial assistance",
    "Accident or Emergency",
    "Transportation",
    "Other"
  ];
  
  
  const requestOptions = requestTitles.map(title => ({ value: title, label: title }));

  const [form, setForm] = useState({ requestTitle: "", requestMessage: "" });
  const [attachment, setAttachment] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requests, setRequests] = useState([]);
  const [requestsLoading, setRequestsLoading] = useState(true);

  useEffect(() => {
    const fetchRequests = async () => {
      if (user_session_token && booking_number) {
        try {
          const { success, data } = await getAllRequestsByUser(user_session_token);
          if (success) setRequests(data.filter(r => r.booking_number === booking_number));
        } catch (err) {
          console.error("Error fetching requests:", err);
        } finally {
          setRequestsLoading(false);
        }
      }
    };
    fetchRequests();
  }, [user_session_token, booking_number]);

  const handleChange = useCallback(
    ({ target: { id, value } }) => setForm(prev => ({ ...prev, [id]: value })),
    []
  );

  const handleFileChange = useCallback(e => {
    setAttachment(e.target.files[0]);
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    if (!form.requestTitle.trim() || !form.requestMessage.trim()) {
      alert("Please select a request title and enter a request message.");
      return;
    }
    setIsSubmitting(true);
    const res = await submitBookingRequest({
      session_token: user_session_token,
      booking_number,
      request_title: form.requestTitle,
      request_message: form.requestMessage,
      request_attachment: attachment
    });
    alert(res.success ? "Request submitted successfully!" : `Error: ${res.error}`);
    if (res.success) navigate(-1);
    setIsSubmitting(false);
  };

  const getAttachmentUrl = path =>
    !path ? "" : path.startsWith("http") ? path : `${process.env.REACT_APP_API_BASE_URL}${path}`;

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <div className="flex-1 w-all-sections-width mx-auto px-4 py-8">
        <img src={banner} alt="banner" className="w-full rounded-lg shadow-md mb-8" />
        <div className="flex flex-col lg:flex-row gap-8">
          <div className="w-full lg:w-[70%]">
            <PackageHeader data={booking} />
            <div className="bg-white p-8 rounded-lg border border-gray-200">
              <h1 className="text-2xl font-bold text-gray-800 mb-4">Raise a Request</h1>
              <p className="text-gray-600 mb-6">Let us know your concern regarding your booking.</p>
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label htmlFor="requestTitle" className="block text-sm font-medium text-gray-700 mb-1">
                    Request Title
                  </label>
                  <Select
                    id="requestTitle"
                    options={requestOptions}
                    value={requestOptions.find(option => option.value === form.requestTitle)}
                    onChange={option =>
                      setForm(prev => ({ ...prev, requestTitle: option ? option.value : "" }))
                    }
                    placeholder="Select a request title"
                    styles={customStyles}
                  />
                </div>
                <div>
                  <label htmlFor="requestMessage" className="block text-sm font-medium text-gray-700 mb-1">
                    Request Details
                  </label>
                  <textarea
                    id="requestMessage"
                    className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-brandGold transition-all"
                    placeholder="Describe your request in detail..."
                    rows="5"
                    value={form.requestMessage}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label htmlFor="attachment" className="block text-sm font-medium text-gray-700 mb-1">
                    Attachment (Optional)
                  </label>
                  <input
                    type="file"
                    id="attachment"
                    accept=".jpeg,.jpg,.png,.pdf"
                    onChange={handleFileChange}
                    className="w-full"
                  />
                </div>
                <button
                  type="submit"
                  className="w-full px-4 py-3 bg-blue-600 text-white rounded-lg font-semibold hover:bg-blue-700 transition-all"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Submit Request"}
                </button>
              </form>
            </div>
            <div className="mt-8 bg-white p-8 rounded-lg border border-gray-200 shadow">
              <h2 className="text-2xl font-bold text-gray-800 mb-4">Your Requests</h2>
              {requestsLoading ? (
                <p className="text-gray-600">Loading requests...</p>
              ) : requests.length ? (
                requests.map(r => (
                  <div key={r.request_id} className="mb-6 p-4 border border-gray-300 rounded-lg">
                    <h3 className="text-xl font-semibold text-gray-800 mb-2">{r.request_title}</h3>
                    <p className="text-gray-600 mb-2">{r.request_message}</p>
                    {r.request_attachment && (
                      <div className="mb-2">
                        <a
                          href={getAttachmentUrl(r.request_attachment)}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-brandGold underline"
                        >
                          View Attachment
                        </a>
                      </div>
                    )}
                    <div className="text-sm text-gray-500">
                      <span>Status: {r.request_status}</span>
                      <span className="ml-4">
                        Time: {new Date(r.created_at).toLocaleString()}
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-gray-600">No requests found for this booking.</p>
              )}
            </div>
          </div>
          <div className="w-full lg:w-[30%]">
            <BookingDetail
              bookingResponse={booking}
              travellers={booking?.adults || 0}
              selectedRooms={packageDetails?.selectedRooms}
              prices={packageDetails?.prices}
              rooms={packageDetails?.rooms}
              totalSelectedRooms={packageDetails?.totalSelectedRooms}
              roomBreakDown={booking?.roomBreakDown || []}
              totalRoomCost={packageDetails?.totalRoomCost}
              total_amount={packageDetails?.total_amount}
              payable_amount={packageDetails?.payable_amount}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RequestPage;
