import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import { submitRatingAndReview } from "../../../api/apiService";
import {
    Footer,
    Header,
    PackageHeader,
    BookingDetail
} from "../../../";
import banner from "../../../assets/Detail-Page/banner.png";

const ReviewPage = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { booking, packageDetails } = state || {};

    const [reviewText, setReviewText] = useState("");
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!reviewText.trim() || rating === 0) {
            alert("Please provide a rating and write a review.");
            return;
        }
        setIsSubmitting(true);

        try {
            const response = await submitRatingAndReview({
                session_token: booking.user_session_token,
                booking_number: booking.booking_number,
                partner_total_stars: rating,
                partner_comment: reviewText,
            });

            if (response.success) {
                alert("Review submitted successfully!");
                navigate(`/booking-info/${booking.booking_number}`);
            } else {
                alert("Error: " + response.error);
            }
        } catch (error) {
            console.error("Error submitting review:", error);
            alert("Failed to submit review. Please try again.");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div>
            <Header />
            <div className="w-all-sections-width mx-auto mb-10">
            <img src={banner} alt="banner" className="w-full rounded-lg shadow-md mb-8" />
                <div className="flex flex-col lg:flex-row gap-6">
                    {/* Left Column */}
                    <div className="w-full lg:w-[70%]">
                        <PackageHeader data={booking} />
                        <div className="bg-white p-8 rounded-lg shadow-xl border">
                            <h1 className="text-3xl font-extrabold text-center text-gray-800 mb-4">
                                Submit Your Review
                            </h1>
                            <p className="text-gray-600 text-center mb-6">How was your experience?</p>

                            <div className="flex justify-center mb-6">
                                {[...Array(5)].map((_, index) => (
                                    <label key={index}>
                                        <input type="radio" name="rating" className="hidden" value={index + 1} onClick={() => setRating(index + 1)} />
                                        <FaStar
                                            size={35}
                                            className={`cursor-pointer transition ${index + 1 <= (hover || rating) ? "text-yellow-500" : "text-gray-300"}`}
                                            onMouseEnter={() => setHover(index + 1)}
                                            onMouseLeave={() => setHover(null)}
                                            onClick={() => setRating(index + 1)}
                                        />
                                    </label>
                                ))}
                            </div>

                            <form onSubmit={handleSubmit}>
                                <textarea
                                    className="w-full p-4 border rounded-lg focus:ring-2 focus:ring-brandGold focus:border-brandGold"
                                    placeholder="Write your review..."
                                    rows="5"
                                    value={reviewText}
                                    onChange={(e) => setReviewText(e.target.value)}
                                ></textarea>

                                <button type="submit" className="w-full mt-4 py-3 bg-[#00963c] text-white rounded-lg text-lg font-bold hover:bg-green-700 transition-all">
                                    {isSubmitting ? "Submitting..." : "Submit Review"}
                                </button>
                            </form>
                        </div>
                    </div>

                    {/* Right Column */}
                    <div className="w-full lg:w-[30%]">
                        <BookingDetail
                            bookingResponse={booking}
                            travellers={booking?.adults || 0}
                            selectedRooms={packageDetails?.selectedRooms}
                            prices={packageDetails?.prices}
                            rooms={packageDetails?.rooms}
                            totalSelectedRooms={packageDetails?.totalSelectedRooms}
                            roomBreakDown={booking?.roomBreakDown || []}
                            totalRoomCost={packageDetails?.totalRoomCost}
                            total_amount={packageDetails?.total_amount}
                            payable_amount={packageDetails?.payable_amount}
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ReviewPage;
