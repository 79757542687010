import React, { useEffect, useState } from "react";
import AuthContext from "./AuthContext";

const AuthContextProvider = ({ children }) => {
  const [login, setLogin] = useState(() => {
    const storedProfile = localStorage.getItem("Login-User-Profile");
    return storedProfile ? JSON.parse(storedProfile) : null;
  });
  
  useEffect(() => {
    if (login) {
      localStorage.setItem("Login-User-Profile", JSON.stringify(login));
    } else {
      localStorage.clear();
    }
  }, [login]);

  const signOut = () => {
    setLogin(null);
    // window.location.href = "/"; // Redirect to '/' after sign out
  };

  return (
    <AuthContext.Provider value={{ login, setLogin, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
