import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Page:
export { default as HomePage } from "./pages/HomePage/HomePage";

//Its Components:
export { default as Header } from "./components/Header/Header";
export { default as Hero } from "./pages/HomePage/components/Hero";
export { default as SearchBar } from "./components/SearchBar/SearchBar";
export { default as Footer } from "./components/Footer/Footer";
export { default as AppDownload } from "./components/AppDownload/AppDownload";
export { default as PopularPackages } from "./pages/HomePage/components/PopularPackages"; // PopularPackages section
export { default as ServicesSection } from "./components/ServicesSection/ServicesSection";
export { default as DiscountSection } from "./pages/HomePage/components/DiscountSection";
export { default as BrowseByPeople } from "./pages/HomePage/components/BrowseByPeople";
export { default as Inspiration } from "./pages/HomePage/components/Inspiration";
export { default as MessageDisplay } from "./components/MessageDisplay/MessageDisplay";
export { default as PackageCardHome } from "./components/PackageCardHome/PackageCardHome";
export { default as ServicesSlider } from "./pages/HomePage/components/ServicesSlider";
export { default as Vision } from "./pages/HomePage/components/Vision";
//Page:
export { default as ListingPage } from "./pages/ListingPage/ListingPage";

//components:
export { default as SortingDiv } from "./pages/ListingPage/components/SortingDiv";
export { default as FiltersComponent } from "./pages/ListingPage/components/Filters";
export { default as PackageCard } from "./pages/ListingPage/components/PackageCard";
export { default as PackageListing } from "./pages/ListingPage/components/PackageListing";

//Page:
export { default as PackageDetailPage } from "./pages/PackageDetailPage/PackageDetailPage";

//components
export { default as PackageHeader } from "./pages/PackageDetailPage/components/LeftColumn/PackageHeader";
export { default as Itinerary } from "./pages/PackageDetailPage/components/LeftColumn/Itinerary";
export { default as RoomOptions } from "./pages/PackageDetailPage/components/LeftColumn/RoomOptions";
export { default as BookingSummary } from "./pages/PackageDetailPage/components/RightColumn/BookingSummary";
export { default as TransportDetails } from "./pages/PackageDetailPage/components/LeftColumn/TransportDetail";
export { default as AirlineDetails } from "./pages/PackageDetailPage/components/LeftColumn/AirlineDetails";
export { default as ZiyarahDetails } from "./pages/PackageDetailPage/components/LeftColumn/ZiyarahDetails";
export { default as PackageItinerary } from "./pages/PackageDetailPage/components/LeftColumn/PackageItinerary";
export { default as Verification } from "./pages/PackageDetailPage/components/LeftColumn/Verification";
export { default as MealsDetails } from "./pages/PackageDetailPage/components/LeftColumn/MealsDetails";

//Components:
export { default as Login } from "./pages/Login/Login";
export { default as SignUp } from "./pages/Signup/Signup";

//Page:
export { default as TravellerInfoPage } from "./pages/TravellerInfoPage/TravellerInfoPage";

//components
export { default as PassportInfo } from "./pages/TravellerInfoPage/components/PassportInfo";
export { default as TravellersPageSummary } from "./pages/TravellerInfoPage/components/TravellersPageSummary";
//component
export { default as Otp } from "./pages/Otp/Otp";

//Page
export { default as AddressDetails } from "./pages/AddressDetailsPage/addressDetailsPage";

//Page
export { default as PaymentPage } from "./pages/PaymentPage/PaymentPage";

//component
export { default as PaymentInfo } from "./pages/PaymentPage/components/PaymentInfo";
export { default as BillingAddress } from "./pages/PaymentPage/components/BillingAddress";
export { default as BankingInfo } from "./pages/PaymentPage/components/BankingInfo";
export { default as PayNowComponent } from "./pages/PaymentPage/components/PayNowComponent";

//Page
export { default as BookingInfoPage } from "./pages/Bookings/BookingInfoPage/BookingInfoPage";

//component
export { default as BookingInfo } from "./pages/Bookings/BookingInfoPage/components/DocUploadAndPayment";
export { default as BookingDetail } from "./pages/Bookings/BookingInfoPage/components/BookingDetailSidebar";
export { default as BookingDetailsComponent } from "./pages/Bookings/BookingInfoPage/components/BookingDetailsComponent";
export { default as ObjectionComponent } from "./pages/Bookings/BookingInfoPage/components/ObjectionComponent";

//Page
export { default as ReviewPage } from "./pages/Bookings/BookingInfoPage/ReviewPage";

//Page
export { default as ComplaintPage } from "./pages/Bookings/BookingInfoPage/ComplaintPage";

//Page
export { default as RequestPage } from "./pages/Bookings/BookingInfoPage/RequestPage";

//Page
export { default as ComplaintsOverviewPage } from "./pages/AllComplaintsPage/AllComplaintsPage";

//Page
export { default as RequestOverviewPage } from "./pages/AllRequestsPage/AllRequestsPage";

//Modal
export { default as Modal } from "./components/Modal/Modal";

//component
export { default as UnderDevelopment } from "./components/UnderDevelopment/UnderDevelopment";

//Page
export { default as About } from "./pages/StaticPages/About/About";
export { default as MissionVision } from "./pages/StaticPages/MissionVision/MissionVision";
export { default as CoreValues } from "./pages/StaticPages/CoreValues/CoreValues";
export { default as HowHuzWorks } from "./pages/StaticPages/HowHuzWorks/HowHuzWorks";
export { default as HuzPartnerPage } from "./pages/StaticPages/HuzPartner/HuzPartnerPage";
export { default as Career } from "./pages/StaticPages/CareerPage/Career";
export { default as JobOne } from "./pages/StaticPages/CareerPage/JobOne";
export { default as ApplyForm } from "./pages/StaticPages/CareerPage/ApplyForm";
export { default as Contactus } from "./pages/Contactus/Contactus";
export { default as HajjGuidance } from "./pages/StaticPages/Guidance/HajjGuidance/HajjGuidance";
export { default as UmrahGuidance } from "./pages/StaticPages/Guidance/UmrahGuidance/UmrahGuidance";
export { default as BrowsingTipsPage } from "./pages/StaticPages/BrowsingTipsPage/BrowsingTipsPage";
export { default as TravelEssential } from "./pages/StaticPages/TravelEssential/TravelEssential";
export { default as FQA } from "./pages/StaticPages/FrequentlyAskedQuestions/FQA";
export { default as Legal } from "./pages/StaticPages/Legal/Legal";
export { default as TermsServices } from "./pages/StaticPages/TermsServices/TermsServices";
export { default as RefundPolicy } from "./pages/StaticPages/RefundPolicy/RefundPolicy";
export { default as PrivacyPolicy } from "./pages/StaticPages/PrivacyPolicy/PrivacyPolicy";
export { default as CancellationPolicy } from "./pages/StaticPages/CancellationPolicy/CancellationPolicy";
export { default as Concierge } from "./pages/StaticPages/Concierge/Consierge";
export { default as Medical } from "./pages/StaticPages/Medical/Medical";
export { default as Insurance } from "./pages/StaticPages/Insurance/Insurance";
export { default as HelpCenter } from "./pages/StaticPages/SupportPage/HelpCenter";
//component
export { default as ButtonsLoader } from "./components/ButtonsLoader/ButtonsLoader";

//pages
export { default as VisaServices } from "./pages/VisaServices/VisaServices";
export { default as ConfirmVisa } from "./pages/VisaServices/ConfirmVisa/ConfirmVisa";

//component
export { default as PaymentOption } from "./pages/PaymentPage/components/PaymentOption";
export { default as JazzInfo } from "./pages/PaymentPage/components/JazzInfo";
export { default as VoucherInfo } from "./pages/PaymentPage/components/VoucherInfo";

//pages
export { default as AllBookings } from "./pages/Bookings/AllBookings";

//component
export { default as DocumentUpload } from "./pages/Bookings/BookingInfoPage/components/DocumentUpload/DocumentUpload";
export { default as PassportUpload } from "./pages/Bookings/BookingInfoPage/components/DocumentUpload/PassportUpload";

//pages
export { default as RewardsWallet } from "./pages/RewardsWallet/RewardsWallet";
export { default as ListPackagePage } from "./pages/ListPackagePage/ListPackagePage";
//component
export { default as CommunityPoliciesPage } from "./pages/StaticPages/SupportPage/Guest/CommunityPolicies";
export { default as ExploreMore } from "./pages/StaticPages/SupportPage/Guest/ExploreMore";
export { default as GetStarted } from "./pages/StaticPages/SupportPage/Guest/GetStarted";
export { default as ManageAccount } from "./pages/StaticPages/SupportPage/Guest/ManageAccount";
export { default as Reservation } from "./pages/StaticPages/SupportPage/Guest/Reservation";
export { default as GettingPaidPage } from "./pages/StaticPages/SupportPage/Host/GettingPaid";
export { default as HelpWithHosting } from "./pages/StaticPages/SupportPage/Host/HelpWithHosting";
export { default as AirCoverPage } from "./pages/StaticPages/SupportPage/Host/AirCoverPage";
export { default as PersonalDetailsPage } from "./pages/UserSetting/PersonalDetailsPage";

export { default as Loader } from "./components/Loader/Loader";
//pages
export { default as Articles } from "./pages/ZiyaratArticlesPages/Articles";
export { default as Makkah } from "./pages/ZiyaratArticlesPages/Makkah/Makkah";
export { default as Madinah } from "./pages/ZiyaratArticlesPages/Madinah/Madinah";
export { default as Jeddah } from "./pages/ZiyaratArticlesPages/Jeddah/Jeddah";
export { default as Taif } from "./pages/ZiyaratArticlesPages/Taif/Taif";
//Places Pages
//Makkah Places
export { default as Kabba } from "./pages/StaticPages/ZiyaratPlaces/MakkahPlaces/Kaaba/Kabba";
export { default as SafaMarwa } from "./pages/StaticPages/ZiyaratPlaces/MakkahPlaces/SafaMarwa/SafaMarwa";
export { default as Mina } from "./pages/StaticPages/ZiyaratPlaces/MakkahPlaces/Mina/Mina";
export { default as Arafah } from "./pages/StaticPages/ZiyaratPlaces/MakkahPlaces/Arafah/Arafah";
export { default as Home } from "./pages/StaticPages/ZiyaratPlaces/MakkahPlaces/Ghar-e-hira/Home";
export { default as Muzdalifah } from "./pages/StaticPages/ZiyaratPlaces/MakkahPlaces/Muzdalifah/Muzdalifah";
export { default as MasjidNimrah } from "./pages/StaticPages/ZiyaratPlaces/MakkahPlaces/MasjidNimra/MasjidNimrah";
export { default as GharEsoor } from "./pages/StaticPages/ZiyaratPlaces/MakkahPlaces/Ghar-e-sur/GharEsoor";
export { default as MasjidAlKhayf } from "./pages/StaticPages/ZiyaratPlaces/MakkahPlaces/MasjidAlKhayf/MasjidAlKhayf";
export { default as AlHudaybiyah } from "./pages/StaticPages/ZiyaratPlaces/MakkahPlaces/Al-Hudaybiyah/AlHudaybiyah";
export { default as MakkahClockTower } from "./pages/StaticPages/ZiyaratPlaces/MakkahPlaces/MakkahClockTower/MakkahClockTower";
export { default as MakkahMuseum } from "./pages/StaticPages/ZiyaratPlaces/MakkahPlaces/MakkahMuseum/MakkahMuseum";
export { default as MasjidJin } from "./pages/StaticPages/ZiyaratPlaces/MakkahPlaces/MasjidJinn/MasjidJin";
export { default as House } from "./pages/StaticPages/ZiyaratPlaces/MakkahPlaces/Muhammad/House";
//Madinah Places
export { default as Wadijin } from "./pages/StaticPages/ZiyaratPlaces/MadinahPlaces/Wadijin/Wadijin";
export { default as UhudMountain } from "./pages/StaticPages/ZiyaratPlaces/MadinahPlaces/Uhud/UhudMountain";
export { default as BirGhars } from "./pages/StaticPages/ZiyaratPlaces/MadinahPlaces/BirGhars/BirGhars";
export { default as MasjidAbuBakar } from "./pages/StaticPages/ZiyaratPlaces/MadinahPlaces/MasjidAbuBakar/MasjidAbuBakar";
export { default as MasjidIjabah } from "./pages/StaticPages/ZiyaratPlaces/MadinahPlaces/MasjidIjabah/MasjidIjabah";
export { default as MasjidGhamama } from "./pages/StaticPages/ZiyaratPlaces/MadinahPlaces/MasjidGhamama/MasjidGhamama";
export { default as MasjidJummah } from "./pages/StaticPages/ZiyaratPlaces/MadinahPlaces/MasjidJummah/MasjidJummah";
export { default as MasjidMiqat } from "./pages/StaticPages/ZiyaratPlaces/MadinahPlaces/MasjidMiqat/MasjidMiqat";
export { default as MasjidMustarah } from "./pages/StaticPages/ZiyaratPlaces/MadinahPlaces/MasjidMustarah/MasjidMustarah";
export { default as MasjidQiblatain } from "./pages/StaticPages/ZiyaratPlaces/MadinahPlaces/MasjidQiblatain/MasjidQiblatain";
export { default as MasjidQuba } from "./pages/StaticPages/ZiyaratPlaces/MadinahPlaces/MasjidQuba/MasjidQuba";
export { default as SalmanFarsiGarden } from "./pages/StaticPages/ZiyaratPlaces/MadinahPlaces/SalmanFarsiGarden/SalmanFarsiGarden";
//Jeddah Places
export { default as AlRehmanMosque } from "./pages/StaticPages/ZiyaratPlaces/JeddahPages/AlRehmanMosque/AlRehmanMosque";
export { default as KhadijahMosque } from "./pages/StaticPages/ZiyaratPlaces/JeddahPages/KhadijahMosque/KhadijahMosque";
export { default as HassanMosque } from "./pages/StaticPages/ZiyaratPlaces/JeddahPages/HassanMosque/HassanMosque";
export { default as KhalidMosque } from "./pages/StaticPages/ZiyaratPlaces/JeddahPages/KhalidMosque/KhalidMosque";
export { default as TurkeyMosque } from "./pages/StaticPages/ZiyaratPlaces/JeddahPages/TurkeyMosque/TurkeyMosque";
export { default as AbuBakarMosque } from "./pages/StaticPages/ZiyaratPlaces/JeddahPages/AbuBakarMosque/AbuBakarMosque";
export { default as NassifHouseMuseum } from "./pages/StaticPages/ZiyaratPlaces/JeddahPages/Nassif-House-Museum/NassifHouseMuseum";
export { default as GlobeRoundAbout } from "./pages/StaticPages/ZiyaratPlaces/JeddahPages/Globe-Roundabout/GlobeRoundAbout";
export { default as SouqAlAlawi } from "./pages/StaticPages/ZiyaratPlaces/JeddahPages/Souq-al-Alawi/SouqAlAlawi";
export { default as TaybatMuseum } from "./pages/StaticPages/ZiyaratPlaces/JeddahPages/TaybatMuseum/TaybatMuseum";
export { default as FlagPole } from "./pages/StaticPages/ZiyaratPlaces/JeddahPages/FlagPole/FlagPole";
export { default as AlBalad } from "./pages/StaticPages/ZiyaratPlaces/JeddahPages/Al-Balad/AlBalad";
//Taif Places
export { default as MasjidAbdulahBinAbbas } from "./pages/StaticPages/ZiyaratPlaces/TaifPages/Abdullah-Bin-Al-Abbas/MasjidAbdulahBinAbbas";
export { default as HalimaSadiaHouse } from "./pages/StaticPages/ZiyaratPlaces/TaifPages/HalimaSadia/HalimaSadiaHouse";
export { default as MasjidAlQantara } from "./pages/StaticPages/ZiyaratPlaces/TaifPages/Masjid-al-Qantara/MasjidAlQantara";
export { default as ALHadaMountain } from "./pages/StaticPages/ZiyaratPlaces/TaifPages/Al-Hada-Mountain/ALHadaMountain";
export { default as ShafaMountain } from "./pages/StaticPages/ZiyaratPlaces/TaifPages/Shafa-Mountain/ShafaMountain";
export { default as ArruddafPark } from "./pages/StaticPages/ZiyaratPlaces/TaifPages/Arruddaf-Park/ArruddafPark";
export { default as ShubraPalace } from "./pages/StaticPages/ZiyaratPlaces/TaifPages/ShubraPalace/ShubraPalace";
export { default as TaifRoseFields } from "./pages/StaticPages/ZiyaratPlaces/TaifPages/RoseFields/TaifRoseFields";
export { default as SaiysadPark } from "./pages/StaticPages/ZiyaratPlaces/TaifPages/Saiysad-Park/SaiysadPark";
export { default as Taifzoo } from "./pages/StaticPages/ZiyaratPlaces/TaifPages/TaifZoo/Taifzoo";
export { default as KingFahad } from "./pages/StaticPages/ZiyaratPlaces/TaifPages/KingFahad/KingFahad";

//pages
export { default as SafetyPage } from "./pages/ServicesPages/SafetyPage";
export { default as CustomerSupport } from "./pages/ServicesPages/CustomerSupport";
export { default as FraudProtection } from "./pages/ServicesPages/FraudProtection";
