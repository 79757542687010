import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { AiOutlineMenu, AiOutlineClose, AiOutlineUser } from "react-icons/ai";
import { motion } from "framer-motion";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useCurrency } from "../../context/CurrencyContext";
import AuthContext from "../../context/AuthContext";
import { Login, Modal, SignUp } from "../../index";
import { useModal } from "../../context/ModalContext";
import { useClickOutside } from "../../Utilities/UtilityFunctions";
import { Globe } from "lucide-react";
import logoIcon from "../../assets/logoIcon.png"; // Ensure this import is correct

const navLinks = [
  { href: "/listing-page", label: "Hajj", type: "Hajj" },
  { href: "/listing-page", label: "Umrah", type: "Umrah" },
  { href: "/visa-services", label: "Visa" },
];

const languages = [
  { code: "en", label: "English" },
  { code: "ar", label: "عَرَبِيّ" },
  { code: "ur", label: "اردو" },
];

const currencies = ["PKR", "SAR", "USD"];

const Dropdown = ({ items, selectedLanguage, selectedCurrency, className }) => (
  <div
    className={`absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-md py-2 z-50 ${className}`}
  >
    {items.map((item, index) => {
      if (item.type === "separator") {
        return (
          <div
            key={`separator-${index}`}
            className="border-b border-gray-300 my-2"
          />
        );
      }
      const isSelected =
        item.type === "language"
          ? item.value === selectedLanguage
          : item.value === selectedCurrency;
      return (
        <button
          key={item.value}
          onClick={item.action}
          className={`block w-full px-4 py-2 text-sm text-left ${
            isSelected ? "underline" : "text-gray-700 hover:bg-gray-100"
          }`}
        >
          {item.label}
        </button>
      );
    })}
  </div>
);

export default function Header() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { login, signOut } = useContext(AuthContext);
  const { setIsModalOpen } = useModal();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [guestMenuOpen, setGuestMenuOpen] = useState(false);
  const [isLangCurrencyOpen, setIsLangCurrencyOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || "en"
  );
  const authRef = useRef(null);
  const langCurrencyRef = useRef(null);

  const isHome = pathname === "/"; // Define isHome here

  useClickOutside(langCurrencyRef, () => setIsLangCurrencyOpen(false));

  useEffect(() => {
    const handleAuthOutsideClick = (event) => {
      if (authRef.current && !authRef.current.contains(event.target)) {
        setUserMenuOpen(false);
        setGuestMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleAuthOutsideClick);
    return () =>
      document.removeEventListener("mousedown", handleAuthOutsideClick);
  }, []);

  useEffect(() => {
    if (selectedLanguage !== "en") {
      window.doGTranslate(`en|${selectedLanguage}`);
    }
  }, [selectedLanguage]);

  const linkClass = useCallback(
    (href) =>
      `text-sm font-medium leading-6 ${
        pathname === href
          ? "text-primaryGray"
          : isHome
          ? "text-white hover:text-brandGold"
          : "text-primaryGray hover:text-brandGold"
      }`,
    [isHome, pathname]
  );

  const handleNav = (href, type) => {
    navigate(type ? `${href}?type=${type}` : href);
    setIsMenuOpen(false);
  };

  const handleLanguageSelect = (langCode) => {
    localStorage.setItem("selectedLanguage", langCode);
    setSelectedLanguage(langCode);
    window.doGTranslate(`en|${langCode}`);
  };

  // Use this instead (from context):
  const { currency, setCurrency, loading } = useCurrency();

  // Update the handleCurrencySelect to use context setter
  const handleCurrencySelect = (newCurrency) => {
    localStorage.setItem("selectedCurrency", newCurrency);
    setCurrency(newCurrency); // This updates the context
  };

  const openModal = (type) => {
    setModalType(type);
    setIsModalOpen(true);
    setIsLangCurrencyOpen(false);
  };

  const globeItems = [
    ...languages.map((lang) => ({
      type: "language",
      value: lang.code,
      label: lang.label,
      action: () => handleLanguageSelect(lang.code),
    })),
    { type: "separator" },
    ...currencies.map((curr) => ({
      type: "currency",
      value: curr,
      label: curr,
      action: () => handleCurrencySelect(curr),
    })),
  ];

  const [modalType, setModalType] = useState(null);
  const modalContent = {
    login: (
      <Login
        closeModal={() => setModalType(null)}
        openSignUpModal={() => openModal("signup")}
      />
    ),
    signup: (
      <SignUp
        closeModal={() => setModalType(null)}
        openLoginModal={() => openModal("login")}
      />
    ),
  };

  const initials =
    login &&
    JSON.parse(localStorage.getItem("Login-User-Profile"))
      ?.name?.split(" ")
      .map((word) => word[0])
      .join("")
      .toUpperCase();

  return (
    <header className="w-all-sections-width mx-auto flex items-center justify-between py-4 sm:py-6 md:py-8">
      <div className="flex items-center gap-4">
        <img src={logoIcon} alt="Logo" className="w-8 h-10" />
        <NavLink
          to="/"
          className={`text-xl sm:text-2xl lg:text-3xl font-medium notranslate ${
            isHome ? "text-white" : "text-black"
          }`}
          translate="no"
        >
          HajjUmrah.co
        </NavLink>
      </div>

      {/* Desktop Navigation */}
      <nav className="hidden xl:flex gap-8 lg:gap-10">
        {navLinks.map(({ href, label, type }) => (
          <button
            key={label}
            onClick={() => handleNav(href, type)}
            className={`${linkClass(href)} xl:text-lg`}
          >
            {label}
          </button>
        ))}
      </nav>

      {/* Desktop Icons */}
      <div className="flex items-center gap-6 xl:gap-8">
        <a
          href="/list-your-packages"
          className={`${linkClass()} xl:text-lg hidden xl:flex`}
        >
          List your Package
        </a>

        {/* Mobile Menu Button (Hamburger) */}
        <button
          onClick={() => setIsMenuOpen((prev) => !prev)}
          className={`xl:hidden ${isHome ? "text-white" : "text-black"}`}
        >
          {isMenuOpen ? (
            <AiOutlineClose size={24} />
          ) : (
            <AiOutlineMenu size={24} />
          )}
        </button>

        <div
          className="relative flex items-center notranslate"
          ref={langCurrencyRef}
          translate="no"
        >
          {/* Globe Icon */}
          <button
            onClick={() => setIsLangCurrencyOpen((prev) => !prev)}
            className={linkClass()}
          >
            <Globe className="w-6 h-6" />
          </button>

          {isLangCurrencyOpen && (
            <Dropdown
              items={globeItems}
              selectedLanguage={selectedLanguage}
              selectedCurrency={currency}
              className="absolute top-full mt-3 right-0 w-48 bg-white shadow-lg rounded-md py-2 z-50 notranslate"
              translate="no"
            />
          )}
        </div>

        {login ? (
          <div className="relative" ref={authRef}>
            <button
              onClick={() => setUserMenuOpen((prev) => !prev)}
              className="w-10 h-10 flex items-center justify-center bg-brandGold rounded-full notranslate"
              translate="no"
            >
              <span
                className="font-bold text-xs text-white notranslate"
                translate="no"
              >
                {initials}
              </span>
            </button>
            {userMenuOpen && (
              <Dropdown
                items={[
                  {
                    value: "bookings",
                    label: "All bookings",
                    action: () => navigate("/all-bookings"),
                  },
                  {
                    value: "complaints",
                    label: "All Complaints",
                    action: () => navigate("/complaints-list"),
                  },
                  {
                    value: "wallet",
                    label: "Rewards and Wallet",
                    action: () => navigate("/account-rewards&wallet"),
                  },
                  {
                    value: "help",
                    label: "Help Center",
                    action: () => navigate("/help-center"),
                  },
                  {
                    value: "account",
                    label: "User Account",
                    action: () => navigate("/personal-details"),
                  },
                  { value: "signout", label: "Sign Out", action: signOut },
                ]}
                selected={null}
              />
            )}
          </div>
        ) : (
          <div className="relative" ref={authRef}>
            <AiOutlineUser
              onClick={() => setGuestMenuOpen((prev) => !prev)}
              size={33}
              className="p-2 bg-brandGold text-white rounded-3xl"
            />
            {guestMenuOpen && (
              <Dropdown
                items={[
                  {
                    value: "login",
                    label: "Login",
                    action: () => openModal("login"),
                  },
                  {
                    value: "signup",
                    label: "Sign Up",
                    action: () => openModal("signup"),
                  },
                ]}
                selected={null}
              />
            )}
          </div>
        )}
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <motion.div
          initial={{ height: 0 }}
          animate={{ height: "auto" }}
          transition={{ duration: 0.3 }}
          className={`absolute top-20 left-0 w-full pb-6 xl:hidden z-50 flex flex-col items-center ${
            isHome
              ? "bg-black bg-opacity-80 text-white"
              : "bg-white text-lightGray"
          }`}
        >
          {/* Map through the navLinks for mobile menu */}
          {navLinks.map(({ href, label, type }) => (
            <button
              key={label}
              onClick={() => handleNav(href, type)} // Handle navigation for each link
              className={`w-full py-4 ${linkClass(href)} text-center`} // Ensure full-width buttons
            >
              {label}
            </button>
          ))}

          {/* List your Package button */}
          <a
            href="https://operator.hajjumrah.co/"
            className={`w-full py-4 ${linkClass()} text-center`} // Full-width for "List your Package"
          >
            List your Package
          </a>
        </motion.div>
      )}

      <Modal isOpen={!!modalType} onClose={() => setModalType(null)}>
        {modalContent[modalType]}
      </Modal>
    </header>
  );
}
