import React, { useEffect } from "react";
import { Footer, Header } from "../../../";

const ApplyForm = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  return (
    <div>
      <Header />
      <main class="main bg-white px-6 md:px-16 py-6 text-[#4B465C]">
        <div class="w-full max-w-xl mx-auto">
          <form action="" method="post">
            <h1 class="text-2xl mb-2 font-bold">Apply For Job</h1>
            <div class="job-info border-b-2 py-2 mb-5">
              {/* <!--    Title      --> */}
              <div class="mb-4">
                <label class="block text-sm mb-2 font-semibold" for="job-title">
                  Title
                </label>
                <input
                  class="appearance-none block w-full bg-white border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none"
                  type="email"
                  id="job-title"
                  name="job-title"
                  placeholder="Frontend Developer"
                  autofocus
                />
              </div>

              {/* <!--     Link to apply     --> */}
              <div class="mb-4">
                <label class="block text-sm mb-2" for="apply-link">
                  Link to apply
                </label>
                <input
                  class="appearance-none block w-full border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none"
                  type="email"
                  id="apply-link"
                  name="apply-link"
                  placeholder="https://www.djangoproject.com/apply"
                />
              </div>

              <div class="md:flex md:justify-between">
                {/* <!--      Job Type      --> */}
                <div class="w-full md:w-3/12 mb-4 md:mb-0">
                  <label class="block text-sm mb-2" for="job-type">
                    Job Type
                  </label>
                  <div class="relative">
                    <select
                      class="block appearance-none w-full bg-white border border-gray-400 py-3 px-4 pr-8 rounded leading-tight focus:outline-none"
                      id="job-type"
                      name="job-type"
                    >
                      <option>Full-time</option>
                      <option>Part-time</option>
                      <option>Freelance</option>
                      <option>Contract</option>
                    </select>

                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
                      <svg
                        class="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </div>

                {/* <!--     Location       --> */}
                <div class="w-full md:w-8/12 mb-4 md:mb-0">
                  <label for="location" class="block text-sm mb-2">
                    Location
                  </label>
                  <input
                    type="text"
                    class="appearance-none block w-full bg-white border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none"
                    id="location"
                    name="location"
                    placeholder="Schwerin"
                  />

                  <div>
                    <label class="text-gray-600 flex items-center" for="remote">
                      <input
                        class="mr-2 leading-tight"
                        type="checkbox"
                        id="remote"
                      />
                      <span class="text-sm">Work can be done remotely</span>
                    </label>
                  </div>
                </div>
              </div>
              {/* <!-- end group --> */}

              {/* <!--    Description      --> */}
              <div>
                <label for="description" class="block text-sm mb-2">
                  Description
                </label>
                <textarea
                  name="description"
                  id="description"
                  rows={6}
                  placeholder="Job description"
                  className="border border-gray-400 rounded-lg p-2.5 w-full"
                ></textarea>
              </div>
              {/* cols="75" rows="7" */}

              <div class="flex flex-wrap -mx-3">
                {/* <!--     Company       --> */}
                <div class="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                  <label for="company" class="block text-gray-700 text-sm mb-2">
                    Company
                  </label>
                  <input
                    type="text"
                    class="appearance-none block w-full bg-white border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none"
                    id="company"
                    name="company"
                    placeholder="Company"
                  />
                </div>

                {/* <!--      Company Website      --> */}
                <div class="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                  <label for="company" class="block text-sm mb-2">
                    Company Website
                  </label>
                  <input
                    type="text"
                    class="appearance-none block w-full bg-white border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none"
                    id="company"
                    name="company"
                    placeholder="https://www.djangoproject.com/"
                  />
                </div>
              </div>
              {/* <!-- end group --> */}

              {/* <!--      Company Website      --> */}
              <div class="mb-4 md:mb-0">
                <label
                  for="company-logo"
                  class="block text-gray-700 text-sm mb-2"
                >
                  Logo Image
                </label>
                <input
                  type="file"
                  class="appearance-none block w-full bg-gray-200 border border-gray-200 rounded py-3 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="company-logo"
                  name="company-logo"
                />
              </div>
              {/* </div> <!-- end job-info --> */}
            </div>
            <div>
              <button
                class="bg-[#00936C] hover:bg-[#00936ce1] font-cairo font-semibold text-white py-2 px-3 rounded"
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ApplyForm;
