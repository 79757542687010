import React, { useEffect } from "react";
import Banner from "../../HeroSection/Banner";
import HeaderFooter from "../../../../ZiyaratArticlesPages/HeaderFooter";
import Slider from "../../MadinahPlaces/Wadijin/Slider";
import Page from "./Page";
import MadhounHouseImg1 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/madhounHouse/image1.jpeg";
import MadhounHouseImg2 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/madhounHouse/image2.webp";
import MadhounHouseImg3 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/madhounHouse/image3.jpg";
import MadhounHouseImg4 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/madhounHouse/image4.webp";
const MasjidAlQantara = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const images = [
    MadhounHouseImg1,
    MadhounHouseImg2,
    MadhounHouseImg3,
    MadhounHouseImg4,
  ];

  return (
    <div>
      <HeaderFooter>
        <Banner image={MadhounHouseImg3} title={"Masjid Al Qantara"} />
        <Page />
        <Slider images={images} />
      </HeaderFooter>
    </div>
  );
};

export default MasjidAlQantara;
