import React, { useEffect } from "react";
import HeaderFooter from "../../../../ZiyaratArticlesPages/HeaderFooter";
import MainPage from "./MainPage";
import Slider from "./Slider";
import Banner from "../../HeroSection/Banner";
import bg from "../../../../../assets/ZiyaratPlaces/Madinah/wadijin/bannerbg.png";
import WadiJinImg1 from "../../../../../assets/ZiyaratPlaces/Madinah/wadijin/sliderimg1.jpg";
import WadiJinImg2 from "../../../../../assets/ZiyaratPlaces/Madinah/wadijin/sliderimg2.jpg";
import WadiJinImg3 from "../../../../../assets/ZiyaratPlaces/Madinah/wadijin/sliderimg3.jpg";
import WadiJinImg4 from "../../../../../assets/ZiyaratPlaces/Madinah/wadijin/sliderimg4.jpg";
import WadiJinImg5 from "../../../../../assets/ZiyaratPlaces/Madinah/wadijin/sliderimg5.jpg";
const Wadijin = () => {
  const images = [
    WadiJinImg1,
    WadiJinImg2,
    WadiJinImg3,
    WadiJinImg4,
    WadiJinImg5,
  ];
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  return (
    <div>
      <HeaderFooter>
        <Banner image={bg} title={"Wadi-e-Jinn"} />
        <MainPage />
        <Slider images={images} />
      </HeaderFooter>
    </div>
  );
};

export default Wadijin;
