// data.js
//Ghar e Hira
import GharEHiraImg1 from "../assets/ZiyaratPlaces/Makkah/Ghar-e-Hira/gharslider1.jpg";
import GharEHiraImg2 from "../assets/ZiyaratPlaces/Makkah/Ghar-e-Hira/gharslider2.jpg";
import GharEHiraImg3 from "../assets/ZiyaratPlaces/Makkah/Ghar-e-Hira/gharslider3.jpg";
import GharEHiraImg4 from "../assets/ZiyaratPlaces/Makkah/Ghar-e-Hira/gharslider4.jpg";
import gharEHiraImg5 from "../assets/ZiyaratPlaces/Makkah/Ghar-e-Hira/gharslider5.jpg";
import GharEHiraImg6 from "../assets/ZiyaratPlaces/Makkah/Ghar-e-Hira/hira.svg";
//Ghar e Soor
import GharESoorImg1 from "../assets/ZiyaratPlaces/Makkah/Ghar-e-Sur/surone.svg";
import GharESoorImg2 from "../assets/ZiyaratPlaces/Makkah/Ghar-e-Sur/surtwo.svg";
import GharESoorImg3 from "../assets/ZiyaratPlaces/Makkah/Ghar-e-Sur/surthree.svg";
import GharESoorImg4 from "../assets/ZiyaratPlaces/Makkah/Ghar-e-Sur/surfour.svg";
import GharESoorImg5 from "../assets/ZiyaratPlaces/Makkah/Ghar-e-Sur/gharsur.svg";
//Prophet House
import ProphetImg1 from "../assets/ZiyaratPlaces/Makkah/ProphetHouse/muhammad1.svg";
import ProphetImg2 from "../assets/ZiyaratPlaces/Makkah/ProphetHouse/muhammad2.svg";
import ProphetImg3 from "../assets/ZiyaratPlaces/Makkah/ProphetHouse/muhammad3.svg";
import ProphetImg4 from "../assets/ZiyaratPlaces/Makkah/ProphetHouse/prophet1.jpg";
import ProphetImg5 from "../assets/ZiyaratPlaces/Makkah/ProphetHouse/prophet2.jpg";
//Nimrah Mosque
import NimrahMosqueImg1 from "../assets/ZiyaratPlaces/Makkah/Namirah/namirah1.jpg";
import NimrahMosqueImg2 from "../assets/ZiyaratPlaces/Makkah/Namirah/namirah2.jpg";
import NimrahMosqueImg3 from "../assets/ZiyaratPlaces/Makkah/Namirah/namirah3.jpg";
import NimrahMosqueImg4 from "../assets/ZiyaratPlaces/Makkah/Namirah/namirah4.jpeg";
//Al Hudaybiyah Images
import AlHudaybiyahImg1 from "../assets/ZiyaratPlaces/Makkah/AlHudaybiyah/huda1.svg";
import AlHudaybiyahImg2 from "../assets/ZiyaratPlaces/Makkah/AlHudaybiyah/huda2.svg";
import AlHudaybiyahImg3 from "../assets/ZiyaratPlaces/Makkah/AlHudaybiyah/huda3.png";
//Masjid Jinn Images
import MasjidJinnImg1 from "../assets/ZiyaratPlaces/Makkah/MasjidJinn/MasjidJinn.webp";
import MasjidJinnImg2 from "../assets/ZiyaratPlaces/Makkah/MasjidJinn/MasjidJinn1.svg";
import MasjidJinnImg3 from "../assets/ZiyaratPlaces/Makkah/MasjidJinn/MasjidJinn2.svg";
import MasjidJinnImg4 from "../assets/ZiyaratPlaces/Makkah/MasjidJinn/MasjidJinn3.svg";
import MasjidJinnImg5 from "../assets/ZiyaratPlaces/Makkah/MasjidJinn/MasjidJinn5.webp";
//Masjid Miqat Images
import MasjidMiqatImg5 from "../assets/ZiyaratPlaces/Madinah/miqat/miqat1.svg";
import MasjidMiqatImg6 from "../assets/ZiyaratPlaces/Madinah/miqat/miqat2.svg";
import MasjidMiqatImg7 from "../assets/ZiyaratPlaces/Madinah/miqat/miqat3.svg";
import MasjidMiqatImg8 from "../assets/ZiyaratPlaces/Madinah/miqat/miqat5.jpg";
import MasjidMiqatImg9 from "../assets/ZiyaratPlaces/Madinah/miqat/miqat6.webp";
//Masjid Mustarah Images
import MasjidMustarahImg5 from "../assets/ZiyaratPlaces/Madinah/mustarah/MasjidMustarah1.svg";
import MasjidMustarahImg6 from "../assets/ZiyaratPlaces/Madinah/mustarah/MasjidMustarah2.svg";
import MasjidMustarahImg7 from "../assets/ZiyaratPlaces/Madinah/mustarah/MasjidMustarah3.svg";
import MasjidMustarahImg8 from "../assets/ZiyaratPlaces/Madinah/mustarah/MasjidMustarah4.svg";
import MasjidMustarahImg9 from "../assets/ZiyaratPlaces/Madinah/mustarah/MasjidMustarah5.jpg";
import MasjidMustarahImg10 from "../assets/ZiyaratPlaces/Madinah/mustarah/MasjidMustarah6.jpg";
//Masjid Ghamama Images
import MasjidGhamamaImg1 from "../assets/ZiyaratPlaces/Madinah/Ghamamah/MasjidGhamama1.svg";
export const gharslider = {
  image1: GharEHiraImg1,
  image2: GharEHiraImg2,
  image3: GharEHiraImg3,
  image4: GharEHiraImg4,
  image5: gharEHiraImg5,
};

export const tabicon = {
  image: "/images/holysites.png",
  image2: "/images/grandmosque.png",
  image3: "/images/mosqueservices.svg",
  image4: "/images/attractionlogo.svg",
  image5: "/images/accom.png",
  image6: "/images/shopping.png",
  image7: "/images/cafes.png",
  image8: "/images/mecca.png",
};

export const pageOneContent = {
  hero: {
    image: GharEHiraImg6,
    heading: "Ghar e Hira",
    description: "5 minutes read - April 2024",
  },
  history: {
    image: GharESoorImg1,
  },
  why: {
    image: GharESoorImg2,
  },
  pilgrimge: {
    image: GharESoorImg3,
  },

  pilgrimge2: {
    image: GharESoorImg4,
  },
};

export const gharSur = {
  hero: {
    image: GharESoorImg5,
    heading: "Ghar e Sur",
  },
  history: {
    image: GharESoorImg1,
  },
  why: {
    image: GharESoorImg2,
  },
  pilgrimage: {
    image: GharESoorImg3,
  },
  pilgrimage2: {
    image: GharESoorImg4,
  },
};

export const muhammad = {
  hero: {
    image: ProphetImg1,
    subHeading: "Home of Hazrat",
    heading: "Holy Prophet Hazrat Muhammad (S.A.W) House",
    subHeading2: "(SAW)",
    description: "5 minutes read - April 2024",
  },
  history: {
    image: ProphetImg2,
  },
  why: {
    image: ProphetImg3,
  },
  pilgrimge: {
    image: ProphetImg4,
  },
  pilgrimge2: {
    image: ProphetImg5,
  },
};

export const alHudaybiyah = {
  hero: {
    image: AlHudaybiyahImg1,
    subHeading: "Treaty of",
    heading: "Al-Hudaybiyah",
    description: "5 minutes read - April 2024",
  },
  history: {
    image: AlHudaybiyahImg2,
  },
  why: {
    image: AlHudaybiyahImg3,
  },
};

// export const uhud = {
//   hero: {
//     image: "/images/uhud1.svg",
//     subHeading: "Battle of",
//     heading: "Uhud",
//     description: "5 minutes read - April 2024",
//   },
//   history: {
//     image: "/images/uhud2.svg",
//   },
//   why: {
//     image: "/images/uhud3.svg",
//   },
//   pilgrimge: {
//     image: "/images/uhud4.webp",
//   },
// };

export const MasjidMiqat = {
  hero: {
    image: MasjidMiqatImg5,
    heading: "Masjid Miqat",
    subHeading2: "(Dhul Hulaifah)",
    description: "5 minutes read - April 2024",
  },
  history: {
    image: MasjidMiqatImg6,
  },
  why: {
    image: MasjidMiqatImg7,
  },
  pilgrimge: {
    image: MasjidMiqatImg8,
  },
  pilgrimge2: {
    image: MasjidMiqatImg9,
  },
};

export const MasjidGhamama = {
  hero: {
    image: MasjidGhamamaImg1,
    heading: "Masjid al-Ghamama",
    description: "5 minutes read - April 2024",
  },
  // history: {
  //   image: "/images/MasjidGhamama2.svg",
  // },
  // why: {
  //   image: "/images/MasjidGhamama3.svg",
  // },
  // pilgrimge: {
  //   image: "/images/MasjidGhamama4.svg",
  // },
  // pilgrimge2: {
  //   image: "/images/MasjidGhamama5.jpg",
  // },
  // pilgrimge3: {
  //   image: "/images/MasjidGhamama6.webp",
  // },
};

export const MasjidMustarahh = {
  hero: {
    image: MasjidMustarahImg5,
    heading: "Masjid al-Mustarah",
    description: "5 minutes read - April 2024",
  },
  history: {
    image: MasjidMustarahImg6,
  },
  why: {
    image: MasjidMustarahImg7,
  },
  pilgrimge: {
    image: MasjidMustarahImg8,
  },
  pilgrimge2: {
    image: MasjidMustarahImg9,
  },
  pilgrimge3: {
    image: MasjidMustarahImg10,
  },
};

export const MasjidJinn = {
  hero: {
    image: MasjidJinnImg1,
    heading: "Masjid al-Jinn",
    description: "5 minutes read - April 2024",
  },
  history: {
    image: MasjidJinnImg2,
  },
  why: {
    image: MasjidJinnImg3,
  },
  masjidjinn1: {
    image: MasjidJinnImg4,
  },

  masjidjinn3: {
    image: MasjidJinnImg5,
  },
};

export const MasjidNimra = {
  hero: {
    image: NimrahMosqueImg1,
    heading: " Masjid-e-Nimra",
    description: "5 minutes read - April 2024",
  },
  history: {
    image: NimrahMosqueImg2,
  },
  why: {
    image: NimrahMosqueImg3,
  },
  pilgrimge: {
    image: NimrahMosqueImg4,
  },
  nimra1: {
    image: NimrahMosqueImg1,
  },
  nimra2: {
    image: NimrahMosqueImg2,
  },
  nimra3: {
    image: NimrahMosqueImg3,
  },
  nimra4: {
    image: NimrahMosqueImg4,
  },
  nimra5: {
    image: NimrahMosqueImg1,
  },
};
