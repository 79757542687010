import React, { useEffect } from "react";
import Banner from "../../HeroSection/Banner";
import HeaderFooter from "../../../../ZiyaratArticlesPages/HeaderFooter";
import Slider from "../../MadinahPlaces/Wadijin/Slider";
import Page from "./Page";
import KingFahadImg1 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/king-garden/image5.webp";
import KingFahadImg2 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/king-garden/image3.jpeg";
import KingFahadImg3 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/king-garden/image2.jpg";
import KingFahadImg4 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/king-garden/image1.jpg";
const KingFahad = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const images = [KingFahadImg1, KingFahadImg2, KingFahadImg3, KingFahadImg4];

  return (
    <div>
      <HeaderFooter>
        <Banner image={KingFahadImg4} title={"King Fahad Garden in Taif"} />
        <Page />
        <Slider images={images} />
      </HeaderFooter>
    </div>
  );
};

export default KingFahad;
