import React from "react";
import underDevelopment from "../../../assets/Booking/under_Development.png";
import { Footer, Header } from "../../../";
import { useNavigate } from "react-router-dom";
const TravelEssential = () => {
  const travelEssentials = [
    {
      title: "Documents",
      description:
        "Passport, visa, vaccination certificates, flight tickets, travel itinerary, and emergency contacts.",
    },
    {
      title: "Ihram Essentials",
      description:
        "Ihram garments for men, simple loose-fitting clothing for women, safety pins, and unscented toiletries.",
    },
    {
      title: "Clothing",
      description:
        "Comfortable clothing, headwear for sun protection, and comfortable sandals or shoes.",
    },
    {
      title: "Health and Hygiene",
      description:
        "Prescribed medicines, first-aid kit, face masks, hand sanitizer, and a refillable water bottle.",
    },
    {
      title: "Travel Accessories",
      description:
        "Small bag or waist pouch, prayer mat, Tawaf counter (optional), and luggage tags.",
    },
    {
      title: "Electronics",
      description:
        "Mobile phone, power bank, chargers, and adapters compatible with Saudi Arabia’s outlets.",
    },
    {
      title: "Food and Snacks",
      description:
        "Non-perishable snacks like energy bars, nuts, and reusable utensils.",
    },
    {
      title: "Other Essentials",
      description:
        "Notebook and pen, small umbrella for sun protection, and reading materials like Quran and dua books.",
    },
  ];

  return (
    <div className="text-secondaryBlue">
      <Header />
      <div className="w-all-sections-width mx-auto py-10">
        <h1 className="text-4xl font-bold text-center">
          Travel Essentials for Hajj and Umrah
        </h1>

        <div className="py-8 space-y-4">
          {/* Travel Essentials Section */}
          <section className="mb-8">
            <h2 className="text-[20px] mb-4 font-bold">
              Travel Essentials for Hajj and Umrah
            </h2>
            <ul className="list-disc list-inside ml-4 mt-2 space-y-1 text-[16px]">
              {travelEssentials.map((essential, index) => (
                <li key={index}>
                  <strong>{essential.title}:</strong> {essential.description}
                </li>
              ))}
            </ul>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TravelEssential;
