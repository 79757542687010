import React from "react";

const TaifHolySites = () => (
  <div className=" w-all-sections-width mx-auto py-5 pt-10">
    <h1 className="text-cairo font-semibold md:text-[25px] text-[30px] text-[#484848] py-2.5">
      Taif Holy Sites
    </h1>
    <p className="text-[16px] text-[#484848] text-left leading-[20px] font-kd">
      Discover the enchanting city of Taif, Saudi Arabia—a hidden gem nestled
      among majestic mountains. Famous for its fragrant roses, ancient forts,
      and vibrant markets, Taif offers a perfect blend of natural beauty and
      cultural richness. Explore winding streets scented with roses, delve into
      history at ancient sites, and embark on thrilling outdoor adventures.
      Whether you seek relaxation or excitement, Taif promises an unforgettable
      experience. Dive into the charm of Taif and create memories that last a
      lifetime.
    </p>
  </div>
);

export default TaifHolySites;
