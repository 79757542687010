import React, { useEffect } from "react";
import Banner from "../../HeroSection/Banner";
import HeaderFooter from "../../../../ZiyaratArticlesPages/HeaderFooter";
import Slider from "../../MadinahPlaces/Wadijin/Slider";
import Page from "./Page";
import AlBaladImg1 from "../../../../../assets/ZiyaratPlaces/Jeddah/al-balad/image5.webp"
import AlBaladImg2 from "../../../../../assets/ZiyaratPlaces/Jeddah/al-balad/image4.jpg"
import AlBaladImg3 from "../../../../../assets/ZiyaratPlaces/Jeddah/al-balad/image3.jpg"
import AlBaladImg4 from "../../../../../assets/ZiyaratPlaces/Jeddah/al-balad/image2.jpg"
import bg from "../../../../../assets/ZiyaratPlaces/Jeddah/al-balad/image1.webp"
const AlBalad = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const images = [
    AlBaladImg1,
    AlBaladImg2,
    AlBaladImg3,
    AlBaladImg4,
  ];

  return (
    <div className="">
      <HeaderFooter>
        <Banner
          image={bg}
          title={"Discovering Al Balad Jeddah"}
        />
        <Page />
        <Slider images={images} />
      </HeaderFooter>
    </div>
  );
};

export default AlBalad;
