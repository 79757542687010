import React from "react";
import underDevelopment from "../../assets/Booking/under_Development.png";
import { Footer, Header } from "../../";
import { useNavigate } from "react-router-dom";
const UnderDevelopment = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };
  return (
    <div>
      <Header />
      <div className="py-10 flex flex-col items-center justify-center bg-gray-100">
        <img
          src={underDevelopment}
          alt="Under Development"
          className="max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg"
        />
        <p className="text-4xl text-brandGold font-bold text-center mt-4">
          Under Development
        </p>
        <button
          onClick={handleClick}
          className="text-xl text-brandGold font-bold underline mt-4"
        >
          Go Back
        </button>
      </div>
      <Footer />
    </div>
  );
};

export default UnderDevelopment;
