import React from "react";
import { Footer, Header } from "../../../../";
import makkahImg from "../../../../assets/HelpPage/makkahimg.jpg";
import madinahImg from "../../../../assets/HelpPage/night.avif";
const ExploreMore = () => {
  return (
    <div className="bg-[#f6f6f6]">
      <Header />
      <div className="container w-all-sections-width mx-auto pt-10">
        <h1 className="text-[20px] font-kd mb-4 font-bold">
          Safety Tips and Guidelines
        </h1>

        {/* General Safety Tips */}
        <section className="mb-8">
          <h2 className="text-[20px] font-kd mb-4 font-bold">
            General Safety Tips
          </h2>
          <p>
            1. Research and choose reputable vendors with positive reviews and a
            track record of safe travel experiences.
          </p>
          <p>
            2. Check the latest travel advisories and guidelines issued by
            relevant authorities.
          </p>
          <p>
            3. Pack essential items such as comfortable footwear, sunscreen,
            hats, and a first aid kit.
          </p>
        </section>

        {/* Hajj and Umrah Specific Safety Tips */}
        <section className="mb-8">
          <h2 className="text-[20px] font-kd mb-4 font-bold">
            Hajj and Umrah Specific Safety Tips
          </h2>
          <p>
            1. Follow the guidelines provided by the Ministry of Hajj and Umrah
            of the respective country.
          </p>
          <p>
            2. Be aware of the potential risks associated with large crowds
            during religious rituals and take necessary precautions.
          </p>
          <p>
            3. Stay hydrated and carry water bottles during outdoor rituals,
            especially under the scorching sun.
          </p>
          <p>
            4. Keep your belongings secure at all times, as crowded areas may
            pose a risk of theft or loss.
          </p>
        </section>

        {/* First Aid Tips */}
        <section className="mb-8">
          <h2 className="text-[20px] font-kd mb-4 font-bold">First Aid Tips</h2>
          <p>
            1. Provide information on common health issues experienced during
            Hajj and Umrah, such as heatstroke, dehydration, and blisters, along
            with their prevention and treatment.
          </p>
          <p>
            2. Encourage travelers to carry essential medications, including
            pain relievers, antidiarrheals, and antiseptics, in their first aid
            kits.
          </p>
        </section>

        {/* Health and Safety Guidelines for Accommodations */}
        <section className="mb-8">
          <h2 className="text-[20px] font-kd mb-4 font-bold">
            Health and Safety Guidelines for Accommodations
          </h2>
          <p>
            1. Ensure accommodations meet safety standards and are located in
            secure areas.
          </p>
          <p>
            2. Provide guidance on fire safety procedures and emergency exits in
            hotels and lodgings.
          </p>
          <p>
            3. Emphasize the importance of proper hygiene and sanitation
            practices, especially during communal activities.
          </p>
        </section>

        {/* Interacting with Others */}
        <section className="mb-8">
          <h2 className="text-[20px] font-kd mb-4 font-bold">
            Safety Tips for Interacting with Others
          </h2>
          <p>
            1. Encourage respectful behavior and adherence to cultural norms
            when interacting with fellow travelers and locals.
          </p>
          <p>
            2. Provide tips on communicating effectively in diverse cultural
            settings to avoid misunderstandings or conflicts.
          </p>
        </section>

        {/* Safety Tips for Outdoor Activities and Excursions */}
        <section className="mb-8">
          <h2 className="text-[20px] font-kd mb-4 font-bold">
            Safety Tips for Outdoor Activities and Excursions
          </h2>
          <p>
            1. Offer guidance on safety measures for outdoor activities, such as
            hiking or visiting historical sites.
          </p>
          <p>
            2. Advise travelers to dress appropriately for the weather
            conditions and terrain, and to carry necessary equipment like hats,
            sunglasses, and walking sticks.
          </p>
        </section>

        {/* COVID-19 Precautions */}
        <section className="mb-8">
          <h2 className="text-[20px] font-kd mb-4 font-bold">
            COVID-19 Precautions
          </h2>
          <p>
            1. Provide information on current COVID-19 guidelines and
            regulations for travel and religious gatherings.
          </p>
          <p>
            2. Encourage travelers to follow recommended precautions such as
            wearing masks, practicing social distancing, and regularly
            sanitizing hands.
          </p>
        </section>

        {/* Emergency Contacts and Support */}
        <section className="mb-8">
          <h2 className="text-[20px] font-kd mb-4 font-bold">
            Emergency Contacts and Support
          </h2>
          <p>
            1. Provide emergency contact information for local authorities,
            medical facilities, and embassy or consulate services.
          </p>
          <p>
            2. Offer 24/7 customer support for travelers in case of emergencies
            or assistance needs.
          </p>
        </section>
        {/* Images */}
        <div className="md:flex gap-4 pb-10">
          <img
            src={makkahImg}
            alt="Hajj"
            className="w-1/2 rounded-lg shadow-md items-start"
          />
          <img
            src={madinahImg}
            alt="Umrah"
            className="w-1/2 rounded-lg shadow-md"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ExploreMore;
