import React, { useEffect } from "react";
import Banner from "../../HeroSection/Banner";
import HeaderFooter from "../../../../ZiyaratArticlesPages/HeaderFooter";
import Slider from "../../MadinahPlaces/Wadijin/Slider";
import Page from "./Page";
import TaybatMuseumImg1 from "../../../../../assets/ZiyaratPlaces/Jeddah/taybatmuseum/image6.jpg";
import TaybatMuseumImg2 from "../../../../../assets/ZiyaratPlaces/Jeddah/taybatmuseum/image5.jpg";
import TaybatMuseumImg3 from "../../../../../assets/ZiyaratPlaces/Jeddah/taybatmuseum/image4.jpg";
import TaybatMuseumImg4 from "../../../../../assets/ZiyaratPlaces/Jeddah/taybatmuseum/image3.webp";
const TaybatMuseum = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const images = [
    TaybatMuseumImg1,
    TaybatMuseumImg2,
    TaybatMuseumImg3,
    TaybatMuseumImg4,
  ];

  return (
    <div>
      <HeaderFooter>
        <Banner
          image={TaybatMuseumImg4}
          title={"Tayebat City International Museum"}
        />
        <Page />
        <Slider images={images} />
      </HeaderFooter>
    </div>
  );
};

export default TaybatMuseum;
