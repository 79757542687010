import React from "react";
import { Header, Footer } from "../../../../";
import makkahImg from "../../../../assets/HelpPage/makkah2.jpg";
function AirCoverPage() {
  return (
    <div className="">
      <Header />
      <div className="bg-gray-100 min-h-screen flex justify-center items-center">
        <div className=" w-all-sections-width mx-auto mt-10 py-8">
          <header className="text-center mb-8">
            <h1 className="text-3xl font-semibold text-gray-800">
              Getting protected through AirCover for Hosts
            </h1>
            <p className="text-lg text-gray-600">
              A colorful, modern title stating AirCover for Hosts in the center.
            </p>
          </header>

          <section className="rounded-lg">
            <p className="text-gray-700 mb-6">
              Sometimes accidents happen, which is why there’s AirCover for
              Hosts.
            </p>

            {/* Add a colorful, modern image or illustration related to AirCover for Hosts here */}
            <div className="flex justify-center mb-8">
              <img
                src={makkahImg}
                alt="AirCover for Hosts"
                className="rounded-lg w-[800px] h-[400px] object-cover"
              />
            </div>

            <p className="text-gray-700 mb-6">
              AirCover for Hosts is top-to-bottom protection for Hosts. It
              includes guest identity verification, reservation screening, $3M
              Host damage protection, $1M Host liability insurance, and a
              24-hour safety line. It also includes $1M Experiences liability
              insurance.
            </p>

            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              Need to start a claim?
            </h2>
            <p className="text-gray-700 mb-6">
              Damage protection covers you if a guest damages your place or
              belongings during an Airbnb stay. You’re reimbursed for certain
              damage caused by guests to your home and belongings if the guest
              does not pay for the damage. It also reimburses for extra cleaning
              services in certain cases, like removing stains left by guests (or
              their invitees) or pet accidents, smoke odor removal, or when
              required due to additional unapproved guests.
            </p>
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110">
              Start a reimbursement request
            </button>

            {/* Add more sections as needed */}
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AirCoverPage;
