import React from "react";
import { useLocation } from "react-router-dom";

const PackageHeader = ({ data }) => {
  const location = useLocation();
  const {
    package_name = "N/A",
    start_date = "",
    end_date = "",
    description = "",
    company_detail: {
      company_name = "N/A",
      company_bio = "No description available.",
    } = {},
  } = data || {};

  const formatDate = (date) =>
    date
      ? new Date(date).toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })
      : "N/A";

  const calculateNights = (start, end) => {
    if (!start || !end) return 0;
    const startDate = new Date(start);
    const endDate = new Date(end);
    const diffTime = endDate - startDate;
    return Math.floor(diffTime / (1000 * 60 * 60 * 24));
  };

  const nights = calculateNights(start_date, end_date);
  const showBio = location.pathname.includes("package-detail-page");

  return (
    <div className="mb-10">
      <div className="flex justify-between items-center">
        <div className="w-[80%]">
          <h1 className="text-xl sm:text-2xl md:text-3xl font-bold leading-[48px] text-left">
            {package_name}
          </h1>
          <p className="text-blueForDate mt-[11px] text-[14px] md:text-[16px] leading-[22px] md:leading-[24px] font-[400] text-left">
            {formatDate(start_date)} to {formatDate(end_date)}
          </p>
          <p className="mt-[1px] text-brandGold font-[500] text-[12px] md:text-[14px] leading-[20px] md:leading-[22px] text-left">
            By <span className="font-semibold">{company_name}</span>
          </p>
        </div>
        <div className="w-[20%] flex justify-end">
          <div className="w-14 h-14 flex flex-col items-center justify-center border-[1px] border-brandGold bg-white rounded-full text-brandGold shadow-sm">
            <p className="text-base font-bold leading-none">{nights}</p>
            <p className="font-bold text-[10px]">DAYS</p>
          </div>
        </div>
      </div>
      {showBio && (
        <p className="mt-[12px] text-primaryGray font-[400] text-[14px] md:text-[16px] leading-[22px] md:leading-[24px] text-justify">
          {description}
        </p>
      )}
    </div>
  );
};

export default PackageHeader;
