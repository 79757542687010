import React, { useState } from "react";
import { Signup, saveUserAddress } from "../../api/AuthApi";
import { FiUser, FiMail, FiMapPin, FiChevronDown } from "react-icons/fi";
import { BiErrorAlt } from "react-icons/bi";
import country_data from "../Login/country_data.json";
import { ButtonsLoader, Otp } from "../..";
import Select from "react-select";

const EMAIL_REGEX = /^[\w.-]+@[\w.-]+\.[A-Za-z]{2,6}$/;
const PHONE_REGEX = /^\d{10}$/;

const countryOptions = country_data.map(({ code, dial_code, name }) => ({
  value: dial_code,
  label: `${name} (${dial_code})`,
  code,
  name,
}));

const SignUp = ({ closeModal, openLoginModal }) => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone_number: "",
    userType: "user",
    city: "",
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const [countrycode, setCountryCode] = useState(
    countryOptions.find((opt) => opt.code === "PK")?.value
  );
  const [showOtp, setShowOtp] = useState(false);
  const [userProfile, setUserProfile] = useState(null);

  const validateEmail = (email) => EMAIL_REGEX.test(email);
  const validatePhoneNumber = (phone) => {
    const clean = phone.replace(/\s+/g, "");
    if (clean.length !== 10 || !PHONE_REGEX.test(clean))
      return {
        isValid: false,
        error:
          clean.length < 10
            ? "Please enter complete phone number"
            : "Invalid phone number",
      };
    return { isValid: true, formattedNumber: clean.replace(/(\d{3})(\d{7})/, "$1 $2") };
  };

  const handleChange = ({ target: { name, value } }) => {
    setFormData((prev) => ({
      ...prev,
      [name]:
        name === "phone_number"
          ? value.replace(/\D/g, "").slice(0, 10).replace(/(\d{3})(\d{0,7})/, "$1 $2").trim()
          : value,
    }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleAddressSubmission = async (token, city) => {
    try {
      await saveUserAddress(token, "empty", "empty", city, "empty", "empty", "empty");
    } catch (error) {
      console.error("Error saving address:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { fullName, email, phone_number, city } = formData;
    const newErrors = {};
    if (!fullName) newErrors.fullName = "Full name is required";
    if (!email) newErrors.email = "Email is required";
    else if (!validateEmail(email)) newErrors.email = "Invalid email address";
    if (!phone_number) newErrors.phone_number = "Phone Number is required";
    else {
      const { isValid, error } = validatePhoneNumber(phone_number);
      if (!isValid) newErrors.phone_number = error;
    }
    if (!city) newErrors.city = "City is required";
    if (Object.keys(newErrors).length) return setErrors(newErrors);

    setIsLoading(true);
    setApiError("");
    try {
      const { formattedNumber } = validatePhoneNumber(phone_number);
      const formattedPhone = (countrycode + formattedNumber).replace(/\s+/g, "");
      const response = await Signup({
        phone_number: formattedPhone,
        name: fullName,
        email,
        user_type: formData.userType,
      });
      if (response?.data?.message === "User with this phone number already exists.") {
        return setApiError("User with this phone number already exists. Please Log in.");
      }
      const userData = response.data;
      setUserProfile(userData);
      await handleAddressSubmission(userData.session_token, city);
      sessionStorage.setItem("fromLocation", window.location.pathname + window.location.search);
      setShowOtp(true);
    } catch (e) {
      console.error("Error", e);
      setApiError(e.response?.data?.message || "An unexpected error occurred.");
    }
    setIsLoading(false);
  };

  if (showOtp && userProfile) {
    return <Otp closeModal={closeModal} userProfile={userProfile} />;
  }

  return (
    <div className="w-full max-h-[90vh] overflow-y-auto z-50">
      <div className="p-4 md:p-6">
        <div className="text-center mb-4">
          <div className="mb-3 md:mb-5 flex justify-center">
            <div className="bg-brandGold/20 p-3 md:p-4 rounded-full">
              <FiUser className="w-4 h-4 md:w-5 md:h-5 text-brandGold" />
            </div>
          </div>
          <h2 className="text-xl md:text-3xl font-bold text-gray-900 mb-2">Create Account</h2>
          <p className="text-sm md:text-base text-gray-500">Join us to get started</p>
        </div>
        <form onSubmit={handleSubmit} className="space-y-3 md:space-y-4 pb-4">
          {[
            {
              name: "fullName",
              type: "text",
              placeholder: "Please enter your full name",
              icon: <FiUser className="w-4 h-4 md:w-5 md:h-5 text-gray-400" />,
            },
            {
              name: "email",
              type: "email",
              placeholder: "email@example.com",
              icon: <FiMail className="w-4 h-4 md:w-5 md:h-5 text-gray-400" />,
            },
            {
              name: "city",
              type: "text",
              placeholder: "Enter your city",
              icon: <FiMapPin className="w-4 h-4 md:w-5 md:h-5 text-gray-400" />,
            },
          ].map(({ name, type, placeholder, icon }) => (
            <div key={name}>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  {icon}
                </div>
                <input
                  type={type}
                  name={name}
                  value={formData[name]}
                  onChange={handleChange}
                  placeholder={placeholder}
                  className="w-full pl-9 md:pl-10 pr-4 py-2 md:py-3 text-sm md:text-base border border-gray-200 rounded-lg focus:ring-2 focus:ring-brandGold focus:border-brandGold/50 transition-colors"
                />
              </div>
              {errors[name] && <p className="text-red-500 text-sm mt-1">{errors[name]}</p>}
            </div>
          ))}
          <div>
            <div className="mb-2">
              <Select
                options={countryOptions}
                value={countryOptions.find((opt) => opt.value === countrycode)}
                onChange={(sel) => setCountryCode(sel.value)}
                formatOptionLabel={(opt, { context }) =>
                  context === "menu" ? opt.label : opt.name
                }
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => <FiChevronDown className="text-gray-400" />,
                }}
                styles={{
                  control: (base) => ({
                    ...base,
                    borderRadius: "0.5rem",
                    padding: "0.15rem 0.5rem",
                    minHeight: "40px",
                    borderColor: errors.phone_number ? "#ef4444" : "#e5e7eb",
                    "&:hover": { borderColor: "#d1d5db" },
                  }),
                  singleValue: (base) => ({ ...base, textAlign: "left" }),
                  option: (base, { isFocused }) => ({
                    ...base,
                    textAlign: "left",
                    backgroundColor: isFocused ? "#fef6e6" : "white",
                    color: "#1f2937",
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: { ...theme.colors, primary: "#f5b301" },
                })}
                className="text-sm"
              />
            </div>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-gray-500">+{countrycode.replace(/\D/g, "")}</span>
              </div>
              <input
                type="text"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleChange}
                placeholder="123 456 7890"
                className="w-full pl-14 pr-4 py-2 md:py-3 text-sm md:text-base border border-gray-200 rounded-lg focus:ring-2 focus:ring-brandGold focus:border-brandGold/50 transition-colors"
              />
            </div>
            {errors.phone_number && (
              <p className="text-red-500 text-sm mt-1">{errors.phone_number}</p>
            )}
          </div>
          {apiError && (
            <div className="mt-2 flex items-center justify-center text-red-600">
              <BiErrorAlt className="w-5 h-5 mr-1" />
              <span className="text-sm">{apiError}</span>
            </div>
          )}
          <p className="text-xs text-justify text-gray-600 mb-2">
            By creating an account, you agree to our{" "}
            <a href="/privacy-policy" className="font-bold">
              Privacy Policy
            </a>{" "}
            and{" "}
            <a href="/terms-services" className="font-bold">
              Terms and Conditions
            </a>.
          </p>
          <button
            type="submit"
            disabled={isLoading}
            className="w-full bg-gradient-to-r from-brandGold to-amber-500 hover:from-amber-500 hover:to-brandGold text-white font-semibold py-2 md:py-3 px-4 rounded-lg transition-all transform hover:scale-[1.02] active:scale-95 disabled:opacity-70 disabled:hover:scale-100"
          >
            {isLoading ? (
              <div className="flex items-center justify-center space-x-2">
                <ButtonsLoader />
                <span>Creating Account...</span>
              </div>
            ) : (
              "Create Account"
            )}
          </button>
          <div className="text-center text-sm text-gray-600 mt-4">
            Already have an account?{" "}
            <button
              type="button"
              onClick={openLoginModal}
              className="text-brandGold font-semibold hover:text-amber-600 transition-colors"
            >
              Log in
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
