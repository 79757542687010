import React from "react";
import WadiJinImg1 from "../../../../../assets/ZiyaratPlaces/Madinah/wadijin/locationsection.png";
import WadiJinImg2 from "../../../../../assets/ZiyaratPlaces/Madinah/wadijin/islamsection.png";
const MainPage = () => {
  return (
    <div>
      <div className="bg-[#F2F2F2]">
        <p className="text-center text-[#484848] font-bold py-4 font-cairo md:text-[25px] text-[15px]">
          Discover the Mystical Valley Where Gravity Defies Logic - Wadi-e-Jinn!
        </p>
      </div>
      <div className=" w-all-sections-width mx-auto">
        <div className="pt-14">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            The Geographical Location of Wadi-e-Jinn
          </h2>
          <div className="flex flex-col lg:flex-row mt-4 gap-10">
            <div className="lg:w-1/2 md:w-full flex-grow">
              <p className="text-[#484848] font-cairo font-medium text-[15px] text-justify">
                Wadi-e Jinn, nestled in the heart of Saudi Arabia, derives its
                name from the jinn, supernatural beings deeply ingrained in
                Arabian folklore. These entities, believed to inhabit unseen
                realms, are sometimes attributed to explain mysterious
                occurrences in the region. Even before the advent of modern
                roads and automobiles, local inhabitants were aware of something
                peculiar about this place, lending it an aura of mystique.
                Stretching over 15 kilometers (9 miles), Wadi-e Jinn winds
                through a landscape where paved roads now coexist with natural
                beauty. Despite efforts to develop picnic grounds along its
                course, the wadi has unfortunately become marred by litter,
                detracting from its natural allure. However, higher up in the
                wadi, one can still experience the unspoiled charm typical of a
                traditional wadi environment. Visitors to Wadi-e Jinn often
                recount anecdotes of hearing voices at night, purportedly from
                the jinn, urging them to depart the wadi.{" "}
                <p className="lg:hidden xl:block ">
                  These tales add to the mystical reputation of the area, where
                  the convergence of natural beauty and supernatural beliefs
                  creates an atmosphere unlike any other. Whether one seeks the
                  thrill of exploring folklore-rich landscapes or simply wishes
                  to revel in the serene beauty of a wadi, Wadi-e Jinn offers a
                  blend of natural wonder and cultural intrigue that captivates
                  the imagination.
                  <br />
                  Unlike other wadis, though, the combination of rounded
                  boulders balancing at improbable angles and the slanted fault
                  lines of rocks can have a disorienting effect. It is a valley
                  just 30 km away from Madinah. In the northwest direction to
                  the city, the place holds a mystery that has attracted and
                  fascinated people from around the world. In the holy land of
                  Saudi Arabia, this place sees many travellers and tourists
                  putting camps on the roadside on weekends.
                </p>
              </p>
            </div>
            <div className="lg:w-1/2 flex-shrink-0">
              <img
                src={WadiJinImg1}
                alt="Wadi-e-Jinn"
                className="rounded-lg shadow-lg w-full h-auto"
              />
            </div>
          </div>
          <p className="text-[#484848] hidden lg:flex xl:hidden mb-4 md:mt-3 w-full text-justify font-cairo font-medium text-[15px] lg:mr-10">
            These tales add to the mystical reputation of the area, where the
            convergence of natural beauty and supernatural beliefs creates an
            atmosphere unlike any other. Whether one seeks the thrill of
            exploring folklore-rich landscapes or simply wishes to revel in the
            serene beauty of a wadi, Wadi-e Jinn offers a blend of natural
            wonder and cultural intrigue that captivates the imagination.
            <br />
            Unlike other wadis, though, the combination of rounded boulders
            balancing at improbable angles and the slanted fault lines of rocks
            can have a disorienting effect. It is a valley just 30 km away from
            Madinah. In the northwest direction to the city, the place holds a
            mystery that has attracted and fascinated people from around the
            world. In the holy land of Saudi Arabia, this place sees many
            travellers and tourists putting camps on the roadside on weekends.
          </p>
        </div>
        {/* location section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Wadi e Jinn in Islam
          </h2>
          <div className="mt-4">
            <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px]">
              According to a hadith, Hazrat Dawood R.A reported from Amir who
              had asked Alqamah about the presence of Ibn Masood with Allah’s
              Messenger PBUH on the night of the Jinn. Alqamah replied in a
              negative tone and told him that they were once in the company of
              the Prophet SAW, whom they lost after a while. They searched for
              him in the hills and valleys but found no sign of him. They spent
              the night in utter devastation and anticipated that either Prophet
              SAW was murdered or taken by Jinn. At dawn, they saw the Prophet
              coming from the direction of Cave Hira. On being asked of his
              whereabouts for the entire night, he replied that a requester came
              on behalf of Jinn, and he went with him to recite Quran for the
              community. The Prophet then showcased the traces of their trails
              and cinders. On asking PBUH about their endowment, Jinn got the
              reply that every bone on which anyone would recite the name of
              Allah would have provision for them. The moment it should fall on
              their hands, it would be covered with epithelium, and the excreta
              of camels would be the food for their animals. Calling Jinn their
              brothers, the Messenger of Allah instructed everyone to not
              perform ‘Istinja’ (the act of cleaning using sandstone, toilet
              paper or a clean stone, as a replacement of water, to clean the
              front and back part of the body after excreting) with any of the
              mentioned things because they were the food items for Jinn.
            </p>
          </div>
          <div className="mt-8 flex flex-col items-center">
            <img
              src={WadiJinImg2}
              alt="Islamic Calligraphy"
              className="w-full h-auto rounded-lg shadow-lg"
            />
            <p className="text-center text-[#484848] font-cairo text-[15px] font-semibold mt-4">
              "And I did not create the jinn and mankind except to worship Me"
              <br />
              <span className="italic font-bold">
                Surah Adz Dzaariyaat [verse 56]
              </span>
            </p>
          </div>
        </div>
        {/* islam section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            How to explore Wadi al Jinn?
          </h2>
          <div className="mt-4">
            <ul className="list-none space-y-2 text-[#484848] mb-4 font-cairo font-medium text-[17px]">
              <li>
                - You can set up a tent along the road in Saudi Arabia's sacred
                region. Try camping here to encounter the unexpected.
              </li>
              <li>
                - Discover the wonders of the location by visiting. Turn off the
                ignition and keep the car in neutral gear to explore the area if
                you're curious enough. If your car starts moving, especially in
                an upward direction, don't be shocked. Without a driver, cars
                have been known to travel as fast as 120 km/h. According to
                scientific theories, there are strong magnetic forces that draw
                automobiles and wheels while promoting movement efficiency.
                These areas with strong magnetic fields give the mind and sight
                an optical illusion. It occurs because of the topography, which
                makes a downward slope appear upward. As a result, many scholars
                think the downhill slope is misinterpreted as an upward slope.
              </li>
              <li>
                - Try spraying some water on the street. You might notice that
                it flows up the hill instead of going down. This occurrence is
                referred to as 'reverse gravity' by geologists. The rocky
                mountains are where this natural occurrence takes place.
              </li>
              <li>
                - A beautiful vista with no buildings in sight and a bright blue
                sky may be found in the valley. Visit this valley on foot for
                some stunning views.
              </li>
              <li>
                - Join a rock climbing expedition to see breathtaking views of
                the valley and the cliffs.
              </li>
              <li>
                - Early sunrise scenes are a sight to behold in the valley.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
