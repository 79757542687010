import React from "react";
import { FiSearch } from "react-icons/fi";

function Search() {
  return (
    <div className="flex items-center justify-center">
      <div className="relative lg:w-[30%] md:w-[55%] w-full mx-6">
        <input
          type="search"
          placeholder="Search how-tos and more"
          className="pl-4 pr-10 w-full text-sm border rounded-full bg-[#f7f7f7] focus:outline-none placeholder:text-[16px] md:py-6 py-3 "
        />
        <button className="absolute right-2 top-1/2 transform -translate-y-1/2 text-white bg-brandGold md:p-3.5 p-2 rounded-full">
          <FiSearch className="md:size-[25px] size-[20px] "/>
        </button>
      </div>
    </div>
  );
}

export default Search;
