import React, { useState } from "react";
import {
  FaShuttleVan,
  FaSnowflake,
  FaTv,
  FaWifi,
  FaArrowCircleUp,
  FaBath,
  FaToilet,
  FaSoap,
  FaToiletPaper,
} from "react-icons/fa";
import {
  getHotelImages,
  makkahHotels,
  madinahHotels,
} from "../../../../Utilities/UtilityFunctions";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Navigation } from "swiper/modules";

const Itinerary = ({ data }) => {
  const { hotel_detail, mecca_nights, madinah_nights, start_date } = data;
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupIndex, setPopupIndex] = useState(0);
  const [popupImages, setPopupImages] = useState([]);

  const formatDateRange = (start, nights) => {
    const startDate = new Date(start);
    const endDate = new Date(start);
    endDate.setDate(startDate.getDate() + nights);
    const options = { weekday: "short", day: "numeric", month: "short" };
    const formattedStart = startDate.toLocaleDateString("en-US", options);
    const formattedEnd = endDate.toLocaleDateString("en-US", options);
    return `${formattedStart} to ${formattedEnd}`;
  };

  const meccaDates = formatDateRange(start_date, mecca_nights);
  const madinahStart = new Date(start_date);
  madinahStart.setDate(madinahStart.getDate() + mecca_nights);
  const madinahDates = formatDateRange(madinahStart, madinah_nights);

  const amenitiesIcons = {
    is_shuttle_services_included: { icon: <FaShuttleVan />, label: "Shuttle Service" },
    is_air_condition: { icon: <FaSnowflake />, label: "Air Conditioning" },
    is_television: { icon: <FaTv />, label: "Television" },
    is_wifi: { icon: <FaWifi />, label: "Free WiFi" },
    is_elevator: { icon: <FaArrowCircleUp />, label: "Elevator" },
    is_attach_bathroom: { icon: <FaBath />, label: "Attached Bathroom" },
    is_washroom_amenities: { icon: <FaSoap />, label: "Washroom Amenities" },
    is_english_toilet: { icon: <FaToilet />, label: "English Toilet" },
    is_indian_toilet: { icon: <FaToiletPaper />, label: "Indian Toilet" },
    is_laundry: { icon: <FaSoap />, label: "Laundry Service" },
  };

  const openPopup = (images, index) => {
    setPopupImages(images);
    setPopupIndex(index);
    setPopupOpen(true);
  };

  const HotelCard = ({ hotel, hotelData }) => {
    const hotelImages = getHotelImages(hotel.hotel_name, hotelData);
    const [swiperInstance, setSwiperInstance] = useState(null);
    const [isBeginning, setIsBeginning] = useState(true);
    const [isEnd, setIsEnd] = useState(false);

    return (
      <div className="z-10 bg-white p-4 md:p-6 rounded-[12px] shadow-md border-[1px] border-custom-gray mb-6">
        <h3 className="text-[18px] md:text-[20px] font-[600] leading-[24px]">
          {hotel.hotel_name}
        </h3>
        <p className="flex items-center text-brandGold">
          {"⭐".repeat(parseInt(hotel.hotel_rating)) || "⭐"}
        </p>
        <p className="text-black font-[500] text-[14px] leading-[22px] mt-1">
          {hotel.hotel_distance} {hotel.distance_type} from Kaaba
        </p>
        <p className="text-[12px] md:text-[14px] font-[400] leading-[22px] text-primaryGray mt-2">
          {hotel.hotel_description ||
            `Stay at ${hotel.hotel_name}, featuring ${hotel.is_air_condition ? "air-conditioned rooms, " : ""
            }${hotel.is_wifi ? "free WiFi, " : ""}${hotel.is_elevator ? "elevators, " : ""
            } and other amenities.`}
        </p>
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
          {Object.keys(amenitiesIcons).map((key) =>
            hotel[key] ? (
              <div key={key} className="flex items-center space-x-2">
                <span className="text-brandGold text-sm md:text-lg">
                  {amenitiesIcons[key].icon}
                </span>
                <p className="text-secondaryGray text-[12px] font-[500] leading-[20px]">
                  {amenitiesIcons[key].label}
                </p>
              </div>
            ) : null
          )}
        </div>
        {hotelImages.length > 0 && (
          <div className="mt-4 z-10">
            {hotelImages.length <= 3 ? (
              <div className={`grid grid-cols-${hotelImages.length} gap-4`}>
                {hotelImages.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`${hotel.hotel_name} image ${index + 1}`}
                    className="z-10 rounded-[8px] shadow-sm cursor-pointer"
                    style={{
                      width: "100%",
                      maxWidth: "230px",
                      height: "150px",
                      objectFit: "cover",
                    }}
                    onClick={() => openPopup(hotelImages, index)}
                  />
                ))}
              </div>
            ) : (
              <div className="relative">
                <Swiper
                  onSwiper={(swiper) => {
                    setSwiperInstance(swiper);
                    setIsBeginning(swiper.isBeginning);
                    setIsEnd(swiper.isEnd);
                  }}
                  onSlideChange={(swiper) => {
                    setIsBeginning(swiper.isBeginning);
                    setIsEnd(swiper.isEnd);
                  }}
                  spaceBetween={10}
                  slidesPerView={1}
                  breakpoints={{
                    640: { slidesPerView: 2 },
                    1024: { slidesPerView: 3 },
                  }}
                  loop={false}
                  autoplay={{ delay: 3000, disableOnInteraction: false }}
                  pagination={{ clickable: true }}
                  modules={[Autoplay, Navigation]}
                >
                  {hotelImages.map((image, index) => (
                    <SwiperSlide key={index}>
                      <img
                        src={image}
                        alt={`${hotel.hotel_name} slider image ${index + 1}`}
                        className="z-10 rounded-[8px] shadow-sm cursor-pointer"
                        style={{ width: "100%", height: "173px", objectFit: "cover" }}
                        onClick={() => openPopup(hotelImages, index)}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
                {!isBeginning && (
                  <button
                    onClick={() => swiperInstance && swiperInstance.slidePrev()}
                    className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 text-black p-2 bg-white rounded-full shadow"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 text-gray-700"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                    </svg>
                  </button>
                )}
                {!isEnd && (
                  <button
                    onClick={() => swiperInstance && swiperInstance.slideNext()}
                    className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 text-black p-2 bg-white rounded-full shadow"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 text-gray-700"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                    </svg>
                  </button>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="mb-10">
      <div className="mb-8">
        <h2 className="text-[20px] md:text-[24px] font-[600] leading-[28px] md:leading-[30px] mb-4">
          Makkah {mecca_nights} Nights{" "}
          <span className="text-[16px] ml-2 font-[500]">{meccaDates}</span>
        </h2>
        {hotel_detail
          .filter((hotel) => hotel.hotel_city.toLowerCase() === "mecca")
          .map((hotel, index) => (
            <HotelCard key={index} hotel={hotel} hotelData={makkahHotels} />
          ))}
      </div>
      <div>
        <h2 className="text-[20px] md:text-[24px] font-[600] leading-[28px] md:leading-[30px] mb-4">
          Madinah {madinah_nights} Nights{" "}
          <span className="text-[16px] ml-2 font-[500]">{madinahDates}</span>
        </h2>
        {hotel_detail
          .filter((hotel) => hotel.hotel_city.toLowerCase() === "madinah")
          .map((hotel, index) => (
            <HotelCard key={index} hotel={hotel} hotelData={madinahHotels} />
          ))}
      </div>
      {popupOpen && (
        <div className="fixed inset-0 z-[999] flex items-center justify-center bg-black bg-opacity-70">
          <div className="relative">
            <img
              src={popupImages[popupIndex]}
              alt="Popup"
              className="max-w-[90vw] max-h-[90vh] object-contain"
            />
            <button
              onClick={() => setPopupOpen(false)}
              className="absolute top-2 right-4 text-white text-2xl"
            >
              ✕
            </button>
            {popupIndex > 0 && (
              <button
                onClick={() => setPopupIndex(popupIndex - 1)}
                className="absolute left-4 top-1/2 -translate-y-1/2 text-white text-3xl"
              >
                &#10094;
              </button>
            )}
            {popupIndex < popupImages.length - 1 && (
              <button
                onClick={() => setPopupIndex(popupIndex + 1)}
                className="absolute right-4 top-1/2 -translate-y-1/2 text-white text-3xl"
              >
                &#10095;
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Itinerary;
