// Modal.jsx
import React from "react";
import { X } from "lucide-react";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50 backdrop-blur-[1px]">
      <div className="bg-white rounded-2xl p-8 md:w-1/2 lg:w-1/3 relative shadow-2xl transform transition-all scale-95 hover:scale-100">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 transition-colors"
        >
          <X size={24} />
        </button>
        <div className="text-center py-4">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;