import React, { useEffect } from "react";
import Banner from "../../HeroSection/Banner";
import HeaderFooter from "../../../../ZiyaratArticlesPages/HeaderFooter";
import Slider from "../Wadijin/Slider";
import Page from "./Page";
import SalmanFarsiGardenImg1 from "../../../../../assets/ZiyaratPlaces/Madinah/salmanfarsigarden/salman2.svg";
import SalmanFarsiGardenImg2 from "../../../../../assets/ZiyaratPlaces/Madinah/salmanfarsigarden/salman3.svg";
import SalmanFarsiGardenImg3 from "../../../../../assets/ZiyaratPlaces/Madinah/salmanfarsigarden/salman4.svg";
import SalmanFarsiGardenImg4 from "../../../../../assets/ZiyaratPlaces/Madinah/salmanfarsigarden/salman5.jpg";
import bg from "../../../../../assets/ZiyaratPlaces/Madinah/salmanfarsigarden/salman1.svg";
const SalmanFarsiGarden = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const images = [
    SalmanFarsiGardenImg1,
    SalmanFarsiGardenImg2,
    SalmanFarsiGardenImg3,
    SalmanFarsiGardenImg4,
  ];

  return (
    <div>
      <HeaderFooter>
        <Banner image={bg} title={"Garden of Salman Farsi (رضي الله عنه)"} />
        <Page />
        <Slider images={images} />
      </HeaderFooter>
    </div>
  );
};

export default SalmanFarsiGarden;
