import { useEffect, useRef } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Footer } from "../../../../../";
import { KhadijahMosqueImages } from "../../../../../Utilities/placesdata";
import BlogHeader from "../../../../ZiyaratArticlesPages/BlogHeader";
import icon from "../../../../../assets/ZiyaratPlaces/Makkah/SafaMarwa/makkahPageIcon.svg";

const KhadijahMosque = () => {
  const swiperRef = useRef(null);

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    // Scroll to top when the component mounts
    scrollToTop();

    // Scroll to top when the page is reloaded or navigated from another page
    window.addEventListener("beforeunload", scrollToTop);

    return () => {
      window.removeEventListener("beforeunload", scrollToTop);
    };
  }, []);

  return (
    <div className="text-[#4b465c] font-cairo">
      {/* <Navbar /> */}
      <BlogHeader />

      <div
        className="w-full h-[300px] bg-cover bg-center bg-no-repeat relative"
        style={{
          backgroundImage: `url(${KhadijahMosqueImages.image1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="bg-[#00936C] opacity-20 w-full h-[300px] z-10"></div>

        <div className="absolute bottom-0 left-0 right-0 p-6 w-[85%] lg:pb-10 mx-auto text-white z-20">
          <h1 className="font-kd font-bold text-4xl">Khadija Baghlaf Mosque</h1>
        </div>
      </div>
      <div className=" bg-[#F2F2F2] mx-auto p-6 text-xl md:text-2xl font-bold text-center ">
        <h1 className="w-[90%] mx-auto">
          Khadija Baghlaf Mosque is a prominent religious site in Jeddah, known
          for its architectural beauty and spiritual significance.
        </h1>
      </div>
      <div className="w-[85%] mx-auto py-10">
        <div>
          <h1 className="text-[25px] font-bold pb-1">
            Reference in Islamic History
          </h1>
          <div className="flex flex-col lg:flex-row gap-4 mt-4 ">
            {" "}
            <div className="lg:w-[55%] space-y-4 font-medium text-[15px]">
              <p>
                Khadija Baghlaf Mosque is renowned for its stunning architecture
                and serene ambiance, making it a significant place of worship in
                Jeddah.
              </p>
              <div className="py-2.5">
                <h1 className="text-[25px] font-bold">Architectural Beauty</h1>
                <p className=" font-medium text-[15px] pt-4">
                  The mosque features a blend of modern and traditional Islamic
                  architectural styles. It is a popular destination for both
                  residents and visitors, who come to admire its intricate
                  design and peaceful environment.
                </p>
                <p className=" font-medium text-[15px] pt-4">
                  The mosque is also known for its role in promoting Islamic
                  education and fostering a sense of unity among Muslims in the
                  region.
                </p>
              </div>
            </div>
            <div className="lg:w-[45%]">
              <img
                src={KhadijahMosqueImages.image2}
                alt="Khadija Baghlaf Mosque"
              />
            </div>
          </div>
        </div>

        <div className="pt-10">
          <h1 className="text-[25px] font-bold pb-1">
            Significance of Khadija Baghlaf Mosque
          </h1>
          <div className="pt-3">
            <div className="font-cairo font-medium text-[15px] space-y-2">
              <p>
                Khadija Baghlaf Mosque serves as a pivotal center for the local
                Muslim community in Jeddah. It is a place where people gather
                for prayers, religious teachings, and community events.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 pt-10">
              <div className="bg-gray-100 p-6">
                <span className="font-bold block text-[25px]">
                  Community Hub:
                </span>
                <p className=" font-normal text-[15px] pt-2">
                  The mosque acts as a hub for various community activities,
                  including educational programs, social gatherings, and charity
                  events.
                </p>
              </div>
              <div className="border-y-2 border-[#E6F4F0] p-6">
                <span className="font-bold block text-[25px]">
                  Religious Services:
                </span>
                <p className=" font-normal text-[15px] pt-2">
                  Khadija Baghlaf Mosque offers a range of religious services,
                  including daily prayers, Friday sermons, and special prayers
                  during Ramadan and other Islamic occasions.
                </p>
              </div>
              <div className="bg-gray-100 p-6">
                <span className="font-bold block text-[25px]">
                  Architectural Marvel:
                </span>
                <p className=" font-normal text-[15px] pt-2">
                  The mosque's design is an architectural marvel, featuring
                  intricate calligraphy, beautiful domes, and a spacious prayer
                  hall that can accommodate thousands of worshippers.
                </p>
              </div>
              <div className="border-y-2 border-[#E6F4F0] p-6">
                <span className="font-bold block text-[25px]">
                  Spiritual Sanctuary:
                </span>
                <p className=" font-normal text-[15px] pt-2">
                  Khadija Baghlaf Mosque provides a serene environment for
                  worshippers to connect with their faith, offering a space for
                  reflection and spiritual growth.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className=" ">
          <h1 className="font-bold block text-[25px] pt-10">
            Activities at Khadija Baghlaf Mosque
          </h1>
          <div className="space-y-1.5">
            <p className=" font-normal text-[15px] pt-2">
              The mosque hosts a variety of activities and events throughout the
              year, attracting both locals and visitors.
            </p>
            <div className="md:flex space-y-4 md:space-y-0 gap-5 text-center pt-5">
              <img
                src={KhadijahMosqueImages.image3}
                alt="Activities at Khadija Baghlaf Mosque"
                className="md:w-1/2 rounded-lg md:h-[300px]"
              />
              <img
                src={KhadijahMosqueImages.image4}
                alt="Activities at Khadija Baghlaf Mosque"
                className="md:w-1/2 md:h-[300px] rounded-lg"
              />
            </div>
          </div>
        </div>
        <h3 className="font-bold text-[24px] md:text-[25px] pb-1 pt-10 ">
          Khadija Baghlaf Mosque: A Spiritual Haven
        </h3>
        <div className="lg:flex md:block block gap-5 pt-5">
          <div className="block lg:w-[55%]">
            <p className=" font-medium text-[15px]">
              Khadija Baghlaf Mosque is a symbol of spiritual devotion and
              architectural excellence in Jeddah. It continues to inspire and
              serve the Muslim community through its religious, educational, and
              social initiatives.
            </p>
            <div className="space-y-1.5 py-1.5">
              <h4 className="font-medium text-[15px]">
                Key Features of Khadija Baghlaf Mosque:
              </h4>
              <ul className=" font-medium text-[15px]">
                <div className="flex gap-2">
                  <img src={icon} alt="icon" />

                  <li className=" leading-relaxed py-2 px-2">
                    Stunning Architecture
                  </li>
                </div>
                <div className="flex gap-2">
                  <img src={icon} alt="icon" />

                  <li className=" leading-relaxed py-2 px-2">
                    Community Programs
                  </li>
                </div>
                <div className="flex gap-2">
                  <img src={icon} alt="icon" />

                  <li className=" leading-relaxed py-2 px-2">Daily Prayers</li>
                </div>
                <div className="flex gap-2">
                  <img src={icon} alt="icon" />

                  <li className=" leading-relaxed py-2 px-2">
                    Religious Teachings
                  </li>
                </div>
                <div className="flex gap-2">
                  <img src={icon} alt="icon" />

                  <li className=" leading-relaxed py-2 px-2">
                    Charitable Activities
                  </li>
                </div>
              </ul>
              <p className="font-medium text-[15px]">
                Khadija Baghlaf Mosque is more than just a place of worship; it
                is a cornerstone of the community, offering a sanctuary for
                spiritual growth and a hub for social and educational
                activities.
              </p>
            </div>
          </div>
          <img
            src={KhadijahMosqueImages.image5}
            alt="Khadija Baghlaf Mosque"
            className="w-full lg:w-[45%] rounded-lg mt-10 lg:mt-0"
          />
        </div>

        <div className="slider-container relative py-20">
          <Swiper
            ref={swiperRef}
            spaceBetween={20}
            loop={true}
            navigation={false} // Disable built-in navigation
            modules={[Navigation]}
            slidesPerView={1} // Default for mobile
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
          >
            <SwiperSlide>
              <img
                src={KhadijahMosqueImages.image6}
                alt="1"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={KhadijahMosqueImages.image7}
                alt="2"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={KhadijahMosqueImages.image2}
                alt="3"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={KhadijahMosqueImages.image3}
                alt="4"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={KhadijahMosqueImages.image4}
                alt="5"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
          </Swiper>

          {/* Custom Navigation Buttons */}
          <button
            onClick={handlePrev}
            className="absolute top-1/2 -left-3 transform -translate-y-1/2 z-10"
          >
            <IoIosArrowBack className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
          </button>
          <button
            onClick={handleNext}
            className="absolute top-1/2 -right-3 transform -translate-y-1/2 z-10"
          >
            <IoIosArrowForward className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
          </button>
        </div>
        {/* <FeaturedBlogs /> */}
      </div>
      <Footer />
    </div>
  );
};

export default KhadijahMosque;
