import React from "react";
import Card from "../Guest/Card";
import manageImg from "../../../../assets/HelpPage/manage.jpg";
import gettingPaidImg from "../../../../assets/HelpPage/gettingpaid.jpg";
import hostingImg from "../../../../assets/HelpPage/hosting.jpg";
import aircoverImg from "../../../../assets/HelpPage/aircover.jpg";
const cardData = [
  {
    title: "Access and manage your account",
    imageUrl: manageImg,
    link: "/manage-account",
    imagestyles: "rounded-lg",
  },
  {
    title: "Getting paid",
    imageUrl: gettingPaidImg,
    link: "/getting-paid",
    imagestyles: "rounded-lg",
  },
  {
    title: "Help with hosting",
    imageUrl: hostingImg,
    link: "/help-hosting",
    imagestyles: "rounded-lg",
  },
  {
    title: "Getting protected through AirCover for Hosts",
    imageUrl: aircoverImg,
    link: "/aircover",
    imagestyles: "rounded-lg",
  },
];

const HostPage = () => {
  return (
    <div>
      <div className="grid md:grid-cols-4 grid-cols-1 gap-4">
        {cardData.map((card, index) => (
          <Card
            key={index}
            title={card.title}
            imageUrl={card.imageUrl}
            link={card.link}
            imagestyles={card.imagestyles}
          />
        ))}
      </div>
    </div>
  );
};

export default HostPage;
