import React from "react";
import { Footer, Header } from "../..";
const PersonalDetailsPage = () => {
  const userDetails = [
    { label: "Name", value: "Hassan Tahir", editable: true },
    { label: "Display name", value: "Choose a display name", editable: true },
    {
      label: "Email address",
      value: "ht.coc147@gmail.com",
      editable: true,
      verified: true,
    },
    { label: "Phone number", value: "Add your phone number", editable: true },
    {
      label: "Date of birth",
      value: "Enter your date of birth",
      editable: true,
    },
    {
      label: "Nationality",
      value: "Select the country/region you're from",
      editable: true,
    },
    { label: "Gender", value: "Select your gender", editable: true },
    { label: "Address", value: "Add your address", editable: true },
    {
      label: "Passport details",
      value: "Not provided",
      editable: true,
      addLink: true,
    },
  ];

  return (
    <div>
      <Header />
      <div className="w-all-sections-width mx-auto">
        <h1 className="h2">Personal details</h1>
        <p className="text-base text-gray-600 mb-6">
          Update your information and find out how it's used.
        </p>
        <div className="space-y-4">
          {userDetails.map((detail, index) => (
            <div
              key={index}
              className="flex justify-between items-center border-b pb-4"
            >
              <div>
                <p className="">{detail.label}</p>
                <p className="text-sm font-light text-gray-600">
                  {detail.value}
                  {detail.verified && (
                    <span className="bg-green-200 text-green-800 px-2 py-1 rounded-full text-xs ml-2">
                      Verified
                    </span>
                  )}
                </p>
                {detail.label === "Email address" && (
                  <p className="text-sm text-gray-500">
                    This is the email address you use to sign in. It's also
                    where we send your booking confirmations.
                  </p>
                )}
                {detail.label === "Phone number" && (
                  <p className="text-sm text-gray-500">
                    Properties or attractions you book will use this number if
                    they need to contact you.
                  </p>
                )}
              </div>
              {detail.editable && (
                <a href="#" className="text-sm font-light text-brandGold">
                  {detail.addLink ? "Add passport" : "Edit"}
                </a>
              )}
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PersonalDetailsPage;
