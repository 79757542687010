import React from "react";

const Banner = ({ image, title }) => {
  return (
    <div
      className="relative text-center h-[364px] w-full bg-[#F0F6F5]"
      style={{
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div className="bg-[#00936C] opacity-20 w-full h-[364px] z-10"></div>
      <div className="absolute bottom-10 md:left-36 left-7 text-white z-20">
        <h1 className="mb-2 font-kd font-bold md:text-[40px] text-[20px]">
          {title}
        </h1>
      </div>
    </div>
  );
};

export default Banner;
