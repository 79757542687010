import React from "react";
import HeroSection from "../../HeroSection/HeroSection";
import Page from "./Page";
import { alHudaybiyah } from "../../../../../Utilities/data";
import HeaderFooter from "../../../../ZiyaratArticlesPages/HeaderFooter";
import Slider from "./Slider";

const AlHudaybiyah = () => {
  return (
    <div>
      <HeaderFooter>
        <HeroSection
          herobg={alHudaybiyah.hero.image}
          heading={alHudaybiyah.hero.heading}
          paragraph={alHudaybiyah.hero.description}
          subheading={alHudaybiyah.hero.subHeading}
        />
        <Page />
        <div className="py-16">
          <Slider />
        </div>
      </HeaderFooter>
    </div>
  );
};

export default AlHudaybiyah;
