import React, { useEffect } from "react";
import Banner from "../../HeroSection/Banner";
import HeaderFooter from "../../../../ZiyaratArticlesPages/HeaderFooter";
import Slider from "../../MadinahPlaces/Wadijin/Slider";
import Page from "./Page";
import HalimaSadiaHouseImg1 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/halimaSadia/image2.jpeg";
import HalimaSadiaHouseImg2 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/halimaSadia/image3.jpeg";
import HalimaSadiaHouseImg3 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/halimaSadia/image4.jpeg";
import HalimaSadiaHouseImg4 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/halimaSadia/image5.jpeg";
import bg from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/halimaSadia/image1.jpeg";
const HalimaSadiaHouse = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const images = [
    HalimaSadiaHouseImg1,
    HalimaSadiaHouseImg2,
    HalimaSadiaHouseImg3,
    HalimaSadiaHouseImg4,
  ];

  return (
    <div>
      <HeaderFooter>
        <Banner image={bg} title={"Halima Sadia House"} />
        <Page />
        <Slider images={images} />
      </HeaderFooter>
    </div>
  );
};

export default HalimaSadiaHouse;
