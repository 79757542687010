// src/pages/HomePage/components/PackageCarousel.js
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { PackageCardHome } from '../../../';
import { Pagination, Autoplay } from 'swiper/modules';

const PackageCarousel = ({ packages }) => {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [showLeft, setShowLeft] = useState(false);
  const [showRight, setShowRight] = useState(false);

  // Update navigation arrow visibility based on the current slide index
  const updateNavigation = (swiper) => {
    const slidesPerView = swiper.params.slidesPerView;
    const totalSlides = swiper.slides.length;
    const currentIndex = swiper.activeIndex;

    // Show the left arrow if we are not at the very beginning
    setShowLeft(currentIndex > 0);
    // Show the right arrow if there are slides remaining after the current view
    setShowRight(currentIndex < totalSlides - slidesPerView);
  };

  const handleSwiper = (swiper) => {
    setSwiperInstance(swiper);
    updateNavigation(swiper);
  };

  return (
    <div className="relative">
      <Swiper
        onSwiper={handleSwiper}
        onSlideChange={(swiper) => updateNavigation(swiper)}
        onResize={(swiper) => updateNavigation(swiper)}
        modules={[Pagination, Autoplay]}
        spaceBetween={10}
        slidesPerView={1}
        slidesPerGroup={1}
        pagination={false}
        loop={false}
        touchEventsTarget="wrapper" // Ensures proper touch detection
        touchRatio={1.5} // Adjusts swipe sensitivity
        simulateTouch={true} // Enables touch interactions
        freeMode={true} // Allows free scrolling
        passiveListeners={false} // Ensures compatibility with trackpads
        breakpoints={{
          640: { slidesPerView: 1, slidesPerGroup: 1, spaceBetween: 10 },
          768: { slidesPerView: 2, slidesPerGroup: 1, spaceBetween: 20 },
          1024: { slidesPerView: 3, slidesPerGroup: 1, spaceBetween: 24 },
        }}
      >
        {packages.map((pkg) => (
          <SwiperSlide key={pkg.huz_token}>
            <PackageCardHome packageData={pkg} />
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Left Arrow - Positioned outside of the card area */}
      {swiperInstance && showLeft && (
        <button
          onClick={() => swiperInstance.slidePrev()}
          className="absolute left-[-20px] top-1/2 transform -translate-y-1/2 z-10 p-2 bg-white rounded-full shadow"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-gray-700"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
          </svg>
        </button>
      )}

      {/* Right Arrow - Positioned outside of the card area */}
      {swiperInstance && showRight && (
        <button
          onClick={() => swiperInstance.slideNext()}
          className="absolute right-[-20px] top-1/2 transform -translate-y-1/2 z-10 p-2 bg-white rounded-full shadow"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-gray-700"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        </button>
      )}
    </div>
  );
};

export default PackageCarousel;
