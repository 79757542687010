import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules"; // Added Pagination module
import { FaPlane } from "react-icons/fa"; // only the airplane icon is needed now

import {
  getHotelImages,
  makkahHotels,
  madinahHotels,
} from "../../../Utilities/UtilityFunctions";
import { useNavigate } from "react-router-dom";
import { useCurrency } from "../../../context/CurrencyContext";
import { formatPrice } from "../../../Utilities/currencyUtils";

const PackageCard = ({ data, filters }) => {
  // Destructure new properties from the data object.
  const {
    huz_token,
    package_name,
    start_date,
    end_date,
    company_detail,
    hotel_detail,
    mecca_nights,
    madinah_nights,
    cost_for_sharing,
    airline_detail = [],
    is_breakfast_included,
    is_lunch_included,
    is_dinner_included,
    ziyarah_detail,
    rating_count,
    package_base_cost,
    cost_for_child,
    cost_for_infants,
    cost_for_quad,
    cost_for_triple,
    cost_for_double,
    cost_for_single,
  } = data;
  const navigate = useNavigate();
  const { currency, convert } = useCurrency();

  // Helper function to format dates as dd-MMM-yyyy
  const formatDate = (dateString) => {
    const d = new Date(dateString);
    const day = d.getDate().toString().padStart(2, "0");
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[d.getMonth()];
    const year = d.getFullYear();
    return `${day} ${month} ${year}`;
  };


  const num_days = mecca_nights + madinah_nights;
  const hotelImages = hotel_detail
    .map((hotel) =>
      getHotelImages(hotel.hotel_name, [...makkahHotels, ...madinahHotels])
    )
    .flat();

  const handleClick = () => {
    navigate(`/package-detail-page/?PackageId=${huz_token}`, {
      state: {
        huz_token,
      },
    });
  };

  const prices = [
    package_base_cost,
    cost_for_child,
    cost_for_infants,
    cost_for_sharing,
    cost_for_quad,
    cost_for_triple,
    cost_for_double,
    cost_for_single,
  ];

  const total_cost = Math.min(...prices.filter(price => price > 0));

  const convertedTotalCost = convert(total_cost, "PKR");
  const departure_city = airline_detail[0]?.flight_from;

  // Determine if any meal is included (if at least one is true)
  const mealsIncluded =
    is_breakfast_included || is_lunch_included || is_dinner_included;

  // Determine if any ziyarah is included (if any ziyarah_list is non-empty)
  const ziyarahIncluded =
    Array.isArray(ziyarah_detail) &&
    ziyarah_detail.some(
      (item) => item.ziyarah_list && item.ziyarah_list.trim() !== ""
    );

  return (
    <div
      className="bg-white border-[1px] border-custom-gray rounded-[8px] p-4 gap-4 hover:shadow-xl transition-all duration-300 flex flex-col lg:flex-row"
      onClick={handleClick}
    >
      {/* Column 1: Hotel Images */}
      <div className="w-full lg:w-[220px] h-48 sm:h-56 md:h-40 lg:h-[180px] overflow-hidden rounded-md shadow-sm">
        {hotelImages.length > 0 ? (
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            loop
            autoplay={false}
            pagination={{ clickable: true }} // Added pagination dots
            modules={[Autoplay, Pagination]} // Include both modules
            className="h-full"
          >
            {hotelImages.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  src={image}
                  alt={package_name}
                  className="w-full h-full object-cover rounded-md"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <img
            src="https://via.placeholder.com/150"
            alt="Default hotel"
            className="w-full h-full object-cover rounded-md"
          />
        )}
      </div>

      {/* Column 2: Package Details */}
      <div className="flex flex-col flex-grow mt-4 lg:mt-0">
        {/* Row 1: Basic Details */}
        <div className="mb-4 cursor-pointer flex flex-col sm:flex-row justify-between gap-3">
          <div className="flex-grow">
            <h3 className="font-bold text-lg sm:text-xl text-gray-800">
              {package_name}
            </h3>
            <p className="text-sm sm:text-base text-gray-500 mt-1">
              {formatDate(start_date)} - {formatDate(end_date)}
            </p>
            <p className="text-brandGold font-medium text-sm mt-1">
              {company_detail?.company_name || "N/A"}
            </p>

            {/* Rating Text */}
            {rating_count && (
              <div className="flex items-center mt-2">
                <span className="w-[30px] h-[30px] flex items-center justify-center rounded-sm bg-brandGold text-white text-sm font-bold">
                  {Number(rating_count.average_stars).toFixed(1)}
                </span>
                <span className="text-xs text-gray-500 ml-1">
                  ({rating_count.rating_count} Reviews)
                </span>
              </div>
            )}
          </div>
          <div className="sm:w-[20%] flex sm:justify-end">
            <div className="w-14 h-14 flex flex-col items-center justify-center border-[1px] border-brandGold bg-white rounded-full text-brandGold shadow-sm">
              <p className="text-base font-bold">{num_days}</p>
              <p className="font-bold text-[10px] leading-none">DAYS</p>
            </div>
          </div>
        </div>
        {/* Row 2: Pricing & Flight Details */}
        <div className="flex items-end justify-between mt-4 sm:mt-0">
          {/* Badge Row: Ziyarah & Meals */}
          <div className="flex flex-wrap gap-2 mt-2">
            {ziyarahIncluded && (
              <span className="px-2 py-1 border border-blue-700 text-blue-700 bg-white text-xs rounded">
                Ziyarah Included
              </span>
            )}
            {mealsIncluded && (
              <span className="px-2 py-1 border border-[#00936c] text-[#00936c] bg-white text-xs rounded">
                Meals Included
              </span>
            )}
          </div>

          <div className="text-right sm:text-left items-end">
            <p className="text-gray-500 text-xs font-medium mb-1 flex items-center justify-end">
              <FaPlane className="text-brandGold mr-1" /> Departs{" "}
              {departure_city}
            </p>
            <div className="space-y-1">
              <p className="text-gray-600 text-xs font-medium text-right">
                Starting from
                <span className="ml-2 text-brandGold text-xl font-bold">
                  {formatPrice(convertedTotalCost, currency)}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageCard;
