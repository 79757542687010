import React, { useEffect } from "react";
import Banner from "../../HeroSection/Banner";
import HeaderFooter from "../../../../ZiyaratArticlesPages/HeaderFooter";
import Slider from "../../MadinahPlaces/Wadijin/Slider";
import Page from "./Page";
import ShubraPalaceImg1 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/shubrapalace/image2.webp";
import ShubraPalaceImg2 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/shubrapalace/image3.jpg";
import ShubraPalaceImg3 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/shubrapalace/image4.jpg";
import ShubraPalaceImg4 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/shubrapalace/image5.jpeg";
const ShubraPalace = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const images = [
    ShubraPalaceImg1,
    ShubraPalaceImg2,
    ShubraPalaceImg3,
    ShubraPalaceImg4,
  ];

  return (
    <div>
      <HeaderFooter>
        <Banner image={ShubraPalaceImg1} title={"Shubra Palace"} />
        <Page />
        <Slider images={images} />
      </HeaderFooter>
    </div>
  );
};

export default ShubraPalace;
