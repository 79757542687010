import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import {
  BookingDetail,
  PackageHeader,
  PassportUpload,
  Footer,
  Header,
  Loader,
} from "../../../../../";
import banner from "../../../../../assets/Detail-Page/banner.png";
import { fetchBookingDetails } from "../../../../../api/apiService";

const DocumentUpload = () => {
  const location = useLocation();
  const [storedPackageDetails, setStoredPackageDetails] = useState(null);
  const [storedBookingResponse, setStoredBookingResponse] = useState(null);
  const [booking, setBooking] = useState(null);
  const [loading, setLoading] = useState(true);

  // Load data from localStorage on component mount
  useEffect(() => {
    const packageDetailsFromStorage = JSON.parse(
      localStorage.getItem("PackageDetails")
    );
    const bookingResponseFromStorage = JSON.parse(
      localStorage.getItem("BookingResponse")
    );
    setStoredPackageDetails(packageDetailsFromStorage);
    setStoredBookingResponse(bookingResponseFromStorage);
  }, []);

  const {
    packageDetails: locPackageDetails,
    bookingResponse: locBookingResponse,
  } = location.state || {};
  const packageDetails = locPackageDetails ?? storedPackageDetails;
  const bookingResponse = locBookingResponse ?? storedBookingResponse;

  const fetchBookingData = useCallback(async () => {
    try {
      const userProfile = JSON.parse(
        localStorage.getItem("Login-User-Profile")
      );
      if (!userProfile?.session_token)
        throw new Error("No session token found");

      const sessionToken = userProfile.session_token;
      const bookingData = await fetchBookingDetails(
        sessionToken,
        bookingResponse.booking_number
      );

      const parseNumber = (value) =>
        !isNaN(Number(value)) ? Number(value) : 0;
      const roomBreakDown = [
        {
          type: "Sharing Room",
          count: parseNumber(bookingData?.sharing),
          price: parseNumber(bookingData?.cost_for_sharing),
        },
        {
          type: "Quad Room",
          count: parseNumber(bookingData?.quad),
          price: parseNumber(bookingData?.cost_for_quad),
        },
        {
          type: "Triple Room",
          count: parseNumber(bookingData?.triple),
          price: parseNumber(bookingData?.cost_for_triple),
        },
        {
          type: "Double Bed Private Room",
          count: parseNumber(bookingData?.double),
          price: parseNumber(bookingData?.cost_for_double),
        },
      ].filter((room) => room.count > 0);

      setBooking({ ...bookingData, roomBreakDown });
    } catch (error) {
      console.error("Error fetching booking details:", error);
    } finally {
      setLoading(false);
    }
  }, [bookingResponse?.booking_number]);

  useEffect(() => {
    if (bookingResponse) {
      fetchBookingData();
    } else {
      console.error("Missing navigation state data.");
      setLoading(false);
    }
  }, [bookingResponse, fetchBookingData]);

  // 🔴 FIX: Ensure Loading Screen Instead of Error
  if (!bookingResponse) {
    return (
      <div className="bg-white flex items-center justify-center h-screen">
        <Loader />
      </div>
    );
  }

  if (loading || !booking) {
    return (
      <div className="bg-white flex items-center justify-center h-screen">
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <Header />
      <div className="w-all-sections-width mx-auto mb-10">
        <img src={banner} alt="banner-image" className="w-full my-10" />
        <div className="flex flex-col lg:flex-row gap-6">
          <div className="w-full lg:w-[70%]">
            <PackageHeader data={bookingResponse} />
            <PassportUpload
              documents={booking?.booking_documents || []} // ✅ Safe check
              data={bookingResponse}
              sessionToken={
                JSON.parse(localStorage.getItem("Login-User-Profile"))
                  ?.session_token
              }
              bookingNumber={bookingResponse?.booking_number} // ✅ Safe check
            />
          </div>
          <div className="w-full lg:w-1/3">
            <BookingDetail
              bookingResponse={booking || {}} // ✅ Prevents accessing undefined properties
              travellers={booking?.adults || 0} // ✅ Safe check
              roomBreakDown={booking?.roomBreakDown || []} // ✅ Safe check
              selectedRooms={packageDetails?.selectedRooms || []}
              prices={packageDetails?.prices || []}
              rooms={packageDetails?.rooms || []}
              totalSelectedRooms={packageDetails?.totalSelectedRooms || 0}
              totalRoomCost={packageDetails?.totalRoomCost || 0}
              total_amount={packageDetails?.total_amount || 0}
              payable_amount={packageDetails?.payable_amount || 0}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DocumentUpload;
