import React from "react";
import {
  Header,
  Hero,
  PopularPackages,
  AppDownload,
  BrowseByPeople,
  ServicesSection,
  ServicesSlider,
  Inspiration,
  Footer,
  Vision,
} from "../../";
import heroimg from "../../assets/homepage/HeroSection/HeroBg.svg";
const HomePage = () => {
  return (
    <main className="bg-white">
      <div
        className="w-full bg-cover bg-center"
        style={{
          backgroundImage: `linear-gradient(0deg, 
            rgba(12, 42, 53, 1) 0%, 
            rgba(24, 77, 97, 0.5) 60%, 
            rgba(12, 42, 53, 1) 100%), 
            url(${heroimg})`,
        }}
      >
        <Header />
        <Hero />
      </div>

      <div className="">
        <ServicesSlider />
        <PopularPackages />
        <ServicesSection />
        <Vision />
        <BrowseByPeople />
        <Inspiration />
        <AppDownload />
        <Footer />
      </div>
    </main>
  );
};

export default HomePage;
