import React from "react";
import HeroSection from "../../HeroSection/HeroSection";
import { gharSur } from "../../../../../Utilities/data";
import Page from "./Page";
import HeaderFooter from "../../../../ZiyaratArticlesPages/HeaderFooter";

const GharEsoor = () => {
  return (
    <div>
      <HeaderFooter>
        <HeroSection
          herobg={gharSur.hero.image}
          heading={gharSur.hero.heading}
        />
        <Page />
      </HeaderFooter>
    </div>
  );
};

export default GharEsoor;
