import React, { useEffect, useRef, useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "./swiper.css";

const BlogCard = ({ title, description, imageUrl, link }) => {
  const navigate = useNavigate();
  const handleClick = () => navigate(link);

  return (
    <div
      className="max-w-sm mb-10 rounded overflow-hidden shadow-lg cursor-pointer bg-white flex flex-col justify-between"
      onClick={handleClick}
    >
      <img className="w-full h-48 object-cover rounded-t-lg" src={imageUrl} alt={title} />
      <div className="flex flex-col justify-between flex-grow px-4 pt-4">
        <div className="font-bold text-[16px] mb-2 font-cairo text-[#484848]">{title}</div>
        <p className="text-[#4B465C] font-kd font-medium text-[14px] h-[60px]">{description.substring(0, 65)}...</p>
      </div>
      <hr className="mt-4 w-full" />
      <div className="flex items-center justify-between p-4">
        <h2 className="text-[16px] font-bold text-[#484848] font-cairo">Read More</h2>
        <FaArrowRight className="text-[16px] text-[#484848]" />
      </div>
    </div>
  );
};

const Section = ({ data, title }) => {
  const [swiper, setSwiper] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const prevButtonRef = useRef(null);
  const nextButtonRef = useRef(null);
  const swiperRef = useRef(null);

  const handlePrev = () => swiperRef.current?.swiper.slidePrev();
  const handleNext = () => swiperRef.current?.swiper.slideNext();

  const updateButtonVisibility = () => {
    if (!swiper || !prevButtonRef.current || !nextButtonRef.current) return;
    prevButtonRef.current.classList.toggle("hidden", currentIndex <= 0 && !swiper.loop);
    nextButtonRef.current.classList.toggle("hidden", currentIndex >= data.length - 1);
  };

  useEffect(() => {
    if (swiper) updateButtonVisibility();
  }, [swiper, currentIndex]);

  return (
    <div className=" w-all-sections-width mx-auto mt-12">
      <div className="text-left mb-4">
        <div className="relative flex items-center mt-3">
          <div className="bg-[#F1F1F1] opacity-80 px-3 rounded-md">
            <h2 className="text-2xl font-cairo text-[#484848] font-bold">{title}</h2>
          </div>
        </div>
      </div>
      <div className="slider-container relative hidden md:block">
        <Swiper
          ref={swiperRef}
          onSwiper={setSwiper}
          loop={false}
          spaceBetween={20}
          slidesPerView={1}
          breakpoints={{
            1440: { slidesPerView: 5, spaceBetween: 10 },
            1024: { slidesPerView: 4, spaceBetween: 10 },
            768: { slidesPerView: 3, spaceBetween: 10 },
          }}
          onSlideChange={(swiper) => {
            setCurrentIndex(swiper.activeIndex);
            updateButtonVisibility();
          }}
          modules={[Navigation]}
          navigation={{
            prevEl: prevButtonRef.current,
            nextEl: nextButtonRef.current,
          }}
        >
          {data.map((pkg, index) => (
            <SwiperSlide key={index}>
              <BlogCard {...pkg} />
            </SwiperSlide>
          ))}
        </Swiper>
        <button onClick={handlePrev} className="absolute top-1/3 -left-4 transform -translate-y-1/3 z-10">
          <IoIosArrowBack className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
        </button>
        <button onClick={handleNext} className="absolute top-1/3 -right-5 transform -translate-y-1/3 z-10">
          <IoIosArrowForward className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
        </button>
      </div>
      <div className="md:hidden block">
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          navigation={{ prevEl: ".swiper-button-prev", nextEl: ".swiper-button-next" }}
          className="mySwiper swiper-container"
          breakpoints={{
            1440: { slidesPerView: 4, spaceBetween: 10 },
            1024: { slidesPerView: 4, spaceBetween: 10 },
            768: { slidesPerView: 3, spaceBetween: 30 },
          }}
          scrollbar={{ draggable: true }}
          onSwiper={setSwiper}
          onSlideChange={(swiper) => setCurrentIndex(swiper.activeIndex)}
        >
          {data.map((pkg, index) => (
            <SwiperSlide key={index}>
              <BlogCard {...pkg} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

const Card = ({ sections }) => (
  <div>
    {sections.map((section, index) => (
      <Section key={index} data={section.data} title={section.title} />
    ))}
  </div>
);

export default Card;
