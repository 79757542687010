import React, { useState, useEffect, useCallback } from "react";
import { getAllRequestsByUser } from "../../api/apiService";
import { Header, Footer, Loader } from "../../";
import { format } from "date-fns";
import banner from "../../assets/Detail-Page/banner.png";

const RequestOverviewPage = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(null);

  const userProfile = JSON.parse(localStorage.getItem("Login-User-Profile") || "{}");
  const sessionToken = userProfile.session_token || "";

  useEffect(() => {
    const fetchRequests = async () => {
      if (sessionToken) {
        try {
          const res = await getAllRequestsByUser(sessionToken);
          if (res.success) {
            setRequests(res.data);
            setSelected(res.data[0] || null);
          }
        } catch (err) {
          console.error("Error fetching requests:", err);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };
    fetchRequests();
  }, [sessionToken]);

  const handleSelect = useCallback((request) => setSelected(request), []);

  const renderRequestItem = useCallback(
    (request) => (
      <div
        key={request.request_id}
        className={`p-6 rounded-lg shadow-sm cursor-pointer transition-all duration-300 transform hover:scale-105 hover:shadow-lg ${
          selected?.request_id === request.request_id
            ? "bg-brandGold text-white shadow-lg"
            : "bg-white border border-gray-200 text-gray-800 hover:bg-gray-100"
        }`}
        onClick={() => handleSelect(request)}
      >
        <h3 className="font-semibold text-lg truncate">{request.request_title}</h3>
        <p className="text-sm mt-1 truncate">{request.request_message}</p>
        <div className="text-xs mt-2 text-gray-400">
          {format(new Date(request.created_at), "PPpp")}
        </div>
      </div>
    ),
    [selected, handleSelect]
  );

  const renderDetails = useCallback(() => {
    if (!selected)
      return (
        <div className="p-8 text-center text-gray-500 animate-fade-in">
          Select a request to view details.
        </div>
      );

    const attachmentUrl =
      selected.request_attachment &&
      (selected.request_attachment.startsWith("http")
        ? selected.request_attachment
        : `${process.env.REACT_APP_API_BASE_URL}${selected.request_attachment}`);

    return (
      <div className="p-8 bg-white shadow-lg rounded-lg border border-gray-200 transform transition-all duration-300 animate-fade-in">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">{selected.request_title}</h2>
        <p className="text-gray-700 mb-6 leading-relaxed">{selected.request_message}</p>
        {attachmentUrl && (
          <div className="mb-6">
            <a
              href={attachmentUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="text-brandGold underline"
            >
              View Attachment
            </a>
          </div>
        )}
        <div className="text-sm space-y-2">
          <div>
            <span className="font-semibold">Status:</span>{" "}
            <span
              className={`px-3 py-1 rounded-full text-xs font-medium ${
                selected.request_status === "Resolved"
                  ? "bg-green-100 text-green-800"
                  : "bg-yellow-100 text-yellow-800"
              }`}
            >
              {selected.request_status}
            </span>
          </div>
          <div>
            <span className="font-semibold">Time:</span>{" "}
            {format(new Date(selected.created_at), "PPpp")}
          </div>
        </div>
      </div>
    );
  }, [selected]);

  if (loading)
    return (
      <div className="bg-white flex items-center justify-center h-screen">
        <Loader />
      </div>
    );

  return (
    <div className="min-h-screen flex flex-col bg-gray-100">
      <Header />
      <div className="w-all-sections-width mx-auto mb-10">
        <img src={banner} alt="banner" className="w-full rounded-lg shadow-md mb-8" />
        <h2 className="h2">My Requests</h2>
        <div className="flex flex-col md:flex-row flex-1 overflow-hidden gap-6">
          <div className="w-full md:w-1/3 bg-white p-4 rounded-lg shadow-md overflow-auto space-y-4 animate-slide-in-left">
            {requests.length ? (
              requests.map(renderRequestItem)
            ) : (
              <div className="p-6 text-gray-500">No requests found.</div>
            )}
          </div>
          <div className="w-full md:w-2/3 overflow-auto animate-slide-in-right">
            {renderDetails()}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RequestOverviewPage;
