// src/Utilities/currencyUtils.js
const CURRENCY_API_URL = 'https://v6.exchangerate-api.com/v6/5b48cfc27070a3cfa2ba7ece/latest/USD';
const CACHE_DURATION = 3600000; // 1 hour in milliseconds

export const fetchExchangeRates = async () => {
  try {
    const cachedData = localStorage.getItem('currencyData');
    const now = Date.now();

    if (cachedData) {
      const { timestamp, data } = JSON.parse(cachedData);
      if (now - timestamp < CACHE_DURATION) {
        return data;
      }
    }

    const response = await fetch(CURRENCY_API_URL);
    if (!response.ok) throw new Error('Failed to fetch rates');
    
    const newData = await response.json();
    const cacheEntry = {
      timestamp: now,
      data: newData
    };
    
    localStorage.setItem('currencyData', JSON.stringify(cacheEntry));
    return newData;

  } catch (error) {
    console.error('Error fetching exchange rates:', error);
    const cachedData = localStorage.getItem('currencyData');
    return cachedData ? JSON.parse(cachedData).data : null;
  }
};

export const convertPrice = (amount, fromCurrency, toCurrency, rates) => {
  if (!rates || fromCurrency === toCurrency) return amount;
  
  // Convert to USD first if needed
  const usdAmount = fromCurrency === 'USD' 
    ? amount 
    : amount / rates[fromCurrency];
  
  // Convert to target currency
  return toCurrency === 'USD' 
    ? usdAmount 
    : usdAmount * rates[toCurrency];
};

export const formatPrice = (amount, currency) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(amount);
};