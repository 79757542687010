import React from "react";
import { Footer, Header } from "../../../";
const RefundPolicy = () => {
  return (
    <div>
      <Header />
      <div className="w-all-sections-width mx-auto h-screen text-4xl font-bold">
        Refund Policy{" "}
      </div>
      <Footer />
    </div>
  );
};

export default RefundPolicy;
