import React from "react";
import { FaCheck } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules"; // Added Pagination here
import {
  getHotelImages,
  makkahHotels,
  madinahHotels,
} from "../../Utilities/UtilityFunctions";
import airPlane from "../../assets/homepage/popularPackage/airFlight.png";
import hotels from "../../assets/homepage/popularPackage/hotel.png";
import transport from "../../assets/homepage/popularPackage/transport.png";
import saudiVisa from "../../assets/homepage/popularPackage/visa.png";
import { useNavigate } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import { useCurrency } from "../../context/CurrencyContext";

const PackageCardHome = ({ packageData }) => {
  const {
    huz_token,
    package_name,
    company_detail: { company_name } = {},
    start_date,
    end_date,
    hotel_detail = [],
    is_visa_included,
    is_flight_included,
    is_transport_included,
    is_tour_guide_included,
    depart_city,
    cost_for_sharing,
    airline_detail = [],
    is_breakfast_included,
    is_lunch_included,
    is_dinner_included,
    package_base_cost,
    cost_for_child,
    cost_for_infants,
    cost_for_quad,
    cost_for_triple,
    cost_for_double,
    cost_for_single,
    ziyarah_detail = [],
  } = packageData;
  const navigate = useNavigate();
  const { currency, convert } = useCurrency();

  // Calculate total days for display
  const totalDays = Math.floor(
    (new Date(end_date) - new Date(start_date)) / (1000 * 3600 * 24)
  );
  const airlineDetailObj = airline_detail.length > 0 ? airline_detail[0] : null;
  const departCity = depart_city || "Islamabad";

  // Dynamic inclusions (for the checklist)
  const inclusions = [
    { label: `Departs from ${departCity}`, included: true },
    ...(airlineDetailObj
      ? [
        {
          label: (
            <>
              <strong>Airline:</strong> {airlineDetailObj.airline_name}
            </>
          ),
          included: true,
        },
      ]
      : []),
  ];

  const travellers = 1;
  const handleClick = () => {
    navigate(`/package-detail-page/?PackageId=${huz_token}`, {
      state: {
        huz_token,
        travellers,
      },
    });
  };

  const prices = [
    package_base_cost,
    cost_for_child,
    cost_for_infants,
    cost_for_sharing,
    cost_for_quad,
    cost_for_triple,
    cost_for_double,
    cost_for_single,
  ];

  const total_cost = Math.min(...prices.filter(price => price > 0));

  return (
    <div className="rounded-lg lg:w-[270px] xl:w-auto h-[470px] overflow-hidden transition-transform duration-300 hover:scale-105 flex flex-col">
      {/* Top Image Section with Swiper */}
      <div className="relative w-full h-[270px] overflow-hidden rounded-lg">
        {hotel_detail.length > 0 ? (
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            loop
            pagination={{ clickable: true }}
            navigation={false}
            modules={[Autoplay, Pagination]}
          >
            {hotel_detail.map((hotel) => {
              const images = getHotelImages(hotel.hotel_name, [
                ...makkahHotels,
                ...madinahHotels,
              ]);
              return images.length > 0 ? (
                images.map((image, idx) => (
                  <SwiperSlide key={`${hotel.hotel_name}-${idx}`}>
                    <div className="relative w-full h-full">
                      <img
                        src={image}
                        alt={hotel.hotel_name}
                        className="object-cover w-full h-full"
                        style={{ width: "100%", height: "270px" }}
                      />
                      <div
                        className="absolute inset-0"
                        style={{
                          background:
                            "linear-gradient(0deg, #0C2A35 0%, rgba(24, 77, 97, 0) 50%)",
                        }}
                      ></div>
                    </div>
                  </SwiperSlide>
                ))
              ) : (
                <SwiperSlide key={hotel.hotel_name}>
                  <div className="relative w-full h-full">
                    <img
                      src="https://plus.unsplash.com/premium_photo-1661964071015-d97428970584?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8aG90ZWx8ZW58MHx8MHx8fDA%3D"
                      alt="Default Hotel"
                      className="object-cover w-full h-full"
                      style={{ width: "100%", height: "270px" }}
                    />
                    <div
                      className="absolute inset-0"
                      style={{
                        background:
                          "linear-gradient(0deg, #0C2A35 0%, rgba(24, 77, 97, 0) 50%)",
                      }}
                    ></div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        ) : (
          <div className="relative w-full h-full">
            <img
              src="default-image-url.jpg"
              alt="Default Package"
              className="object-cover w-full h-full"
              style={{ width: "100%", height: "270px" }}
            />
            <div
              className="absolute inset-0"
              style={{
                background:
                  "linear-gradient(0deg, #0C2A35 0%, rgba(24, 77, 97, 0) 50%)",
              }}
            ></div>
          </div>
        )}
        <div onClick={handleClick}>
          <div className="absolute top-4 right-4 bg-white text-brandGold w-12 h-12 flex items-center justify-center rounded-full z-50 cursor-pointer">
            <p className="text-sm text-center leading-4">
              {totalDays} <br /> days
            </p>
          </div>

          <div className="absolute bottom-8 left-4 text-start text-white z-50 cursor-pointer">
            <h3 className="font-[500] text-lg lg:text-2xl leading-none">
              {package_name}
            </h3>
            <p className="text-brandGold font-[600] text-sm leading-none">
              By {company_name}
            </p>
            <p className="text-sm font-semibold leading-5 ">
              {new Date(start_date).toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
              })}{" "}
              till{" "}
              {new Date(end_date).toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
              })}
            </p>
          </div>
        </div>
      </div>

      {/* Lower Section with Border */}
      <div
        className="bg-white border-[1px] border-custom-gray rounded-b-lg px-6 pb-4 flex flex-col flex-1 justify-between cursor-pointer"
        onClick={handleClick}
      >
        {/* Features Row Section */}
        <div className="flex gap-1 md:gap-0 md:px-2 justify-between mt-4 mb-6 text-gray-500 text-xs">
          <div className="flex text-center items-center flex-col">
            <img
              src={airPlane}
              alt="airPlane"
              className={`${airlineDetailObj?.is_return_flight_included
                ? "text-brandGold"
                : "text-mediumBlue"
                } mb-1`}
            />
            <span className="text-xs text-gray-500">
              {airlineDetailObj?.is_return_flight_included
                ? "Round Trip"
                : is_flight_included
                  ? "One Way"
                  : "No Flight"}
            </span>
          </div>
          <div className="flex text-center items-center flex-col">
            <img src={hotels} alt="hotel" className="text-brandGold mb-1" />
            <span className="text-xs text-gray-500">
              {hotel_detail.length === 1
                ? "1 Hotel"
                : hotel_detail.length === 2
                  ? "2 Hotels"
                  : "No Hotel"}
            </span>
          </div>
          <div className="flex items-center text-center flex-col">
            <img
              src={transport}
              alt="transport"
              className={`${is_transport_included ? "text-brandGold" : "text-mediumBlue"
                } mb-1`}
            />
            <span className="text-xs text-gray-500">Transport</span>
          </div>
          <div className="flex items-center text-center flex-col">
            <img
              src={saudiVisa}
              alt="visa"
              className={`${is_visa_included ? "text-brandGold" : "text-mediumBlue"
                } mb-1`}
            />
            <span className="text-xs text-gray-500">Visa</span>
          </div>
        </div>

        {/* Inclusions Section */}
        <div className="space-y-2 mb-4">
          {inclusions.map((item, index) => (
            <div
              key={index}
              className="flex items-center gap-4 font-light text-secondaryGray"
            >
              <FaCheck className="text-brandGold" />
              <span className="text-sm">{item.label}</span>
            </div>
          ))}
        </div>

        {/* Bottom Section with Meals, Ziyarah, and Price */}
        <div>
          <div className="flex justify-between items-center mt-auto">
            <div className="flex gap-2">
              {(is_breakfast_included || is_lunch_included || is_dinner_included) && (
                <div className="border border-[#00936c] text-xs rounded-sm text-[#00936c] p-1">
                  Meals <span className="ml-1">✓</span>
                </div>
              )}
              {ziyarah_detail &&
                ziyarah_detail.some(
                  (item) => item.ziyarah_list && item.ziyarah_list.trim() !== ""
                ) && (
                  <div className="border border-blue-700 text-xs rounded-sm text-blue-700 p-1">
                    Ziyarah <span className="ml-1">✓</span>
                  </div>
                )}
            </div>
            <p className="text-darkBlue text-end lg:text-lg xl:text-xl font-medium">
              <p className="text-brandGold font-[500] text-xs mb-2 lg:mb-0">
                Starting from
              </p>
              <NumericFormat
                value={convert(total_cost, "PKR")}
                displayType={"text"}
                thousandSeparator
                prefix={`${currency} `}
                decimalScale={0}
                fixedDecimalScale={true}
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageCardHome;
