import React, { useEffect } from "react";
import Banner from "../../HeroSection/Banner";
import HeaderFooter from "../../../../ZiyaratArticlesPages/HeaderFooter";
import Slider from "../../MadinahPlaces/Wadijin/Slider";
import Page from "./Page";
import AlHadaMountainImg1 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/hadaMountain/image2.jpeg";
import AlHadaMountainImg2 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/hadaMountain/image3.jpg";
import AlHadaMountainImg3 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/hadaMountain/image4.jpg";
import AlHadaMountainImg4 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/hadaMountain/image5.webp";
import bg from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/hadaMountain/image1.webp";
const ALHadaMountain = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const images = [
    AlHadaMountainImg1,
    AlHadaMountainImg2,
    AlHadaMountainImg3,
    AlHadaMountainImg4,
  ];

  return (
    <div>
      <HeaderFooter>
        <Banner image={bg} title={"Al-Hada-Mountain"} />
        <Page />
        <Slider images={images} />
      </HeaderFooter>
    </div>
  );
};

export default ALHadaMountain;
