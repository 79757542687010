import React from "react";
import TaifZooImg4 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/taifzoo/image2.avif";
import TaifZooImg5 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/taifzoo/image1.jpg";
const MainPage = () => {
  return (
    <div>
      <div className="bg-[#F2F2F2]">
        <p className="text-center text-[#484848] font-bold py-4 font-cairo text-[25px]">
          Taif Zoo: A Hidden Gem in Saudi Arabia
        </p>
      </div>
      <div className=" w-all-sections-width mx-auto">
        {/* upper section named location */}
        <div className="pt-14">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Introduction
          </h2>
          <div className="flex flex-col lg:flex-row mt-4 gap-4">
            <div className="md:w-full text-[#484848] mb-4 font-cairo font-medium text-[15px] leading-7">
              <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px]">
                Nestled in the scenic city of Taif, Saudi Arabia, the Taif Zoo
                is a popular destination for both locals and tourists. Offering
                a blend of educational experiences and recreational activities,
                the zoo is a fantastic place for families, nature enthusiasts,
                and anyone looking to explore the diverse wildlife in the
                region.
                <br />
                Location and Accessibility
                <br />
                Taif Zoo is conveniently located in the heart of Taif, making it
                easily accessible from various parts of the city. The zoo is
                situated near King Fahad Park, another prominent attraction in
                Taif, which makes it a great spot for a full day of outdoor
                activities. Visitors can reach the zoo by car, taxi, or local
                transport, with ample parking available on-site.
                {/*  */}
              </p>
              <p className="lg:hidden xl:block">
                Taif Zoo is conveniently located in the heart of Taif, making it
                easily accessible from various parts of the city. The zoo is
                situated near King Fahad Park, another prominent attraction in
                Taif, which makes it a great spot for a full day of outdoor
                activities. Visitors can reach the zoo by car, taxi, or local
                transport, with ample parking available on-site.
              </p>
            </div>
            <div className="lg:w-1/2 lg:mt-0">
              <img
                src={TaifZooImg5}
                alt="Wadi-e-Jinn"
                className="rounded-lg shadow-lg w-[523px]"
              />
            </div>
          </div>
          <p className="hidden lg:flex xl:hidden text-[#484848] mb-4 font-cairo font-medium text-[15px]">
            Taif Zoo is conveniently located in the heart of Taif, making it
            easily accessible from various parts of the city. The zoo is
            situated near King Fahad Park, another prominent attraction in Taif,
            which makes it a great spot for a full day of outdoor activities.
            Visitors can reach the zoo by car, taxi, or local transport, with
            ample parking available on-site.
          </p>
        </div>
        {/* location section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Exhibits and Animals
          </h2>
          <div className="mt-4">
            <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px] ">
              The zoo boasts a wide variety of animals from different parts of
              the world. It is home to mammals, birds, reptiles, and amphibians,
              providing a comprehensive look at the animal kingdom. Some of the
              star attractions include:
              <br />
              Big Cats: Lions, tigers, and leopards are some of the majestic big
              cats that visitors can admire. The enclosures are designed to
              mimic their natural habitats, offering a safe and enriching
              environment for these powerful predators.
            </p>
          </div>
          <div className="mt-8 flex flex-col items-center">
            <img
              src={TaifZooImg4}
              alt="Islamic Calligraphy"
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>
        </div>
        {/* islam section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            A Rich Tapestry of Wildlife
          </h2>
          <div className="mt-4">
            A Rich Tapestry of Wildlife Taif Zoo is home to a variety of animal
            species from different parts of the world. Visitors can marvel at
            the majestic lions and tigers, watch playful monkeys swing from tree
            to tree, and observe the grace of giraffes as they browse on tall
            vegetation. The zoo also features a range of birds, reptiles, and
            smaller mammals, providing a comprehensive view of the animal
            kingdom. One of the highlights is the large aviary, where visitors
            can walk through and see exotic birds up close. The zoo’s efforts to
            recreate natural habitats ensure that the animals live in
            environments that closely resemble their wild homes, promoting their
            well-being and allowing for natural behaviors. Educational and
            Interactive Experiences Education is a key component of Taif Zoo's
            mission. The zoo offers numerous educational programs and
            interactive experiences aimed at fostering a deeper understanding
            and appreciation of wildlife. School groups and families can
            participate in guided tours led by knowledgeable staff, who provide
            insights into the animals’ behaviors, habitats
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
