import React from "react";
import Card from "./Card";
import makkahImg from "../../../../assets/HelpPage/makkahimg.jpg";
import manageImg from "../../../../assets/HelpPage/manage.jpg";
import kabbaImg from "../../../../assets/HelpPage/kabba1.jpeg";
import airCoverImg from "../../../../assets/HelpPage/aircover2.jpg";
const cardData = [
  {
    title: "Getting started with Huz",
    imageUrl: makkahImg,
    link: "/get-started",
    imagestyles: "rounded-lg",
  },
  {
    title: "Access and manage your account",
    imageUrl: manageImg,
    link: "/manage-account",
    imagestyles: "rounded-lg",
  },
  {
    title: "Help with a reservation",
    imageUrl: kabbaImg,
    link: "/reservation",
    imagestyles: "rounded-lg",
  },
  {
    title: "AirCover for Pilgrims",
    imageUrl: airCoverImg,
    imagestyles: "rounded-lg",
  },
];

const GuestPage = () => {
  return (
    <div>
      <div className="grid md:grid-cols-4 grid-cols-1 gap-4">
        {cardData.map((card) => (
          <Card
            title={card.title}
            imageUrl={card.imageUrl}
            link={card.link}
            imagestyles={card.imagestyles}
          />
        ))}
      </div>
    </div>
  );
};

export default GuestPage;
