// src/components/MessageDisplay/MessageDisplay.js
import React from "react";
import { FaSadTear, FaSpinner, FaInfoCircle } from "react-icons/fa";

const ButtonsLoader = ({ message, type }) => {
  const renderIcon = () => {
    switch (type) {
      case "loading":
        return <FaSpinner className="animate-spin text-white text-xl" />;
      case "error":
        return <FaSadTear className="text-white text-xl" />;
      case "no-data":
        return <FaInfoCircle className="text-white text-xl" />;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col items-center justify-center text-center">
      {renderIcon()}
      <p className="text-gray-500 text-lg">{message}</p>
    </div>
  );
};

export default ButtonsLoader;
