import React, { useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import chatLogo from "../../../../assets/Booking/chat.png";
import { AiOutlineInfoCircle } from "react-icons/ai";

const BookingInfo = ({ bookingResponse, handleClick }) => {
  const navigate = useNavigate();
  const [isTimerExpired, setIsTimerExpired] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState("");

  useEffect(() => {
    if (
      bookingResponse.booking_status === "Confirm" &&
      bookingResponse.payment_detail?.length > 0
    ) {
      const transactionTime = new Date(
        bookingResponse.payment_detail[0].transaction_time
      );
      const deadline = new Date(transactionTime.getTime() + 172800000);
      const now = new Date();

      if (now >= deadline) {
        setIsTimerExpired(true);
        setTimeRemaining("00:00:00");
      } else {
        const updateTimer = () => {
          const now = new Date();
          const diff = deadline - now;
          if (diff <= 0) {
            setIsTimerExpired(true);
            setTimeRemaining("00:00:00");
            clearInterval(intervalId);
          } else {
            const hours = Math.floor(diff / (1000 * 60 * 60));
            const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((diff % (1000 * 60)) / 1000);
            setTimeRemaining(
              `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(
                seconds
              ).padStart(2, "0")}`
            );
          }
        };

        updateTimer();
        const intervalId = setInterval(updateTimer, 1000);
        return () => clearInterval(intervalId);
      }
    }
  }, [bookingResponse.booking_status, bookingResponse.payment_detail]);

  const disabledStatuses = useMemo(() => ["Active", "Completed", "Closed"], []);
  const isStatusDisabled = useMemo(
    () => disabledStatuses.includes(bookingResponse.booking_status),
    [bookingResponse.booking_status, disabledStatuses]
  );

  const totalPaid = useMemo(
    () =>
      bookingResponse.payment_detail?.reduce(
        (sum, { transaction_amount }) => sum + transaction_amount,
        0
      ) || 0,
    [bookingResponse.payment_detail]
  );

  const remainingBalance = bookingResponse.total_price - totalPaid;
  const payments = bookingResponse.payment_detail || [];
  const hasTwoPayments = payments.length === 2;
  const hasUnapprovedPayment =
    hasTwoPayments && payments.some(({ payment_status }) => payment_status === null);

  const paymentButtonConfig = useMemo(() => {
    if (remainingBalance === 0) {
      return hasUnapprovedPayment
        ? {
          text: "Payment is not approved yet",
          disabled: true,
          class: "bg-red-50 cursor-not-allowed",
        }
        : {
          text: "Completed",
          disabled: true,
          class: "bg-green-50 cursor-not-allowed",
        };
    }
    return payments.length === 1
      ? { text: "Payment Pending", disabled: false, class: "bg-yellow-50" }
      : { text: "Pay Remaining", disabled: false, class: "bg-yellow-50" };
  }, [remainingBalance, payments.length, hasUnapprovedPayment]);

  const isTimerCondition =
    bookingResponse.booking_status === "Confirm" && isTimerExpired;
  const { text: buttonText, class: paymentButtonClass } = paymentButtonConfig;

  const passportButtonText =
    bookingResponse.booking_status === "Confirm" ? "In Progress" : "Completed";
  const passportButtonClass =
    isStatusDisabled || isTimerCondition
      ? "bg-gray-200 cursor-not-allowed border-green bg-green-50 text-black"
      : bookingResponse.booking_status === "Confirm"
        ? "bg-yellow-50"
        : "bg-green-50";

  const handleRedirect = (path) => navigate(path);
  const isPassportDisabled = isStatusDisabled || isTimerCondition;
  const isPaymentDisabled =
    paymentButtonConfig.disabled || isStatusDisabled || isTimerCondition;

  return (
    <div className="text-secondaryBlue space-y-4">
      {bookingResponse.booking_status === "Paid" && (
        <div className="bg-white p-6 border-[1px] rounded-md">
          <p className="text-xl font-bold">Payment Confirmation is Pending</p>
          <p className="text-lg">
            Your Booking Number is:
            <span className="text-green-500 pl-1">
              {bookingResponse.booking_number}
            </span>
            <p className="text-sm font-light mt-2">
              <strong>Dear {bookingResponse.user_fullName},</strong>
              <br /><br />
              We hope this message finds you well. Your payment is currently under review. This process typically takes less than 10 mins. Stay tuned, we will email you as soon as everything is confirmed.<br /><br />
              Thank you for your patience.<br /><br />
              Thanks,<br />
              HajjUmrah.co
            </p>
          </p>
        </div>
      )}

      {["Confirm", "Pending", "Completed", "Active", "Closed"].includes(
        bookingResponse.booking_status
      ) && (
          <div className="space-y-4">
            <div className="bg-white p-6 border-[1px] rounded-md">

              {!["Completed", "Active", "Closed"].includes(bookingResponse.booking_status) && (
                <div>
                  <p className="text-xl font-bold">Documents uploaded successfully.</p>
                  <p className="text-lg">
                    Booking Number:
                    <span className="text-green-500 pl-1">
                      {bookingResponse.booking_number}
                    </span>
                  </p>
                </div>
              )}
              {bookingResponse.booking_status === "Pending" && (
                <div className="flex items-strt text-sm mt-6 text-black">
                  <AiOutlineInfoCircle className="mr-2 mt-1" />
                  <span>Your service provider is verifying all travelers' documents. <br></br>  You will be notified immediately once approved.
                  </span>
                </div>

              )}
              {bookingResponse.booking_status === "Confirm" && (
                <p className="text-lg mt-2">
                  Important: Please complete the following within{" "}
                  <span className="text-brandGold">

                    <span className="mt-4">
                      <span className="text-brandGold">{timeRemaining}</span>
                      {isTimerExpired && (
                        <p className="text-sm text-red-500 mt-2">
                          The 48-hour window has expired. Uploading passports and paying
                          the remaining amount is no longer available. Please contact the Help Center immediately.
                        </p>
                      )}
                    </span>

                  </span>
                </p>
              )}
              <div className="space-y-8 mt-8">
                <div
                  className={`md:flex justify-between items-center ${isPassportDisabled ? "cursor-not-allowed" : "cursor-pointer"
                    }`}
                  onClick={() => {
                    if (!isPassportDisabled) handleRedirect("/document-detail");
                  }}
                >
                  <div>
                    <p className="text-sm font-medium">
                      Upload Passport(s) & Passport Photograph(s)
                  </p>
                    <p className="text-sm">
                      Upload a clear copy of the first page of the passport, and a picture of the traveler.
                  </p>
                  </div>
                  <button
                    className={`flex p-2 px-4 text-sm border border-green-500 text-secondaryBlue ${passportButtonClass} rounded-md`}
                    disabled={isPassportDisabled}
                    title={
                      isPassportDisabled
                        ? "This action is unavailable."
                        : "Upload your passport details"
                    }
                  >
                    {passportButtonText}
                  </button>
                </div>

                <div
                  className={`md:flex justify-between items-center ${isPaymentDisabled ? "cursor-not-allowed" : "cursor-pointer"
                    }`}
                  onClick={() => {
                    if (!isPaymentDisabled)
                      handleRedirect(
                        `/payment-page/?bookingNumber=${bookingResponse.booking_number}`
                      );
                  }}
                >
                  <div>
                    <p className="text-sm font-medium">Any Remaining Payment</p>
                    <p className="text-sm">
                      Upload a clear picture of the Deposit slip.
                  </p>
                  </div>
                  <button
                    className={`flex p-2 px-4 text-sm border border-green-500 text-secondaryBlue ${isPaymentDisabled
                      ? "border-green bg-green-50 text-black cursor-not-allowed"
                      : paymentButtonClass
                      } rounded-md`}
                    disabled={isPaymentDisabled}
                    title={
                      isPaymentDisabled
                        ? "This action is unavailable."
                        : "Pay the remaining amount"
                    }
                  >
                    {buttonText}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

      <div className="bg-white p-6 border-[1px] rounded-md">
        <p className="text-xl font-bold">Need Help?</p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
          <button
            onClick={handleClick}
            className="p-4 border-[1px] flex flex-col justify-center items-center text-center"
          >
            <img src={chatLogo} alt="chat" className="mb-2" />
            <p className="text-sm font-semibold">Connect with us</p>
            <p className="text-xs font-bold">Available 24/7</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default BookingInfo;
