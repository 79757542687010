import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef
} from "react";
import {
  createBookingRequest,
  getPackageDetailByPackageId,
} from "../../api/apiService";
import {
  AirlineDetails,
  BookingSummary,
  Footer,
  Header,
  Itinerary,
  Loader,
  PackageHeader,
  PackageItinerary,
  RoomOptions,
  TransportDetails,
  ZiyarahDetails,
  MealsDetails,
} from "../../";
import banner from "../../assets/Detail-Page/banner.png";
import roomImg from "../../assets/Detail-Page/rooms_image.png";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";

const PackageDetailPage = () => {
  const [packageData, setPackageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [travellers, setTravellers] = useState(1);
  const [selectedRooms, setSelectedRooms] = useState({
    double: 0,
    triple: 0,
    quad: 0,
    sharing: 0,
    single: 0,
  });
  const searchParams = new URLSearchParams(window.location.search);
  const huz_token = searchParams.get("PackageId");
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const roomOptionsRef = useRef(null);

  useEffect(() => {
    const fetchPackageDetails = async () => {
      try {
        const result = await getPackageDetailByPackageId({ huz_token });
        if (result.success && result.packageDetail.length > 0) {
          setPackageData(result.packageDetail[0]);
        }
      } catch (error) {
        console.error("Error fetching package details:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchPackageDetails();
  }, [huz_token]);

  const prices = useMemo(
    () => ({
      cost_for_double: packageData?.cost_for_double,
      cost_for_triple: packageData?.cost_for_triple,
      cost_for_quad: packageData?.cost_for_quad,
      cost_for_sharing: packageData?.cost_for_sharing,
      cost_for_single: packageData?.cost_for_single,
    }),
    [packageData]
  );
  const rooms = useMemo(() => {
    const allRooms = [
      {
        type: "Sharing Room",
        price: prices.cost_for_sharing,
        image: roomImg,
        description: "Room with 5 beds",
        beds: 5,
        plus: true,
        sleeps: "5+",
        tags: ["Lowest Best Price"],
        key: "sharing",
      },
      {
        type: "Quad Room",
        price: prices.cost_for_quad,
        image: roomImg,
        description: "Room with 4 beds",
        beds: 4,
        sleeps: 4,
        key: "quad",
      },
      {
        type: "Triple Room",
        price: prices.cost_for_triple,
        image: roomImg,
        description: "Room with 3 beds",
        beds: 3,
        sleeps: 3,
        key: "triple",
      },
      {
        type: "Double Room",
        price: prices.cost_for_double,
        image: roomImg,
        description: "Room with 2 beds",
        beds: 2,
        sleeps: 2,
        key: "double",
      },
      {
        type: "Single Room",
        price: prices.cost_for_single,
        image: roomImg,
        description: "Room with single bed",
        beds: 1,
        sleeps: 1,
        key: "single",
      },
    ];
    return allRooms.filter(room => room.price > 0);
  }, [prices]);

  useEffect(() => {
    if (rooms.length > 0) {
      const lowestPriceRoom = rooms.reduce((prev, curr) =>
        prev.price < curr.price ? prev : curr
      );
      setSelectedRooms((prev) => ({
        ...prev,
        [lowestPriceRoom.key]: travellers,
      }));
    }
  }, [rooms]);

  const handleTravellerChange = useCallback((type) => {
    setTravellers((prev) => {
      const updated = type === "increase" ? prev + 1 : Math.max(1, prev - 1);
      setTimeout(() => roomOptionsRef.current?.scrollIntoView({ behavior: "smooth" }), 0);
      return updated;
    });
  }, []);

  const checkRoomSelection = useCallback(() => {
    const totalBeds = Object.values(selectedRooms).reduce(
      (sum, count) => sum + count,
      0
    );
    return totalBeds === travellers;
  }, [selectedRooms, travellers]);

  const roomBreakDown = useMemo(() => {
    return Object.keys(selectedRooms)
      .map((roomKey) => {
        const room = rooms.find((r) => r.key === roomKey);
        if (room && selectedRooms[roomKey] > 0) {
          return {
            type: room.type,
            count: selectedRooms[roomKey],
            price: room.price,
            accommodates: selectedRooms[roomKey],
          };
        }
        return null;
      })
      .filter(Boolean);
  }, [selectedRooms, rooms]);

  const totalRoomCost = useMemo(
    () =>
      roomBreakDown.reduce(
        (acc, room) => acc + room.price * room.count,
        0
      ),
    [roomBreakDown]
  );

  const package_cost = packageData?.package_base_cost || 0;
  const total_amount = totalRoomCost + package_cost * travellers;
  const totalSelectedRooms = Object.values(selectedRooms).reduce(
    (sum, count) => sum + count,
    0
  );
  const isRoomSelectionValid = checkRoomSelection();

  const handleClick = async () => {
    let session_token = "";
    if (login) {
      const newUserProfile = JSON.parse(
        localStorage.getItem("New-User-Profile") || "{}"
      );
      session_token = login.session_token || newUserProfile.session_token || "";
    }
    const payload = {
      session_token,
      partner_session_token: packageData.partner_session_token,
      huz_token: packageData.huz_token,
      adults: travellers,
      child: 0,
      infants: 0,
      sharing: selectedRooms.sharing || 0,
      quad: selectedRooms.quad || 0,
      triple: selectedRooms.triple || 0,
      double: selectedRooms.double || 0,
      single: selectedRooms.single || 0,
      start_date: packageData.start_date,
      end_date: packageData.end_date,
      total_price: total_amount,
      special_request: "Null",
      payment_type: "Bank",
    };

    if (isRoomSelectionValid) {
      setLoading(true);
      try {
        const bookingResponse = await createBookingRequest(
          payload.session_token,
          payload.partner_session_token,
          payload.huz_token,
          payload.adults,
          payload.child,
          payload.infants,
          payload.sharing,
          payload.quad,
          payload.triple,
          payload.double,
          payload.single,
          payload.start_date,
          payload.end_date,
          payload.total_price,
          payload.special_request,
          payload.payment_type
        );
        navigate(
          `/traveller-info-page/?packageId=${encodeURIComponent(huz_token)}`,
          { state: { bookingResponse: bookingResponse.data } }
        );
      } catch (error) {
        console.error("Error during booking:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  if (loading)
    return (
      <div className="bg-white flex items-center justify-center h-screen">
        <Loader />
      </div>
    );
  if (!packageData)
    return (
      <div className="bg-white flex items-center justify-center h-screen">
        <p>No package data available</p>
      </div>
    );

  return (
    <div className=''>
      <Header />
      <div className="w-all-sections-width mx-auto mb-10">
        <img src={banner} alt="banner" className="w-full my-10" />
        <div className="flex flex-col lg:flex-row gap-6">
          <div className="w-full lg:w-[70%] z-10">
            <PackageHeader data={packageData} />
            <div className="lg:flex gap-4 mb-6">
              <TransportDetails data={packageData} />
              <AirlineDetails data={packageData} />
            </div>
            <PackageItinerary data={packageData} />
            <Itinerary data={packageData} />
            <ZiyarahDetails data={packageData} />
            <MealsDetails data={packageData} />
            <RoomOptions
              travellers={travellers}
              selectedRooms={selectedRooms}
              setSelectedRooms={setSelectedRooms}
              rooms={rooms}
              totalSelectedRooms={totalSelectedRooms}
              ref={roomOptionsRef}
            />
          </div>
          <div className="w-full lg:w-[30%] lg:mb-10 z-50">
            <div className="sticky top-6">
              <BookingSummary
                data={packageData}
                travellers={travellers}
                handleClick={handleClick}
                roomBreakDown={roomBreakDown}
                total_amount={total_amount}
                isRoomSelectionValid={isRoomSelectionValid}
                handleTravellerChange={handleTravellerChange}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PackageDetailPage;
