import React, { useEffect } from "react";
import Banner from "../../HeroSection/Banner";
import HeaderFooter from "../../../../ZiyaratArticlesPages/HeaderFooter";
import Slider from "../../MadinahPlaces/Wadijin/Slider";
import Page from "./Page";
import SaiysadParkImg1 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/saiysadpark/image6.webp";
import SaiysadParkImg2 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/saiysadpark/image5.jpeg";
import SaiysadParkImg3 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/saiysadpark/image4.jpg";
import SaiysadParkImg4 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/saiysadpark/image3.jpeg";
import SaiysadParkImg5 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/saiysadpark/image2.avif";
const SaiysadPark = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const images = [
    SaiysadParkImg1,
    SaiysadParkImg2,
    SaiysadParkImg3,
    SaiysadParkImg4,
  ];

  return (
    <div>
      <HeaderFooter>
        <Banner image={SaiysadParkImg5} title={"Saiysad Park"} />
        <Page />
        <Slider images={images} />
      </HeaderFooter>
    </div>
  );
};

export default SaiysadPark;
