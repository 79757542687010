import React from "react";
import PackageCard from "./PackageCard";
import { Loader, MessageDisplay } from "../../../";

const PackageListing = ({
  packages,
  loading,
  error,
  onPageChange,
  currentPage = 1,
  pageSize = 5,
  totalItems = 0,
}) => {
  const totalPages = Math.ceil(totalItems / pageSize);

  // Helper function with delay to scroll to top
  const handlePageChange = (direction) => {
    onPageChange(direction);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 100);
  };

  return (
    <section className="w-full mx-auto">
      <div className="space-y-4">
        {loading ? (
          <Loader />
        ) : error ? (
          <MessageDisplay message={error} type="error" />
        ) : packages.length > 0 ? (
          packages.map((pkg) => <PackageCard key={pkg.huz_token} data={pkg} />)
        ) : (
          <MessageDisplay message="No packages found" type="no-data" />
        )}
      </div>

      {totalPages > 1 && (
        <div className="flex justify-between items-center mt-8">
          <button
            onClick={() => handlePageChange("prev")}
            disabled={currentPage === 1}
            className={`px-4 py-2 rounded-md border ${
              currentPage > 1
                ? "border-brandGold text-brandGold hover:bg-brandGold hover:text-white"
                : "border-gray-300 text-gray-300 cursor-not-allowed"
            }`}
          >
            Previous
          </button>
          <span className="text-gray-600 text-sm">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => handlePageChange("next")}
            disabled={currentPage >= totalPages}
            className={`px-4 py-2 rounded-md border ${
              currentPage < totalPages
                ? "border-brandGold text-brandGold hover:bg-brandGold hover:text-white"
                : "border-gray-300 text-gray-300 cursor-not-allowed"
            }`}
          >
            Next
          </button>
        </div>
      )}
    </section>
  );
};

export default PackageListing;
