import React from "react";
import { TiTick } from "react-icons/ti";

const MealsDetails = ({ data }) => {
  const { is_breakfast_included, is_lunch_included, is_dinner_included } = data;

  // Create an array of meals that are included.
  const meals = [];
  if (is_breakfast_included) meals.push("Breakfast");
  if (is_lunch_included) meals.push("Lunch");
  if (is_dinner_included) meals.push("Dinner");

  // If no meals are included, do not render anything.
  if (meals.length === 0) return null;

  return (
    <div className="w-full mb-4">
      <div className="flex items-center gap-2 mb-4">
        <h2 className="text-2xl font-bold">Meals</h2>
      </div>
      <div className="bg-white rounded-xl p-4 border border-lightGray">
        <ul className="pl-4 text-gray-700 text-sm">
          {meals.map((meal, idx) => (
            <div key={idx} className="flex justify-between items-center space-y-2">
              <li className="list-none">{meal} Included</li>
              <div className="text-brandGold text-xl">
                <TiTick />
              </div>
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MealsDetails;
