import React, { useMemo } from "react";
import Select from "react-select";
const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: "0.375rem",
    borderColor: "#d1d5db",
    padding: "5px",
    boxShadow: "none",
    "&:hover": {
      borderColor: "#b45309",
    },
  }),
  option: (provided, { isSelected }) => ({
    ...provided,
    backgroundColor: isSelected ? "#d1a554" : "#fff",
    color: isSelected ? "#fff" : "#000",
    "&:hover": {
      backgroundColor: "#d1a554",
      color: "#fff",
    },
  }),
};

const FiltersComponent = ({
  packages,
  filters,
  onFilterChange,
  clearFilters,
}) => {
  // Memoize filter options to prevent unnecessary recalculations
  const filterOptions = useMemo(
    () => ({
      airlines: [...new Set(
        packages.flatMap((p) =>
          p.airline_detail?.map((a) => a.airline_name).filter(Boolean)
        )
      )],
  
      departureCities: [
        { value: "", label: "All Cities" },
        ...Array.from(
          new Set(
            packages.flatMap((p) =>
              p.airline_detail?.map((a) => a.flight_from).filter(Boolean)
            )
          )
        ).map((city) => ({ value: city, label: city })),
      ],
  
      destinationCities: [
        { value: "", label: "All Destinations" },
        ...Array.from(
          new Set(
            packages.flatMap((p) =>
              p.airline_detail?.map((a) => a.flight_to).filter(Boolean)
            )
          )
        ).map((city) => ({ value: city, label: city })),
      ],
  
      durations: [
        { value: "", label: "All Durations" },
        ...Array.from(new Set(packages.map((p) => p.mecca_nights + p.madinah_nights)))
          .sort((a, b) => a - b)
          .map((duration) => ({ value: duration, label: `${duration} Nights` })),
      ],
    }),
    [packages]
  );
  
  // Handle input changes
  const handleChange = (key, value) => {
    onFilterChange({ ...filters, [key]: value });
  };

  return (
    <div className="bg-white p-6 rounded-lg border-[1px]">
      {/* Departure Date */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Departure Date</label>
        <input
          type="date"
          value={filters.departureDate || ""}
          onChange={(e) => handleChange("departureDate", e.target.value)}
          className="w-full p-2 border rounded-md"
          min={new Date().toISOString().split("T")[0]}
        />
      </div>

      {/* Departure City */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Departure City</label>
        <Select
          options={filterOptions.departureCities}
          value={filterOptions.departureCities.find(
            (option) => option.value === filters.departureCity
          ) || filterOptions.departureCities[0]}
          onChange={(selected) =>
            handleChange("departureCity", selected?.value)
          }
          styles={customStyles}
          isClearable
        />
      </div>

      {/* Destination City */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Destination City</label>
        <Select
          options={filterOptions.destinationCities}
          value={filterOptions.destinationCities.find(
            (option) => option.value === filters.destinationCity
          ) || filterOptions.destinationCities[0]}
          onChange={(selected) => handleChange("destinationCity", selected?.value)}
          styles={customStyles}
          isClearable
        />
      </div>

      {/* Trip Duration */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Trip Duration</label>
        <Select
          options={filterOptions.durations}
          value={filterOptions.durations.find(
            (option) => option.value === filters.umrahDuration
          ) || filterOptions.durations[0]}
          onChange={(selected) => handleChange("umrahDuration", selected?.value)}
          styles={customStyles}
          isClearable
        />
      </div>

      {/* Distance Sliders */}
      {["makkah", "madinah"].map((city) => (
        <div key={city} className="mb-4">
          <label className="block text-sm font-medium mb-2">
            {city.charAt(0).toUpperCase() + city.slice(1)} Hotel Distance
          </label>
          <input
            type="range"
            min="0"
            max="20"
            step="0.5"
            value={filters[`${city}HotelDistance`] || 20}
            onChange={(e) =>
              handleChange(`${city}HotelDistance`, parseFloat(e.target.value))
            }
            className="w-full appearance-none h-2 rounded-lg outline-none transition-all 
                 focus:ring-2 focus:ring-[rgba(209,165,84,1)]"
            style={{
              background: `linear-gradient(to right, rgba(209,165,84,1) 0%, rgba(209,165,84,1) ${
                (filters[`${city}HotelDistance`] || 20) * 5
              }%, 
        #e5e7eb ${(filters[`${city}HotelDistance`] || 20) * 5}%, #e5e7eb 100%)`,
            }}
          />
          <style jsx>{`
            input[type="range"]::-webkit-slider-thumb {
              appearance: none;
              width: 16px;
              height: 16px;
              background-color: #b45309; /* Tailwind bg-gold-800 */
              border-radius: 50%;
              cursor: pointer;
            }
            input[type="range"]::-moz-range-thumb {
              width: 16px;
              height: 16px;
              background-color: #b45309; /* Tailwind bg-gold-800 */
              border-radius: 50%;
              cursor: pointer;
            }
            input[type="range"]:focus::-webkit-slider-thumb {
              outline: 2px solid rgba(209, 165, 84, 1);
            }
            input[type="range"]:focus::-moz-range-thumb {
              outline: 2px solid rgba(209, 165, 84, 1);
            }
          `}</style>
          <span>{filters[`${city}HotelDistance`] || 20} km</span>
        </div>
      ))}

      {/* New Meals Filter */}
      <div className="mb-4 flex items-center">
        <input
          type="checkbox"
          checked={filters.mealsIncluded || false}
          onChange={(e) => handleChange("mealsIncluded", e.target.checked)}
          className="mr-2"
        />
        <label className="text-sm font-medium">Meals Included</label>
      </div>

      {/* New Ziyarah Filter */}
      <div className="mb-4 flex items-center">
        <input
          type="checkbox"
          checked={filters.hasZiyarah || false}
          onChange={(e) => handleChange("hasZiyarah", e.target.checked)}
          className="mr-2"
        />
        <label className="text-sm font-medium">Ziyarah Included</label>
      </div>

      {/* Clear Button */}
      <button
        onClick={clearFilters}
        className="w-full py-2 border-[1px] border-brandGold text-brandGold rounded-md hover:bg-gray-50"
      >
        Clear Filters
      </button>
    </div>
  );
};

export default FiltersComponent;
