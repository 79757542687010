import React from "react";
import { MessageDisplay } from "../../";

const Loader = () => {
  return (
    <div>
      <MessageDisplay message="Loading ..." type="loading" />
    </div>
  );
};

export default Loader;
