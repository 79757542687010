import React, { useEffect, useRef } from "react";
import { TbCheckbox } from "react-icons/tb";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { MasjidNimra } from "../../../../../Utilities/data";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const Page = () => {
  useEffect(() => {
    // Define the function inside useEffect
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Call the function
    scrollToTop();
  }, []);

  const swiperRef = useRef(null);

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const facts = [
    "Masjid Nimra, also known as Masjid al-Namirah, is famous for Prophet Muhammad's Farewell Sermon during Hajj.",
    "Located in Arafat, Saudi Arabia, it's a crucial stop during the pilgrimage, emphasizing unity among Muslims.",
    "Prophet Muhammad's sermon emphasized equality, justice, and compassion, guiding Muslim conduct.",
    "Masjid Nimra symbolizes universal brotherhood, with millions of diverse pilgrims gathering during Hajj.",
  ];

  // console.log("hello", gharSur.hero.img);

  return (
    <div className=" w-all-sections-width mx-auto">
      <h1 className="font-cairo bg-[#cce9e2] mx-64 p-4 rounded-2xl md:text-[15px] text-[15px] font-semibold text-center justify-center flex my-10">
        Address: Wadi Uranah near Mecca in the Hejazi region of Saudi Arabia
      </h1>
      <h1 className="font-cairo md:text-[25px] text-[15px] font-bold text-[#484848]">
        History
      </h1>
      <div className="flex gap-10">
        <p className="font-cairo w-1/2 md:text-[16px] text-[10px] font-normal leading-[32px]">
          Masjid Nimrah (Arabic: مسجد نمرة) is located in Waadi Urana. When the
          Prophet (ﷺ) was at Arafat on his farewell Hajj on the 9th of Dhul
          Hijjah 10 AH he camped here. After midday he delivered his famous
          sermon (reproduced below) in the Urana Valley while seated on his
          camel, after which he led the salah. More than 100,000 Sahabah
          accompanied him on this Hajj. Shortly after the Prophet (ﷺ) had
          finished addressing the people, he received a revelation from
          Allah: “Today I have perfected your religion for you and completed My
          favour upon you, and I have chosen Islam as your religion.”[5:3] In
          the second century of Islam, Masjid Nimrah was built at the spot where
          the Prophet (ﷺ) delivered the sermon and led the salah. Because Waadi
          Urana, where the sermon was delivered is outside the boundaries of
          Arafat, that section of the masjid is naturally also outside the
          boundaries. 
        </p>
        <div className="w-1/2 text-center">
          <img
            src={MasjidNimra.history.image}
            alt="Jabal al-Nour and the Cave of Hira"
            className="w-full h-[340px] rounded-lg"
          />
          <p>
            <em>
              "Pilgrims gathered at Masjid Nimra in Arafat, Saudi Arabia, during
              Hajj."
            </em>
          </p>
        </div>
      </div>
      <div className="pb-6">
        <div className="w-full mx-auto">
          <h2 className="text-[25px] font-bold font-cairo text-left text-gray-800 mb-5">
            4 Facts about Masjid Nimra
          </h2>
          <ul className="space-y-3">
            {facts.map((fact, index) => (
              <li key={index} className="flex items-start gap-2 rounded-lg">
                <TbCheckbox className="text-[15px] text-gray-700 mt-1" />
                <p className="text-gray-700 text-lg font-cairo leading-relaxed">
                  {fact}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="py-5">
        <h1 className="font-cairo text-[28px] md:text-[25px] font-bold text-gray-800">
          Unraveling the Sanctity of Masjid-e-Nimra
        </h1>
        <h2 className="font-cairo font-normal text-[18px] md:text-[15px] py-4 text-gray-600">
          Masjid-e-Nimra, also known as Jannat-ul-Mawla, stands as a revered
          sanctuary in Mecca, steeped in Islamic history. Serving as a burial
          ground for esteemed companions of Prophet Muhammad (peace be upon him)
          and other luminaries of faith, it holds profound spiritual
          significance. Over centuries, it has drawn pilgrims seeking solace and
          connection with the early pioneers of Islam. Today, Masjid-e-Nimra
          continues to inspire awe and reverence, serving as a beacon of
          spiritual enlightenment. Its sacred grounds echo with the timeless
          devotion of those who dedicated their lives to the propagation of
          Islam, leaving a lasting legacy for generations to come.
        </h2>
      </div>
      <div className="2xl:flex xl:flex lg:flex md:block block gap-14">
        <img
          src={MasjidNimra.why.image}
          alt="Cave of Hira"
          className="w-full md:w-1/2 lg:w-1/2 rounded-lg"
        />
        <div className="block">
          <h3 className="font-cairo font-bold text-[24px] md:text-[25px] pb-2 text-gray-800">
            Sacred Serenity: The Legacy of Masjid-e-Nimra
          </h3>
          <p className="font-cairo font-normal text-[10px] md:text-[18px] leading-[32px] text-gray-600">
            Majestic Tranquility: The Spirit of Masjid-e-Nimra Masjid-e-Nimra
            stands as a timeless symbol of reverence, drawing devout worshippers
            from across the globe to honor its sacred significance and seek
            solace in its spiritual aura. Each pilgrimage to this revered
            sanctuary is a profound journey, where visitors immerse themselves
            in contemplation, prayer, and reflection upon the profound legacies
            left by those who have graced its hallowed precincts. The atmosphere
            resonates with a tangible sense of devotion and humility, as
            worshippers traverse the grounds, offering silent prayers and
            seeking divine blessings. The serene ambiance of Masjid-e-Nimra
            invites introspection and spiritual rejuvenation, fostering a deep
            connection with the enduring spirit of faith and piety that
            permeates its sacred environs. Indeed, Masjid-e-Nimra stands as a
            living testament to the enduring legacy of Islam, providing a
            sanctuary for believers to nurture their spiritual journey and honor
            the noble souls who have shaped its history. As pilgrims depart from
            this sacred sanctuary, they carry with them not only the blessings
            of the departed but also a renewed sense of purpose and devotion to
            Allah, inspired by the timeless teachings and examples set forth by
            those who find eternal peace within the blessed precincts of
            Masjid-e-Nimra.
          </p>
        </div>
      </div>
      <div className="bg-[#cce9e2] p-4 rounded text-left mx-auto my-10">
        <p className="text-[#484848] font-semibold font-cairo text-[18px]">
          "Discover Divine Tranquility: Masjid-e-Nimra Awaits. Step into a realm
          of serenity and spiritual fulfillment. Join us as we unveil the
          timeless allure of this sacred sanctuary in Makkah. Start your journey
          today."
        </p>
      </div>
      <div className="2xl:flex xl:flex lg:flex md:block block gap-5 mt-10">
        <div className="block">
          <p className="font-cairo font-normal text-[22px] leading-[40px]">
            Embark on a journey of spiritual discovery: Masjid-e-Nimra, the
            Sanctuary of Serenity. This sacred site in Makkah beckons pilgrims
            from across the globe, celebrated for its historical importance and
            divine tranquility. Immerse yourself in the peaceful atmosphere,
            where reflections on Allah's blessings abound. Visiting this blessed
            masjid fosters a deep sense of connection and devotion, enriching
            the hearts of believers with its spiritual treasures.
          </p>
        </div>
        <img
          src={MasjidNimra.pilgrimge.image}
          alt=""
          className="w-1/2 rounded-lg "
        />
      </div>
      <div className="gap-5 mt-7 flex">
        <img
          src={MasjidNimra.nimra1.image}
          alt=""
          className="pt-1.5 w-1/2 rounded-lg"
        />
        <div className="">
          <h1 className="font-cairo font-bold text-[24px] md:text-[25px] pb-2 text-gray-800">
            Exploring the Sacred Essence of Masjid-e-Nimra:
          </h1>
          <div className="block">
            <p className="font-cairo font-normal text-[10px] md:text-[18px] leading-[32px] text-gray-600">
              Embark on a profound journey into the revered traditions of
              Masjid-e-Nimra, often revered as a sanctuary of spiritual
              enlightenment. Situated in the heart of Makkah, this sacred haven
              beckons believers from every corner of the globe to converge in
              profound reverence. Deeply rooted in the Islamic heritage,
              Masjid-e-Nimra stands as a timeless symbol of spiritual devotion
              and rejuvenation, inviting pilgrims to embark on a transformative
              voyage of the soul. As pilgrims set foot within its sacred
              precincts, they are enveloped by an atmosphere of tranquility and
              serenity, where the echoes of prayers and reflections resonate
              through the air. Amidst the fragrant gardens and lush greenery,
              seekers of spiritual solace find sanctuary as they ponder their
              blessings and offer heartfelt supplications to the Divine.
            </p>
          </div>
        </div>
      </div>

      {/* Slider */}
      <div className="slider-container relative pt-7">
        <Swiper
          ref={swiperRef}
          spaceBetween={20}
          slidesPerView={3}
          loop={true}
          navigation={false} // Disable built-in navigation
          modules={[Navigation]}
        >
          <SwiperSlide>
            <img
              src={MasjidNimra.nimra1.image}
              alt="jamrat0"
              className="rounded-lg w-full h-[340px]"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={MasjidNimra.nimra2.image}
              alt="jamrat1"
              className="rounded-lg w-full h-[340px]"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={MasjidNimra.nimra3.image}
              alt="jamrat2"
              className="rounded-lg w-full h-[340px]"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={MasjidNimra.nimra4.image}
              alt=""
              className="rounded-lg h-[340px]"
            />
          </SwiperSlide>
        </Swiper>
        {/* Custom Navigation Buttons */}
        <button
          onClick={handlePrev}
          className="absolute top-1/2 -left-3 transform -translate-y-1/2 z-10"
        >
          <IoIosArrowBack className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
        </button>
        <button
          onClick={handleNext}
          className="absolute top-1/2 -right-3 transform -translate-y-1/2 z-10"
        >
          <IoIosArrowForward className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
        </button>
      </div>
    </div>
  );
};

export default Page;
