import React from "react";
import bankLogo from "../../../assets/Payment/bank.svg";
import uploadLogo from "../../../assets/Payment/uploadlogo.png";
const BankingInfo = ({
  transferID,
  validateTransferID,
  errors,
  previewFile,
  setTransferID,
  handleFileChange,
}) => {
  const handleTransferInputChange = (event) => {
    setTransferID(event.target.value);
    if (validateTransferID) validateTransferID(event.target.value);
  };

  return (
    <div className="text-secondaryBlue">
      <p className="text-[24px] font-600 my-2">Bank Transfer </p>
      <div className="space-y-5 p-6 border-[1px] border-lightGray bg-white rounded-md">
        <p>Here are the Bank account details where you can make the payment:</p>
        <div className="flex gap-4">
          <img src={bankLogo} alt="Bank" className="h-12" />
          <div className="text-xs">
            <p>
              <span className="font-bold">Account Title:</span> HUZ Solutions
              (Pvt) Limited.
            </p>
            <p>
              <span className="font-bold">Account #:</span> 6540 3120 1030 0013
            </p>
            <p>
              <span className="font-bold">IBAN:</span> PK12 BPUN 6540 3120 1030 0013
            </p>
          </div>
        </div>
        <div className="space-y-1">
          <label>Enter Payment Transfer or Reference number:</label>
          <input
            type="text"
            placeholder="Enter Transfer ID here"
            value={transferID}
            onChange={handleTransferInputChange}
            onBlur={() => validateTransferID(transferID)}
            className="w-full p-2.5 text-sm rounded-md border-[1px] border-lightGray"
          />
        </div>
        {errors.file && <p className="text-red-500 text-xs">{errors.file}</p>}
        <p className="mt-1 text-center font-bold">OR</p>
        <div className="space-y-1">
          <label className="text-[18px] font-600 ">
            Upload a clear copy of the Deposit slip or Transaction receipt:
          </label>
          <label htmlFor="fileInput">
            <div
              className={`h-24 w-full border border-dashed border-gray-300 rounded-lg flex flex-col justify-center items-center cursor-pointer relative`}
            >
              <img
                src={uploadLogo}
                alt="Custom Icon"
                className="h-6 w-6 text-gray-500 mb-2"
              />
              <input
                id="fileInput"
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />

              <p className="text-gray-600 font-light">
                Drop files here or click to upload
              </p>
            </div>
          </label>
          {previewFile && (
            <img src={previewFile} alt="Preview" className="w-[20%]" />
          )}
          {errors.file && <p className="text-red-500 text-xs">{errors.file}</p>}
        </div>
      </div>
    </div>
  );
};

export default BankingInfo;
