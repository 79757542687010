import React, { useEffect } from "react";
import Banner from "../../HeroSection/Banner";
import HeaderFooter from "../../../../ZiyaratArticlesPages/HeaderFooter";
import Slider from "../../MadinahPlaces/Wadijin/Slider";
import Page from "./Page";
import NassifHouseMuseumImg1 from "../../../../../assets/ZiyaratPlaces/Jeddah/naseefhouse/image5.jpg";
import NassifHouseMuseumImg2 from "../../../../../assets/ZiyaratPlaces/Jeddah/naseefhouse/image2.jpg";
import NassifHouseMuseumImg3 from "../../../../../assets/ZiyaratPlaces/Jeddah/naseefhouse/image3.jpg";
import NassifHouseMuseumImg4 from "../../../../../assets/ZiyaratPlaces/Jeddah/naseefhouse/image4.jpeg";
const NassifHouseMuseum = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const images = [
    NassifHouseMuseumImg1,
    NassifHouseMuseumImg2,
    NassifHouseMuseumImg3,
    NassifHouseMuseumImg4,
  ];

  return (
    <div>
      <HeaderFooter>
        <Banner image={NassifHouseMuseumImg3} title={"Nassif House Museum"} />
        <Page />
        <Slider images={images} />
      </HeaderFooter>
    </div>
  );
};

export default NassifHouseMuseum;
