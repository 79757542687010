import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  PayNowComponent,
  Footer,
  Header,
  PackageHeader,
  PaymentInfo,
  PaymentOption,
  Loader,
} from "../../";
import banner from "../../assets/Detail-Page/banner.png";
import {
  fetchBookingDetails,
  createBookingRequestbyTransactionNumber,
  createBookingRequestbyTransactionPhoto,
} from "../../api/apiService";
import AuthContext from "../../context/AuthContext";
import { useCurrency } from "../../context/CurrencyContext"; // Added currency context

const PaymentPage = () => {
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  const { currency, convert } = useCurrency(); // Using currency hook
  const sessionToken = login?.session_token || "";
  const [searchParams] = useSearchParams();
  const bookingNumber = searchParams.get("bookingNumber");

  const [bookingDetails, setBookingDetails] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [transferID, setTransferID] = useState("");
  const [errors, setErrors] = useState({});
  const [previewFile, setPreviewFile] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPaymentAmount, setSelectedPaymentAmount] = useState(0);
  const [selectedPaymentType, setSelectedPaymentType] = useState("Minimum");
  const [clickRadio, setClickRadio] = useState("bank");
  const handleRadio = (type) => {
    setClickRadio(type);
  };

  useEffect(() => {
    const getBookingDetails = async () => {
      if (!sessionToken || !bookingNumber) return;
      try {
        const data = await fetchBookingDetails(sessionToken, bookingNumber);
        const parseNumber = (value) =>
          !isNaN(Number(value)) ? Number(value) : 0;

        const roomBreakdown = [
          {
            type: "Sharing",
            count: parseNumber(data?.sharing),
            price: parseNumber(data?.cost_for_sharing),
          },
          {
            type: "Quad",
            count: parseNumber(data?.quad),
            price: parseNumber(data?.cost_for_quad),
          },
          {
            type: "Triple",
            count: parseNumber(data?.triple),
            price: parseNumber(data?.cost_for_triple),
          },
          {
            type: "Double",
            count: parseNumber(data?.double),
            price: parseNumber(data?.cost_for_double),
          },
        ].filter((room) => room.count > 0 && room.price > 0);

        const totalPaid =
          data.payment_detail?.reduce(
            (acc, payment) => acc + (payment.transaction_amount || 0),
            0
          ) || 0;
        const remainingAmount = parseNumber(data.total_price) - totalPaid;

        setBookingDetails({
          ...data,
          roomBreakdown,
          totalPaid,
          remainingAmount,
        });

        setSelectedPaymentAmount(remainingAmount > 0 ? remainingAmount : 0);
      } catch (error) {
        console.error("Error fetching booking details:", error);
        setBookingDetails({});
      }
    };
    getBookingDetails();
  }, [sessionToken, bookingNumber]);

  if (!bookingDetails)
    return (
      <div className="bg-white flex items-center justify-center h-screen">
        <Loader />
      </div>
    );

  const validateTransferID = (id) => {
    if (!id || id.trim().length < 6) {
      setErrors((prev) => ({
        ...prev,
        transferID: "Invalid Transfer ID (min 6 characters)",
      }));
    } else {
      setErrors((prev) => ({ ...prev, transferID: "" }));
    }
  };

  const handleFileChange = (e) => {
    const inputSelectedFile = e.target.files[0];
    setSelectedFiles(inputSelectedFile);
    setPreviewFile(URL.createObjectURL(inputSelectedFile));
    validateFile(inputSelectedFile);
  };

  const validateFile = (file) => {
    if (!file) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        file: "Please upload a transaction photo.",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        file: null,
      }));
    }
  };

  const handleSubmit = async (paymentAmount) => {
    if (!transferID && !selectedFiles) {
      setErrors({
        transferID: "Please enter a Transfer ID or upload a payment receipt.",
      });
      return;
    }

    setIsLoading(true);
    try {
      if (transferID) {
        await createBookingRequestbyTransactionNumber(
          sessionToken,
          bookingDetails.booking_number,
          transferID,
          paymentAmount,
          selectedPaymentType
        );
        localStorage.setItem("Transaction-ID", JSON.stringify(transferID));
      } else if (selectedFiles) {
        const formData = new FormData();
        formData.append("session_token", sessionToken);
        formData.append("booking_number", bookingDetails.booking_number);
        formData.append("transaction_photo", selectedFiles);
        formData.append("transaction_amount", paymentAmount);
        formData.append("transaction_type", selectedPaymentType);
        await createBookingRequestbyTransactionPhoto(formData);
        localStorage.setItem(
          "Transaction-Photo",
          JSON.stringify(selectedFiles)
        );
      }
      navigate(`/booking-info/${bookingDetails.booking_number}`);
    } catch (error) {
      console.error("Error processing payment:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Header />
      <div className="w-all-sections-width mx-auto mb-10">
        <img src={banner} alt="banner" className="w-full my-10" />
        <div className="flex flex-col lg:flex-row gap-6">
          <div className="w-full lg:w-[70%]">
            <PackageHeader data={bookingDetails} />
            <PaymentOption
              transferID={transferID}
              setTransferID={setTransferID}
              validateTransferID={validateTransferID}
              errors={errors}
              handleRadio={handleRadio}
              clickRadio={clickRadio}
              handleFileChange={handleFileChange}
              previewFile={previewFile}
            />
          </div>
          <div className="w-full lg:w-[30%]">
            <div className="sticky top-6">
              <PayNowComponent
                data={bookingDetails}
                handleSubmit={handleSubmit}
                setSelectedPaymentType={setSelectedPaymentType}
                clickRadio={clickRadio}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PaymentPage;
