import React, { useEffect } from "react";
import Banner from "../../HeroSection/Banner";
import HeaderFooter from "../../../../ZiyaratArticlesPages/HeaderFooter";
import Slider from "../../MadinahPlaces/Wadijin/Slider";
import Page from "./Page";
import FlagPoleImg1 from "../../../../../assets/ZiyaratPlaces/Jeddah/flagpole/image1.jpg";
import FlagPoleImg2 from "../../../../../assets/ZiyaratPlaces/Jeddah/flagpole/image2.jpg";
import FlagPoleImg3 from "../../../../../assets/ZiyaratPlaces/Jeddah/flagpole/image3.jpeg";

const FlagPole = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const images = [FlagPoleImg1, FlagPoleImg2, FlagPoleImg3, FlagPoleImg1];

  return (
    <div>
      <HeaderFooter>
        <Banner image={FlagPoleImg1} title={"The Jeddah Flagpole"} />
        <Page />
        <Slider images={images} />
      </HeaderFooter>
    </div>
  );
};

export default FlagPole;
