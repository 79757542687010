import React, { useEffect } from "react";
import Banner from "../../HeroSection/Banner";
import HeaderFooter from "../../../../ZiyaratArticlesPages/HeaderFooter";
import Slider from "../Wadijin/Slider";
import Page from "./Page";
import MasjidMiqatImg1 from "../../../../../assets/ZiyaratPlaces/Madinah/miqat/miqat2.jpg";
import MasjidMiqatImg2 from "../../../../../assets/ZiyaratPlaces/Madinah/miqat/miqat3.jpg";
import MasjidMiqatImg3 from "../../../../../assets/ZiyaratPlaces/Madinah/miqat/miqat4.png";
import MasjidMiqatImg4 from "../../../../../assets/ZiyaratPlaces/Madinah/miqat/miqat5.jpg";
import bg from "../../../../../assets/ZiyaratPlaces/Madinah/miqat/miqat1.jpg";
const MasjidMiqat = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const images = [
    MasjidMiqatImg1,
    MasjidMiqatImg2,
    MasjidMiqatImg3,
    MasjidMiqatImg4,
  ];

  return (
    <div>
      <HeaderFooter>
        <Banner image={bg} title={"Masjid Miqat"} />
        <Page />
        <Slider images={images} />
      </HeaderFooter>
    </div>
  );
};

export default MasjidMiqat;
