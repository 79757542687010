import React from "react";
import Marquee from "react-fast-marquee";
import visa from "../../assets/homepage/includePackageImg/visa.png";
import ticket from "../../assets/homepage/includePackageImg/airTickets.png";
import insurance from "../../assets/homepage/includePackageImg/insurance.png";
import makkahZiyarah from "../../assets/homepage/includePackageImg/makkahZiyarah.png";
import madinahZiyarah from "../../assets/homepage/includePackageImg/madinahZiyarah.png";
import tourGuide from "../../assets/homepage/includePackageImg/tourGuide.png";
import support from "../../assets/homepage/includePackageImg/support.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";

const ServicesSection = () => {
  const data = [
    { imagee: visa, text: "Saudi Visas" },
    { imagee: ticket, text: "Air Tickets" },
    { imagee: insurance, text: "Insurance" },
    { imagee: makkahZiyarah, text: "Makkah Ziyarah" },
    { imagee: madinahZiyarah, text: "Madinah Ziyarah" },
    { imagee: tourGuide, text: "Tour Guides" },
    { imagee: support, text: "24/7 Support" },
  ];

  return (
    <div className="w-full bg-[#244957]">
      <div className="py-10">
        {/* XL screens marquee slider */}
        <div className="xl:block hidden">
          <Marquee gradient={false} speed={50}>
            {data.map((item, index) => (
              <div key={index} className="flex flex-col items-center mx-14 space-y-4">
                <img
                  src={item.imagee}
                  alt={item.text}
                  className="xl:w-[35px] xl:h-[35px]"
                  style={{ position: "relative", top: "1.78px", left: "4.58px" }}
                />
                <p className="text-[17px] font-normal text-white">{item.text}</p>
              </div>
            ))}
          </Marquee>
        </div>

        {/* Mobile to Large (except XL) slider */}
        <div className="flex justify-between xl:hidden">
          <Swiper
            spaceBetween={20}
            pagination={{ clickable: true }}
            autoplay={{
              delay: 1000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            breakpoints={{
              0: { slidesPerView: 2 },
              720: { slidesPerView: 4 },
              1024: { slidesPerView: 5 },
            }}
            className="mySwiper"
          >
            {data.map((item, index) => (
              <SwiperSlide key={index}>
                <div className="flex flex-col items-center space-y-4">
                  <img
                    src={item.imagee}
                    alt={item.text}
                    className="w-[30px] h-[30px] sm:w-[28px] sm:h-[28px] md:w-[30px] md:h-[30px] lg:w-[32px] lg:h-[32px]"
                    style={{ position: "relative", top: "1.78px", left: "4.58px" }}
                  />
                  <p className="text-[16px] font-semibold text-primaryGray">
                    {item.text}
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;
