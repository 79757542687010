import React, { useState } from "react";
import uploadLogo from "../../../../../assets/Booking/uploadLogo.png";
import {
  uploadUserPassport,
  uploadUserPassportPhoto,
} from "../../../../../api/apiService";
import { Loader } from "../../../../../";
const MAX_FILE_SIZE = 5 * 1024 * 1024;
const SUPPORTED_FILE_TYPES = [
  "image/jpeg",
  "image/png",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

const PassportUpload = ({ data, sessionToken, bookingNumber }) => {
  const [passportFiles, setPassportFiles] = useState({});
  const [errorMessages, setErrorMessages] = useState({});
  const [loading, setLoading] = useState(false);
  const [updatedData, setUpdatedData] = useState(data);
  const [enlargedImage, setEnlargedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const formatDate = (date) =>
    new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

  const handleFileChange = async (e, index, isPassportPhoto) => {
    const file = e.target.files[0];
    const fileKey = `${index}-${isPassportPhoto ? "photo" : "passport"}`;

    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setErrorMessages((prev) => ({
          ...prev,
          [fileKey]: "File size exceeds 5MB.",
        }));
        return;
      }

      if (!SUPPORTED_FILE_TYPES.includes(file.type)) {
        setErrorMessages((prev) => ({
          ...prev,
          [fileKey]:
            "Only .jpg, .png, .pdf, .doc, .docx files are supported.",
        }));
        return;
      }

      setErrorMessages((prev) => ({ ...prev, [fileKey]: "" }));
      setPassportFiles((prev) => ({ ...prev, [fileKey]: file }));

      try {
        setLoading(true);

        const passportData = {
          user_passport: isPassportPhoto ? null : file,
          user_photo: isPassportPhoto ? file : null,
          passport_id:
            updatedData?.passport_validity_detail?.[index]?.passport_id,
          session_token: sessionToken,
          booking_number: bookingNumber,
        };

        let response;
        if (isPassportPhoto) {
          response = await uploadUserPassportPhoto(passportData);
        } else {
          response = await uploadUserPassport(passportData);
        }

        setUpdatedData(response.data);
        setSuccessMessage("Your document has been uploaded successfully.");

        e.target.value = "";
        setPassportFiles((prev) => {
          const updatedFiles = { ...prev };
          delete updatedFiles[fileKey];
          return updatedFiles;
        });
      } catch (error) {
        console.error("Error uploading file:", error);
        alert("Failed to upload file. Please try again.");
      } finally {
        setLoading(false);
      }
    }
  };

  const renderTravelerFields = (index) => {
    return (
      <div
        key={index}
        className="bg-white border-[1px] p-4 rounded-md shadow-md space-y-2"
      >
        <h3 className="text-[22px] font-semibold mb-4 text-gray-800">
          Traveler {index + 1} - Info
        </h3>
        <div className="p-4 space-y-4">
          <div className="p-2 px-4 border-[1px] rounded-md">
            <p className="text-[16px] font-semibold text-gray-700">
              {updatedData?.passport_validity_detail?.[index]?.first_name}{" "}
              {updatedData?.passport_validity_detail?.[index]?.last_name} -{" "}
              {updatedData?.passport_validity_detail?.[index]?.passport_country}
            </p>
            <div className="flex gap-10 items-center text-[14px] font-medium text-primaryGray">
              <p>
                Passport:{" "}
                {updatedData?.passport_validity_detail?.[index]?.passport_number}
              </p>
              <p>
                Passport Expiry:{" "}
                {formatDate(
                  updatedData?.passport_validity_detail?.[index]?.expiry_date
                )}
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 items-start">
            {["passport", "photo"].map((docType, pageIndex) => {
              const fileUrl =
                docType === "passport"
                  ? updatedData?.passport_validity_detail?.[index]?.user_passport
                  : updatedData?.passport_validity_detail?.[index]?.user_photo;

              return (
                <div key={pageIndex} className="w-full space-y-1">
                  <label
                    htmlFor={`fileInput-${docType}-${index}`}
                    className="cursor-pointer inline-flex items-center space-x-2 border border-gray-300 rounded-md px-3 py-2"
                  >
                    <img src={uploadLogo} alt="Upload" className="h-6 w-6" />
                    <span className="text-[16px] font-bold text-gray-700">
                      {docType === "passport"
                        ? "Upload Passport"
                        : "Upload Passport-size Photo"}
                    </span>
                    <input
                      id={`fileInput-${docType}-${index}`}
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) =>
                        handleFileChange(e, index, docType === "photo")
                      }
                    />
                  </label>

                  {fileUrl ? (
                    <div className="mt-4">
                      <p className="text-gray-600 text-[14px]">Uploaded File</p>
                      {docType === "passport" &&
                        (fileUrl.endsWith(".jpg") ||
                          fileUrl.endsWith(".jpeg") ||
                          fileUrl.endsWith(".png")) ? (
                        <img
                          src={`${API_BASE_URL}${fileUrl}`}
                          alt="Uploaded File"
                          className="w-32 h-32 object-cover mt-2 border-2 border-gray-300 rounded-md cursor-pointer"
                          onClick={() => openModal(`${API_BASE_URL}${fileUrl}`)}
                        />
                      ) : (
                        <a
                          href={`${API_BASE_URL}${fileUrl}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 underline mt-2 block"
                        >
                          Open Document
                        </a>
                      )}
                    </div>
                  ) : (
                    <div className="mt-4 text-red-500">
                      {`No ${docType} available`}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        {successMessage && (
          <div className="p-4 bg-green-100 text-green-700 rounded-md">
            {successMessage}
          </div>
        )}
      </div>
    );
  };

  const openModal = (imageUrl) => {
    setEnlargedImage(imageUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEnlargedImage(null);
  };

  return (
    <div className="space-y-8">

      {Array.from({ length: updatedData.adults }).map((_, index) =>
        renderTravelerFields(index)
      )}

      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-4 rounded-md">
            <img
              src={enlargedImage}
              alt="Enlarged"
              className="max-w-full max-h-[80vh]"
            />
            <button
              className="mt-4 p-2 bg-red-500 text-white rounded"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {loading && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-50 w-screen">
          Uploading...
        </div>
      )}
    </div>
  );
};

export default PassportUpload;
