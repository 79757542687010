import React, { useEffect } from "react";
import HeaderFooter from "../../../../ZiyaratArticlesPages/HeaderFooter";
import Banner from "./Banner";
import Slider from "../Wadijin/Slider";
import Page from "./Page";
import BirGharsImg1 from "../../../../../assets/ZiyaratPlaces/Madinah/birghars/birghars.jpg";
import BirGharsImg2 from "../../../../../assets/ZiyaratPlaces/Madinah/birghars/birghars1.jpg";
import BirGharsImg3 from "../../../../../assets/ZiyaratPlaces/Madinah/birghars/birghars2.jpg";
import BirGharsImg4 from "../../../../../assets/ZiyaratPlaces/Madinah/birghars/birghars3.jpg";
import BirGharsImg5 from "../../../../../assets/ZiyaratPlaces/Madinah/birghars/ghars4.jpg";
import BirGharsImg6 from "../../../../../assets/ZiyaratPlaces/Madinah/birghars/ghars5.jpg";
const BirGhars = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const images = [
    BirGharsImg1,
    BirGharsImg2,
    BirGharsImg3,
    BirGharsImg4,
    BirGharsImg5,
    BirGharsImg6,
  ];

  return (
    <div>
      <HeaderFooter>
        <Banner />
        <Page />
        <Slider images={images} />
      </HeaderFooter>
    </div>
  );
};

export default BirGhars;
