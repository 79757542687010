import React, { useEffect } from "react";
import Banner from "../../HeroSection/Banner";
import HeaderFooter from "../../../../ZiyaratArticlesPages/HeaderFooter";
import Slider from "../Wadijin/Slider";
import Page from "./Page";
import MasjidQubaImg1 from "../../../../../assets/ZiyaratPlaces/Madinah/MasjidQuba/MasjidQuba1.svg";
import MasjidQubaImg2 from "../../../../../assets/ZiyaratPlaces/Madinah/MasjidQuba/MasjidQuba2.svg";
import MasjidQubaImg3 from "../../../../../assets/ZiyaratPlaces/Madinah/MasjidQuba/MasjidQuba3.svg";
import MasjidQubaImg4 from "../../../../../assets/ZiyaratPlaces/Madinah/MasjidQuba/MasjidQuba4.svg";
import MasjidQubaImg5 from "../../../../../assets/ZiyaratPlaces/Madinah/MasjidQuba/MasjidQuba6.jpg";
import bg from "../../../../../assets/ZiyaratPlaces/Madinah/MasjidQuba/MasjidQuba.svg";
const MasjidQuba = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const images = [
    MasjidQubaImg1,
    MasjidQubaImg2,
    MasjidQubaImg3,
    MasjidQubaImg4,
    MasjidQubaImg5,
  ];

  return (
    <div>
      <HeaderFooter>
        <Banner image={bg} title={"Masjid Quba"} />
        <Page />
        <Slider images={images} />
      </HeaderFooter>
    </div>
  );
};

export default MasjidQuba;
