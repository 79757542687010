// PassportInfo.jsx
import React, { useMemo, useEffect, useCallback } from "react";
import countryList from "react-select-country-list";
import Select from "react-select";
import InputMask from "react-input-mask";
import { FaSpinner } from "react-icons/fa";
import dayjs from "dayjs";

const PassportInfo = ({
  passports,
  setPassports,
  errorMessages,
  onSubmit,
  isSubmitting,
}) => {
  const countryOptions = useMemo(() => countryList().getData(), []);
  const defaultCountry =
    countryOptions.find((opt) => opt.label === "Pakistan") || countryOptions[0];

  useEffect(() => {
    setPassports((prev) =>
      prev.map((p) => ({
        ...p,
        nationality: p.nationality || defaultCountry.label,
      }))
    );
  }, [defaultCountry.label, setPassports]);

  const handleInputChange = useCallback(
    (index, field, value) =>
      setPassports((prev) =>
        prev.map((p, i) => (i === index ? { ...p, [field]: value } : p))
      ),
    [setPassports]
  );

  const handleCountryChange = useCallback(
    (index, option) =>
      handleInputChange(index, "nationality", option.label),
    [handleInputChange]
  );

  const headers = [
    "First Name",
    "Middle Name",
    "Last Name",
    "Date of Birth",
    "Nationality",
    "Passport Number",
    "Passport Expiry",
  ];

  return (
    <div className="p-6 bg-gray-50 rounded-lg">
      <h2 className="text-2xl font-bold text-gray-800 mb-6">
        Travelers Information
      </h2>
      <div className="overflow-x-auto rounded-lg border border-gray-200">
        <table className="min-w-full bg-white">
          <thead className="bg-gray-50">
            <tr>
              {headers.map((header) => (
                <th
                  key={header}
                  className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {passports.map((p, index) => (
              <React.Fragment key={index}>
                <tr className="hover:bg-gray-50 transition-colors">
                  {["firstName", "middleName", "lastName"].map((field) => (
                    <td key={field} className="px-2 py-4">
                      <input
                        type="text"
                        value={p[field] || ""}
                        onChange={(e) =>
                          handleInputChange(index, field, e.target.value)
                        }
                        className="w-full px-2 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-gray-500"
                        style={{ width: "100px", fontSize: "14px" }}
                      />
                    </td>
                  ))}
                  <td className="px-2 py-4">
                    <input
                      type="date"
                      value={p.dob || ""}
                      onChange={(e) =>
                        handleInputChange(index, "dob", e.target.value)
                      }
                      max={dayjs().format("YYYY-MM-DD")}
                      onKeyDown={(e) => e.preventDefault()}
                      className="w-full px-2 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-gray-500"
                    />
                  </td>
                  <td className="px-2 py-4">
                    <Select
                      options={countryOptions}
                      value={
                        countryOptions.find(
                          (opt) => opt.label === p.nationality
                        ) || defaultCountry
                      }
                      onChange={(option) => handleCountryChange(index, option)}
                      isSearchable
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        control: (base, state) => ({
                          ...base,
                          minHeight: "38px",
                          borderColor: state.isFocused
                            ? "#6b7280"
                            : "#e2e8f0",
                          boxShadow: state.isFocused
                            ? "0 0 0 2px #6b7280"
                            : "none",
                          "&:hover": { borderColor: "#cbd5e1" },
                        }),
                      }}
                    />
                  </td>
                  <td className="px-1 py-2">
                    <InputMask
                      mask="*********"
                      maskChar={null}
                      value={p.passportNumber || ""}
                      onChange={(e) =>
                        handleInputChange(index, "passportNumber", e.target.value.toUpperCase())
                      }                      
                    >
                      {(inputProps) => (
                        <input
                          {...inputProps}
                          type="text"
                          placeholder="AB1234567"
                          className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-1 focus:ring-gray-500 text-sm"
                          style={{ width: "150px", height: "38px" }}
                        />
                      )}
                    </InputMask>
                  </td>
                  <td className="px-2 py-4">
                    <input
                      type="date"
                      value={p.expiryDate || ""}
                      onChange={(e) =>
                        handleInputChange(index, "expiryDate", e.target.value)
                      }
                      min={dayjs().format("YYYY-MM-DD")}
                      onKeyDown={(e) => e.preventDefault()}
                      className="w-full px-2 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-gray-500"
                    />
                  </td>
                </tr>
                {errorMessages[index] && (
                  <tr>
                    <td colSpan="7" className="px-6 py-2 bg-red-50">
                      <p className="text-red-600 text-sm font-medium">
                        {errorMessages[index]}
                      </p>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-6 flex justify-end">
        <button
          type="button"
          onClick={onSubmit}
          disabled={isSubmitting}
          className={`px-6 py-2 rounded-md text-white font-medium flex items-center justify-center gap-2 w-[300px] ${
            isSubmitting ? "bg-brandGold cursor-not-allowed" : "bg-brandGold"
          } transition-colors`}
        >
          {isSubmitting ? (
            <FaSpinner className="animate-spin text-xl" />
          ) : (
            "Submit Travelers Information"
          )}
        </button>
      </div>
      <div className="mt-4 text-sm text-gray-500">
        <p>* All fields are mandatory.</p>
        <p>
          * Please ensure that all fields—including Date of Birth, Passport Number,
          and Expiry Date—are completed accurately.
        </p>
        <p>
          * Inaccurate or incomplete submissions may lead to delays,
          cancellations, or additional charges.
        </p>
        <p>
          <strong>
            * Passports must remain valid for a minimum of six months beyond the travel date.
          </strong>
        </p>
      </div>
    </div>
  );
};

export default PassportInfo;
