import React from "react";
import { Link, useLocation } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Header } from "../../";

const BlogHeader = () => {
  const { pathname } = useLocation();
  const travelPlaces = [
    { name: "Makkah", path: "/articles/makkah" },
    { name: "Madinah", path: "/articles/madinah" },
    { name: "Jeddah", path: "/articles/jeddah" },
    { name: "Taif", path: "/articles/taif" },
    // { name: "Alula", path: "/articles/alula" },
  ];

  return (
    <div
      className="pb-4"
      style={{
        // backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        position: "relative",
      }}
    >
      <Header />
      <div className="border-t border-[#efefef] my-4"></div>
      <div className="lg:flex flex-row lg:justify-start mt-4 mx-auto w-all-sections-width">
        <div className="hidden lg:flex justify-between w-full">
          <div className="text-[#484848] font-cairo text-[17px] font-bold">
            <p>Holy Sites Inspiration</p>
          </div>
          <nav className="hidden lg:flex space-x-6 text-[#827F7F]">
            {travelPlaces.map((place, index) => (
              <Link
                key={index}
                to={place.path}
                className={`${
                  pathname.startsWith(place.path)
                    ? "font-semibold text-sm text-gray-800 underline cursor-pointer whitespace-nowrap"
                    : "hover:text-[#00936C] cursor-pointer"
                }`}
              >
                {place.name}
              </Link>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default BlogHeader;
