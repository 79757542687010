import React, { useEffect } from "react";
import Banner from "../../HeroSection/Banner";
import HeaderFooter from "../../../../ZiyaratArticlesPages/HeaderFooter";
import Slider from "../Wadijin/Slider";
import Page from "./Page";
import MasjidGhamamaImg1 from "../../../../../assets/ZiyaratPlaces/Madinah/Ghamamah/ghamama3.jpg";
import MasjidGhamamaImg2 from "../../../../../assets/ZiyaratPlaces/Madinah/Ghamamah/ghamama4.jpg";
import MasjidGhamamaImg3 from "../../../../../assets/ZiyaratPlaces/Madinah/Ghamamah/ghamamah5.jpg";
import MasjidGhamamaImg4 from "../../../../../assets/ZiyaratPlaces/Madinah/Ghamamah/ghamamah6.jpg";
import bg from "../../../../../assets/ZiyaratPlaces/Madinah/MasjidEjabah/ejabah2.svg";
const MasjidGhamama = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const images = [
    MasjidGhamamaImg1,
    MasjidGhamamaImg2,
    MasjidGhamamaImg3,
    MasjidGhamamaImg4,
  ];

  return (
    <div className="">
      <HeaderFooter>
        <Banner image={bg} title={"Masjid Al Ghamamah"} />
        <Page />
        <Slider images={images} />
      </HeaderFooter>
    </div>
  );
};

export default MasjidGhamama;
