import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { submitBookingComplaint, getAllComplaintsByUser } from "../../../api/apiService";
import { Footer, Header, PackageHeader, BookingDetail } from "../../../";
import banner from "../../../assets/Detail-Page/banner.png";

const brandGold = "rgba(209, 165, 84, 1)";
const gray400 = "rgba(223, 228, 234, 1)";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? brandGold : provided.borderColor,
    boxShadow: state.isFocused ? `0 0 0 1px ${brandGold}` : provided.boxShadow,
    "&:hover": { borderColor: gray400 }
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? brandGold
      : state.isFocused
      ? gray400
      : provided.backgroundColor,
    color: state.isSelected ? "white" : provided.color,
    
  })
};

const ComplaintPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { booking, packageDetails } = state || {};
  const { user_session_token, booking_number } = booking || {};

  const complaintTitles = [
    "Hotel booking",
    "Transport",
    "Airline or Flight",
    "Visa",
    "Cleanliness or maintenance",
    "Communication",
    "Fees or charges",
    "Request Booking modification",
    "Other"
  ];  
  
  const complaintOptions = complaintTitles.map(title => ({ value: title, label: title }));

  const [form, setForm] = useState({ complaintTitle: "", complaintMessage: "" });
  const [audioMessage, setAudioMessage] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [complaints, setComplaints] = useState([]);
  const [complaintsLoading, setComplaintsLoading] = useState(true);

  useEffect(() => {
    const fetchComplaints = async () => {
      if (user_session_token && booking_number) {
        try {
          const { success, data } = await getAllComplaintsByUser(user_session_token);
          if (success)
            setComplaints(data.filter(c => c.booking_number === booking_number));
        } catch (err) {
          console.error("Error fetching complaints:", err);
        } finally {
          setComplaintsLoading(false);
        }
      }
    };
    fetchComplaints();
  }, [user_session_token, booking_number]);

  const startRecording = useCallback(async () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      mediaRecorder.stream.getTracks().forEach(t => t.stop());
    }
    setAudioMessage(null);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      const chunks = [];
      recorder.ondataavailable = ({ data }) => chunks.push(data);
      recorder.onstop = () =>
        setAudioMessage(new Blob(chunks, { type: "audio/wav" }));
      recorder.start();
      setMediaRecorder(recorder);
      setIsRecording(true);
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  }, [mediaRecorder]);

  const stopRecording = useCallback(() => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setIsRecording(false);
      mediaRecorder.stream.getTracks().forEach(t => t.stop());
    }
  }, [mediaRecorder]);

  const handleChange = useCallback(
    ({ target: { id, value } }) =>
      setForm(prev => ({ ...prev, [id]: value })),
    []
  );

  const handleSubmit = async e => {
    e.preventDefault();
    if (!form.complaintTitle.trim() || !form.complaintMessage.trim()) {
      alert("Please select a complaint title and enter a complaint message.");
      return;
    }
    setIsSubmitting(true);
    const res = await submitBookingComplaint({
      session_token: user_session_token,
      booking_number,
      complaint_title: form.complaintTitle,
      complaint_message: form.complaintMessage,
      response_message: "",
      audio_message: audioMessage
    });
    alert(res.success ? "Complaint submitted successfully!" : `Error: ${res.error}`);
    if (res.success) navigate(-1);
    setIsSubmitting(false);
  };

  const getAudioUrl = audioPath =>
    !audioPath
      ? ""
      : audioPath.startsWith("http")
      ? audioPath
      : `${process.env.REACT_APP_API_BASE_URL}${audioPath}`;

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <div className="flex-1 w-all-sections-width mx-auto px-4 py-8">
        <img src={banner} alt="banner" className="w-full rounded-lg shadow-md mb-8" />
        <div className="flex flex-col lg:flex-row gap-8">
          <div className="w-full lg:w-[70%]">
            <PackageHeader data={booking} />
            <div className="bg-white p-8 rounded-lg border border-gray-200">
              <h1 className="text-2xl font-bold text-gray-800 mb-4">Raise a Complaint</h1>
              <p className="text-gray-600 mb-6">Tell us what went wrong. We're here to help.</p>
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label htmlFor="complaintTitle" className="block text-sm font-medium text-gray-700 mb-1">
                    Complaint Title
                  </label>
                  <Select
                    id="complaintTitle"
                    options={complaintOptions}
                    value={complaintOptions.find(option => option.value === form.complaintTitle)}
                    onChange={option =>
                      setForm(prev => ({ ...prev, complaintTitle: option ? option.value : "" }))
                    }
                    placeholder="Select a complaint title"
                    styles={customStyles}
                  />
                </div>
                <div>
                  <label htmlFor="complaintMessage" className="block text-sm font-medium text-gray-700 mb-1">
                    Complaint Details
                  </label>
                  <textarea
                    id="complaintMessage"
                    className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-brandGold transition-all"
                    placeholder="Describe your complaint in detail..."
                    rows="5"
                    value={form.complaintMessage}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex flex-col items-center">
                  <button
                    type="button"
                    onClick={isRecording ? stopRecording : startRecording}
                    className={`w-48 py-3 rounded-full text-white font-bold transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                      isRecording
                        ? "bg-gradient-to-r from-brandGold to-yellow-600 hover:from-brandGold hover:to-yellow-600"
                        : "bg-gradient-to-r from-gray-500 to-gray-600 hover:from-gray-600 hover:to-gray-700"
                    }`}
                  >
                    {isRecording ? "Stop Recording" : "Record Audio"}
                  </button>
                  {audioMessage && (
                    <div className="mt-4 w-full max-w-md bg-white rounded-lg shadow-lg p-4 flex flex-col items-center">
                      <p className="text-gray-700 mb-2">Preview your recording:</p>
                      <audio controls className="w-full">
                        <source src={URL.createObjectURL(audioMessage)} type="audio/wav" />
                        Your browser does not support the audio element.
                      </audio>
                    </div>
                  )}
                </div>
                <button
                  type="submit"
                  className="w-full px-4 py-3 bg-brandGold text-white rounded-lg font-semibold hover:bg-brandGold transition-all"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Submit Complaint"}
                </button>
              </form>
            </div>
            <div className="mt-8 bg-white p-8 rounded-lg border border-gray-200 shadow">
              <h2 className="text-2xl font-bold text-gray-800 mb-4">Your Complaints</h2>
              {complaintsLoading ? (
                <p className="text-gray-600">Loading complaints...</p>
              ) : complaints.length ? (
                complaints.map(c => (
                  <div key={c.complaint_id} className="mb-6 p-4 border border-gray-300 rounded-lg">
                    <h3 className="text-xl font-semibold text-gray-800 mb-2">{c.complaint_title}</h3>
                    <p className="text-gray-600 mb-2">{c.complaint_message}</p>
                    {c.audio_message && (
                      <audio controls className="w-full mb-2">
                        <source src={getAudioUrl(c.audio_message)} type="audio/wav" />
                        Your browser does not support the audio element.
                      </audio>
                    )}
                    <div className="text-sm text-gray-500">
                      <span>Status: {c.complaint_status}</span>
                      <span className="ml-4">
                        Time: {new Date(c.complaint_time).toLocaleString()}
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-gray-600">No complaints found for this booking.</p>
              )}
            </div>
          </div>
          <div className="w-full lg:w-[30%]">
            <BookingDetail
              bookingResponse={booking}
              travellers={booking?.adults || 0}
              selectedRooms={packageDetails?.selectedRooms}
              prices={packageDetails?.prices}
              rooms={packageDetails?.rooms}
              totalSelectedRooms={packageDetails?.totalSelectedRooms}
              roomBreakDown={booking?.roomBreakDown || []}
              totalRoomCost={packageDetails?.totalRoomCost}
              total_amount={packageDetails?.total_amount}
              payable_amount={packageDetails?.payable_amount}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ComplaintPage;
