import React, { useEffect } from "react";
import { Footer, Header } from "../../../";

const HuzPartnerPage = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const customClass = "w-[85%] mx-auto";
  return (
    <div>
      <Header />
      <div className="">
        <header className="bg-brandGold text-white py-6">
          <div className="container mx-auto text-center  font-kd ">
            <h1 className="text-[20px] font-semibold">
              Welcome to HajjUmrah.co
            </h1>
            <p className="mt-2 text-[15px]">
              Your gateway to connecting with pilgrims globally. Discover how
              Huz Partner can amplify your reach and simplify managing your
              travel packages.
            </p>
          </div>
        </header>

        <main className="container mx-auto pt-10 px-4 md:px-0">
          <section className={`${customClass} p-6 mb-10 font-kd`}>
            <h2 className="text-[20px] font-bold text-brandGold mb-4">
              What is Huz Partner?
            </h2>
            <p className="text-lg text-gray-700">
              Huz Partner is a dedicated platform under the Huz Solution
              umbrella, specifically designed to empower Hajj, Umrah, and
              Ziyarat agencies. Our platform provides a unique opportunity for
              agencies to enroll their packages and digitally connect with a
              global audience of pilgrims. Whether you're offering
              budget-friendly packages or luxury experiences, Huz Partner is the
              bridge that links your services to the pilgrims who need them.
            </p>
            <ul className="list-disc list-inside mt-4 space-y-2">
              <li>
                Digital exposure to a global audience: Leverage our platform to
                showcase your packages to pilgrims from all around the world.
              </li>
              <li>
                Easy package management: Our user-friendly interface makes it
                simple to upload and manage your travel packages.
              </li>
              <li>
                Direct connection with potential clients: Interact directly with
                interested pilgrims, answer their queries, and build trust.
              </li>
            </ul>
          </section>
          <section className={`${customClass} p-6 mb-10 font-kd`}>
            <h2 className="text-[20px] font-bold text-brandGold mb-4">
              Benefits of Joining Huz Partner
            </h2>
            <p className="text-[15px] text-gray-700">
              By becoming a Huz Partner, you unlock a multitude of benefits
              designed to streamline your operations and expand your customer
              base. Here are some key advantages:
            </p>
            <ul className="list-disc list-inside text-[15px] text-gray-700 space-y-2">
              <li>
                Access to a large network of potential customers: Our platform
                attracts a diverse range of pilgrims actively seeking Hajj,
                Umrah, and Ziyarat packages.
              </li>
              <li>
                Streamlined package management tools: Our intuitive tools help
                you efficiently manage your offerings, ensuring your packages
                are always up-to-date and appealing.
              </li>
              <li>
                Enhanced visibility and credibility: A strong digital presence
                on Huz Partner enhances your agency's visibility and
                credibility, making it easier to attract and retain clients.
              </li>
              <li>
                Opportunities for growth and expansion: Tap into new markets and
                grow your business by reaching out to a broader audience through
                our platform.
              </li>
            </ul>
          </section>

          <section className={`${customClass} p-6 mb-10 font-kd`}>
            <h2 className="text-[20px] font-bold text-brandGold mb-4">
              How to Get Started
            </h2>
            <p className="text-[15px] text-gray-700">
              Joining Huz Partner is a straightforward process. Follow these
              simple steps to get started and start connecting with pilgrims
              worldwide:
            </p>
            <ol className="list-decimal list-inside text-[15px] text-gray-700 space-y-2">
              <li>
                Create your account: Sign up on our platform with your agency's
                details to get started.
              </li>
              <li>
                Enroll your travel packages: Use our easy-to-use interface to
                list your Hajj, Umrah, and Ziyarat packages, complete with all
                necessary details and pricing.
              </li>
              <li>
                Connect with pilgrims: Engage with potential clients, answer
                their questions, and finalize bookings through our secure
                platform.
              </li>
            </ol>
          </section>

          <section className="bg-brandGold text-white font-kd p-6 ">
            <div className={`${customClass} font-kd`}>
              <h2 className="text-[20px] font-bold mb-4 text-center">
                Join HajjUmrah.co Today!
              </h2>
              <p className="text-[15px] ">
                Become a Huz Partner and take advantage of our platform to
                expand your reach and improve your service offerings. Join us
                today and connect with pilgrims from around the world looking
                for the best Hajj, Umrah, and Ziyarat packages. Enhance your
                agency's visibility, credibility, and growth potential by
                leveraging the power of Huz Solution.
              </p>
              <div className="justify-center text-center align-middle flex">
                <button className="mt-6 px-6 py-2  bg-white text-brandGold font-semibold rounded-lg shadow-md hover:bg-gray-200">
                  Get Started
                </button>
              </div>
            </div>
          </section>
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default HuzPartnerPage;
