import React, { useEffect } from "react";
import Banner from "../../HeroSection/Banner";
import HeaderFooter from "../../../../ZiyaratArticlesPages/HeaderFooter";
import Slider from "../Wadijin/Slider";
import Page from "./Page";
import MasjidQiblaImg1 from "../../../../../assets/ZiyaratPlaces/Madinah/MasjidQiblatain/qibla6.jpg";
import MasjidQiblaImg2 from "../../../../../assets/ZiyaratPlaces/Madinah/MasjidQiblatain/qibla1.png";
import MasjidQiblaImg3 from "../../../../../assets/ZiyaratPlaces/Madinah/MasjidQiblatain/qibla2.svg";
import MasjidQiblaImg4 from "../../../../../assets/ZiyaratPlaces/Madinah/MasjidQiblatain/qibla3.svg";
import MasjidQiblaImg5 from "../../../../../assets/ZiyaratPlaces/Madinah/MasjidQiblatain/qibla4.jpg";
import bg from "../../../../../assets/ZiyaratPlaces/Madinah/MasjidQiblatain/qibla.svg";
const MasjidQiblatain = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const images = [
    MasjidQiblaImg1,
    MasjidQiblaImg2,
    MasjidQiblaImg3,
    MasjidQiblaImg4,
    MasjidQiblaImg5,
  ];

  return (
    <div>
      <HeaderFooter>
        <Banner image={bg} title={"Masjid Al Qiblatain"} />
        <Page />
        <Slider images={images} />
      </HeaderFooter>
    </div>
  );
};

export default MasjidQiblatain;
