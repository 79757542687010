import React, { useEffect } from "react";
import Banner from "../../HeroSection/Banner";
import HeaderFooter from "../../../../ZiyaratArticlesPages/HeaderFooter";
import Slider from "../../MadinahPlaces/Wadijin/Slider";
import Page from "./Page";
import MasjidAbdullahBinAbbasImg1 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/abdullahMasjid/image2.jpeg";
import MasjidAbdullahBinAbbasImg2 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/abdullahMasjid/image3.jpeg";
import MasjidAbdullahBinAbbasImg3 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/abdullahMasjid/image4.jpeg";
import MasjidAbdullahBinAbbasImg4 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/abdullahMasjid/image5.jpeg";
import bg from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/abdullahMasjid/image1.jpeg";
const MasjidAbdulahBinAbbas = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const images = [
    MasjidAbdullahBinAbbasImg1,
    MasjidAbdullahBinAbbasImg2,
    MasjidAbdullahBinAbbasImg3,
    MasjidAbdullahBinAbbasImg4,
  ];

  return (
    <div>
      <HeaderFooter>
        <Banner image={bg} title={"Masjid-abdullah-bin-abbas"} />
        <Page />
        <Slider images={images} />
      </HeaderFooter>
    </div>
  );
};

export default MasjidAbdulahBinAbbas;
