import React, { forwardRef, useRef, useEffect } from "react";
import { MinusIcon, PlusIcon, UsersIcon } from "lucide-react";
import { ReactComponent as BedIcon } from "../../../../assets/Detail-Page/bed.svg";

const RoomOptions = forwardRef((props, ref) => {
  const {
    travellers,
    selectedRooms,
    setSelectedRooms,
    rooms = [],
    totalSelectedRooms
  } = props;

  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current && rooms.length > 0) {
      initialized.current = true;
      const lowestPriceRoom = rooms.reduce((prev, curr) =>
        prev.price < curr.price ? prev : curr
      );
      setSelectedRooms(prev => ({
        ...prev,
        [lowestPriceRoom.key]: travellers,
      }));
    }
  }, [rooms, travellers, setSelectedRooms]);

  const handleDecrement = (roomKey) => {
    setSelectedRooms((prev) => ({
      ...prev,
      [roomKey]: Math.max(0, prev[roomKey] - 1),
    }));
  };

  const handleIncrement = (roomKey) => {
    setSelectedRooms((prev) => ({
      ...prev,
      [roomKey]: prev[roomKey] + 1,
    }));
  };

  const renderBedIcons = (room) => (
    <div className="flex items-center gap-1">
      {Array.from({ length: room.beds }).map((_, i) => (
        <BedIcon key={i} className="w-6 h-6" />
      ))}
      {room.plus && <span className="text-xl">+</span>}
    </div>
  );

  if (!rooms.length) return <div>Loading rooms...</div>;

  return (
    <div ref={ref} className="mb-10">
      <h1 className="text-2xl font-bold mb-4">Choose Room</h1>
      <div className="space-y-4">
        {rooms.map((room) => (
          <div
            key={room.key}
            className="bg-white rounded-xl border border-lightGray overflow-hidden p-4"
          >
            <div className="grid gap-4 lg:flex lg:justify-between">
              <div className="flex flex-col gap-4 w-full">
                <div>
                  <h2 className="text-lg font-bold text-[#111928] mb-2">
                    {room.type}
                  </h2>
                  <div className="mb-2">
                    {room.beds ? renderBedIcons(room) : room.description}
                  </div>
                  <div className="flex items-center gap-2 text-[#D1A554]">
                    <UsersIcon className="w-5 h-5" />
                    <span className="text-xs font-medium">
                      Sleeps {room.sleeps}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex gap-5 items-center w-full justify-between">
                <div className="flex flex-col text-left">
                  <div className="md:text-lg xl:w-[200px] font-semibold text-[#111928]">
                    PKR {room.price?.toLocaleString()} / person
                  </div>
                  <div className="text-xs text-primaryGray">TAX INCLUDED</div>
                </div>
                {selectedRooms[room.key] > 0 ? (
                  <div className="w-auto">
                    <div className="flex border border-[#D1A554] rounded-md overflow-hidden">
                      <button
                        onClick={() => handleDecrement(room.key)}
                        className="flex-1 md:px-4 md:py-3 px-2 py-2 hover:bg-gray-50 flex items-center justify-center border-r border-[#D1A554]"
                      >
                        <MinusIcon className="w-3.5 h-3.5 text-[#111928]" />
                      </button>
                      <div className="flex-1 md:px-6 md:py-3 px-2 py-2 flex items-center justify-center text-base font-medium text-[#111928]">
                        {selectedRooms[room.key]}
                      </div>
                      <button
                        onClick={() => handleIncrement(room.key)}
                        disabled={totalSelectedRooms >= travellers}
                        className={`flex-1 md:px-4 md:py-3 px-2 py-2 flex items-center justify-center border-l ${
                          totalSelectedRooms >= travellers
                            ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                            : "hover:bg-gray-50"
                        }`}
                      >
                        <PlusIcon className="w-3.5 h-3.5 text-[#111928]" />
                      </button>
                    </div>
                    <div className="text-left mt-1 text-xs text-secondaryBlue">
                      {selectedRooms[room.key]} bed(s) @ PKR{" "}
                      {(room.price * selectedRooms[room.key]).toLocaleString()}
                    </div>
                  </div>
                ) : (
                  <button
                    onClick={() => handleIncrement(room.key)}
                    disabled={totalSelectedRooms >= travellers}
                    className={`w-auto px-4 py-3 ${
                      totalSelectedRooms >= travellers
                        ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                        : "bg-[#D1A554] hover:bg-[#C4A052] text-white"
                    } rounded-md flex items-center justify-center gap-2 text-sm`}
                  >
                    <PlusIcon className="w-4 h-4" />
                    Add
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});

export default RoomOptions;
