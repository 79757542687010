import React from 'react';
import {
  FaFileAlt,
  FaPlane,
  FaHotel,
  FaCar,
  FaFilePdf,
  FaFileWord,
  FaFileImage,
} from 'react-icons/fa';
import UserIcon from '../../../../assets/Booking/user.svg';
import CallIcon from '../../../../assets/Booking/call.svg';
import { BookingInfo } from '../../../..';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Reusable Section Component
const Section = ({ icon: Icon, title, children, className = '' }) => (
  <section className={`bg-white p-6 rounded-xl border-[1px] ${className}`}>
    <header className="flex items-center gap-3 mb-6">
      <Icon className="text-brandGold text-2xl" />
      <h2 className="text-2xl font-bold text-gray-800">{title}</h2>
    </header>
    {children}
  </section>
);

// Contact Detail Component
const ContactDetail = ({ city, name, contact }) => (
  <div className="mb-6 p-4 bg-gray-50 rounded-lg transition-all hover:bg-gray-100">
    <h3 className="font-semibold text-lg text-gray-800 mb-3">{city}</h3>
    <div className="space-y-2">
      <div className="flex items-center text-gray-600">
        <img src={UserIcon} alt="User" className="w-5 h-5 mr-2" />
        <span className="font-medium">{name}</span>
      </div>
      <div className="flex items-center text-gray-600">
        <img src={CallIcon} alt="Phone" className="w-5 h-5 mr-2" />
        <span className="font-medium">{contact}</span>
      </div>
    </div>
  </div>
);

// File Icon Component
const FileIcon = ({ fileUrl }) => {
  const extension = fileUrl?.split('.').pop()?.toLowerCase() || '';

  const iconConfig = {
    pdf: { icon: FaFilePdf, color: 'text-red-500' },
    doc: { icon: FaFileWord, color: 'text-blue-500' },
    docx: { icon: FaFileWord, color: 'text-blue-500' },
    jpg: { icon: FaFileImage, color: 'text-green-500' },
    jpeg: { icon: FaFileImage, color: 'text-green-500' },
    png: { icon: FaFileImage, color: 'text-green-500' },
    gif: { icon: FaFileImage, color: 'text-green-500' },
    default: { icon: FaFileAlt, color: 'text-brandGold' },
  };

  const { icon: Icon, color } = iconConfig[extension] || iconConfig.default;

  return <Icon className={`${color} text-4xl mb-3`} />;
};

// Document Card Component
const DocumentCard = ({ document, type, index }) => (
  <a
    href={`${API_BASE_URL}${document.document_link}`}
    target="_blank"
    rel="noopener noreferrer"
    className="flex flex-col items-center p-4 bg-white rounded-lg border hover:border-brandGold transition-all hover:shadow-lg"
  >
    <FileIcon fileUrl={document.document_link} />
    <span className="text-sm font-medium text-gray-700">
      {type} #{index + 1}
    </span>
  </a>
);

// Main Component
const BookingDetailsComponent = ({ bookingResponse }) => {
  if (!bookingResponse) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-50">
        <p className="text-red-500 font-medium">
          No booking details available. Please check your connection.
        </p>
      </div>
    );
  }

  const {
    booking_documents_status = [{}],
    booking_airline_details = [],
    booking_hotel_and_transport_details = [],
    booking_documents = [],
  } = bookingResponse;

  // Section Renderers
  const renderDocuments = (type, filterKey) => {
    const filteredDocs = booking_documents.filter(doc => doc.document_for === filterKey);

    return filteredDocs.length ? (
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {filteredDocs.map((doc, index) => (
          <DocumentCard key={doc.document_id} document={doc} type={type} index={index} />
        ))}
      </div>
    ) : (
      <p className="text-gray-500">No {type.toLowerCase()} documents available</p>
    );
  };

  // Add a helper for rendering hotel details in full-width cards
  const renderHotelDetail = (detail, index) => (
    <div key={`hotel-${index}`} className="w-full p-6 bg-gray-50 rounded-lg">
      <div className="flex">
        {detail.mecca_name && detail.mecca_number && (
          <div className="w-1/2">
            <ContactDetail city="Mecca" name={detail.mecca_name} contact={detail.mecca_number} />
          </div>
        )}
        {detail.madinah_name && detail.madinah_number && (
          <div className="w-1/2">
            <ContactDetail city="Madinah" name={detail.madinah_name} contact={detail.madinah_number} />
          </div>
        )}
      </div>

      {(detail.comment_1 || detail.comment_2) && (
        <div className="mt-4 p-4 bg-white rounded-lg">
          {detail.comment_1 && (
            <p className="text-gray-600 mb-2">✏️ {detail.comment_1}</p>
          )}
          {detail.comment_2 && (
            <p className="text-gray-600">✏️ {detail.comment_2}</p>
          )}
        </div>
      )}
    </div>
  );

  const renderDetailSection = (type, filterKey) => {
    const filteredDetails = booking_hotel_and_transport_details.filter(
      (detail) => detail.detail_for === filterKey
    );

    if (type === "Hotel") {
      return filteredDetails.length ? (
        <div className="space-y-6">
          {filteredDetails.map((detail, index) => renderHotelDetail(detail, index))}
        </div>
      ) : (
        <p className="text-gray-500">No {type.toLowerCase()} details available</p>
      );
    }

    // For non-hotel details (unchanged)
    return filteredDetails.length ? (
      <div className="space-y-6">
        {filteredDetails.map((detail, index) => (
          <div key={`${filterKey}-${index}`} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {['Jeddah', 'Mecca', 'Madinah'].map((city) => {
                const name = detail[`${city.toLowerCase()}_name`];
                const number = detail[`${city.toLowerCase()}_number`];
                return name && number ? (
                  <ContactDetail key={city} city={city} name={name} contact={number} />
                ) : null;
              })}
            </div>
            {(detail.comment_1 || detail.comment_2) && (
              <div className="p-4 bg-gray-50 rounded-lg">
                {detail.comment_1 && (
                  <p className="text-gray-600 mb-2">✏️ {detail.comment_1}</p>
                )}
                {detail.comment_2 && (
                  <p className="text-gray-600">✏️ {detail.comment_2}</p>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    ) : (
      <p className="text-gray-500">No {type.toLowerCase()} details available</p>
    );
  };



  return (
    <main className="max-w-7xl mx-auto space-y-8">
      {/* Visa Section */}
      <Section icon={FaFileAlt} title="Shared Visa Details">
        {booking_documents_status[0]?.is_visa_completed ? (
          renderDocuments('Visa Document', 'eVisa')
        ) : (
          <p className="text-gray-500">Visa details pending</p>
        )}
      </Section>

      {/* Airline Section */}
      <Section icon={FaPlane} title="Shared Airline Tickets">
        {booking_documents_status[0]?.is_airline_completed ? (
          <>
            {renderDocuments('Airline Ticket', 'airline')}
            <div className="mt-6 space-y-4">
              {booking_airline_details.map((flight, index) => (
                <div key={index} className="p-4 bg-gray-50 rounded-lg">
                  <p className="text-gray-600">
                    ✈️ Departs <strong>{flight.flight_from}</strong> to{' '}
                    <strong>{flight.flight_to}</strong> on{' '}
                    {new Date(flight.flight_date).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}{' '}
                    at {flight.flight_time}
                  </p>
                </div>
              ))}
            </div>
          </>
        ) : (
          <p className="text-gray-500">Airline details pending</p>
        )}
      </Section>

      {/* Transport Section */}
      <Section icon={FaCar} title="Shared Transport Details">
        {booking_documents_status[0]?.is_transport_completed
          ? renderDetailSection('Transport', 'Transport')
          : <p className="text-gray-500">Transport details pending</p>}
      </Section>

      {/* Hotel Section */}
      <Section icon={FaHotel} title="Shared Hotel Details">
        {booking_documents_status[0]?.is_hotel_completed
          ? renderDetailSection('Hotel', 'Hotel')
          : <p className="text-gray-500">Hotel details pending</p>}
      </Section>

      <BookingInfo bookingResponse={bookingResponse} />
    </main>
  );
};

export default BookingDetailsComponent;