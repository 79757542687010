import React from "react";
import "swiper/css";
import "swiper/css/navigation";
import { MasjidMustarahh } from "../../../../../Utilities/data";

const Page = () => {
  return (
    <div>
      <div className="bg-[#F2F2F2]">
        <p className="text-center text-[#484848] font-bold py-4 font-cairo md:text-[25px] text-[15px]">
          Masjid al-Mustarah is appriximately 4 km away from Madina
        </p>
      </div>
      <div className=" w-all-sections-width mx-auto">
        <div className="mt-12">
          <div className="flex flex-col md:flex-row">
            <div className="md:w-1/2">
              <h1 className="font-cairo font-bold text-[24px] md:text-[25px] pb-1 text-gray-800">
                Historical Background
              </h1>
              <p
                className="font-cairo font-normal leading-[32px]"
                style={{ marginTop: "10px" }}
              >
                Masjid al-Mustarah, a mosque of profound historical and
                spiritual significance, is situated in the city of Medina, Saudi
                Arabia. Often overshadowed by the more famous Masjid al-Nabawi,
                Masjid al-Mustarah holds a unique place in Islamic history due
                to its direct connection with the Prophet Muhammad (peace be
                upon him). Masjid Al-Mustarah, also know n as Bani Haritsah
                Mosque, is situated in Medina, Saudi Arabia, on Sayyid
                asy-Syuhada' street. Its name originates from an incident
                involving the Prophet Muhammad, who rested at this location
                after the Battle of Uhud. "Mustarah" translates to "the place
                for resting" in Arabic. Renovated during the reign of King Fahd,
                the mosque now spans an area of 491 square meters. Adjacent to
                the mosque are residences belonging to members of the Bani
                Harithah tribe. This site held strategic importance during the
                time of the Prophet, serving as the starting point for digging
                the trench during the Battle of the Trench. Additionally, it was
                the entry point for the army of Mu'awiya I and its leader Muslim
                ibn Uqba into the city.
              </p>
            </div>
            <div className="md:w-1/2 mt-4 md:mt-0 md:ml-8">
              <img
                src={MasjidMustarahh.why.image}
                alt="Wadi-e Jinn"
                className="w-full h-full rounded-lg shadow-lg"
              />
            </div>
          </div>
        </div>
        <div className="mt-12">
          <div className="">
            <div className="">
              <h1 className="font-cairo font-bold text-[24px] md:text-[25px] pb-1 text-gray-800">
                Visiting Masjid al-Mustarah
              </h1>
              <p
                className="font-cairo font-normal leading-[32px]"
                style={{ marginTop: "10px" }}
              >
                Masjid al-Mustarah is located in the northwestern part of
                Medina, not far from the more prominent Masjid al-Nabawi. The
                mosque is easily accessible by public transportation and is
                well-connected by roads. Signage and local guides are available
                to assist visitors in finding their way to the mosque. Visitors
                to Masjid al-Mustarah are expected to adhere to the proper
                etiquette when entering the mosque. This includes dressing
                modestly, removing shoes before entering the prayer hall, and
                maintaining a respectful demeanor. Photography is generally
                allowed, but visitors should be mindful of their surroundings
                and avoid disturbing those who are praying. To enhance the
                visitor experience, guided tours are often available. These
                tours provide detailed information about the history and
                significance of Masjid al-Mustarah, as well as insights into the
                architectural features and the events associated with the site.
                Guides are usually knowledgeable and can answer questions,
                making the visit more informative and engaging.
              </p>
            </div>
            <div className="mt-4">
              <img
                src={MasjidMustarahh.pilgrimge2.image}
                alt="Wadi-e Jinn"
                className="w-full h-full rounded-lg shadow-lg"
              />
            </div>
          </div>
        </div>
        <div className="mt-12">
          <div className="flex flex-col md:flex-row">
            <div className="md:w-1/2">
              <h1 className="font-cairo font-bold text-[24px] md:text-[25px] pb-1 text-gray-800">
                Spiritual Significance
              </h1>
              <p
                className="font-cairo font-normal leading-[32px]"
                style={{ marginTop: "10px" }}
              >
                For many Muslims, Masjid al-Mustarah is not just a place for
                performing the daily prayers but also a site for reflection and
                remembrance. The connection to the Prophet Muhammad (peace be
                upon him) adds a layer of spiritual depth, making it a place
                where visitors can contemplate the challenges faced by the early
                Muslim community and draw inspiration from their resilience and
                faith. The fact that the Prophet Muhammad (peace be upon him)
                rested at this site imbues Masjid al-Mustarah with a unique
                spiritual aura. Pilgrims and visitors often reflect on the
                Prophet's experiences and seek to deepen their understanding of
                his life and teachings. The mosque serves as a reminder of the
                humanity of the Prophet and the tangible experiences he and his
                companions endured. While Masjid al-Mustarah is not one of the
                primary sites of pilgrimage in Islam, such as the Kaaba in Mecca
                or the Prophet's Mosque in Medina, it holds a special place in
                the hearts of many pilgrims. Those who visit Medina often
                include Masjid al-Mustarah in their itinerary, seeking to
                connect with the history of the Prophet Muhammad (peace be upon
                him) and the early Muslim community.
              </p>
            </div>
            <div className="md:w-1/2 mt-4 md:mt-0 md:ml-8">
              <img
                src={MasjidMustarahh.history.image}
                alt="Wadi-e Jinn"
                className="w-full h-full rounded-lg shadow-lg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page;
