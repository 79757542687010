//Makkah Holyplaces
import kaabaImg from "../assets/ZiyaratPlaces/Makkah/Kabba/kabba.jpg";
import safaImg from "../assets/ZiyaratPlaces//Makkah/SafaMarwa/safamarwa1.jpg";
import minaImg from "../assets/ZiyaratPlaces/Makkah/Mina/mina1.jpg";
import arafahImg from "../assets/ZiyaratPlaces/Makkah/Arafah/arafahheader.jpg";
import gharEhiraImg from "../assets/ZiyaratPlaces/Makkah/Ghar-e-Hira/gharHira.svg";
import muzdalifahImg from "../assets/ZiyaratPlaces/Makkah/Muzdalifah/muzdalifah2.webp";
import namirahImg from "../assets/ZiyaratPlaces/Makkah/Namirah/namirah1.jpg";
import gharEsoorImg from "../assets/ZiyaratPlaces/Makkah/Ghar-e-Sur/gharsur.svg";
//Makkah Attractions Images
import khayefImg from "../assets/ZiyaratPlaces/Makkah/MasjidAlKhayf/alkaif4.webp";
import hudaybiyahImg from "../assets/ZiyaratPlaces/Makkah/AlHudaybiyah/huda2.svg";
import clockTowerImg from "../assets/ZiyaratPlaces/Makkah/ClockTower/makkahclocktower1.jpg";
import museumImg from "../assets/ZiyaratPlaces/Makkah/MakkahMuseum/makkahmuseum.jpg";
import masjidJinImg from "../assets/ZiyaratPlaces/Makkah/MasjidJinn/MasjidJinn.webp";
//Madinah Holyplaces
import wadiJinImg from "../assets/ZiyaratPlaces/Madinah/wadijin/Wadijin5.svg";
import uhudImg from "../assets/ZiyaratPlaces/Madinah/mountainUhud/uhud2.svg";
import birGharsImg from "../assets/ZiyaratPlaces/Madinah/birghars/ghar3.svg";
import masjidAbuBakarImg from "../assets/ZiyaratPlaces/Madinah/masjidabubakar/abubakar3.svg";
import masjidEjabahImg from "../assets/ZiyaratPlaces/Madinah/MasjidEjabah/ejabah2.svg";
import masjidGhammamaImg from "../assets/ZiyaratPlaces/Madinah/Ghamamah/MasjidGhamama3.svg";
//Madinah Attractions Images
import masjidJummahImg from "../assets/ZiyaratPlaces/Madinah/MasjidJummah/masjidjummah4.svg";
import miqatImg from "../assets/ZiyaratPlaces/Madinah/miqat/miqat2.svg";
import masjidMustarahImg from "../assets/ZiyaratPlaces/Madinah/mustarah/MasjidMustarah3.svg";
import masjidQiblatainImg from "../assets/ZiyaratPlaces/Madinah/MasjidQiblatain/qibla1.png";
import masjidQubaImg from "../assets/ZiyaratPlaces/Madinah/MasjidQuba/MasjidQuba2.svg";
import salmanFarsiGardenImg from "../assets/ZiyaratPlaces/Madinah/salmanfarsigarden/salman2.svg";
//Jeddah Holyplaces
import AlRehmanImg from "../assets/ZiyaratPlaces/Jeddah/AlRehmanMosque/AlRehman1.jpg";
import KhadijahMosqueImg from "../assets/ZiyaratPlaces/Jeddah/KhadijahMosque/KhadijahMosque1.jpg";
import HassanMosqueImg from "../assets/ZiyaratPlaces/Jeddah/HassanMosque/HassanMosque1.webp";
import KhalidMosqueImg from "../assets/ZiyaratPlaces/Jeddah/KhalidMosque/KhalidMosque1.jpg";
import TurkeyMosqueImg from "../assets/ZiyaratPlaces/Jeddah/TurkeyMosque/TurkeyMosque1.jpg";
import MasjidAbuBakarImg from "../assets/ZiyaratPlaces/Jeddah/AbuBakarMosque/AbuBakarMosque1.jpg";
//Jeddah Attractions Images
import NaseefHouseImg from "../assets/ZiyaratPlaces/Jeddah/naseefHouse.jpg";
import GlobeImg from "../assets/ZiyaratPlaces/Jeddah/globe.avif";
import SouqImg from "../assets/ZiyaratPlaces/Jeddah/souq.jpg";
import TaybatImg from "../assets/ZiyaratPlaces/Jeddah/taybatmuseum.jpg";
import FlagPoleImg from "../assets/ZiyaratPlaces/Jeddah/flagpole.jpg";
import BaladImg from "../assets/ZiyaratPlaces/Jeddah/alBalad.jpg";
//Taif Holyplaces
import AbdullahImg from "../assets/ZiyaratPlaces/Taif/holyplace/abdullah.jpg";
import BibiHalimaImg from "../assets/ZiyaratPlaces/Taif/holyplace/bibihalimah.jpg";
import MadhunHouseImg from "../assets/ZiyaratPlaces/Taif/holyplace/madhoun-house.jpg";
import HadaMountainImg from "../assets/ZiyaratPlaces/Taif/holyplace/mountain.jpeg";
import ShafaMountainImg from "../assets/ZiyaratPlaces/Taif/holyplace/al-shafa.jpg";
//Taif Attractions Images
import RudafParkImg from "../assets/ZiyaratPlaces/Taif/holyplace/rudafPark.jpeg";
import ShubraPalaceImg from "../assets/ZiyaratPlaces/Taif/holyplace/shubrapalace.jpeg";
import RoseFieldImg from "../assets/ZiyaratPlaces/Taif/holyplace/RoseFields.jpeg";
import SaiysadParkImg from "../assets/ZiyaratPlaces/Taif/holyplace/saiysadpark.jpeg";
import TaifZooImg from "../assets/ZiyaratPlaces/Taif/holyplace/taifzoo.jpeg";
import KingFahadGardenImg from "../assets/ZiyaratPlaces/Taif/holyplace/KindFahad.jpeg";
//Kabba Images
import KabbaImg1 from "../assets/ZiyaratPlaces/Makkah/Kabba/kabba.jpg";
import KabbaImg2 from "../assets/ZiyaratPlaces/Makkah/Kabba/kabba1.jpg";
import KabbaImg3 from "../assets/ZiyaratPlaces/Makkah/Kabba/kabba3.jpg";
import kabbaImg4 from "../assets/ZiyaratPlaces/Makkah/Kabba/kabba5.png";
import KabbaImg5 from "../assets/ZiyaratPlaces/Makkah/Kabba/kabba6.jpeg";
import KabbaImg6 from "../assets/ZiyaratPlaces/Makkah/Kabba/kabba7.jpg";
import KabbaImg7 from "../assets/ZiyaratPlaces/Makkah/Kabba/kabba8.jpg";
import KabbaImg8 from "../assets/ZiyaratPlaces/Makkah/Kabba/kabba9.jpeg";
import KabbaImg9 from "../assets/ZiyaratPlaces/Makkah/Kabba/kabba10.jpg";
import KabbaImg10 from "../assets/ZiyaratPlaces/Makkah/Kabba/kabba11.jpg";
import KabbaImg11 from "../assets/ZiyaratPlaces/Makkah/Kabba/kabba12.jpg";
//Safa Marwa Images
import SafaMarwaImg1 from "../assets/ZiyaratPlaces/Makkah/SafaMarwa/safamarwa1.jpg";
import SafaMarwaImg2 from "../assets/ZiyaratPlaces/Makkah/SafaMarwa/safamarwa2.jpg";
import SafaMarwaImg3 from "../assets/ZiyaratPlaces/Makkah/SafaMarwa/safamarwa3.jpg";
import SafaMarwaImg4 from "../assets/ZiyaratPlaces/Makkah/SafaMarwa/safamarwa4.jpg";
import SafaMarwaImg5 from "../assets/ZiyaratPlaces/Makkah/SafaMarwa/safamarwa5.jpg";
//Mina Images
import MinaImg1 from "../assets/ZiyaratPlaces/Makkah/Mina/mina1.jpg";
import MinaImg2 from "../assets/ZiyaratPlaces/Makkah/Mina/mina2.jpg";
import MinaImg3 from "../assets/ZiyaratPlaces/Makkah/Mina/mina3.jpg";
import MinaImg4 from "../assets/ZiyaratPlaces/Makkah/Mina/mina4.jpg";
import MinaImg5 from "../assets/ZiyaratPlaces/Makkah/Mina/mina5.jpg";
import MinaImg6 from "../assets/ZiyaratPlaces/Makkah/Mina/minastone.jpg";
//Arafah Images
import ArafahImg1 from "../assets/ZiyaratPlaces/Makkah/Arafah/arafah1.jpg";
import ArafahImg2 from "../assets/ZiyaratPlaces/Makkah/Arafah/arafah2.webp";
import ArafahImg3 from "../assets/ZiyaratPlaces/Makkah/Arafah/arafah3.jpg";
import ArafahImg4 from "../assets/ZiyaratPlaces/Makkah/Arafah/arafa4.jpeg";
import ArafahImg5 from "../assets/ZiyaratPlaces/Makkah/Arafah/arafah5.jpg";
//Muzdalifah Images
import MuzdalifahImg1 from "../assets/ZiyaratPlaces/Makkah/Muzdalifah/muzdalifah1.jpg";
import MuzdalifahImg2 from "../assets/ZiyaratPlaces/Makkah/Muzdalifah/muzdalifah2.webp";
import MuzdalifahImg3 from "../assets/ZiyaratPlaces/Makkah/Muzdalifah/muzdalifah3.webp";
import MuzdalifahImg4 from "../assets/ZiyaratPlaces/Makkah/Muzdalifah/muzdalifah4.webp";
import MuzdalifahImg5 from "../assets/ZiyaratPlaces/Makkah/Muzdalifah/muzdalifah5.jpg";
//Ghar e Soor Images
import GharESoorImg6 from "../assets/ZiyaratPlaces/Makkah/Ghar-e-Sur/Gharesoor.webp";
import GharESoorImg7 from "../assets/ZiyaratPlaces/Makkah/Ghar-e-Sur/Gharesoor2.jpg";
import GharESoorImg8 from "../assets/ZiyaratPlaces/Makkah/Ghar-e-Sur/gharesoor3.jpg";
//Masjid Al-Khayf Images
import MasjidKhayfImg1 from "../assets/ZiyaratPlaces/Makkah/MasjidAlKhayf/alkaif1.webp";
import MasjidKhayfImg2 from "../assets/ZiyaratPlaces/Makkah/MasjidAlKhayf/alkaif2.webp";
import MasjidKhayfImg3 from "../assets/ZiyaratPlaces/Makkah/MasjidAlKhayf/alkaif3.webp";
import MasjidKhayfImg4 from "../assets/ZiyaratPlaces/Makkah/MasjidAlKhayf/alkaif4.webp";
//Clock Tower Images
import ClockTowerImg1 from "../assets/ZiyaratPlaces/Makkah/ClockTower/makkahclocktower1.jpg";
import ClockTowerImg2 from "../assets/ZiyaratPlaces/Makkah/ClockTower/makkahclocktower2.jpg";
import ClockTowerImg3 from "../assets/ZiyaratPlaces/Makkah/ClockTower/makkahclocktower3.jpg";
import ClockTowerImg4 from "../assets/ZiyaratPlaces/Makkah/ClockTower/makkahclocktower4.jpg";
import ClockTowerImg5 from "../assets/ZiyaratPlaces/Makkah/ClockTower/makkahclocktower5.jpg";
//Makkah Museum Images
import MakkahMuseumImg1 from "../assets/ZiyaratPlaces/Makkah/MakkahMuseum/makkahmuseum.jpg";
import MakkahMuseumImg2 from "../assets/ZiyaratPlaces/Makkah/MakkahMuseum/makkahmuseum2.jpg";
import MakkahMuseumImg3 from "../assets/ZiyaratPlaces/Makkah/MakkahMuseum/makkahmuseum3.webp";
import MakkahMuseumImg4 from "../assets/ZiyaratPlaces/Makkah/MakkahMuseum/makkahmuseum4.jpg";
import MakkahMuseumImg5 from "../assets/ZiyaratPlaces/Makkah/MakkahMuseum/makkahmuseum5.jpg";
//Masjid Qiblatain Images
import bg from "../assets/ZiyaratPlaces/Madinah/MasjidQiblatain/qibla.svg";
import MasjidQiblaImg1 from "../assets/ZiyaratPlaces/Madinah/MasjidQiblatain/qibla6.jpg";
import MasjidQiblaImg2 from "../assets/ZiyaratPlaces/Madinah/MasjidQiblatain/qibla1.png";
import MasjidQiblaImg3 from "../assets/ZiyaratPlaces/Madinah/MasjidQiblatain/qibla2.svg";
import MasjidQiblaImg4 from "../assets/ZiyaratPlaces/Madinah/MasjidQiblatain/qibla3.svg";
import MasjidQiblaImg5 from "../assets/ZiyaratPlaces/Madinah/MasjidQiblatain/qibla4.jpg";
import MasjidQiblaImg6 from "../assets/ZiyaratPlaces/Madinah/MasjidQiblatain/qibla5.jpg";
import MasjidQiblaImg7 from "../assets/ZiyaratPlaces/Madinah/MasjidQiblatain/qibla7.jpg";
import MasjidQiblaImg8 from "../assets/ZiyaratPlaces/Madinah/MasjidQiblatain/qibla8.png";
//AlRehman Mosque Images
import AlRehmanMosqueImg1 from "../assets/ZiyaratPlaces/Jeddah/AlRehmanMosque/AlRehman1.jpg";
import AlRehmanMosqueImg2 from "../assets/ZiyaratPlaces/Jeddah/AlRehmanMosque/AlRehman2.webp";
import AlRehmanMosqueImg3 from "../assets/ZiyaratPlaces/Jeddah/AlRehmanMosque/AlRehman3.jpg";
import AlRehmanMosqueImg4 from "../assets/ZiyaratPlaces/Jeddah/AlRehmanMosque/AlRehman4.jpeg";
import AlRehmanMosqueImg5 from "../assets/ZiyaratPlaces/Jeddah/AlRehmanMosque/AlRehman5.jpg";
import AlRehmanMosqueImg6 from "../assets/ZiyaratPlaces/Jeddah/AlRehmanMosque/AlRehman6.jpeg";
import AlRehmanMosqueImg7 from "../assets/ZiyaratPlaces/Jeddah/AlRehmanMosque/AlRehman7.webp";
import AlRehmanMosqueImg8 from "../assets/ZiyaratPlaces/Jeddah/AlRehmanMosque/AlRehman8.jpeg";
//Khadijah Mosque Images
import KhadijahMosqueImg1 from "../assets/ZiyaratPlaces/Jeddah/KhadijahMosque/KhadijahMosque1.jpg";
import KhadijahMosqueImg2 from "../assets/ZiyaratPlaces/Jeddah/KhadijahMosque/KhadijahMosque2.webp";
import KhadijahMosqueImg3 from "../assets/ZiyaratPlaces/Jeddah/KhadijahMosque/KhadijahMosque3.jpg";
import KhadijahMosqueImg4 from "../assets/ZiyaratPlaces/Jeddah/KhadijahMosque/KhadijahMosque4.jpg";
import KhadijahMosqueImg5 from "../assets/ZiyaratPlaces/Jeddah/KhadijahMosque/KhadijahMosque5.webp";
import KhadijahMosqueImg6 from "../assets/ZiyaratPlaces/Jeddah/KhadijahMosque/KhadijahMosque6.webp";
import KhadijahMosqueImg7 from "../assets/ZiyaratPlaces/Jeddah/KhadijahMosque/KhadijahMosque7.jpg";
//Hassan Mosque Images
import HassanMosqueImg1 from "../assets/ZiyaratPlaces/Jeddah/HassanMosque/HassanMosque1.webp";
import HassanMosqueImg2 from "../assets/ZiyaratPlaces/Jeddah/HassanMosque/HassanMosque2.jpg";
import HassanMosqueImg3 from "../assets/ZiyaratPlaces/Jeddah/HassanMosque/HassanMosque3.jpg";
import HassanMosqueImg4 from "../assets/ZiyaratPlaces/Jeddah/HassanMosque/HassanMosque4.jpg";
import HassanMosqueImg5 from "../assets/ZiyaratPlaces/Jeddah/HassanMosque/HassanMosque5.jpg";
import HassanMosqueImg6 from "../assets/ZiyaratPlaces/Jeddah/HassanMosque/HassanMosque6.jpg";
import HassanMosqueImg7 from "../assets/ZiyaratPlaces/Jeddah/HassanMosque/HassanMosque7.jpg";
import HassanMosqueImg8 from "../assets/ZiyaratPlaces/Jeddah/HassanMosque/HassanMosque8.jpg";
//Khalid Mosque Images
import KhalidMosqueImg1 from "../assets/ZiyaratPlaces/Jeddah/KhalidMosque/KhalidMosque1.jpg";
import KhalidMosqueImg2 from "../assets/ZiyaratPlaces/Jeddah/KhalidMosque/KhalidMosque2.jpg";
import KhalidMosqueImg3 from "../assets/ZiyaratPlaces/Jeddah/KhalidMosque/KhalidMosque3.jpg";
import KhalidMosqueImg4 from "../assets/ZiyaratPlaces/Jeddah/KhalidMosque/KhalidMosque4.jpg";
import KhalidMosqueImg5 from "../assets/ZiyaratPlaces/Jeddah/KhalidMosque/KhalidMosque5.jpeg";
import KhalidMosqueImg6 from "../assets/ZiyaratPlaces/Jeddah/KhalidMosque/KhalidMosque6.jpg";
import KhalidMosqueImg7 from "../assets/ZiyaratPlaces/Jeddah/KhalidMosque/KhalidMosque7.jpg";
import KhalidMosqueImg8 from "../assets/ZiyaratPlaces/Jeddah/KhalidMosque/KhalidMosque8.jpg";
//Turkey Mosques Images
import TurkeyMosqueImg1 from "../assets/ZiyaratPlaces/Jeddah/TurkeyMosque/TurkeyMosque1.jpg";
import TurkeyMosqueImg2 from "../assets/ZiyaratPlaces/Jeddah/TurkeyMosque/TurkeyMosque2.jpeg";
import TurkeyMosqueImg3 from "../assets/ZiyaratPlaces/Jeddah/TurkeyMosque/TurkeyMosque3.jpg";
import TurkeyMosqueImg4 from "../assets/ZiyaratPlaces/Jeddah/TurkeyMosque/TurkeyMosque4.webp";
import TurkeyMosqueImg5 from "../assets/ZiyaratPlaces/Jeddah/TurkeyMosque/TurkeyMosque5.png";
import TurkeyMosqueImg6 from "../assets/ZiyaratPlaces/Jeddah/TurkeyMosque/TurkeyMosque6.jpg";
import TurkeyMosqueImg7 from "../assets/ZiyaratPlaces/Jeddah/TurkeyMosque/TurkeyMosque7.jpg";
import TurkeyMosqueImg8 from "../assets/ZiyaratPlaces/Jeddah/TurkeyMosque/TurkeyMosque8.webp";
//Abu Bakar Mosque Images
import AbuBakarMosqueImg1 from "../assets/ZiyaratPlaces/Jeddah/AbuBakarMosque/AbuBakarMosque1.jpg";
import AbuBakarMosqueImg2 from "../assets/ZiyaratPlaces/Jeddah/AbuBakarMosque/AbuBakarMosque2.jpg";
import AbuBakarMosqueImg3 from "../assets/ZiyaratPlaces/Jeddah/AbuBakarMosque/AbuBakarMosque3.jpg";
import AbuBakarMosqueImg4 from "../assets/ZiyaratPlaces/Jeddah/AbuBakarMosque/AbuBakarMosque4.jpg";
import AbuBakarMosqueImg5 from "../assets/ZiyaratPlaces/Jeddah/AbuBakarMosque/AbuBakarMosque5.jpg";
import AbuBakarMosqueImg6 from "../assets/ZiyaratPlaces/Jeddah/AbuBakarMosque/AbuBakarMosque6.jpg";
import AbuBakarMosqueImg7 from "../assets/ZiyaratPlaces/Jeddah/AbuBakarMosque/AbuBakarMosque7.webp";
export const MakkahPlaces = [
  {
    imageUrl: "/images/muhammad3.svg",
    title: "Prophet (S.A.W) House",
    description:
      "The history of the Prophet Muhammad's (peace be upon him) home...",
    link: "/articles/makkah/prophet-house",
    large: true,
  },
];

export const MadinahPlacesHolySites = [
  {
    imageUrl: wadiJinImg,
    title: "Wadi e Jinn in Islam",
    description:
      "Calling Jinn their brothers, the Messenger of Allah instructed everyone to not perform...",
    link: "/articles/madinah/wadi-jin",
    large: true,
  },

  // {
  //   imageUrl: uhudImg,
  //   title: "Mountain Uhud",
  //   description:
  //     "The Battle of Uhud, a significant engagement between the early Muslims and...",
  //   link: "/articles/madinah/uhud-mountain",
  //   large: true,
  // },
  {
    imageUrl: birGharsImg,
    title: "Al-Ghars Well",
    description:
      "According to tradition, Bilal Ibn Rabah used to fetch water from this well for Prophet...",
    link: "/articles/madinah/bir-ghars",
    large: true,
  },
  {
    imageUrl: masjidAbuBakarImg,
    title: "Masjid Abu-Bakr",
    description:
      "It was built-up for the first time in the Princedom of Omar Bin Abdul Aziz in...",
    link: "/articles/madinah/masjid-abu-bakr",
    large: true,
  },
  {
    imageUrl: masjidEjabahImg,
    title: "Al-Ijabah Mosque",
    description:
      "The significance of Masjid Ijabah is that it is the location where the Prophet...",
    link: "/articles/madinah/masjid-ijabah",
    large: true,
  },

  {
    imageUrl: masjidGhammamaImg,
    title: "Mosque of Al-Ghamama",
    description:
      "After the Battle of Uhud, which took place in 625 CE, the Prophet Muhammad and his...",
    link: "/articles/madinah/masjid-al-ghammamah",
    large: true,
  },
];

export const MadinaPlacesAttraction = [
  {
    imageUrl: masjidJummahImg,
    title: "Masjid al-Jummah",
    description:
      "The history of Masjid al-Jummah dates back to the early years of Islam when the Prophet...",
    link: "/articles/madinah/masjid-jummah",
    large: true,
  },
  {
    imageUrl: miqatImg,
    title: "Dhul Hulaifah Miqat Mosque",
    description:
      "Miqat mosque holds a special place because the Prophet (P.B.U.H) defined it as the...",
    link: "/articles/madinah/masjid-miqat",
    large: true,
  },
  {
    imageUrl: masjidMustarahImg,
    title: "Masjid al-Mustarah",
    description:
      "Mustarah translates to the place for resting in Arabic. Renovated during the reign of King...",
    link: "/articles/madinah/masjid-mustarah",
    large: true,
  },

  {
    imageUrl: masjidQiblatainImg,
    title: "Masjid al-Qiblatayn",
    description:
      "The mosque, believed to be one of the earliest in Medina, was constructed by Sawad ibn...",
    link: "/articles/madinah/masjid-qiblatayn",
    large: true,
  },
  {
    imageUrl: masjidQubaImg,
    title: "Masjid Quba",
    description:
      "Masjid Quba holds great historical significance in Islam as it is the first mosque built by...",
    link: "/articles/madinah/masjid-quba",
    large: true,
  },
  {
    imageUrl: salmanFarsiGardenImg,
    title: "The Garden Of Salman Al Farsi",
    description:
      "Salman the Persian (رضي الله عنه) came to the Prophet (ﷺ) to seek his counsel and his...",
    link: "/articles/madinah/garden-of-salman-farsi",
    large: true,
  },
];

export const MakkahPlacesHolySites = [
  {
    imageUrl: kaabaImg,
    title: "Kaaba",
    description:
      "The Kaaba is a sacred structure located at the center of the Grand Mosque (Masjid al-Haram) in Mecca, Saudi Arabia. It is the most revered site in Islam,",
    link: "/articles/makkah/holysites/kabba",
    large: true,
  },
  {
    imageUrl: safaImg,
    title: "Safa Marwa and Sa'i",
    description:
      "Safa Marwa and Sa'i are essential rituals in Hajj and Umrah, symbolizing Hagar's search for water for her son, Ishmael. Pilgrims walk seven times between the hills of Safa and Marwa within the Masjid al-Haram in Mecca, reflecting on faith, endurance, and divine providence. This practice commemorates Hagar's perseverance and is a powerful act of devotion and spiritual reflection for Muslims....",
    link: "/articles/makkah/holysites/safa-marwa",
    large: true,
  },

  {
    imageUrl: minaImg,
    title: "Mina the City Of Tents",
    description:
      'Mina, the "City of Tents" in Saudi Arabia, is a sacred site where millions of Muslims unite during Hajj perform the symbolic stoning of the devil....',
    link: "/articles/makkah/holysites/mina",
    large: true,
  },
  {
    imageUrl: arafahImg,
    title: "Arafah",
    description:
      "Discover the spiritual heart of Saudi Arabia at Arafah, the pivotal site of the Hajj pilgrimage. Each year, millions of Muslims gather here to stand in prayer and reflection, seeking forgiveness and blessings. Dive into the profound significance and transformative experience of Arafah on our blog—join us to uncover why this sacred site captivates hearts worldwide...",
    link: "/articles/makkah/holysites/arafah",
    large: true,
  },
  {
    imageUrl: gharEhiraImg,
    title: "Cave of Hira",
    description:
      "History A site where the Prophet Muhammad (peace be upon him) received the first revelations of the..",
    link: "/articles/makkah/gar-e-hira",
    large: true,
  },

  {
    imageUrl: muzdalifahImg,
    title: "Muzdalifah",
    description:
      "Allah called it al- Mash'ar al-Haram in the Holy Qur’an: (But when you depart from 'Arafat, remember Allah at al- Mash'ar al-Haram). It is a stage of the Hajj journey. It is located between Mina and Arafah. The pilgrims stay overnight there after they depart Arafah. They join and shorten Maghrib and Isha prayers and collect pebbles for Jamarat, in Mina. Pilgrims stay there for part of the night or until the dawn of the next day, the day of Eid, to move to Mina...",
    link: "/articles/makkah/holysites/muzdalifah",
    large: true,
  },
  {
    imageUrl: namirahImg,
    title: "Exploring Namirah Mosque",
    description:
      "Discover the historical and architectural grandeur of Namirah",
    link: "/articles/makkah/holysites/namirah-mosque",
    large: true,
  },
  {
    imageUrl: gharEsoorImg,
    title: "Thawr Cave",
    description:
      "Jabal Thawr is a mountain in Saudi Arabia that is located in the lower part of Makkah south of the Misfalah district. The place is popular among people belonging to the Islamic religion as it houses the..",
    link: "/articles/makkah/ghar-e-soor",
    large: true,
  },
];

export const MakkahPlacesAttractions = [
  {
    imageUrl: khayefImg,
    title: "Masjid al-Khayf",
    description:
      "Masjid al-Khayf (Arabic: مسجد الخيف) is located near the smallest Jamarat at the base of the mountain in the south of Mina and is considered the most important mosque in this area. It is here that the Prophet ﷺ and 70 other prophets before him had performed salah.",
    link: "/articles/makkah/holysites/masjid-al-Khayf",
    large: true,
  },
  {
    imageUrl: hudaybiyahImg,
    title: "Treaty of al-Hudaybiya",
    description:
      "The Treaty of al-Hudaybiya, a significant event during the lifetime of the Islamic...",
    link: "/articles/makkah/al-hudaybiyah",
    large: true,
  },
  {
    imageUrl: clockTowerImg,
    title: "Makkah Clock Tower",
    description:
      "The Makkah Clock Tower, also known as Abraj Al Bait, is a prominent skyscraper complex located in Mecca, Saudi Arabia",
    link: "/articles/makkah/holysites/makkah-clock-tower",
    large: true,
  },
  {
    imageUrl: museumImg,
    title: "Mecca Museum",
    description:
      "The Makkah Clock Tower, also known as Abraj Al Bait, is a prominent skyscraper complex located in Mecca, Saudi Arabia",
    link: "/articles/makkah/holysites/makkah-museum",
    large: true,
  },
  {
    imageUrl: masjidJinImg,
    title: "Mosque of the Jinn",
    description:
      "Jinn mosque (Azerbaijani: Cin məscidi, Persian: مسجد جن) is a historical mosque of the XIV century.",
    link: "/articles/makkah/holysites/masjid-jinn",
    large: true,
  },
];

export const TaifPlacesHolyPlaces = [
  {
    imageUrl: AbdullahImg,
    title: "Masjid Abdullah Bin Al Abbas",
    description:
      "History A site where the Prophet Muhammad (peace be upon him) received the first revelations of the..",
    link: "/articles/taif/holysites/masjid-abdullah-bin-abbas",
    large: true,
  },
  {
    imageUrl: BibiHalimaImg,
    title: "House of Halima Sadia",
    description:
      "The House of Halimah Sadia exudes timeless elegance and modern sophistication. Its blend of traditional architecture and contemporary design..",
    link: "/articles/taif/holysites/halima-sadia-house",
    large: true,
  },
  {
    imageUrl: MadhunHouseImg,
    title: "Masjid al-Qantara",
    description:
      "Al Madhoun House is a stunning blend of heritage and modernity. Its architecture showcases intricate traditional designs while incorporating contemporary elements for a luxurious feel. Sunlit interiors reveal rich textures and elegant decor.",
    link: "/articles/taif/holysites/masjid-al-qantara",
    large: true,
  },
  {
    imageUrl: HadaMountainImg,
    title: "Al-Hada Mountain",
    description:
      " Although not a traditional holy site, Al-Hada Mountain is considered sacred by many locals due to its stunning natural beauty and its significance in the region.",
    link: "/articles/taif/holysites/al-hada-mountain",
    large: true,
  },
  {
    imageUrl: ShafaMountainImg,
    title: "Al-Shafa Mountains",
    description:
      "The Al-Shafa Mountains, located near Taif in Saudi Arabia, are a breathtaking natural wonder. Known for their cool climate and stunning vistas, they are a haven ",
    link: "/articles/taif/holysites/shafa-mountain",
    large: true,
  },
];

export const TaifPlacesAttractions = [
  {
    imageUrl: RudafParkImg,
    title: "Arruddaf Park",
    description:
      "Rudaf Park, located in Taif, Saudi Arabia, is a picturesque oasis of natural beauty and recreation. The park features expansive green lawns, vibrant flower gardens",
    link: "/articles/taif/attractions/arruddaf-park",
    large: true,
  },
  {
    imageUrl: ShubraPalaceImg,
    title: "Shubra Palace",
    description:
      "Shubra Palace, situated in Riyadh, Saudi Arabia, is a splendid blend of history and architecture. This historic palace showcases traditional Saudi design with intricate woodwork ",
    link: "/articles/taif/attractions/shubra-palace",
    large: true,
  },
  {
    imageUrl: RoseFieldImg,
    title: "Taif Rose Fields",
    description:
      "The Taif Rose Fields in Saudi Arabia are a breathtaking sight, renowned for their beauty and fragrance. Every spring, these fields burst into bloom with delicate pink roses, filling the air with their sweet scent. Nestled amidst the majesti",
    link: "/articles/taif/attractions/taif-rose-fields",
    large: true,
  },
  {
    imageUrl: SaiysadParkImg,
    title: "Saiysad National Park",
    description:
      "Saiysad National Park, located near Taif in Saudi Arabia, is a pristine natural reserve known for its rugged beauty and diverse wildlife. The park encompasses rolling hills, rocky outcrops, and dense vegetation, creating a habitat for various ",
    link: "/articles/taif/attractions/saiysad-park",
    large: true,
  },
  {
    imageUrl: TaifZooImg,
    title: "Taif Zoo",
    description:
      "Taif Zoo, located in Taif, Saudi Arabia, is a popular attraction offering visitors a chance to see a diverse range of animals from around the world. Set amidst picturesque surroundings, the zoo provides habitats for various species,",
    link: "/articles/taif/attractions/taif-zoo",
    large: true,
  },
  {
    imageUrl: KingFahadGardenImg,
    title: "King Fahd Garden",
    description:
      "King Fahd Garden, situated in Taif, Saudi Arabia, is a splendid botanical oasis spanning vast acres of lush greenery and colorful blooms. This expansive park offers a serene retreat with shaded pathways, tranquil lakes, and meticulously landscaped gardens.",
    link: "/articles/taif/attractions/king-fahad",
    large: true,
  },
];

export const JeddahPlacesHolySites = [
  {
    imageUrl: AlRehmanImg,
    title: "Al Rahmah Mosque",
    description:
      "Located on the Red Sea coast in Jeddah, Al Rahmah Mosque is known for its unique floating design and stunning views. It serves as a place of worship and reflection for local residents and visitors alike.",
    link: "/articles/jeddah/holysites/al-rehman-mosque",
    large: true,
  },
  {
    imageUrl: KhadijahMosqueImg,
    title: "Khadija Baghlaf Mosque",
    description:
      "A historic mosque in Jeddah's Al-Balad district, named after Khadija Baghlaf, a prominent local figure. It features traditional Islamic architecture and serves as a community hub for prayers and gatherings",
    link: "/articles/jeddah/holysites/khadijah-mosque",
    large: true,
  },

  {
    imageUrl: HassanMosqueImg,
    title: "Hassan Enany Mosque",
    description:
      " Situated in Jeddah, the Hassan Enany Mosque is celebrated for its intricate woodwork and exquisite craftsmanship, showcasing traditional Saudi Arabian architectural styles.",
    link: "/articles/jeddah/holysites/hassan-mosque",
    large: true,
  },

  {
    imageUrl: KhalidMosqueImg,
    title: "King Khalid Grand Mosque",
    description:
      "One of Jeddah's largest and most impressive mosques, King Khalid Grand Mosque features modern Islamic architecture and hosts thousands of worshippers during daily prayers and special occasions.",
    link: "/articles/jeddah/holysites/khalid-mosque",
    large: true,
  },
  {
    imageUrl: TurkeyMosqueImg,
    title: "Imam Turki Bin Abd. Mosque",
    description:
      "A prominent mosque in Riyadh known for its expansive grounds and elegant architecture, named after Imam Turki bin Abdullah, a respected religious figure in Saudi Arabia.",
    link: "/articles/jeddah/holysites/turkey-mosque",
    large: true,
  },

  {
    imageUrl: MasjidAbuBakarImg,
    title: "Abu Bakar Masjid",
    description:
      "A mosque named after Abu Bakr, the first Caliph of Islam, known for its serene atmosphere and traditional architecture. It serves as a place of worship and community gatherings in Jeddah",
    link: "/articles/jeddah/holysites/abu-bakar-mosque",
    large: true,
  },
];

export const JeddahPlacesAttractions = [
  {
    imageUrl: NaseefHouseImg,
    title: "Nassif House Museum",
    description:
      "Nassif House Museum, located in Jeddah, Saudi Arabia, offers visitors an immersive journey into the country's cultural and historical legacy.",
    link: "/articles/jeddah/attractions/nasif-house-museum",
    large: true,
  },
  {
    imageUrl: GlobeImg,
    title: "The Globe Roundabout Jeddah",
    description:
      "The Globe Roundabout in Jeddah, Saudi Arabia, stands as a striking symbol of the city's blend of modernity and tradition. Featuring a massive, intricately designed globe at its center, this landmark represents Jeddah's global connections and its role as a gateway to the world",
    link: "/articles/jeddah/attractions/globe-round-about",
    large: true,
  },
  {
    imageUrl: SouqImg,
    title: "Souq Al Alawi Jeddah",
    description:
      "Souq Al Alawi, located in the heart of Jeddah's historic Al-Balad district, is one of the oldest and most vibrant marketplaces in Saudi Arabia.",
    link: "/articles/jeddah/attractions/souq-al-alawi",
    large: true,
  },
  {
    imageUrl: TaybatImg,
    title: "Taybat City Museum",
    description:
      "Al Taybat International City Museum of Science and Information in Jeddah, Saudi Arabia, offers a fascinating journey through the region's rich heritage and advancements.",
    link: "/articles/jeddah/attractions/taybat-museum",
    large: true,
  },
  {
    imageUrl: FlagPoleImg,
    title: "Jeddah Flagpole",
    description:
      "The Jeddah Flagpole, soaring at an impressive height of 171 meters, is one of the tallest flagpoles in the world. Located in King Abdullah Square,",
    link: "/articles/jeddah/attractions/flag-pole",
    large: true,
  },
  {
    imageUrl: BaladImg,
    title: "Al Balad: Jeddah's",
    description:
      "The Jeddah Flagpole, soaring at an impressive height of 171 meters, is one of the tallest flagpoles in the world. Located in King Abdullah Square,",
    link: "/articles/jeddah/attractions/al-balad",
    large: true,
  },
];

export const MinaImagesdata = {
  image1: MinaImg1,
  image2: MinaImg2,
  image3: MinaImg3,
  image4: MinaImg4,
  image5: MinaImg5,
  image6: MinaImg6,
};

export const arafahImagesdata = {
  image1: ArafahImg1,
  image2: ArafahImg2,
  image3: ArafahImg3,
  image4: ArafahImg4,
  image5: ArafahImg5,
};

export const safamarwa = {
  image1: SafaMarwaImg1,
  image2: SafaMarwaImg2,
  image3: SafaMarwaImg3,
  image4: SafaMarwaImg4,
  image5: SafaMarwaImg5,
};

export const MuzdalifahImages = {
  image1: MuzdalifahImg1,
  image2: MuzdalifahImg2,
  image3: MuzdalifahImg3,
  image4: MuzdalifahImg4,
  image5: MuzdalifahImg5,
};
export const GharEsurImages = {
  image1: GharESoorImg6,
  image2: GharESoorImg7,
  image3: GharESoorImg8,
};

export const MasjidalKhayfimages = {
  image1: MasjidKhayfImg1,
  image2: MasjidKhayfImg2,
  image3: MasjidKhayfImg3,
  image4: MasjidKhayfImg4,
};

export const ClockTowerImages = {
  image1: ClockTowerImg1,
  image2: ClockTowerImg2,
  image3: ClockTowerImg3,
  image4: ClockTowerImg4,
  image5: ClockTowerImg5,
};

export const MuseumImages = {
  image1: MakkahMuseumImg1,
  image2: MakkahMuseumImg2,
  image3: MakkahMuseumImg3,
  image4: MakkahMuseumImg4,
  image5: MakkahMuseumImg5,
};

export const kabba = {
  image1: KabbaImg1,
  image2: KabbaImg2,
  image4: KabbaImg3,
  image6: kabbaImg4,
  image7: KabbaImg5,
  image8: KabbaImg6,
  image9: KabbaImg7,
  image10: KabbaImg8,
  image11: KabbaImg9,
  image12: KabbaImg10,
  image13: KabbaImg11,
};

export const masjidQiblatayn = {
  image1: bg,
  image2: MasjidQiblaImg2,
  image3: MasjidQiblaImg3,
  image4: MasjidQiblaImg4,
  image5: MasjidQiblaImg5,
  image6: MasjidQiblaImg6,
  image7: MasjidQiblaImg1,
  image8: MasjidQiblaImg7,
  image9: MasjidQiblaImg8,
};

// jeddah images
export const AlRehmanMosqueImages = {
  image1: AlRehmanMosqueImg1,
  image2: AlRehmanMosqueImg2,
  image3: AlRehmanMosqueImg3,
  image4: AlRehmanMosqueImg4,
  image5: AlRehmanMosqueImg5,
  image6: AlRehmanMosqueImg6,
  image7: AlRehmanMosqueImg7,
  image8: AlRehmanMosqueImg8,
};

export const KhadijahMosqueImages = {
  image1: KhadijahMosqueImg1,
  image2: KhadijahMosqueImg2,
  image3: KhadijahMosqueImg3,
  image4: KhadijahMosqueImg4,
  image5: KhadijahMosqueImg5,
  image6: KhadijahMosqueImg6,
  image7: KhadijahMosqueImg7,
};

export const HassanMosqueImages = {
  image1: HassanMosqueImg1,
  image2: HassanMosqueImg2,
  image3: HassanMosqueImg3,
  image4: HassanMosqueImg4,
  image5: HassanMosqueImg5,
  image6: HassanMosqueImg6,
  image7: HassanMosqueImg7,
  image8: HassanMosqueImg8,
};

export const KhalidMosqueImages = {
  image1: KhalidMosqueImg1,
  image2: KhalidMosqueImg2,
  image3: KhalidMosqueImg3,
  image4: KhalidMosqueImg4,
  image5: KhalidMosqueImg5,
  image6: KhalidMosqueImg6,
  image7: KhalidMosqueImg7,
  image8: KhalidMosqueImg8,
};

export const TurkeyMosqueImages = {
  image1: TurkeyMosqueImg1,
  image2: TurkeyMosqueImg2,
  image3: TurkeyMosqueImg3,
  image4: TurkeyMosqueImg4,
  image5: TurkeyMosqueImg5,
  image6: TurkeyMosqueImg6,
  image7: TurkeyMosqueImg7,
  image8: TurkeyMosqueImg8,
};

export const AbuBakarMosqueImages = {
  image1: AbuBakarMosqueImg1,
  image2: AbuBakarMosqueImg2,
  image3: AbuBakarMosqueImg3,
  image4: AbuBakarMosqueImg4,
  image5: AbuBakarMosqueImg5,
  image6: AbuBakarMosqueImg6,
  image7: AbuBakarMosqueImg7,
};
