import React from "react";
import { Plane } from "lucide-react";

const AirlineDetails = ({ data }) => {
  const { airline_name, flight_from, flight_to, ticket_type } =
    data?.airline_detail[0];
  const flights = [
    {
      type: airline_name,
      route: `${flight_from} - ${flight_to}`,
    },
    {
      type: airline_name,
      route: `${flight_to} - ${flight_from}`,
    },
  ];

  return (
    <div className="w-full">
      <div className="flex items-center gap-2 mb-4">
        <h2 className="text-xl font-semibold">Air tickets</h2>
        <Plane className="w-5 h-5" />
      </div>

      <div className="bg-white rounded-xl p-4 border border-lightGray space-y-4">
        {flights.map((flight, index) => (
          <div key={index} className="flex justify-between items-center">
            <div className="flex items-center gap-3">
              <Plane className="w-5 h-5 text-[#D1A554]" />
              <div>
                <div className="flex items-center gap-2">
                  <span className="text-xs text-[#626971]">{flight.type}</span>
                </div>
                <p className="text-xs text-[#626971]">{flight.route}</p>
              </div>
            </div>
            <div className="text-right">
              <span className="text-xs font-medium text-[#111928]">{ticket_type}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AirlineDetails;
