import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    Authorization: process.env.REACT_APP_AUTH_TOKEN,
    "Content-Type": "application/json",
  },
});

const validateParams = (params, requiredFields) => {
  for (const field of requiredFields) {
    if (!params[field]) return `${field} is required.`;
  }
  return null;
};

const apiGet = async (
  endpoint,
  params = {},
  errorMapping = {
    400: "Bad Request: Missing or invalid input data.",
    401: "Unauthorized: Admin permissions required.",
    404: "Not Found: No data found for the given criteria.",
    500: "Server Error: Internal server error.",
  }
) => {
  try {
    const response = await api.get(endpoint, { params });
    if (response.status === 200 && response.data)
      return { success: true, data: response.data };
    return { success: false, error: "Unexpected response format from server." };
  } catch (error) {
    if (error.response) {
      const status = error.response.status;
      return {
        success: false,
        error: errorMapping[status] || `Unexpected status code: ${status}`,
      };
    }
    return {
      success: false,
      error:
        error.message ||
        "An unknown error occurred. Please try again later.",
    };
  }
};

const apiRequestError = (
  error,
  errorMapping = {
    400: "Bad Request: Invalid file format or size, or missing required information.",
    401: "Unauthorized: Admin permissions required.",
    404: "Not Found: User, booking detail, or package detail not found.",
    409: "Conflict: Payment issues or all documents already uploaded.",
    500: "Server Error: Internal server error.",
  }
) => {
  if (error.response) {
    const status = error.response.status;
    return {
      success: false,
      error: errorMapping[status] || `Unexpected status code: ${status}`,
    };
  }
  return {
    success: false,
    error:
      error.message ||
      "An unknown error occurred. Please try again later.",
  };
};

const uploadFileHelper = async (endpoint, fileKey, data) => {
  const errorMessage = validateParams(data, [
    fileKey,
    "passport_id",
    "session_token",
    "booking_number",
  ]);
  if (errorMessage) return { success: false, error: errorMessage };

  const formData = new FormData();
  formData.append(fileKey, data[fileKey]);
  formData.append("passport_id", data.passport_id);
  formData.append("session_token", data.session_token);
  formData.append("booking_number", data.booking_number);

  try {
    const response = await api.post(endpoint, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (response.status === 201)
      return { success: true, data: response.data };
    return {
      success: false,
      error: "Unexpected response format from server.",
    };
  } catch (error) {
    return apiRequestError(error);
  }
};

export const getFeaturedPackages = async ({ package_type }) => {
  const validationError = validateParams({ package_type }, ["package_type"]);
  if (validationError) return { success: false, error: validationError };

  const result = await apiGet("/partner/get_featured_packages/", { package_type });
  if (result.success) {
    const data = result.data;
    return {
      success: true,
      packages: data.results || [],
      count: data.count || 0,
      next: data.next || null,
      previous: data.previous || null,
    };
  }
  return result;
};

export const getShortPackageDetails = async ({ package_type }) => {
  const validationError = validateParams({ package_type }, ["package_type"]);
  if (validationError) return { success: false, error: validationError };

  const result = await apiGet("/partner/get_package_short_detail_for_web/?", { package_type });
  return result.success ? result.data || [] : [];
};

export const getPackageDetailByCityAndDate = async ({
  package_type,
  start_date,
  flight_from,
  page = 1,
  page_size = 10,
}) => {
  const validationError = validateParams(
    { package_type, start_date, flight_from },
    ["package_type", "start_date", "flight_from"]
  );
  if (validationError) return { success: false, error: validationError };

  const result = await apiGet("/partner/get_package_detail_by_city_and_date/", {
    package_type,
    start_date,
    flight_from,
    page,
    page_size,
  });
  return result.success ? result.data || [] : [];
};

export const getPackageDetailByPackageId = async ({ huz_token }) => {
  const validationError = validateParams({ huz_token }, ["huz_token"]);
  if (validationError) return { success: false, error: validationError };

  const result = await apiGet("/partner/get_package_detail_by_package_id_for_web/", { huz_token });
  return result.success ? { success: true, packageDetail: result.data } : result;
};

export const createBookingRequest = async (
  session_token,
  partner_session_token,
  huz_token,
  adults,
  child,
  infants,
  sharing,
  quad,
  triple,
  double,
  single,
  start_date,
  end_date,
  total_price,
  special_request,
  payment_type
) => {
  const response = await api.post("/bookings/create_booking_view/", {
    session_token,
    partner_session_token,
    huz_token,
    adults,
    child,
    infants,
    sharing,
    quad,
    triple,
    double,
    single,
    start_date,
    end_date,
    total_price,
    special_request,
    payment_type,
  });
  return response;
};

export const checkPassportValidity = async (passportData) => {
  console.log(passportData)
  try {
    const response = await api.post("/bookings/check_passport_validity/", passportData);
    return response.data;
  } catch (error) {
    if (error.response)
      return { error: true, status: error.response.status, message: error.response.data };
    if (error.request)
      return { error: true, status: 500, message: "No response from the server" };
    return { error: true, status: 500, message: error.message };
  }
};

export const createBookingRequestbyTransactionPhoto = async (formData) => {
  const response = await api.post("/bookings/pay_booking_amount_by_transaction_photo/", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
};

export const createBookingRequestbyTransactionNumber = async (
  session_token,
  booking_number,
  transaction_number,
  transaction_amount,
  transaction_type
) => {
  const response = await api.post("bookings/pay_booking_amount_by_transaction_number/", {
    session_token,
    booking_number,
    transaction_number,
    transaction_amount,
    transaction_type,
  });
  return response;
};

export const fetchBookingsByUser = async (sessionToken) => {
  try {
    const response = await apiGet(`/bookings/get_all_booking_short_detail_by_user/?session_token=${sessionToken}`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404)
      throw new Error("Booking detail not found");
    throw new Error("Failed to fetch bookings");
  }
};

export const fetchBookingDetails = async (sessionToken, bookingNumber) => {
  try {
    const response = await apiGet(
      `/bookings/create_booking_view/?session_token=${sessionToken}&booking_number=${bookingNumber}`
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch booking details");
  }
};

export const updateObjectionResponse = async (
  sessionToken,
  bookingNumber,
  objectionDocument,
  clientRemarks,
  objectionId
) => {
  const formData = new FormData();
  formData.append("session_token", sessionToken);
  formData.append("booking_number", bookingNumber);
  formData.append("objection_document", objectionDocument);
  formData.append("client_remarks", clientRemarks);
  formData.append("objection_id", objectionId);
  for (let pair of formData.entries()) console.log(pair[0] + ", " + pair[1]);

  try {
    const response = await api.put("/bookings/objection_response_by_user/", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  } catch (error) {
    console.error("Error updating objection response:", error);
    throw error;
  }
};

export const respondToObjection = async (formData) => {
  try {
    console.log("Uploading data:", Object.fromEntries(formData.entries()));
    const response = await api.put("/bookings/objection_response_by_user/", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (response.status === 201)
      return { success: true, data: response.data };
    return { success: false, error: "Unexpected response format." };
  } catch (error) {
    if (error.response) {
      const status = error.response.status;
      const errorMessages = {
        400: "Invalid input or booking status.",
        401: "Unauthorized: Admin permissions required.",
        404: "User, booking, or objection detail not found.",
        500: "Internal server error.",
      };
      return { success: false, error: errorMessages[status] || `Error: ${status}` };
    }
    return { success: false, error: "Network error or unexpected issue." };
  }
};

export const uploadUserPassport = async (passportData) =>
  uploadFileHelper("/bookings/manage_user_passport/", "user_passport", passportData);

export const uploadUserPassportPhoto = async (passportData) =>
  uploadFileHelper("/bookings/manage_user_passport_photo/", "user_photo", passportData);

export const submitRatingAndReview = async (reviewData) => {
  const validationError = validateParams(reviewData, [
    "session_token",
    "booking_number",
    "partner_total_stars",
    "partner_comment",
  ]);
  if (validationError) return { success: false, error: validationError };

  const payload = {
    session_token: reviewData.session_token,
    booking_number: reviewData.booking_number,
    huz_concierge: 0,
    huz_support: 0,
    huz_platform: 0,
    huz_service_quality: 0,
    huz_response_time: 0,
    huz_comment: null,
    partner_total_stars: reviewData.partner_total_stars,
    partner_comment: reviewData.partner_comment,
  };

  try {
    const response = await api.post("/bookings/rating_and_review/", payload);
    if (response.status === 201)
      return { success: true, data: response.data };
    return { success: false, error: "Unexpected response format from server." };
  } catch (error) {
    if (error.response) {
      const status = error.response.status;
      const errorMessages = {
        400: "Bad Request: Missing required data fields or invalid input format.",
        401: "Unauthorized: Admin permissions required.",
        404: "Not Found: User, booking, or review already exists.",
        409: "Conflict: Reviews can only be submitted for completed or closed bookings.",
        500: "Server Error: Internal server error.",
      };
      return { success: false, error: errorMessages[status] || `Unexpected error: ${status}` };
    }
    return { success: false, error: error.message || "An unknown error occurred." };
  }
};

export const submitBookingComplaint = async (complaintData) => {
  const validationError = validateParams(complaintData, [
    "session_token",
    "booking_number",
    "complaint_title",
    "complaint_message",
  ]);
  if (validationError) return { success: false, error: validationError };

  const formData = new FormData();
  formData.append("session_token", complaintData.session_token);
  formData.append("booking_number", complaintData.booking_number);
  formData.append("complaint_title", complaintData.complaint_title);
  formData.append("complaint_message", complaintData.complaint_message);
  if (complaintData.response_message)
    formData.append("response_message", complaintData.response_message);

  if (complaintData.audio_message) {
    const audioFile = complaintData.audio_message;
    const allowedFormats = ["audio/wav", "audio/mpeg"];
    const maxFileSize = 5 * 1024 * 1024;
    if (!allowedFormats.includes(audioFile.type))
      return { success: false, error: "Invalid audio format. Supported formats: WAV, MP3." };
    if (audioFile.size > maxFileSize)
      return { success: false, error: "Audio file size exceeds the limit. Maximum size: 5MB." };
    const fileName = `audio_message_${Date.now()}.${audioFile.type.split("/")[1]}`;
    formData.append("audio_message", audioFile, fileName);
  }

  for (let [key, value] of formData.entries())
    console.log(key, value);

  try {
    const response = await api.post("/bookings/raise_complaint_booking_wise/", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (response.status === 201)
      return { success: true, data: response.data };
    return { success: false, error: "Unexpected response format from server." };
  } catch (error) {
    if (error.response) {
      const status = error.response.status;
      const errorMessages = {
        400: "Bad Request: Missing required data fields, invalid file format, or size limit exceeded.",
        401: "Unauthorized: Admin permissions required.",
        404: "Not Found: User, booking detail, or package provider not found.",
        409: "Conflict: Complaint can only be raised when booking status is Pending, Complete, Active, or Close.",
        500: "Server Error: Internal server error.",
      };
      return { success: false, error: errorMessages[status] || `Unexpected error: ${status}` };
    }
    return { success: false, error: error.message || "An unknown error occurred." };
  }
};

/**
 * Retrieve all complaints submitted by a user.
 * @param {string} session_token - User session token.
 * @returns {Promise<{success: boolean, data: Array, error: string}>} - Response object.
 */
export const getAllComplaintsByUser = async (session_token) => {
  // Validate session_token
  if (!session_token) {
    return { success: false, error: "Session token is required." };
  }

  try {
    const response = await api.get("/bookings/get_all_complaints_by_user/", {
      params: { session_token },
    });

    // Check for a successful response
    if (response.status === 200) {
      return { success: true, data: response.data };
    }

    // Handle unexpected response format
    return { success: false, error: "Unexpected response format from server." };
  } catch (error) {
    // Handle API errors
    if (error.response) {
      const status = error.response.status;
      const errorMessages = {
        400: "Bad Request: Missing required data fields.",
        401: "Unauthorized: Admin permissions required.",
        404: "Not Found: User not found or no complaints found.",
        500: "Server Error: Internal server error.",
      };
      return { success: false, error: errorMessages[status] || `Unexpected error: ${status}` };
    } else if (error.message) {
      return { success: false, error: error.message };
    }
    return { success: false, error: "An unknown error occurred." };
  }
};


//Raise A Request

export const submitBookingRequest = async (requestData) => {
  const requiredFields = ["session_token", "booking_number", "request_title", "request_message"];
  const missing = requiredFields.filter(field => !requestData[field]);
  if (missing.length) return { success: false, error: `Missing fields: ${missing.join(", ")}` };

  const formData = new FormData();
  formData.append("session_token", requestData.session_token);
  formData.append("booking_number", requestData.booking_number);
  formData.append("request_title", requestData.request_title);
  formData.append("request_message", requestData.request_message);
  if (requestData.request_attachment) {
    const file = requestData.request_attachment;
    const allowedFormats = ["image/jpeg", "image/png", "application/pdf"];
    const maxFileSize = 10 * 1024 * 1024;
    if (!allowedFormats.includes(file.type))
      return { success: false, error: "Invalid file format. Supported: JPEG, PNG, PDF." };
    if (file.size > maxFileSize)
      return { success: false, error: "File size exceeds limit of 10MB." };
    const fileName = `request_attachment_${Date.now()}.${file.type.split("/")[1]}`;
    formData.append("request_attachment", file, fileName);
  }

  try {
    const response = await api.post("/bookings/raise_a_request/", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (response.status === 201)
      return { success: true, data: response.data };
    return { success: false, error: "Unexpected response format from server." };
  } catch (error) {
    if (error.response) {
      const status = error.response.status;
      const errorMessages = {
        400: "Bad Request: Missing required fields, invalid file format, or file too large.",
        401: "Unauthorized: Admin permissions required.",
        404: "Not Found: User or booking details not found.",
        409: "Conflict: Request can only be raised when booking status is Completed or Closed.",
        500: "Server error: Internal server error.",
      };
      return { success: false, error: errorMessages[status] || `Unexpected error: ${status}` };
    }
    return { success: false, error: error.message || "An unknown error occurred." };
  }
};

//get All Requests

export const getAllRequestsByUser = async (session_token) => {
  if (!session_token) return { success: false, error: "Session token is required." };

  try {
    const response = await api.get("/bookings/get_user_all_request/", {
      params: { session_token },
    });
    if (response.status === 200) return { success: true, data: response.data };
    return { success: false, error: "Unexpected response format from server." };
  } catch (error) {
    if (error.response) {
      const status = error.response.status;
      const errorMessages = {
        400: "Bad Request: Missing required fields.",
        401: "Unauthorized: Admin permissions required.",
        404: "Not Found: User not found or no requests available.",
        500: "Server error: Internal server error.",
      };
      return { success: false, error: errorMessages[status] || `Unexpected error: ${status}` };
    }
    return { success: false, error: error.message || "An unknown error occurred." };
  }
};
