import React from "react";
import MasjidGhamamaImg5 from "../../../../../assets/ZiyaratPlaces/Madinah/Ghamamah/ghamamah1.jpg";
import MasjidGhamamaImg6 from "../../../../../assets/ZiyaratPlaces/Madinah/Ghamamah/ghamamah2.jpg"
const Page = () => {
  return (
    <div>
      <div className="bg-[#F2F2F2]">
        <p className="text-center text-[#484848] font-bold py-4 font-cairo md:text-[25px] text-[15px]">
          The distance between Masjid al-ghamamah in Medina and Mecca, both
          cities in Saudi Arabia, is approximately 420 kilometers (260 miles) by
          road.
        </p>
      </div>
      <div className=" w-all-sections-width mx-auto">
        <div className="pt-14">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Historical Background & Early Islamic Period
          </h2>
          <div className="flex flex-col lg:flex-row mt-4 gap-10">
            <div className="w-full">
              <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px] leading-7">
                Masjid al-Ghamama, meaning "Mosque of the Cloud," holds a
                special place near the Prophet's Mosque (Al-Masjid an-Nabawi) in
                Medina, Saudi Arabia. This mosque is steeped in historical
                significance, primarily known as the location where Prophet
                Muhammad (peace be upon him) performed Eid prayers on multiple
                occasions. Significance: The name "Ghamama" is derived from the
                Arabic word for cloud. According to Islamic tradition, during
                one of Prophet Muhammad's prayers at this site, a cloud appeared
                and provided shade—an event considered miraculous and symbolic
                of Allah's favor towards the Prophet. This occurrence
                underscored his prophethood and served as a visible sign of
                divine presence and approval. Historical Context: The exact date
                of Masjid al-Ghamama's initial construction is not precisely
                documented, but it is believed to have been built during the
                early Islamic period, shortly after the Prophet's migration
                (Hijrah) to Medina in 622 CE. The mosque's proximity to
                Al-Masjid an-Nabawi, where the Prophet lived and taught, adds to
                its significance as a place deeply associated with his life and
                teachings. Architectural Evolution: Over the centuries, Masjid
                al-Ghamama has undergone several renovations and expansions,
                reflecting the evolving architectural styles and cultural
                influences of Islamic civilizations.
              </p>
              <p className="lg:hidden xl:block text-[#484848] mb-4 font-cairo font-medium text-[15px] text-justify">
                {" "}
                Notably, the Ottoman Empire made substantial contributions to
                the mosque's restoration and maintenance, ensuring its
                preservation as a prominent landmark in Medina. The architecture
                typically features elements common to traditional Islamic
                mosques, such as domes and minarets, designed to facilitate
                prayer and community gatherings. These structures are symbolic
                of the spiritual and communal significance of Masjid al-Ghamama
                within the Islamic faith.
              </p>
            </div>
            <div className="lg:w-1/2 mt-4 lg:mt-0">
              <img
                src={MasjidGhamamaImg5}
                alt="Wadi-e-Jinn"
                className="rounded-lg shadow-lg h-[392px]"
              />
            </div>
          </div>
          <p className="hidden lg:flex xl:hidden text-[#484848] mb-4 font-cairo font-medium text-[15px] text-justify">
            {" "}
            Notably, the Ottoman Empire made substantial contributions to the
            mosque's restoration and maintenance, ensuring its preservation as a
            prominent landmark in Medina. The architecture typically features
            elements common to traditional Islamic mosques, such as domes and
            minarets, designed to facilitate prayer and community gatherings.
            These structures are symbolic of the spiritual and communal
            significance of Masjid al-Ghamama within the Islamic faith.
          </p>
        </div>
        {/* location section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Spiritual Significance
          </h2>
          <div className="mt-4">
            <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px] ">
              Masjid al-Ghamama's primary spiritual significance lies in its
              association with Prophet Muhammad. It is believed that the Prophet
              performed the Eid prayers at this site, making it a place of deep
              reverence for Muslims. The miraculous incident of the cloud
              providing shade to the Prophet during prayer further enhances its
              spiritual importance, symbolizing divine protection and favor. For
              many Muslims, visiting Masjid al-Ghamama is an opportunity for
              prayer and reflection. The mosque's serene environment and
              historical connections make it an ideal place for spiritual
              contemplation. Pilgrims often visit the mosque to offer prayers
              and seek blessings, inspired by the presence of the Prophet and
              the miraculous events associated with the site. Masjid al-Ghamama
              showcases a blend of early Islamic and Ottoman architectural
              styles. The mosque's structure is relatively simple yet elegant,
              reflecting the humility and spiritual focus characteristic of
              early Islamic architecture. The mosque's layout includes a
              rectangular prayer hall, a central dome, and several smaller domes
              that add to its aesthetic appeal.
            </p>
          </div>
          <div className="mt-8 flex flex-col items-center">
            <img
              src={MasjidGhamamaImg6}
              alt="Islamic Calligraphy"
              className="w-full h-auto rounded-lg shadow-lg"
            />
            <p className="text-center text-[#484848] font-cairo text-[15px] font-semibold mt-4">
              Masjid Al-Ghamamah Mosque In saudi Arabia
            </p>
          </div>
        </div>
        {/* islam section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            How to Explore Masjid Al Ghamamah?
          </h2>
          <div className="mt-4">
            <ul className="list-none space-y-2 text-[#484848] mb-4 font-cairo font-medium text-[17px]">
              <li>
                - Location and Accessibility: Masjid Al Ghamamah is located in
                Medina, near the Prophet’s Mosque. It is easily accessible by
                car, taxi, or on foot for those visiting the central area of
                Medina.
              </li>
              <li>
                - Historical Significance: The mosque is historically
                significant as it is believed to be the site where the Prophet
                Muhammad performed the Eid prayers and prayed for rain (Salat
                Al-Istisqa), leading to the name "Al Ghamamah" which means
                "cloud" in Arabic.
              </li>
              <li>
                - Best Visiting Times: Early morning or late evening visits are
                recommended to avoid the heat and enjoy a peaceful experience.
                Visiting during off-peak seasons can also enhance your
                experience.
              </li>
              <li>
                - Activities: Perform the five daily prayers at the mosque,
                engage in supplication, and spend time in quiet reflection,
                contemplating the historical events associated with the site.
              </li>
              <li>
                - Cultural Etiquette: Dress modestly and maintain a respectful
                demeanor. Keep the mosque and its surroundings clean, and engage
                with locals or guides to learn more about its historical
                significance.
              </li>
              <li>
                - Nearby Landmarks: Combine your visit with trips to nearby
                significant sites such as the Prophet’s Mosque, Quba Mosque, and
                Masjid Al-Qiblatain to get a comprehensive understanding of
                Medina’s rich Islamic heritage.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page;
