import React, { useContext, useState, useMemo, useCallback } from "react";
import {
  Calendar,
  Clock,
  CheckCircle,
  AlertCircle,
  Users,
} from "lucide-react";
import visaImg from "../../../../assets/Detail-Page/visa.png";
import masterImg from "../../../../assets/Detail-Page/master.png";
import { NumericFormat } from "react-number-format";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../../../context/AuthContext";
import { useModal } from "../../../../context/ModalContext";
import { Login, Modal, SignUp } from "../../../../";
import { useCurrency } from "../../../../context/CurrencyContext";

const BookingSummary = React.memo(({
  data,
  travellers,
  handleClick,
  roomBreakDown,
  total_amount,
  isRoomSelectionValid,
  handleTravellerChange,
}) => {
  const { start_date } = data;
  const { login } = useContext(AuthContext);
  const { setIsModalOpen } = useModal();
  const { currency, convert } = useCurrency();
  const [modalType, setModalType] = useState(null);
  const isUserLoggedIn = Boolean(login?.session_token);
  const isButtonEnabled = isRoomSelectionValid && isUserLoggedIn;
  const location = useLocation();
  const navigate = useNavigate();

  // Memoized values
  const convertedTotal = useMemo(
    () => convert(total_amount, "PKR"),
    [total_amount, convert]
  );

  const memoizedRoomBreakDown = useMemo(
    () =>
      roomBreakDown
        .map((room) => ({
          ...room,
          price: convert(room.price * room.accommodates, "PKR"),
        }))
        .sort((a, b) => a.price - b.price),
    [roomBreakDown, convert]
  );


  const formattedDate = useMemo(
    () => new Date(start_date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }),
    [start_date]
  );

  // Memoized callbacks
  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const openModal = useCallback((type) => {
    setModalType(type);
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  const closeModal = useCallback(() => {
    setModalType(null);
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  return (
    <div className="w-full bg-white sticky rounded-lg border border-lightGray p-6 space-y-6">
      <div className="space-y-4">
        <h2 className="text-lg font-semibold text-brandGold">
          <NumericFormat
            value={convertedTotal}
            displayType={"text"}
            thousandSeparator
            prefix={`${currency} `}
            decimalScale={0}
            fixedDecimalScale
          />{" "}
          for {travellers} traveler{travellers > 1 && "s"}
        </h2>
        <div className="border-b border-lightGray" />
      </div>

      <div className="space-y-4">
        <div className="space-y-2">
          <label className="text-sm font-medium text-blueForDate">
            Departure Date
          </label>
          <div className="flex items-center gap-2 p-3 border border-lightGray rounded-md bg-white/70">
            <Calendar className="w-4 h-4 text-[#6B7280]" />
            <input
              type="text"
              value={formattedDate}
              className="bg-transparent text-sm outline-none text-[#9CA3AF]"
              readOnly
            />
          </div>
        </div>

        <div className="space-y-2">
          <label className="text-sm font-medium text-blueForDate">
            Total Travelers
          </label>
          <div className="flex gap-2 w-full">
            <div className="flex items-center text-secondaryBlue">
              <button
                className="border border-lightGray px-3 py-1 hover:bg-gray-200 text-base font-medium transition-transform duration-200"
                onClick={() => handleTravellerChange("decrease")}
              >
                -
              </button>
              <span className="border-y border-lightGray px-5 py-1 text-base font-medium">
                {travellers}
              </span>
              <button
                className="border border-lightGray px-3 py-1 hover:bg-gray-200 text-base font-medium transition-transform duration-200"
                onClick={() => handleTravellerChange("increase")}
              >
                +
              </button>
            </div>
          </div>
        </div>
        {!isRoomSelectionValid && (
          <div className="flex items-center gap-3 bg-redBg p-4 rounded-lg">
            <AlertCircle className="w-5 h-5 text-redIcon" />
            <p className="text-redText text-sm font-medium">
              Select beds and room type for all travelers.
          </p>
          </div>
        )}
      </div>
      <div className="space-y-4">
        <div className="border-b border-lightGray" />
        {memoizedRoomBreakDown.map((room, index) => (
          <div key={index} className="space-y-4">
            <div className="flex items-center justify-between">
              <h3 className="text-sm font-semibold text-blueForDate">
                {room.type}
              </h3>
            </div>
            <p className="text-xs text-primaryGray">
              <NumericFormat
                value={room.price}
                displayType={"text"}
                thousandSeparator
                prefix={`${currency} `}
                decimalScale={0}
                fixedDecimalScale
              />{" "}
              for {room.accommodates} bed(s)
            </p>
          </div>
        ))}
      </div>

      <div className="space-y-4">
        <div className="border-b border-lightGray" />
        <div className="flex justify-between text-sm text-brandGold font-bold">
          <span>Total Amount</span>
          <NumericFormat
            value={convertedTotal}
            displayType={"text"}
            thousandSeparator
            prefix={`${currency} `}
            decimalScale={0}
            fixedDecimalScale
          />
        </div>
      </div>
      <button
        onClick={handleClick}
        className={`w-full py-3 px-7 ${isButtonEnabled
          ? "bg-brandGold text-white"
          : "bg-[#E5E7EB] text-[#6B7280]"
          } rounded-md font-medium`}
      >
        Book Now
          </button>
      <button
        onClick={handleBack}
        className="w-full py-3 px-7 border-brandGold border-[1px] text-secondaryBlue shadow-sm bg-white rounded-md font-medium"
      >
        Back
      </button>

      <div className="space-y-2">
        <div className="flex items-center gap-3 text-primaryGray">
          <Clock className="w-5 h-5 text-brandGold" />
          <span className="text-sm">Booking only takes 2 minutes</span>
        </div>
        <div className="flex items-center gap-3 text-primaryGray">
          <CheckCircle className="w-5 h-5 text-brandGold" />
          <span className="text-sm">Instant confirmation</span>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <img src={visaImg} alt="Visa" className="h-4" />
        <img src={masterImg} alt="Mastercard" className="h-4" />
      </div>
      <div className="z-50">
        <Modal isOpen={!!modalType} onClose={closeModal}>
          {modalType === "login" ? (
            <Login closeModal={closeModal} openSignUpModal={() => openModal("signup")} />
          ) : (
            <SignUp closeModal={closeModal} openLoginModal={() => openModal("login")} />
          )}
        </Modal>
      </div>
    </div>
  );
});

export default BookingSummary;