// src/context/CurrencyContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { fetchExchangeRates, convertPrice } from '../Utilities/currencyUtils';

const CurrencyContext = createContext();

export const CurrencyProvider = ({ children }) => {
  const [currency, setCurrency] = useState(
    localStorage.getItem('selectedCurrency') || 'PKR'
  );
  const [rates, setRates] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadRates = async () => {
      try {
        const data = await fetchExchangeRates();
        setRates(data?.conversion_rates || {});
      } catch (error) {
        console.error('Failed to load exchange rates:', error);
      } finally {
        setLoading(false);
      }
    };

    loadRates();
    const interval = setInterval(loadRates, 3600000); // Refresh hourly
    return () => clearInterval(interval);
  }, []);

  const convert = (amount, fromCurrency) => {
    if (!rates || currency === fromCurrency) return amount;
    return convertPrice(amount, fromCurrency, currency, rates);
  };

  return (
    <CurrencyContext.Provider value={{ currency, setCurrency, rates, convert, loading }}>
      {children}
    </CurrencyContext.Provider>
  );
};

export const useCurrency = () => {
  const context = useContext(CurrencyContext);
  if (!context) {
    throw new Error('useCurrency must be used within a CurrencyProvider');
  }
  return context;
};