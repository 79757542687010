import React, { useEffect } from "react";
import Banner from "../../HeroSection/Banner";
import HeaderFooter from "../../../../ZiyaratArticlesPages/HeaderFooter";
import Slider from "../Wadijin/Slider";
import Page from "./Page";
import { MasjidMustarahh } from "../../../../../Utilities/data";
import MasjidMustarahImg1 from "../../../../../assets/ZiyaratPlaces/Madinah/mustarah/mustara1.jpg";
import MasjidMustarahImg2 from "../../../../../assets/ZiyaratPlaces/Madinah/mustarah/mustara2.jpg";
import MasjidMustarahImg3 from "../../../../../assets/ZiyaratPlaces/Madinah/mustarah/mustara3.jpg";
import MasjidMustarahImg4 from "../../../../../assets/ZiyaratPlaces/Madinah/mustarah/mustara4.jpg";
const MasjidMustarah = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const images = [
    MasjidMustarahImg1,
    MasjidMustarahImg2,
    MasjidMustarahImg3,
    MasjidMustarahImg4,
  ];
  return (
    <div>
      <HeaderFooter>
        <Banner image={MasjidMustarahh.hero.image} title={"Masjid Mustarah"} />
        <Page />
        <Slider images={images} />
      </HeaderFooter>
    </div>
  );
};

export default MasjidMustarah;
