import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/swiper-bundle.css";
import { Link } from "react-router-dom";
import image1 from "../../../assets/homepage/servicesSwiper/image 95.svg";
import image2 from "../../../assets/homepage/servicesSwiper/image 96.svg";
import image3 from "../../../assets/homepage/servicesSwiper/image 97.svg";

const servicesData = [
  {
    title: "Enhanced Safety & Tracking",
    description: "Featuring our unique 'Secure Hajji' system using GPS and smart tags.",
    background: image1,
    link: "/safety-tracking",
  },
  {
    title: "Extended Customer Support",
    description: "Support center, complaint management and 24/7 on-call concierge services.",
    background: image3,
    link: "/customer-support",
  },
  {
    title: "Transparency & Fraud Protection",
    description: "Secure online booking of verified packages from certified service providers.",
    background: image2,
    link: "/fraud-protection",
  },
];

const ServiceCard = ({ title, description, background, link }) => (
  <Link to={link} className="block">
    <div className="relative w-full h-[185px] rounded-lg overflow-hidden flex flex-col text-[#484848] p-4">
      <img src={background} alt={title} className="absolute inset-0 w-full h-full object-cover" />
    </div>
    <div className="relative z-10 flex flex-col mt-2">

      <h3 className="text-base font-bold">{title}</h3>
      <p className="text-sm mt-1">{description}</p>

    </div>
  </Link>
);

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(window.matchMedia(query).matches);
  useEffect(() => {
    const media = window.matchMedia(query);
    const listener = () => setMatches(media.matches);
    media.addEventListener("change", listener);
    return () => media.removeEventListener("change", listener);
  }, [query]);
  return matches;
};

const ServicesSlider = () => {
  const isMobile = useMediaQuery("(max-width: 820px)");
  return (
    <div className="bg-white w-all-sections-width mx-auto my-s10 md:my-m12 lg:my-l14 sm:py-0 py-4">
      <h2 className="h2 font-bold text-secondaryBlue mb-6 text-start">
        Exclusive services offered with every package
      </h2>
      {isMobile ? (
        <Swiper spaceBetween={10} slidesPerView={1} pagination={{ clickable: true }} modules={[Pagination]}>
          {servicesData.map((service, i) => (
            <SwiperSlide key={i} className="flex justify-center items-center">
              <ServiceCard {...service} />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div className="grid grid-cols-3 gap-6">
          {servicesData.map((service, i) => (
            <ServiceCard key={i} {...service} />
          ))}
        </div>
      )}
    </div>
  );
};

export default ServicesSlider;
