import React, { useEffect, useState } from "react";
import logo from "../../assets/logoIcon.png";
import { NavLink } from "react-router-dom";
import tickLogo from "../../assets/listPackage/ticktLogo.svg";
import satisfactionLogo from "../../assets/listPackage/satisfasction.svg";
import digitalImg from "../../assets/listPackage/digital.svg";
import expImg from "../../assets/listPackage/exp.svg";
import Footer from "../../components/Footer/Footer";
const ListPackagePage = () => {
  const [text, setText] = useState("Umrah");
  useEffect(() => {
    const interval = setInterval(() => {
      setText((prev) => (prev === "Umrah" ? "Hajj" : "Umrah"));
    }, 2000);
    return () => clearInterval(interval);
  }, []);
  const features = [
    {
      title: "Peace of mind",
      description:
        "Every financial transaction is tracked via completely transparent and robust payments system against each Booking and User ID.",
    },
    {
      title: "Extended customer services",
      description:
        "Interact directly with pilgrims – answer their queries, and build trust. HajjUmrah.co provides timely assistance with resolving customer queries for smooth delivery of all services throughout the journey. Boost sales and increase revenue through confirmed bookings & advance payments.",
    },
    {
      title: "Showcase your services globally",
      description:
        "Promote business and feature packages while enjoying access to a large network of potential customers. Our platform attracts a diverse range of pilgrims actively seeking Hajj and Umrah packages.",
    },
    {
      title: "Live tracking of all travelers",
      description:
        "Organise multiple groups and stay informed via GPS tags, monitoring every member to ensure smooth operations, and curtail overstays.",
    },
    {
      title: "Digital dashboard and simple tools",
      description:
        "Upload packages, manage customer bookings and complaints directly from a dedicated console. Our intuitive and easy package management tools help you efficiently manage your offerings, ensuring your packages are always up-to-date and appealing.",
    },
    {
      title: "Boost sales & Increase revenue",
      description:
        "Boost sales and increase revenue through confirmed bookings & advance payments. Opportunities for growth and expansion. Tap into new markets and grow your business by reaching out to a broader audience.",
    },
  ];
  const highlights = [
    {
      title: "100% Customer Satisfaction",
      description:
        "With our review and rating system, and strong focus on service quality, we ensure that pilgrims’ expectations are met, from flight bookings to hotel check-ins and beyond.",
      icon: satisfactionLogo,
    },
    {
      title: "Unmatched Experience",
      description:
        "Offering round-the-clock customer support, a dedicated complaint management system, call center, and on-site concierge teams to ensure a smooth, peaceful & satisfactory spiritual experience.",
      icon: expImg,
    },
    {
      title: "Digital Transformation",
      description:
        "Rapid growth in digital transactions and millions of new internet users daily, paired with ease of booking and transparency – HajjUmrah.co is the perfect partner for service providers, group operators, and travel agents.",
      icon: digitalImg,
    },
  ];

  return (
    <div className="text-secondaryBlue">
      <div className="bg-[#0C2A35]">
        <div className="w-all-sections-width mx-auto">
          <div className=" py-8 flex items-center gap-4">
            <img src={logo} alt="Logo" className="w-8 h-10" />
            <NavLink
              to="/"
              className={`text-xl sm:text-2xl lg:text-3xl font-medium notranslate text-white`}
              translate="no"
            >
              HajjUmrah.co
            </NavLink>
          </div>
          <div className="flex lg:gap-0 xl:gap-6 py-8">
            <div className="w-full">
              <p className=" text-white text-[40px] font-bold">List your </p>
              <p className="text-brandGold text-[40px] font-bold">
                {" "}
                {text} Packages
              </p>
              <p className=" text-white text-[40px] font-bold">
                on HajjUmrah.co
              </p>
              <p className="text-white w-3/4 text-lg pt-4 font-semibold">
                HajjUmrah.co Business portal allows seemless registration of new
                travel agents & operators from any city, along with a complete
                package management dashboard.
              </p>
            </div>
            <div className="bg-white border-4 h-[400px] border-brandGold rounded-sm p-8 text-sm w-1/2 space-y-4 ">
              <p className="font-bold text-xl text-secondaryBlue">
                Register for free
              </p>
              <ul className="text-sm text-secondaryBlue list-disc pl-5 pb-5 space-y-2">
                <li>70% of operators get their first booking within a week</li>
                <li>Instantly upload & manage all packages.</li>
                <li>We'll facilitate payments for you</li>
              </ul>
              <div className="border-[1px] border-brandGold"></div>
              <div className="p-2 py-[12px] bg-brandGold  text-white rounded-md text-center">
                <a href="https://operator.hajjumrah.co/" className="">
                  Get Started Now
                </a>
              </div>
              <div className="border-[1px] border-brandGold"></div>
              <p className="text-sm font-bold">Already a Partner?</p>
              <a href="https://operator.hajjumrah.co/" className="text-secondaryGray">
                Sign in
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="w-all-sections-width mx-auto py-16 text-secondaryBlue ">
          <p className="h3">
            Benefits for Service Providers, Group Operators & Travel Agents
          </p>
          <p className="text-md pt-4 w-3/4 text-secondaryGray">
            First global marketplace, where we simplify the Hajj & Umrah
            experience by centralizing services and streamlining the entire
            process, making it easier for all pilgrims to plan and execute their
            spiritual journey.
          </p>
          <div className="grid grid-cols-2 gap-10 py-6">
            {features.map((feature, index) => (
              <div key={index} className="">
                <div className="flex items-start gap-4">
                  <img src={tickLogo} alt="tick" />
                  <div>
                    <h3 className="text-xl font-bold mb-2">
                      {feature.title}
                    </h3>
                    <p className="text-sm font-semibold text-secondaryGray">
                      {feature.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="p-2 mt-4 bg-brandGold w-1/3 text-white rounded-md text-center">
            <a href="https://operator.hajjumrah.co/" className="">
              List Your Package Today
            </a>
          </div>
        </div>
      </div>
      <div className="w-all-sections-width mx-auto text-secondaryBlue bg-[#F6F6F6] py-16">
        <p className="h2">Stand out from the start</p>
        <div className="grid grid-cols-3 gap-8 py-6">
          {highlights.map((highlight, index) => (
            <div key={index} className="">
              <div className="flex flex-col ">
                <img src={highlight.icon} alt="" className="w-20 h-20" />
                <h3 className="text-lg font-bold mt-4 mb-2">
                  {highlight.title}
                </h3>
                <p className="text-sm font-semibold text-secondaryGray">{highlight.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ListPackagePage;
