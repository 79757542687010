import React, { useEffect } from "react";
import Banner from "../../HeroSection/Banner";
import HeaderFooter from "../../../../ZiyaratArticlesPages/HeaderFooter";
import Slider from "../../MadinahPlaces/Wadijin/Slider";
import Page from "./Page";
import SouqAlAlwiImg1 from "../../../../../assets/ZiyaratPlaces/Jeddah/al-alawi/image6.webp";
import SouqAlAlwiImg2 from "../../../../../assets/ZiyaratPlaces/Jeddah/al-alawi/image4.avif";
import SouqAlAlwiImg3 from "../../../../../assets/ZiyaratPlaces/Jeddah/al-alawi/image2.jpg";
import SouqAlAlwiImg4 from "../../../../../assets/ZiyaratPlaces/Jeddah/al-alawi/image1.jpg";
const SouqAlAlawi = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const images = [
    SouqAlAlwiImg1,
    SouqAlAlwiImg2,
    SouqAlAlwiImg3,
    SouqAlAlwiImg4,
  ];

  return (
    <div>
      <HeaderFooter>
        <Banner
          image={SouqAlAlwiImg4}
          title={"Souq Al Alawi Historical Gem in Jeddah"}
        />
        <Page />
        <Slider images={images} />
      </HeaderFooter>
    </div>
  );
};

export default SouqAlAlawi;
