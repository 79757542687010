import React, { useState } from "react";
// import cardImg from "../../../assets/Payment/card.png";
// import jazzCard from "../../../assets/Payment/jazz.svg";
import voucherImg from "../../../assets/Payment/voucher.svg";
import bankImg from "../../../assets/Payment/bank1.svg";
import { BankingInfo, JazzInfo, PaymentInfo, VoucherInfo } from "../../../";

const PaymentOption = ({
  transferID,
  setTransferID,
  validateTransferID,
  previewFile,
  handleFileChange,
  errors,
  handleRadio,
  clickRadio,
  // setSelectedFiles,
}) => {
  // // Default to "card"
  // const [clickRadio, setClickRadio] = useState("bank");

  // const handleRadio = (type) => {
  //   setClickRadio(type);
  // };

  return (
    <div className="text-secondaryBlue">
      <p className="text-[24px] font-600 mb-2">Payment Method</p>
      <div className="p-6 gap-2 grid lg:grid-cols-3 xl:grid-cols-4 border-[1px] border-lightGray bg-white rounded-md">
        {/* <div
          className={`flex justify-between items-center border-2 p-2 rounded-md cursor-pointer ${
            clickRadio === "card" ? "border-[#3fb2aa]" : "border-lightGray"
          }`}
          onClick={() => handleRadio("card")}
        >
          <img src={cardImg} alt="card" />
          <p>Card</p>
          <input
            type="radio"
            name="card"
            value="card"
            checked={clickRadio === "card"}
            onChange={() => handleRadio("card")}
          />
        </div> */}

        {/* <div
          className={`flex justify-between items-center border-2 p-2 rounded-md cursor-pointer ${
            clickRadio === "jazz" ? "border-[#3fb2aa]" : "border-lightGray"
          }`}
          onClick={() => handleRadio("jazz")}
        >
          <img src={jazzCard} alt="JazzCash" />
          <p>JazzCash</p>
          <input
            type="radio"
            name="jazz"
            value="jazz"
            checked={clickRadio === "jazz"}
            onChange={() => handleRadio("jazz")}
          />
        </div> */}

        <div
          className={`flex justify-between items-center border-2 p-2 rounded-md cursor-pointer ${
            clickRadio === "bank" ? "border-[#3fb2aa]" : "border-lightGray"
          }`}
          onClick={() => handleRadio("bank")}
        >
          <div className="flex gap-2 items-center">
            <img src={voucherImg} alt="Bank" />
            <p>Bank</p>
          </div>
          <input
            type="radio"
            name="bank"
            value="bank"
            checked={clickRadio === "bank"}
            onChange={() => handleRadio("bank")}
          />
        </div>

        {/* <div
          className={`flex justify-between items-center border-2 p-2 rounded-md cursor-pointer ${
            clickRadio === "voucher" ? "border-[#3fb2aa]" : "border-lightGray"
          }`}
          onClick={() => handleRadio("voucher")}
        >
          <img src={bankImg} alt="Voucher" />
          <p>Voucher</p>
          <input
            type="radio"
            name="voucher"
            value="voucher"
            checked={clickRadio === "voucher"}
            onChange={() => handleRadio("voucher")}
          />
        </div> */}
      </div>

      {/* {clickRadio === "card" && (
        <PaymentInfo
          transferID={transferID}
          setTransferID={setTransferID}
          validateTransferID={validateTransferID}
          errors={errors}
        />
      )} */}
      {clickRadio === "bank" && (
        <BankingInfo
          transferID={transferID}
          setTransferID={setTransferID}
          validateTransferID={validateTransferID}
          errors={errors}
          handleFileChange={handleFileChange}
          previewFile={previewFile}
          // setSelectedFiles={setSelectedFiles}
        />
      )}
      {/* {clickRadio === "jazz" && (
        <JazzInfo
          transferID={transferID}
          setTransferID={setTransferID}
          validateTransferID={validateTransferID}
          errors={errors}
        />
      )} */}
      {/* {clickRadio === "voucher" && (
        <VoucherInfo
          previewFile={previewFile}
          handleFileChange={handleFileChange}
          errors={errors}
        />
      )} */}
    </div>
  );
};

export default PaymentOption;
