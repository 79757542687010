import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Header, Footer } from "../../";
import passportImg from "../../assets/visa/passport.svg";
import cnicImg from "../../assets/visa/CNIC.svg";
import picturesImg from "../../assets/visa/pictures.svg";
import contactImg from "../../assets/visa/contact.svg";
import reserveImg from "../../assets/visa/reservation.svg";
import familyImg from "../../assets/visa/ffmaily.svg";
const VisaServices = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    mobileNumber: "",
  });

  const [formErrors, setFormErrors] = useState({
    firstName: false,
    email: false,
    mobileNumber: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validateForm = () => {
    const errors = {
      firstName: formData.firstName === "",
      email: !validateEmail(formData.email),
      mobileNumber: formData.mobileNumber.length < 10,
    };
    setFormErrors(errors);
    return !Object.values(errors).includes(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // proceed with form submission
      navigate("/evisa-services/confirmation-process");
    }
  };

  const data = [
    {
      image: passportImg,
      title: "Passport 1st and 2nd pages",
      description:
        "A passport should be valid for more than 6 months for visa processing",
    },
    {
      image: cnicImg,
      title: "NIC / Smart CNIC",
      description: "Adult / Child / Infant",
    },
    {
      image: picturesImg,
      title: "Pictures",
      description: "01 passport size picture with white background",
    },
    {
      image: contactImg,
      title: "Contact Information",
      description: "Emergency contact number",
    },
    {
      image: reserveImg,
      title: "Reservations",
      description:
        "Tentative Hotel and Ticket reservations provided by Sastaticket",
    },
    {
      image: familyImg,
      title: "Family Details",
      description: "Applicant’s mother and father names",
    },
  ];

  return (
    <div className="bg-[#f8f8f8] text-secondaryBlue">
      <Header />
      <div className=" w-all-sections-width mx-auto">
        <div className="py-6 space-y-2">
          <p className="text-[16px] font-[400] text-brandGold">
            Apply for Umrah Visa Now!
          </p>
          <p className="text-[14px] font-[400]">Umrah Visa Price</p>
        </div>
        <div className="mb-10">
          <div className="bg-white p-6 px-8 pb-14 rounded-t-lg rounded-b-sm border-[1px] border-[#cbcbcb]">
            <div className="md:flex md:justify-between items-center">
              <div className="flex gap-5 items-center">
                <h1 className="text-[20px] font-semibold">Umrah Visa</h1>
                <p className="px-2 border-[1px] border-brandGold text-white text-[12px] font-[400] rounded-[3px] bg-brandGold bg-opacity-5">
                  eVisa
                </p>
              </div>
              <p className="text-[20px] font-[400]">PKR 45,000</p>
            </div>
            <p className="text-[14px] font-[400] py-3 xl:py-5">
              In order to process your Umrah Visa, you will need the following
              documents. Processing time will be 8 working days.
            </p>
            <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 md:gap-3 xl:gap-6 space-y-3">
              {data.map((item, index) => (
                <div className="flex gap-4 items-center p-2" key={index}>
                  <img
                    src={item.image}
                    alt={item.title}
                    className="h-12 w-12"
                  />
                  <div className="space-y-1.5">
                    <h1 className="text-[14px] xl:text-[16px] font-[400]">
                      {item.title}
                    </h1>
                    <p className="text-[12px] xl:text-[14px] font-[400] opacity-60">
                      {item.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="bg-white p-6 px-8 rounded-t-sm rounded-b-lg border-[1px] border-[#cbcbcb]">
              <h1 className="text-[20px] font-semibold ">
                Enter Contact Details
              </h1>
              <p className="text-[14px] font-[400] py-3 xl:py-4">
                In order to process your Umrah Visa, you will need the following
                documents. Processing time will be 8 working days.
              </p>
              <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6">
                <div className="flex flex-col w-full space-y-2">
                  <label className="text-[14px] font-semibold ">
                    First Name
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    placeholder="Enter Full Name"
                    className={`p-2 rounded-md border ${
                      formErrors.firstName
                        ? "border-red-500"
                        : "border-gray-300"
                    } opacity-60 text-[14px] font-[400] outline-none w-full`}
                  />
                  {formErrors.firstName && (
                    <span className="text-red-500 text-[12px]">
                      First Name is required
                    </span>
                  )}
                </div>
                <div className="flex flex-col w-full space-y-2">
                  <label className="text-[14px] font-semibold">
                    Email Address
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Enter email address"
                    className={`p-2 rounded-md border ${
                      formErrors.email ? "border-red-500" : "border-gray-300"
                    } opacity-60 text-[14px] font-[400] outline-none w-full`}
                  />
                  {formErrors.email && (
                    <span className="text-red-500 text-[12px]">
                      Valid Email is required
                    </span>
                  )}
                </div>
                <div className="flex flex-col w-full space-y-2">
                  <label className="text-[14px] font-semibold ">
                    Mobile Number
                  </label>
                  <div className="flex mt-1">
                    <div className="flex items-center px-4 border border-gray-300 bg-gray-50 rounded-l-md">
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/32/Flag_of_Pakistan.svg/2560px-Flag_of_Pakistan.svg.png"
                        alt="Pakistan Flag"
                        className="h-4 w-6 lg:mr-1 xl:mr-2"
                      />
                      <span>+92</span>
                    </div>
                    <input
                      type="text"
                      name="mobileNumber"
                      value={formData.mobileNumber}
                      onChange={handleChange}
                      placeholder="3001234567"
                      className={`p-2 block w-full border ${
                        formErrors.mobileNumber
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded-r-md`}
                    />
                  </div>
                  {formErrors.mobileNumber && (
                    <span className="text-red-500 text-[12px]">
                      Minimum 10 digits required
                    </span>
                  )}
                </div>
              </div>
              <div className="flex justify-center py-10">
                <button
                  type="submit"
                  className="bg-brandGold text-white text-[16px] font-[400] p-2 px-6 rounded-md"
                >
                  Talk to our Visa Expert
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default VisaServices;
