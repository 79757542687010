import React, { useState } from "react";
import { respondToObjection } from "../../../../api/apiService";

const MAX_FILE_SIZE_MB = 2; // 2MB limit
const ALLOWED_EXTENSIONS = ["pdf", "doc", "docx", "jpg", "png"];

const ObjectionComponent = ({ booking, updateBookingStatus }) => {
  const [objectionResponses, setObjectionResponses] = useState({});
  const [notification, setNotification] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleFileChange = (objectionId, file) => {
    if (!file) return;
    if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
      setNotification(`File size must be less than ${MAX_FILE_SIZE_MB}MB.`);
      return;
    }
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (!ALLOWED_EXTENSIONS.includes(fileExtension)) {
      setNotification(
        `Invalid file type. Allowed types: ${ALLOWED_EXTENSIONS.join(", ")}`
      );
      return;
    }

    setNotification("");
    setObjectionResponses((prev) => ({
      ...prev,
      [objectionId]: {
        ...prev[objectionId],
        file,
        fileName: file.name,
        fileUrl: URL.createObjectURL(file),
      },
    }));
  };

  const handleRemarksChange = (objectionId, remarks) => {
    setObjectionResponses((prev) => ({
      ...prev,
      [objectionId]: { ...prev[objectionId], remarks },
    }));
  };

  const handleSubmit = async (objectionId) => {
    const response = objectionResponses[objectionId];

    if (!response?.file || !response?.remarks) {
      setNotification("Please provide both a document and remarks.");
      return;
    }

    setIsSubmitting(true);

    const sessionToken =
      booking.user_session_token || localStorage.getItem("session_token");
    if (!sessionToken) {
      setNotification("Session token is missing. Please log in again.");
      setIsSubmitting(false);
      return;
    }

    const formData = new FormData();
    formData.append("objection_document", response.file, response.fileName);
    formData.append("client_remarks", response.remarks);
    formData.append("objection_id", objectionId);
    formData.append("booking_number", booking.booking_number);
    formData.append("session_token", sessionToken);

    try {
      const result = await respondToObjection(formData);

      if (result.success) {
        setNotification("Objection resolved successfully!");
        setObjectionResponses((prev) => {
          const newState = { ...prev };
          delete newState[objectionId];
          return newState;
        });
        updateBookingStatus("Pending");
      } else {
        setNotification(`Error: ${result.error || "Unknown error occurred"}`);
      }
    } catch (error) {
      console.error("Submission error:", error);
      setNotification("Failed to submit response. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-white p-6 border-[1px] mb-10 rounded-md">
      <h2 className="text-[20px] font-700">Booking Objections</h2>
      <p className="text-[16px] font-400 mt-2">
        {booking.booking_objections?.length > 0
          ? "Your booking has objections that need to be addressed before proceeding."
          : "No objections found."}
      </p>

      {notification && (
        <div className="mt-4 p-3 bg-yellow-100 text-yellow-800 rounded-md">
          {notification}
        </div>
      )}

      {booking.booking_objections?.map((objection, index) => {
        const currentResponse = objectionResponses[objection.objection_id] || {};
        const isEditable =
          objection.client_remarks === null &&
          objection.required_document_for_objection === null;

        return (
          <div key={objection.objection_id} className="p-4 border-b">
            <p className="text-[16px] font-600">Objection {index + 1}:</p>
            <p className="text-[14px] font-400 mt-1 text-gray-600">
              {objection.remarks_or_reason}
            </p>

            {!isEditable ? (
              <div className="mt-4 p-4 bg-gray-100 rounded-md">
                <p className="text-[14px] font-600">Client Response:</p>
                <p className="text-[14px] text-gray-700">
                  {objection.client_remarks || "No response provided"}
                </p>

                {objection.required_document_for_objection && (
                  <div className="mt-2">
                    <p className="text-[14px] font-600">Submitted Document:</p>
                    <a
                      href={objection.required_document_for_objection}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-brandGold underline"
                    >
                      View Document
                    </a>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <div className="mt-4">
                  <input
                    type="file"
                    onChange={(e) =>
                      handleFileChange(
                        objection.objection_id,
                        e.target.files[0]
                      )
                    }
                    className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-brandGold file:text-white hover:file:bg-yellow-600"
                    accept=".pdf,.doc,.docx,.jpg,.png"
                  />
                  {currentResponse.fileName && (
                    <p className="mt-2 text-sm text-gray-600">
                      Selected file: {currentResponse.fileName}
                    </p>
                  )}

                  {currentResponse.file &&
                    ["jpg", "png"].includes(
                      currentResponse.fileName.split(".").pop().toLowerCase()
                    ) && (
                      <img
                        src={currentResponse.fileUrl}
                        alt="Preview"
                        className="mt-2 max-w-[200px] border-[1px] rounded-md"
                      />
                    )}

                  {currentResponse.file &&
                    ["pdf", "doc", "docx"].includes(
                      currentResponse.fileName.split(".").pop().toLowerCase()
                    ) && (
                      <p className="mt-2">
                        <a
                          href={currentResponse.fileUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-brandGold underline"
                        >
                          Open Document
                        </a>
                      </p>
                    )}
                </div>

                <textarea
                  className="w-full p-2 mt-4 border-[1px] rounded-md focus:ring-2 focus:ring-yellow-600 focus:border-yellow-600"
                  placeholder="Enter your response remarks"
                  value={currentResponse.remarks || ""}
                  onChange={(e) =>
                    handleRemarksChange(objection.objection_id, e.target.value)
                  }
                  rows="4"
                />

                <button
                  className={`mt-4 px-6 py-2 bg-brandGold text-white rounded-md hover:bg-yellow-600 transition-colors ${
                    isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  onClick={() => handleSubmit(objection.objection_id)}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Submit Response"}
                </button>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ObjectionComponent;
