import React, { useEffect } from "react";
import { Footer, Header } from "../../../";

const CoreValues = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  return (
    <div>
      <Header />
      <section className="bg-white text-[#484848] py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="mb-16">
            <h2 className="text-[20px] text-center  font-kd font-bold text-[#484848] mb-4">
              Core Values
            </h2>
            <p className="text-[15px] font-kd leading-7 text-[#484848]">
              At hajjumrah.co, our core values are the foundation of everything
              we do. They guide our actions, shape our culture, and reflect our
              commitment to providing exceptional Hajj and Umrah services.
            </p>
          </div>
          <div className="space-y-8">
            <div>
              <h3 className="text-[20px] font-kd font-semibold text-[#484848] mb-4">
                Integrity
              </h3>
              <p className="text-[15px] font-kd leading-7 text-[#484848]">
                Integrity is at the heart of our business. We are committed to
                honesty, transparency, and ethical practices in all our
                interactions. Our dedication to integrity ensures that we build
                trust and reliability with our customers, vendors, and partners.
                We believe that by maintaining the highest standards of
                integrity, we can create a solid foundation for long-term
                relationships and success.
              </p>
            </div>
            <div>
              <h3 className="text-[20px] font-kd font-semibold text-[#484848] mb-4">
                Customer Focus
              </h3>
              <p className="text-[15px] font-kd leading-7 text-[#484848]">
                Our customers are at the center of everything we do. We strive
                to understand their needs and exceed their expectations,
                ensuring their satisfaction and well-being throughout their
                journey. By providing personalized service and support, we aim
                to make every Hajj and Umrah experience seamless and meaningful.
                Our commitment to our customers drives us to continuously
                improve and adapt our services to better serve them.
              </p>
            </div>
            <div>
              <h3 className="text-[20px] font-kd font-semibold text-[#484848] mb-4">
                Innovation
              </h3>
              <p className="text-[15px] font-kd leading-7 text-[#484848]">
                We believe in the power of innovation to transform the Hajj and
                Umrah experience. By leveraging cutting-edge technology, we
                continuously enhance our services and user experience. Our focus
                on innovation allows us to offer new and improved solutions that
                meet the evolving needs of our customers. We are committed to
                staying ahead of industry trends and embracing new ideas to
                provide the best possible experience for our users.
              </p>
            </div>
            <div>
              <h3 className="text-[20px] font-kd font-semibold text-[#484848] mb-4">
                Community
              </h3>
              <p className="text-[15px] font-kd leading-7 text-[#484848]">
                We foster a sense of community and support among our users,
                partners, and within our organization. Together, we create a
                supportive and inclusive environment where everyone feels valued
                and respected. Our commitment to community extends beyond our
                platform, as we actively engage in initiatives that benefit the
                broader Muslim community. We believe that by working together,
                we can achieve greater success and create a positive impact.
              </p>
            </div>
            <div>
              <h3 className="text-[20px] font-kd font-semibold text-[#484848] mb-4">
                Inclusivity
              </h3>
              <p className="text-[15px] font-kd leading-7 text-[#484848]">
                Hajj and Umrah should be accessible to all Muslims. We are
                dedicated to offering affordable packages and financial
                assistance to ensure everyone can fulfill their spiritual
                obligations. Our inclusive approach means we cater to the
                diverse needs of our users, regardless of their financial status
                or background. We believe that by making these sacred journeys
                accessible to all, we can help more people connect with their
                faith and enrich their lives.
              </p>
            </div>
            <div>
              <h3 className="text-[20px] font-kd font-semibold text-[#484848] mb-4">
                Excellence
              </h3>
              <p className="text-[15px] font-kd leading-7 text-[#484848]">
                We pursue excellence in every aspect of our business. From
                customer service to service delivery, we aim to provide the
                highest quality experience for our users. Our commitment to
                excellence drives us to continually improve our processes,
                services, and products. We believe that by striving for
                excellence, we can achieve our mission and vision, and make a
                lasting positive impact on the Hajj and Umrah experience for all
                Muslims.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-100 text-[#484848] py-14">
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-[20px] font-kd font-bold text-[#484848] mb-4">
              How We Live Our Values
            </h2>
            <p className="text-[15px] font-kd leading-7 text-[#484848]">
              At hajjumrah.co, our values are not just words; they are lived
              experiences. From the transparency in our pricing to the
              continuous innovation in our booking process, we embody our values
              every day. Our customers' testimonials reflect our commitment to
              these principles, and our vendors appreciate our integrity and
              partnership.
            </p>
          </div>
        </div>
      </section>
      <section className="bg-white text-[#484848] py-14">
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-[20px] font-kd font-bold text-[#484848] mb-4">
              Commitment to Core Values
            </h2>
            <p className="text-[15px] font-kd leading-7 text-[#484848]">
              We are dedicated to upholding these core values in all our future
              endeavors. Our commitment to integrity, customer focus,
              innovation, community, inclusivity, and excellence will continue
              to guide our path forward.
            </p>
          </div>
        </div>
      </section>
      <section className="bg-gray-100 text-[#484848] py-14 ">
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-[20px] font-kd font-bold text-[#484848] mb-4">
              Join Us
            </h2>
            <p className="text-[15px] font-kd leading-7 text-[#484848] mb-8">
              Join us in our mission to make Hajj and Umrah accessible and
              meaningful for all. Engage with us, provide feedback, or join our
              community.
            </p>
            <p className="text-[15px] font-kd leading-7 text-[#484848]">
              For more information about our core values, contact us at [contact
              information].
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default CoreValues;
