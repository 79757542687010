import React from "react";
import MasjidIjabahImg5 from "../../../../../assets/ZiyaratPlaces/Madinah/MasjidEjabah/ejabah4.jpg";
import MasjidIjabahImg6 from "../../../../../assets/ZiyaratPlaces/Madinah/MasjidEjabah/ejabah2.svg";
const Page = () => {
  return (
    <div>
      <div className="bg-[#F2F2F2]">
        <p className="text-center text-[#484848] font-bold py-4 font-cairo md:text-[25px] text-[15px]">
          13 min (6.7 km) distance from madina via Prince Abdulmajeed Ibn
          Abdulaziz
        </p>
      </div>
      <div className=" w-all-sections-width mx-auto">
        {/* upper section named location */}
        <div className="pt-14">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Historical Background & The Foundation of Masjid Ejabah
          </h2>
          <div className="flex flex-col lg:flex-row mt-4 gap-10">
            <div className="w-full">
              <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px] text-justify">
                Masjid Ejabah, also known as Masjid Al-Ijabah, stands as a
                profound symbol of historical and spiritual significance within
                the Islamic world. Located in the heart of Madinah, Saudi
                Arabia, this mosque holds a rich heritage intertwined with the
                life of Prophet Muhammad (peace be upon him) and the early days
                of Islam. History and Significance: Masjid Ejabah is situated
                approximately 580 meters north of Al-Masjid an-Nabawi, the
                Prophet’s Mosque, in Madinah. Its name, "Ejabah," translates to
                "Response" in Arabic, which is deeply rooted in a significant
                event from Islamic history. According to tradition, this mosque
                marks the spot where Prophet Muhammad (peace be upon him)
                supplicated to Allah, and his prayer was answered—hence the name
                "Masjid Ejabah." This historical event imbues the mosque with
                profound spiritual significance, symbolizing the power of faith
                and divine response in Islamic tradition. It serves as a
                reminder of the intimate connection between supplication and
                divine providence, a core tenet of Islamic belief. Architecture:
                Architecturally, Masjid Ejabah reflects the traditional Islamic
                style prevalent in the region. While specific architectural
                details may vary, mosques in Madinah typically feature simple
                yet elegant designs, with domes and minarets serving as
                prominent features. The emphasis is often on creating a serene
                and conducive environment for prayer and reflection. Cultural
                Impact: Beyond its architectural beauty and historical
                importance, Masjid Ejabah plays a crucial role in the cultural
                fabric of Madinah and the broader Islamic world.{" "}
                <p className="lg:hidden xl:block text-[#484848] mb-4 font-cairo font-medium text-[15px] text-justify">
                  It serves as a pilgrimage site for Muslims seeking spiritual
                  solace and blessings, drawing visitors from around the globe
                  who come to pay homage to the Prophet’s legacy and to
                  experience the spiritual atmosphere of the holy city. The
                  mosque also serves as a center for community gatherings,
                  religious lectures, and educational activities, fostering a
                  sense of unity and spiritual growth among its worshippers.
                </p>
              </p>
            </div>
            <div className="lg:w-1/2 mt-4 lg:mt-0">
              <img
                src={MasjidIjabahImg5}
                alt="Wadi-e-Jinn"
                className="rounded-lg shadow-lg h-[392px] w-[523px]"
              />
            </div>
          </div>
          <p className="hidden lg:flex xl:hidden text-[#484848] mb-4 font-cairo font-medium text-[15px] text-justify">
            It serves as a pilgrimage site for Muslims seeking spiritual solace
            and blessings, drawing visitors from around the globe who come to
            pay homage to the Prophet’s legacy and to experience the spiritual
            atmosphere of the holy city. The mosque also serves as a center for
            community gatherings, religious lectures, and educational
            activities, fostering a sense of unity and spiritual growth among
            its worshippers.
          </p>
        </div>
        {/* location section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            The Era of the Prophet Muhammad
          </h2>
          <div className="mt-4">
            <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px] text-justify">
              During the Prophet Muhammad's time, Madinah was not just a city;
              it was the epicenter of the nascent Muslim community. Masjid
              Ejabah's proximity to Al-Masjid an-Nabawi meant that it was within
              the sphere of the Prophet’s daily activities and interactions. It
              is documented that the Prophet frequently visited this location,
              making it a place of personal and communal supplication. This
              close connection to the Prophet himself adds a layer of reverence
              and sanctity to the mosque.
              <br />
              <br />
              Over the centuries, Masjid Ejabah has witnessed various phases of
              renovation and expansion, reflecting the growth and changing
              dynamics of the Islamic world. Different Islamic caliphates and
              rulers have contributed to its upkeep and embellishment, ensuring
              that the mosque remains a vibrant place of worship and historical
              remembrance.
            </p>
          </div>
          <div className="mt-8 flex flex-col items-center">
            <img
              src={MasjidIjabahImg6}
              alt="Islamic Calligraphy"
              className="w-full h-auto rounded-lg shadow-lg"
            />
            <p className="text-center text-[#484848] font-cairo text-[15px] font-semibold mt-4">
              Masjid Al-Ijabah Mosque In saudi Arabia
            </p>
          </div>
        </div>
        {/* islam section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            How to Explore Masjid Ejabah?
          </h2>
          <div className="mt-4">
            <ul className="list-none space-y-2 text-[#484848] mb-4 font-cairo font-medium text-[17px] text-justify">
              <li>
                - Location and Accessibility: Masjid Ejabah is located in
                Medina, near the Prophet’s Mosque. It is easily accessible by
                car, taxi, or public transport.
              </li>
              <li>
                - Historical Significance: The mosque is known for the incident
                where the Prophet Muhammad made a supplication (dua) and
                received a response from Allah.
              </li>
              <li>
                - Best Visiting Times: Early morning or late evening visits are
                recommended for a serene experience. Avoid peak pilgrimage
                seasons like Hajj and Ramadan for fewer crowds.
              </li>
              <li>
                - Activities: Engage in prayer and supplication, reflecting on
                the historical event associated with the mosque. Spend time in
                quiet reflection and reading Islamic texts.
              </li>
              <li>
                - Cultural Etiquette: Dress modestly and maintain respectful
                behavior. Ensure cleanliness and engage with locals or guides to
                learn more about the mosque’s significance.
              </li>
              <li>
                - Nearby Landmarks: Combine your visit with trips to nearby
                significant sites like the Prophet’s Mosque, Quba Mosque, and
                Al-Baqi Cemetery.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page;
