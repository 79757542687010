import React, { useEffect } from "react";
import Banner from "./Banner";
import Card from "../Card";
import MakkahHolySites from "./MakkahBanner";
import {
  MakkahPlacesHolySites,
  MakkahPlacesAttractions,
} from "../../../Utilities/placesdata";

const Makkah = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const sections = [
    { title: "Holy Sites", data: MakkahPlacesHolySites },
    { title: "Attractions", data: MakkahPlacesAttractions },
  ];
  return (
    <div>
      <Banner />
      <MakkahHolySites />
      <Card sections={sections} />
    </div>
  );
};

export default Makkah;
