import React from "react";

const MadinaHolySites = () => (
  <div className=" w-all-sections-width mx-auto py-5 pt-10">
    <h1 className="text-cairo font-semibold md:text-[25px] text-[30px] text-[#484848] py-2.5">
      Madina Holy Sites
    </h1>
    <p className="text-[16px] text-[#484848] text-left leading-[20px] font-kd">
      In the serene city of Madina, nestled in the heart of Islam, lies the
      revered Masjid An-Nabawi, the radiant resting place of Prophet Muhammad
      (peace be upon him). For Muslims worldwide, Madina is a cherished
      destination, a beacon of spiritual significance. Just as pilgrims yearn
      for Makkah, the call of Madina resonates deeply in every believer's heart.
      It is here, in this blessed city, that the Prophet's divine mission
      unfolded, spreading the illuminating teachings of Islam far and wide after
      the revelation of the Holy Quran. Embraced by the tranquil landscapes of
      western Saudi Arabia, Madina has thrived under the guardianship of Saudi
      kings, devoted to enriching the pilgrimage journey. The timeless beauty of
      Masjid An-Nabawi has witnessed countless expansions, ensuring pilgrims
      find solace and tranquility within its sacred walls. From every corner of
      the globe, believers are drawn to Madina, where the legacy of Prophet
      Muhammad continues to inspire hearts and souls with its profound spiritual
      significance.
    </p>
  </div>
);

export default MadinaHolySites;
