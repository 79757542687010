import { useEffect, useRef } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Footer } from "../../../../../";
import { MuzdalifahImages } from "../../../../../Utilities/placesdata";
import BlogHeader from "../../../../ZiyaratArticlesPages/BlogHeader";
import icon from "../../../../../assets/ZiyaratPlaces/Makkah/SafaMarwa/makkahPageIcon.svg";

const Muzdalifah = () => {
  const swiperRef = useRef(null);

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    // Scroll to top when the component mounts
    scrollToTop();

    // Scroll to top when the page is reloaded or navigated from another page
    window.addEventListener("beforeunload", scrollToTop);

    return () => {
      window.removeEventListener("beforeunload", scrollToTop);
    };
  }, []);

  return (
    <div className="text-[#4b465c] font-cairo">
      {/* <Navbar /> */}
      <BlogHeader />

      <div
        className="w-full h-[300px] bg-cover bg-center bg-no-repeat relative"
        style={{
          backgroundImage: `url(${MuzdalifahImages.image1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="bg-[#00936C] opacity-20 w-full h-[300px] z-10"></div>

        <div className="absolute bottom-0 left-0 right-0 p-6 w-[85%] lg:pb-10 mx-auto text-white z-20">
          <h1 className="font-kd font-bold text-4xl">Muzdalifah</h1>
        </div>
      </div>
      <div className=" bg-[#F2F2F2] mx-auto p-6 text-xl md:text-2xl font-bold text-center ">
        <h1 className="w-[90%] mx-auto">
          On the 9th Dhul Hijjah (second day of Hajj), pilgrims arrive here
          after sunset from Arafat and spend the night here.
        </h1>
      </div>
      <div className="w-[85%] mx-auto py-10">
        <div>
          <h1 className="text-[25px] font-bold pb-1">Reference in the Quran</h1>
          <div className="flex flex-col lg:flex-row gap-4 mt-4 ">
            {" "}
            <div className="lg:w-[55%] space-y-4 font-medium text-[15px]">
              <p>
                Allah (ﷻ) mentions in Surah Baqarah in the Quran:“When you leave
                Arafat, then remember Allah at the Mash’arul Haram.” (‘The
                Sacred Monument’, referring to Muzdalifah according to Abdullah
                bin Umar (رضي الله عنه))
              </p>
              <div className="py-2.5">
                <h1 className="text-[25px] font-bold">
                  Combining of salahs at Muzdalifah
                </h1>
                <p className=" font-medium text-[15px] pt-4">
                  During the farewell Hajj, the Prophet (ﷺ) performed the
                  Maghrib and Isha salats together here. He stayed at the spot
                  where the present Masjid Mash’arul Haram currently is (towards
                  the Qibla side). From there, the Prophet (ﷺ) said:“Although I
                  am staying here, you may stay anywhere throughout Muzdalifah.”
                  [Muslim] <br />
                  During Hajj It is waajib to perform Maghrib and Isha together
                  here at Isha time.
                </p>
                <p className="lg:hidden xl:block">
                  Arafat holds historical significance dating back to the time
                  of Prophet Muhammad (peace be upon him), who delivered his
                  farewell sermon during his final pilgrimage (Hajjat-ul-Wada)
                  from the plains of Arafat. This sermon, known as the Farewell
                  Sermon, emphasized principles of unity, equality, justice, and
                  compassion among Muslims, leaving a lasting legacy for
                  generations to come.
                </p>
              </div>
            </div>
            <div className="lg:w-[45%]">
              <img
                src={MuzdalifahImages.image3}
                alt="Jabal al-Nour and the Cave of Hira"
              />
            </div>
          </div>
          <p className="hidden lg:flex xl:hidden">
            Arafat holds historical significance dating back to the time of
            Prophet Muhammad (peace be upon him), who delivered his farewell
            sermon during his final pilgrimage (Hajjat-ul-Wada) from the plains
            of Arafat. This sermon, known as the Farewell Sermon, emphasized
            principles of unity, equality, justice, and compassion among
            Muslims, leaving a lasting legacy for generations to come.
          </p>
        </div>

        <div className="pt-10">
          <h1 className="text-[25px] font-bold pb-1">Meaning of Muzdalifah</h1>
          <div className="pt-3">
            <div className="font-cairo font-medium text-[15px] space-y-2">
              <p>
                Muzdalifah holds deep significance in the Islamic tradition,
                serving as a crucial station during the Hajj pilgrimage near
                Mecca. This open, level area is where pilgrims gather after
                sunset from Arafat on the 9th day of Dhu al-Hijjah.
              </p>
              <p>
                Muzdalifah, nestled between Mecca and Mina, is a serene
                sanctuary during the Hajj pilgrimage. Here, millions gather
                under the starry sky, reflecting on their faith and unity.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 pt-10">
              <div className="bg-gray-100 p-6">
                <span className="font-bold block text-[25px]">
                  Historical and Spiritual Significance:
                </span>
                <p className=" font-normal text-[15px] pt-2">
                  Muzdalifah is celebrated as the place where pilgrims collect
                  pebbles for the ritual of Rami al-Jamarat, where stones are
                  thrown at three pillars representing the devil, reenacting the
                  actions of Prophet Abraham.
                </p>
              </div>
              <div className="border-y-2 border-[#E6F4F0] p-6">
                <span className="font-bold block text-[25px]">
                  The Rituals Performed:
                </span>
                <p className=" font-normal text-[15px] pt-2">
                  Pilgrims spend the night under the open sky at Muzdalifah, an
                  act reflecting humility and devotion. They also perform
                  Maghrib and Isha prayers combined, symbolizing unity and
                  faith.
                </p>
              </div>
              <div className="bg-gray-100 p-6">
                <span className="font-bold block text-[25px]">
                  Spiritual Reflection and Devotion:
                </span>
                <p className=" font-normal text-[15px] pt-2">
                  The night spent at Muzdalifah is a time for prayer and
                  reflection, allowing pilgrims to prepare spiritually for the
                  final days of Hajj. It is a moment to seek forgiveness and
                  mercy from God.
                </p>
              </div>
              <div className="border-y-2 border-[#E6F4F0] p-6">
                <span className="font-bold block text-[25px]">
                  Unity and Continuity:
                </span>
                <p className=" font-normal text-[15px] pt-2">
                  The communal gathering at Muzdalifah emphasizes the unity of
                  the Muslim Ummah, as millions of pilgrims share the same
                  space, praying and reflecting together, highlighting the
                  egalitarian spirit of Hajj.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className=" ">
          <h1 className="font-bold block text-[25px] pt-10">
            Collecting pebbles
          </h1>
          <div className="space-y-1.5">
            <p className=" font-normal text-[15px] pt-2">
              Although pebbles for pelting the Jamarat may be collected from
              anywhere, they are best collected from Muzdalifah.
            </p>
            <div className="md:flex space-y-4 md:space-y-0 gap-5 text-center pt-5">
              <img
                src={MuzdalifahImages.image2}
                alt="muzdalifah collecting pebbles"
                className="md:w-1/2 rounded-lg md:h-[300px]"
              />
              <img
                src={MuzdalifahImages.image5}
                alt="muzdalifah collecting pebbles"
                className="md:w-1/2 md:h-[300px] rounded-lg"
              />
            </div>
          </div>
        </div>
        <h3 className="font-bold text-[24px] md:text-[25px] pb-1 pt-10 ">
          Muzdalifah: A Key Location in Hajj
        </h3>
        <div className="lg:flex md:block block gap-5 pt-5">
          <div className="block lg:w-[55%]">
            <p className=" font-medium text-[15px]">
              Muzdalifah is an open, level area located between Mount Arafat and
              Mina, where pilgrims gather during the Hajj. This location is
              known for the ritual of collecting pebbles for the stoning of the
              devil at Jamaraat.
            </p>
            <div className="space-y-1.5 py-1.5">
              <h4 className="font-medium text-[15px]">
                Significant Practices at Muzdalifah:
              </h4>
              <ul className=" font-medium text-[15px]">
                <div className="flex gap-2">
                  <img src={icon} alt="h-4 mt-1.5" />

                  <li className=" leading-relaxed py-2 px-2">
                    Gathering Pebbles
                  </li>
                </div>
                <div className="flex gap-2">
                  <img src={icon} alt="h-4 mt-1.5" />

                  <li className=" leading-relaxed py-2 px-2">
                    Maghrib and Isha Prayers
                  </li>
                </div>
                <div className="flex gap-2">
                  <img src={icon} alt="h-4 mt-1.5" />

                  <li className=" leading-relaxed py-2 px-2">Overnight Stay</li>
                </div>
                <div className="flex gap-2">
                  <img src={icon} alt="h-4 mt-1.5" />

                  <li className=" leading-relaxed py-2 px-2">
                    Spiritual Reflection
                  </li>
                </div>
                <div className="flex gap-2">
                  <img src={icon} alt="h-4 mt-1.5" />

                  <li className=" leading-relaxed py-2 px-2">
                    Preparation for Rami al-Jamarat
                  </li>
                </div>
              </ul>
            </div>
            <p className="font-medium text-[15px]">
              The stay in Muzdalifah is a profound moment for spiritual
              reflection and a crucial part of the pilgrimage, marking the
              transition towards the concluding rites of Hajj.
            </p>
          </div>
          <img
            src={MuzdalifahImages.image4}
            alt="Muzdalifah"
            className="w-full lg:w-[45%] rounded-lg mt-10 lg:mt-0"
          />
        </div>

        <div className="slider-container relative py-20">
          <Swiper
            ref={swiperRef}
            spaceBetween={20}
            loop={true}
            navigation={false} // Disable built-in navigation
            modules={[Navigation]}
            slidesPerView={1} // Default for mobile
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
          >
            <SwiperSlide>
              <img
                src={MuzdalifahImages.image1}
                alt="1"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={MuzdalifahImages.image2}
                alt="2"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={MuzdalifahImages.image4}
                alt="3"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={MuzdalifahImages.image5}
                alt="4"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={MuzdalifahImages.image2}
                alt="5"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
          </Swiper>

          {/* Custom Navigation Buttons */}
          <button
            onClick={handlePrev}
            className="absolute top-1/2 -left-3 transform -translate-y-1/2 z-10"
          >
            <IoIosArrowBack className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
          </button>
          <button
            onClick={handleNext}
            className="absolute top-1/2 -right-3 transform -translate-y-1/2 z-10"
          >
            <IoIosArrowForward className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
          </button>
        </div>
        {/* <FeaturedBlogs /> */}
      </div>
      <Footer />
    </div>
  );
};

export default Muzdalifah;
