import React from "react";
import { Bus } from "lucide-react";
import { TiTick } from "react-icons/ti";

const TransportDetails = ({ data }) => {
  const { transport_name, transport_type, routes } =
    data && data.transport_detail[0];

  const routeParts = routes.split(",").map((route) => route.trim());

  return (
    <div className="w-full mb-4 lg:mb-0">
      <div className="flex items-center gap-2 mb-4">
        <h2 className="text-xl font-semibold">
          {transport_type} {transport_name}
        </h2>
        <Bus className="w-5 h-5" />
      </div>
      <div className="bg-white rounded-xl p-4 border border-lightGray">
        <div className="relative">
          {routeParts.map((route, index) => (
            <div key={index} className="flex justify-between w-full items-start gap-3 relative pb-4">
              <span className="text-sm text-[#626971] font-medium">
                {index + 1}. {route}
              </span>
              <div className="text-right">
                <span className="text-xl font-medium text-brandGold">
                  <TiTick />
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TransportDetails;
