import React, { useState } from "react";
import GuestPage from "./Guest/GuestPage";
import HostPage from "./Host/HostPage";

const TabsWithSearch = () => {
  const [activeTab, setActiveTab] = useState("hajj");

  // Dynamically set the title with counts
  const tabs = [
    {
      id: "hajj",
      title: `Guest`,
      content: <GuestPage />,
    },
    {
      id: "umrah",
      title: `Partner`,
      content: <HostPage />,
    },
    {
      id: "Partner Experience",
      title: "Partner Experience",
      content: (
        <div className="">
          <section class="">
            <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
              <div class="mx-auto max-w-screen-sm text-center">
                <p class="mb-4 text-3xl tracking-tight font-bold text-brandGold md:text-4x">
                  This Page is Under Development.
                </p>
                <p class="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
                  Sorry, we develop that page. You'll find lots to explore on
                  the home page.{" "}
                </p>
                <a
                  href="/"
                  class="inline-flex text-white bg-brandGold focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4"
                >
                  Back to Homepage
                </a>
              </div>
            </div>
          </section>
        </div>
      ),
    },
  ];

  const getTabContent = () => {
    const active = tabs.find((tab) => tab.id === activeTab);
    return active ? active.content : "No content available.";
  };
  return (
    <div className=" w-all-sections-width mx-auto">
      <div className="md:flex pt-10  mx-auto justify-between">
        <div className="flex gap-4 mb-4">
          {tabs.map((tab) => (
            <div className="">
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`flex items-center py-2 px-3 md:py-1.5 2xl:text-[15px] lg:text-[15px] md:text-[15px] text-[8px] font-normal justify-between
                                    ${
                                      activeTab === tab.id
                                        ? " text-[#484848] border-b-2 border-[#222222] font-bold"
                                        : " text-gray-700"
                                    }`}
              >
                {tab.title}
              </button>
            </div>
          ))}
        </div>
      </div>
      <div className="w-full h-[1px] bg-[#DEDEDE] -mt-4"></div>
      <div className="md:flex border-2 border-[#dcdcdc] shadow-sm rounded-lg p-5 justify-between my-10">
        <div className="w-full">
          <h2 className="font-noraml text-[#484848] md:text-[26px] text-[16px] leading-[30px]">
            We’re here for you
          </h2>
          <p className="font-noraml text-[#484848] text-[16px] leading-[30px]">
            Log in to get help with your reservations, account, and more.
          </p>
        </div>
        <div className="bg-brandGold md:w-96 text-lg font-semibold text-white rounded-lg text-center justify-center m-auto h-full md:py-3 py-1 mt-3 md:mt-0">
          <button>Log in or sign up</button>
        </div>
      </div>
      <div className="mx-auto rounded-lg">{getTabContent()}</div>
    </div>
  );
};

export default TabsWithSearch;
