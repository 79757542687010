import React from "react";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GharEhiraImg from "../../../assets/ZiyaratPlaces/Makkah/Ghar-e-Hira/image3.svg";
import WadiJinImg from "../../../assets/ZiyaratPlaces/Madinah/wadijin/Wadijin5.svg";
import ProphetHouseImg from "../../../assets/ZiyaratPlaces/Makkah/ProphetHouse/muhammad3.svg";
// import "./DotsStyle.css"; // Import the custom CSS for green dots

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
};

const cards = [
  {
    imageUrl: GharEhiraImg,
    title: "History of Ghar-e-Hira",
    description:
      "History of Ghar-e-Hira is intertwined with the spiritual journey of Prophet Muhammad(S.A.W)...",
    large: true,
    link: "/articles/makkah/gar-e-hira",
  },
  {
    imageUrl: WadiJinImg,
    title: "Wadi e Jinn in Islam",
    description:
      "Calling Jinn their brothers, the Messenger of Allah instructed everyone to not perform ‘Istinja’...",
    large: false,
    link: "/articles/madinah/wadi-jin",
  },
  {
    imageUrl: ProphetHouseImg,
    title: "Prophet (S.A.W) House",
    description:
      "The history of the Prophet Muhammad's (peace be upon him) home...",
    large: false,
    link: "/articles/makkah/prophet-house",
  },
];

const Card = ({ imageUrl, title, description, large, link }) => {
  const navigate = useNavigate();
  const handleClick = () => navigate(link);

  return (
    <div
      className={`lg:p-0 relative text-[#484848] ${
        large ? "w-full lg:w-1/2" : "w-full lg:w-1/4"
      }`}
      onClick={handleClick}
    >
      <img
        className="w-full object-cover rounded-lg"
        style={{ height: large ? "400px" : "300px", width: large && "950px" }}
        src={imageUrl}
        alt={title}
      />
      {large ? (
        <div className="absolute inset-0 lg:p-6 md:pb-10 md:p-3.5 lg:pb-16 xl:pb-12 flex flex-col justify-end">
          <h3 className="lg:text-xl text-base font-semibold text-white">
            {title}
          </h3>
          <p className="lg:text-base text-sm mt-1 text-white">{description}</p>
        </div>
      ) : (
        <div className="lg:p-4">
          <h3 className="text-[20px] font-bold text-[#484848] font-cairo">
            {title}
          </h3>
          <p className="text-sm text-gray-600">{description}</p>
        </div>
      )}
    </div>
  );
};

const Inspiration = () => (
  <div className=" w-all-sections-width mx-auto mt-12 mb-20 cursor-pointer">
    <div className="flex justify-between items-center mb-4">
      <h2 className="h2 mb-1 text-secondaryBlue">
        Get inspired
      </h2>
      <a href="/articles/makkah" className="text-secondaryBlue underline">
        Explore More
      </a>
    </div>
    <div className="hidden lg:block">
      <div className="flex flex-wrap lg:flex-nowrap gap-x-[20px] w-full">
        {cards.map((card, index) => (
          <Card key={index} {...card} />
        ))}
      </div>
    </div>
    <div className="lg:hidden">
      <Slider {...settings}>
        {cards.map((card, index) => (
          <Card key={index} {...card} large={false} />
        ))}
      </Slider>
    </div>
  </div>
);

export default Inspiration;
