import React from "react";
import { Footer, Header } from "../../../../";
const GetStarted = () => {
  return (
    <div className="bg-gray-100">
      <Header />
      <div className=" w-all-sections-width mx-auto md:p-10 p-3.5 mt-5 md:mt-0">
        <div className="">
          <div className="mx-auto">
            <h1 className="md:text-3xl font-bold text-center text-[#484848] opacity-80 mb-6">
              Get Started with Our Hajj and Umrah Platform
            </h1>
            <img
              src={"/images/HelpPage/makkah2.jpg"}
              alt=""
              className="md:w-1/2 w-full flex"
            />
            <section className="mb-8 mt-5">
              <h2 className="text-2xl font-semibold text-gray-700 mb-4">
                Step 1: Sign Up and Create Your Vendor Account
              </h2>
              <ol className="list-decimal list-inside text-gray-600">
                <li className="mb-2">
                  <strong>Visit the Registration Page:</strong> Go to the
                  registration page and click on the "Sign Up" button for
                  vendors.
                </li>
                <li className="mb-2">
                  <strong>Fill in Your Details:</strong> Enter your business
                  name, contact information, and other relevant details. Create
                  a secure password for your account.
                </li>
                <li>
                  <strong>Verify Your Email:</strong> Check your email for a
                  verification link. Click on the link to verify your account
                  and complete the registration process.
                </li>
              </ol>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold text-gray-700 mb-4">
                Step 2: Complete Your Vendor Profile
              </h2>
              <ol className="list-decimal list-inside text-gray-600">
                <li className="mb-2">
                  <strong>Login to Your Account:</strong> Use your registered
                  email and password to log in.
                </li>
                <li className="mb-2">
                  <strong>Profile Information:</strong> Go to the "Profile"
                  section. Upload your business logo, provide a detailed
                  description of your services, and include any relevant
                  certifications.
                </li>
                <li>
                  <strong>Payment Information:</strong> Enter your bank details
                  for secure transactions. Choose your preferred payment method
                  for receiving funds.
                </li>
              </ol>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold text-gray-700 mb-4">
                Step 3: Create and Manage Packages
              </h2>
              <ol className="list-decimal list-inside text-gray-600">
                <li className="mb-2">
                  <strong>Access the Dashboard:</strong> Once logged in,
                  navigate to your vendor dashboard.
                </li>
                <li className="mb-2">
                  <strong>Add New Packages:</strong> Click on "Create New
                  Package." Fill in details such as package name, duration,
                  itinerary, pricing, inclusions, and exclusions. Upload
                  high-quality images and videos to attract customers.
                </li>
                <li>
                  <strong>Manage Existing Packages:</strong> Use the dashboard
                  to edit, update, or remove your packages. Monitor bookings and
                  customer inquiries from the dashboard.
                </li>
              </ol>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold text-gray-700 mb-4">
                Step 4: Set Up Promotions and Discounts
              </h2>
              <ol className="list-decimal list-inside text-gray-600">
                <li className="mb-2">
                  <strong>Create Promotions:</strong> Go to the "Promotions"
                  section in your dashboard. Set up discount codes and
                  promotional offers to attract more customers.
                </li>
                <li>
                  <strong>Special Deals:</strong> Highlight special deals during
                  peak seasons like Ramadan and Hajj season.
                </li>
              </ol>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold text-gray-700 mb-4">
                Step 5: Communicate with Customers
              </h2>
              <ol className="list-decimal list-inside text-gray-600">
                <li className="mb-2">
                  <strong>Respond to Inquiries:</strong> Check the "Messages"
                  section regularly for customer inquiries. Provide prompt and
                  detailed responses to build trust and rapport.
                </li>
                <li>
                  <strong>Customer Reviews:</strong> Encourage satisfied
                  customers to leave reviews. Monitor and respond to reviews to
                  maintain a positive reputation.
                </li>
              </ol>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold text-gray-700 mb-4">
                Step 6: Monitor Your Performance
              </h2>
              <ol className="list-decimal list-inside text-gray-600">
                <li className="mb-2">
                  <strong>Analytics:</strong> Use the analytics tool in your
                  dashboard to track your performance. Monitor metrics such as
                  the number of bookings, revenue, and customer feedback.
                </li>
                <li>
                  <strong>Reports:</strong> Generate detailed reports to
                  understand your business growth and identify areas for
                  improvement.
                </li>
              </ol>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-700 mb-4">
                Step 7: Get Support
              </h2>
              <ol className="list-decimal list-inside text-gray-600">
                <li className="mb-2">
                  <strong>Help Center:</strong> Access our Help Center for FAQs,
                  guides, and tutorials.
                </li>
                <li>
                  <strong>Contact Support:</strong> If you need further
                  assistance, contact our support team via email or live chat.
                </li>
              </ol>
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default GetStarted;
