import React, { useEffect } from "react";
import Banner from "../../HeroSection/Banner";
import HeaderFooter from "../../../../ZiyaratArticlesPages/HeaderFooter";
import Slider from "../../MadinahPlaces/Wadijin/Slider";
import Page from "./Page";
import TaifZooImg1 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/taifzoo/image5.jpeg";
import TaifZooImg2 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/taifzoo/image4.jpg";
import TaifZooImg3 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/taifzoo/image3.jpg";
import TaifZooImg4 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/taifzoo/image2.avif";
const Taifzoo = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const images = [TaifZooImg1, TaifZooImg2, TaifZooImg3, TaifZooImg4];

  return (
    <div>
      <HeaderFooter>
        <Banner image={TaifZooImg4} title={"Taif Zoo"} />
        <Page />
        <Slider images={images} />
      </HeaderFooter>
    </div>
  );
};

export default Taifzoo;
