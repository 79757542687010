import axios from "axios";
// Ensure the token is correctly formatted without spaces
const authHeader = `${process.env.REACT_APP_AUTH_TOKEN}`;
const baseURL = process.env.REACT_APP_API_BASE_URL;

const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: authHeader,
  },
};
//Login
export const checkUserExistence = async (phone_number) => {
  try {
    const response = await axios.post(
      `${baseURL}/common/is_user_exist/`,
      { phone_number },
      config
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return error.response;
    }
    throw error;
  }
};

export const sendOtp = async (phoneNumber) => {
  const data = JSON.stringify({ phone_number: phoneNumber });
  try {
    const response = await axios.post(
      `${baseURL}/common/send_otp_sms/`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Verify OTP
export const verifyOtp = async (phone_number, otp_password) => {
  try {
    const payload = {
      phone_number,
      otp_password,
    };
    const response = await axios.put(
      `${baseURL}/common/verify_otp/`,
      payload,
      config
    );
    return response.data;
  } catch (error) {
    // Re-throw the error to handle it in the component
    throw error;
  }
};

export const Signup = async ({ phone_number, name, email, user_type }) => {
  try {
    const response = await axios.post(
      `${baseURL}/common/manage_user_account/`,
      {
        phone_number,
        name,
        email,
        user_type,
      },
      config
    );
    return response;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return { message: "Something went wrong" };
    }
    throw error;
  }
};


// Save User Address
export const saveUserAddress = async (
  session_token,
  street_address,
  address_line2,
  city,
  state,
  country,
  postal_code
) => {
  try {
    const response = await axios.post(
      `${baseURL}/common/manage_user_address_detail/`,
      {
        session_token,
        street_address,
        address_line2,
        city,
        state,
        country,
        postal_code,
        lat: "1223",  // Hardcoded value
        long: "-23212", // Hardcoded value
      },
      config
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      // Server responded with a status other than 2xx
      throw error.response.data;
    } else if (error.request) {
      // Request was made but no response received
      throw new Error("No response from the server. Please try again later.");
    } else {
      // Something else caused the error
      throw new Error("An unexpected error occurred.");
    }
  }
};

