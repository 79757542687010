import React, { useContext, useState } from "react";
import { Calendar, Loader } from "lucide-react";
import { NumericFormat } from "react-number-format";
import AuthContext from "../../../context/AuthContext";
import { useCurrency } from "../../../context/CurrencyContext";

const PayNowComponent = ({
  data,
  handleSubmit,
  setSelectedPaymentType,
  clickRadio,
}) => {
  const { login } = useContext(AuthContext);
  const isUserLoggedIn = login?.session_token;
  const { currency, convert } = useCurrency();

  const totalAmount = data.total_price || 0;
  const totalApprovedPaid =
    data.payment_detail
      ?.filter((payment) => payment.payment_status !== null)
      .reduce((acc, payment) => acc + (payment.transaction_amount || 0), 0) ||
    0;
  const remainingAmount = totalAmount - totalApprovedPaid;
  const totalTravelers = data.adults + data.child + data.infants;
  const minimumPayment = totalAmount * 0.1;

  const convertedTotalAmount = convert(totalAmount, "PKR");
  const convertedTotalApprovedPaid = convert(totalApprovedPaid, "PKR");
  const convertedRemainingAmount = convert(remainingAmount, "PKR");
  const convertedMinimumPayment = convert(minimumPayment, "PKR");

  const [paymentType, setPaymentType] = useState(
    `${clickRadio === "bank" || "voucher" ? "Full" : "Minimum"}`
  );
  const [isLoading, setIsLoading] = useState(false);

  const bedBreakdown = [];
  if (data.double > 0) {
    bedBreakdown.push({
      type: "Double Room",
      count: data.double,
      price: data.cost_for_double,
    });
  }
  if (data.triple > 0) {
    bedBreakdown.push({
      type: "Triple Room",
      count: data.triple,
      price: data.cost_for_triple,
    });
  }
  if (data.quad > 0) {
    bedBreakdown.push({
      type: "Quad Room",
      count: data.quad,
      price: data.cost_for_quad,
    });
  }
  if (data.sharing > 0) {
    bedBreakdown.push({
      type: "Sharing Room",
      count: data.sharing,
      price: data.cost_for_sharing,
    });
  }

  return (
    <div className="w-full bg-white rounded-lg border border-lightGray p-6 space-y-6">
      <div className="border-b space-y-1 pb-4">
        <h2 className="text-lg font-semibold text-brandGold">
          <NumericFormat
            value={convertedTotalAmount}
            displayType="text"
            thousandSeparator
            prefix={`${currency} `}
          />
        </h2>
        <p className="text-sm font-medium">{totalTravelers} TRAVELLERS</p>
      </div>

      <div className="space-y-2">
        <label className="text-sm font-medium text-blueForDate">
          Departure date
        </label>
        <div className="flex items-center gap-2 p-3 border border-lightGray rounded-md bg-white/70">
          <Calendar className="w-4 h-4 text-[#6B7280]" />
          <input
            type="text"
            value={new Date(data.start_date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
            className="bg-transparent text-sm outline-none text-[#9CA3AF]"
            readOnly
          />
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-sm font-medium text-blueForDate">
          Departs from
        </label>
        <div className="flex items-center gap-2 p-3 border border-lightGray rounded-md bg-white/70">
          <input
            type="text"
            value={`${data?.airline_detail?.[0]?.flight_from || ""}`}
            className="bg-transparent text-sm outline-none text-[#9CA3AF]"
            readOnly
          />
        </div>
      </div>

      <div className="space-y-4 border-t py-3">
        {bedBreakdown.map((bed, index) => (
          <div
            key={index}
            className="border-b text-sm text-primaryGray font-normal pb-4"
          >
            <h3>{bed.type}</h3>
            <p>{bed.count} Bed(s)</p>
            <div className="flex justify-between">
              <span>
                <NumericFormat
                  value={convert(bed.price, "PKR")}
                  displayType={"text"}
                  thousandSeparator
                  prefix={`${currency} `}
                  decimalScale={0}
                  fixedDecimalScale={true}
                />{" "}
                x {bed.count} Persons
              </span>
              <span>
                <NumericFormat
                  value={convert(bed.price * bed.count, "PKR")}
                  displayType="text"
                  thousandSeparator
                  prefix={`${currency} `}
                  decimalScale={0}
                  fixedDecimalScale={true}
                />
              </span>
            </div>
          </div>
        ))}
      </div>

      {totalApprovedPaid === 0 && (
        <div>
          <h3 className="text-lg font-bold text-gray-700 mb-4">
            Select Payment Option
          </h3>
          <div className="flex flex-col space-y-3">
            <label className="flex items-center gap-3 lg:gap-1 xl:gap-3 text-sm xl:text-[16px] cursor-pointer">
              <input
                type="radio"
                name="paymentOption"
                value="Full"
                checked={paymentType === "Full"}
                onChange={() => setPaymentType("Full")}
              />
              {clickRadio === "bank" || "voucher" ? "Total Amount" : "Pay Full"}
              <span className="font-bold text-black">
                {`${currency} ${convertedTotalAmount.toLocaleString()}`}
              </span>
            </label>
            {clickRadio === "bank" || "voucher" ? (
              ""
            ) : (
              <label className="flex items-center gap-3 lg:gap-1 xl:gap-3 text-sm xl:text-[16px] cursor-pointer text-green-600">
                <input
                  type="radio"
                  name="paymentOption"
                  value="Minimum"
                  checked={paymentType === "Minimum"}
                  onChange={() => setPaymentType("Minimum")}
                />
                Pay Minimum{" "}
                <span className="font-bold">
                  {`${currency} ${convertedMinimumPayment.toLocaleString()}`}
                </span>
              </label>
            )}
          </div>
        </div>
      )}
      {data.payment_detail && data.payment_detail.length > 0 && (
        <div className="space-y-4">
          <div className="flex justify-between text-sm font-bold">
            <span>TOTAL PAYABLE</span>
            <NumericFormat
              value={convertedTotalAmount}
              displayType="text"
              thousandSeparator
              prefix={`${currency} `}
            />
          </div>
          <div className="flex justify-between font-bold text-sm text-brandGold">
            <span>Already Paid (Only Approved Payments)</span>
            <NumericFormat
              value={convertedTotalApprovedPaid}
              displayType="text"
              thousandSeparator
              prefix={`${currency} `}
            />
          </div>
          <div className="flex justify-between text-sm text-green-600 font-bold">
            <span>Payment due now</span>
            <NumericFormat
              value={convertedRemainingAmount}
              displayType="text"
              thousandSeparator
              prefix={`${currency} `}
            />
          </div>
        </div>
      )}
      <button
        onClick={async () => {
          setSelectedPaymentType(paymentType);
          setIsLoading(true);
          try {
            await handleSubmit(
              totalApprovedPaid === 0
                ? paymentType === "Full"
                  ? convertedTotalAmount
                  : convertedMinimumPayment
                : convertedRemainingAmount
            );
          } finally {
            setIsLoading(false);
          }
        }}
        className="w-full bg-brandGold text-white py-3 rounded-md text-center font-semibold flex items-center justify-center gap-2"
        disabled={isLoading}
      >
        {isLoading ? (
          <>
            <Loader className="animate-spin w-5 h-5" />
            <span>Processing...</span>
          </>
        ) : (
          <span>{clickRadio === "bank" || "voucher" ? "Submit" : "Pay Now"}</span>
        )}
      </button>
    </div>
  );
};

export default PayNowComponent;
