import "swiper/css";
import "swiper/css/navigation";
import { masjidQiblatayn } from "../../../../../Utilities/placesdata";

const Page = () => {
  const spiritualSignificanceContent = [
    {
      subTitle: "Symbol of Unity and Faith",
      content: `Masjid al-Qiblatain embodies a transformative moment in Islamic history. The change of the Qibla signifies obedience to divine instruction and symbolizes the unity and direction of the Muslim Ummah.`,
    },
    {
      subTitle: "Historical Remembrance",
      content: `Pilgrims and visitors often reflect on the historical events associated with the mosque. The preservation of the old mihrab serves as a tangible connection to the early days of Islam, allowing worshippers to contemplate the steadfastness and devotion of the Prophet and his companions.`,
    },
    {
      subTitle: "Educational Importance",
      content: `The mosque is a focal point for learning and understanding the evolution of Islamic practices. It represents the dynamic and responsive nature of Islamic teachings to divine guidance.`,
    },
  ];
  return (
    <div>
      <div className="bg-[#F2F2F2]">
        <p className="text-center text-[#484848] w-all-sections-width mx-auto font-bold py-4 font-cairo md:text-[25px] text-[15px]">
          The Masjid al-Qiblatayn (مَسْجِد ٱلْقِبْلَتَیْن‎), 'Mosque of the Two
          Qiblas', is a mosque in Medina that is historically important for
          Muslims as the place where, after prophet Muhammad received the
          command to change the Qiblah (Direction of Prayer).
        </p>
      </div>
      <div className=" w-all-sections-width mx-auto pb-10 pt-10">
        <div className="lg:flex gap-10">
          <div className="block lg:w-1/2">
            <h1 className="font-cairo block md:text-[25px] text-[16px] font-bold text-[#484848]">
              Introduction
            </h1>
            <p className="font-cairo md:text-[16px] text-[10px] font-normal md:leading-[32px] leading-[20px]">
              Masjid al-Qiblatain, located in Medina, Saudi Arabia, holds a
              unique place in Islamic history. Its name translates to "the
              Mosque of the Two Qiblas," symbolizing its distinctive feature of
              having two prayer niches, each indicating a different direction of
              prayer. This mosque is not only a place of worship but also a
              significant site that marks a pivotal moment in Islamic history.
              This blog will delve into the historical background, architectural
              features, spiritual significance, and visitor experience of Masjid
              al-Qiblatain.
            </p>
            <div className="lg:hidden xl:block">
              <h1 className="font-cairo block md:text-[25px] text-[16px] font-bold text-[#484848]">
                Early Islamic Context
              </h1>
              <p className="font-cairo md:text-[16px] text-[10px] font-normal md:leading-[32px] leading-[20px]">
                In the nascent period of Islam, Prophet Muhammad (PBUH) and his
                followers initially prayed facing Jerusalem, a practice rooted
                in the Abrahamic tradition. This direction was known as the
                first Qibla. However, in the second year of Hijra (622 CE), a
                profound change was revealed to the Prophet during a
                congregational prayer.
              </p>
            </div>
          </div>
          <div className="">
            <img
              src={masjidQiblatayn.image2}
              alt=""
              className="w-full h-[430px] rounded-lg"
            />
          </div>
        </div>
        <div className="hidden lg:block xl:hidden">
          <h1 className="font-cairo block md:text-[25px] text-[16px] font-bold text-[#484848]">
            Early Islamic Context
          </h1>
          <p className="font-cairo md:text-[16px] text-[10px] font-normal md:leading-[32px] leading-[20px]">
            In the nascent period of Islam, Prophet Muhammad (PBUH) and his
            followers initially prayed facing Jerusalem, a practice rooted in
            the Abrahamic tradition. This direction was known as the first
            Qibla. However, in the second year of Hijra (622 CE), a profound
            change was revealed to the Prophet during a congregational prayer.
          </p>
        </div>
        <h1 className="font-cairo mt-5 block md:text-[25px] text-[16px] font-bold text-[#484848]">
          The Revelation
        </h1>
        <p className="font-cairo md:text-[16px] text-[10px] font-normal md:leading-[32px] leading-[20px]">
          While leading the Dhuhr (midday) prayer at the Banu Salamah tribe's
          mosque, the Prophet received a divine revelation through Angel Jibreel
          (Gabriel) instructing him to turn towards the Kaaba in Mecca. This
          momentous event is recorded in the Quran in Surah Al-Baqarah (2:144):
          <br />
          "Verily, We have seen the turning of your face towards the heaven.
          Surely, We shall turn you to a Qibla that shall please you. So turn
          your face in the direction of Al-Masjid-al-Haram (in Mecca)."
          <br />
          This sudden change, mid-prayer, established the Kaaba as the new Qibla
          for all Muslims worldwide. The mosque where this change took place
          became known as Masjid al-Qiblatain.
          <br />
          Significance of the Qibla Change The shift of the Qibla holds deep
          symbolic meaning, marking a spiritual and communal realignment for
          Muslims. It underscored the distinct identity of the Islamic
          community, centered on the Kaaba, which is linked to the monotheistic
          legacy of Prophet Ibrahim (Abraham). This event strengthened the unity
          and coherence of the Muslim Ummah (community).
        </p>
        <div className="mt-7">
          <img
            src={masjidQiblatayn.image6}
            alt="Jabal al-Nour and the Cave of Hira"
            className="w-full rounded-lg"
          />
        </div>
        <div className="py-5">
          <h1 className="font-cairo text-[18px] md:text-[25px] font-bold text-gray-800">
            Historical Background
          </h1>
          <div>
            <p className="font-cairo font-normal text-[14px] md:text-[15px] py-1 text-gray-600">
              The historical significance of Masjid al-Qiblatain dates back to
              the early days of Islam. Initially, Muslims prayed facing
              Jerusalem (Al-Quds), but in the second year after the Hijra
              (migration of Prophet Muhammad from Mecca to Medina), a divine
              revelation (Surah Al-Baqarah, 2:144) instructed the Prophet to
              change the direction of the Qibla to the Kaaba in Mecca. This
              event took place during a prayer led by the Prophet at Masjid
              al-Qiblatain, making it the only mosque where prayer was conducted
              towards two different Qiblas.
            </p>
            <div className="md:flex gap-5">
              <div className="block md:w-10/12">
                <h2 className="text-3xl font-semibold font-cairo text-[#484848]">
                  Spiritual Significance
                </h2>
                {spiritualSignificanceContent.map((section, index) => (
                  <div className="bg-white rounded-lg py-2.5" key={index}>
                    <h3 className="text-xl font-semibold mb-4">
                      {section.subTitle}
                    </h3>
                    <p className="text-gray-600">{section.content}</p>
                  </div>
                ))}
              </div>
              <div className="">
                <img
                  src={masjidQiblatayn.image2}
                  alt="Jabal al-Nour and the Cave of Hira"
                  className="w-full rounded-lg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page;
