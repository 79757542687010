import React from "react";
import MasjidJummahImg5 from "../../../../../assets/ZiyaratPlaces/Madinah/Ghamamah/ghamamah1.jpg";
import MasjidJummahImg6 from "../../../../../assets/ZiyaratPlaces/Madinah/MasjidJummah/masjidjummah2.svg";
import MasjidJummahImg7 from "../../../../../assets/ZiyaratPlaces/Madinah/MasjidJummah/masjidjummah3.svg"
const Page = () => {
  return (
    <div>
      <div className="bg-[#F2F2F2]">
        <p className="text-center text-[#484848] font-bold py-4 font-cairo md:text-[25px] text-[15px]">
          The distance from Masjid al-Jummah in Medina to the city Center of
          Medina, <br /> where Masjid al-Nabawi is located, is approximately 3.5
          kilometres.
        </p>
      </div>
      <div className=" w-all-sections-width mx-auto">
        {/* upper section named location */}
        <div className="pt-14">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Historical Significance of Masjid-al-Jummah
          </h2>
          <div className="flex flex-col lg:flex-row mt-4 gap-10">
            <div className="w-full">
              <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px] leading-7">
                Masjid al-Jummah, also known as the Mosque of Friday, holds
                profound historical and religious significance within Islam.
                Located in Medina, Saudi Arabia, this mosque is associated with
                pivotal events during the time of Prophet Muhammad (peace be
                upon him) and serves as a symbol of communal worship and
                spiritual renewal. Establishment and Name: Masjid al-Jummah was
                established in the early years of Islam by Prophet Muhammad and
                his companions in Medina. It gained its name due to its role as
                the venue for the weekly congregational prayers held every
                Friday, known as Jummah prayers. These prayers hold particular
                importance in Islam, as they signify the gathering of Muslims
                for collective worship and spiritual reflection.
              </p>
              <p className="lg:hidden xl:block text-[#484848] mb-4 font-cairo font-medium text-[15px] leading-7">
                Historical Events: One notable event associated with Masjid
                al-Jummah occurred during the time of Prophet Muhammad. It is
                recorded that the Prophet delivered a sermon at the mosque,
                emphasizing themes of unity, faith, and community among Muslims.
                This sermon served as a foundational message for the Muslim
                community, highlighting the significance of the Friday
                congregational prayers as a means of spiritual and communal
                renewal. Cultural and Religious Importance: Throughout history,
                Masjid al-Jummah has remained a center for congregational
                prayers, religious gatherings, and community events in Medina.
                It continues to be a place where Muslims gather weekly to
                participate in Jummah prayers, listen to sermons (khutbahs), and
                strengthen their bonds as a community.
              </p>
            </div>
            <div className="lg:w-1/2 mt-4 lg:mt-0">
              <img
                src={MasjidJummahImg5}
                alt="Wadi-e-Jinn"
                className="rounded-lg shadow-lg"
              />
            </div>
          </div>
          <p className="text-[#484848] hidden lg:flex xl:hidden mb-4 font-cairo font-medium text-[15px] leading-7">
                Historical Events: One notable event associated with Masjid
                al-Jummah occurred during the time of Prophet Muhammad. It is
                recorded that the Prophet delivered a sermon at the mosque,
                emphasizing themes of unity, faith, and community among Muslims.
                This sermon served as a foundational message for the Muslim
                community, highlighting the significance of the Friday
                congregational prayers as a means of spiritual and communal
                renewal. Cultural and Religious Importance: Throughout history,
                Masjid al-Jummah has remained a center for congregational
                prayers, religious gatherings, and community events in Medina.
                It continues to be a place where Muslims gather weekly to
                participate in Jummah prayers, listen to sermons (khutbahs), and
                strengthen their bonds as a community.
              </p>
        </div>
        {/* location section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Spiritual Significance
          </h2>
          <div className="mt-4">
            <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px] ">
              Masjid al-Ghamama's primary spiritual significance lies in its
              association with Prophet Muhammad. It is believed that the Prophet
              performed the Eid prayers at this site, making it a place of deep
              reverence for Muslims. The miraculous incident of the cloud
              providing shade to the Prophet during prayer further enhances its
              spiritual importance, symbolizing divine protection and favor. For
              many Muslims, visiting Masjid al-Ghamama is an opportunity for
              prayer and reflection. The mosque's serene environment and
              historical connections make it an ideal place for spiritual
              contemplation. Pilgrims often visit the mosque to offer prayers
              and seek blessings, inspired by the presence of the Prophet and
              the miraculous events associated with the site. Masjid al-Ghamama
              showcases a blend of early Islamic and Ottoman architectural
              styles. The mosque's structure is relatively simple yet elegant,
              reflecting the humility and spiritual focus characteristic of
              early Islamic architecture. The mosque's layout includes a
              rectangular prayer hall, a central dome, and several smaller domes
              that add to its aesthetic appeal.
            </p>
          </div>
          <div className="mt-8 flex flex-col items-center">
            <img
              src={MasjidJummahImg6}
              alt="Islamic Calligraphy"
              className="w-full h-auto rounded-lg shadow-lg"
            />
            <p className="text-center text-[#484848] font-cairo text-[15px] font-semibold mt-4">
              Masjid Al-Jummah Mosque In saudi Arabia
            </p>
          </div>
        </div>
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            The Hijrah and the Establishment of Masjid-al-Jummah
          </h2>
          <div className="mt-4">
            <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px] ">
              The Hijrah, or migration of the Prophet Muhammad (PBUH) from Mecca
              to Medina in 622 CE, was a pivotal moment in Islamic history. Upon
              reaching the village of Quba on the outskirts of Medina, the
              Prophet stayed for a few days and built the Quba Mosque, which is
              considered the first mosque in Islam. After leaving Quba, he
              proceeded towards Medina and stopped in the Ranuna Valley. It was
              here, on a Friday, that the Prophet led his first congregational
              Friday prayer. This event is commemorated by the establishment of
              Masjid-al-Jummah. The prayer was significant as it was not only
              the first Jummah in Medina but also symbolized the formation of a
              new, united Muslim community.
            </p>
          </div>
          <div className="mt-8 flex items-center">
            <img
              src={MasjidJummahImg7}
              alt="Islamic Calligraphy"
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>
          <p className="text-center text-[#484848] font-cairo text-[15px] font-semibold mt-4">
            Masjid Al-Jummah Mosque In saudi Arabia
          </p>
        </div>
        {/* islam section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            How to Explore Masjid Al Jummah?
          </h2>
          <div className="mt-4">
            <ul className="list-none space-y-2 text-[#484848] mb-4 font-cairo font-medium text-[17px]">
              <li>
                - Location and Accessibility: Masjid Al Jummah is located in
                Quba, near Medina. It is easily accessible by car, taxi, or
                public transport.
              </li>
              <li>
                - Historical Significance: The mosque is known for being the
                site where the Prophet Muhammad led the first Friday prayer
                (Jummah) after his migration to Medina.
              </li>
              <li>
                - Best Visiting Times: Early morning or late evening visits are
                recommended for a serene experience. Fridays are particularly
                significant due to the historical importance of Jummah prayers.
              </li>
              <li>
                - Activities: Engage in prayer, especially the Jummah prayer if
                visiting on a Friday. Spend time in quiet reflection and read
                Islamic texts to deepen your understanding of the mosque’s
                significance.
              </li>
              <li>
                - Cultural Etiquette: Dress modestly and maintain respectful
                behavior. Ensure cleanliness and engage with locals or guides to
                learn more about the mosque’s history and significance.
              </li>
              <li>
                - Nearby Landmarks: Combine your visit with trips to nearby
                significant sites such as the Quba Mosque and Masjid
                Al-Qiblatain for a comprehensive understanding of Medina’s rich
                Islamic heritage.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page;
