import React from "react";
import WeatherCard from "./WeatherCard"; // Adjust the import path as necessary
import bg from "../../../assets/ZiyaratPlaces/Makkah/makkahbg2.jpg";
const Banner = () => {
  const currentDate = new Date().toLocaleDateString("en-GB", {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <div
      className="relative text-center h-[400px] bg-[#F0F6F5]"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="absolute bottom-10 left-36 text-white">
        <h1 className="text-4xl mb-2 font-kd font-bold">Makkah</h1>
      </div>
      <div className="absolute bottom-10 right-36">
        <WeatherCard
          location="Makkah"
          date={currentDate}
          temperature={35.1}
          high={44.0}
          low={27.9}
          description="Clouds"
          icon="M256,104c-83.813,0-152,68.187-152,152s68.187,152,152,152,152-68.187,152-152S339.813,104,256,104Zm0,272A120,120,0,1,1,376,256,120.136,120.136,0,0,1,256,376Z"
        />
      </div>
    </div>
  );
};

export default Banner;
