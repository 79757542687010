import React from "react";
import Select from "react-select";

const SortDropdownButton = ({ sortOrder, setSortOrder }) => {
  const sortOptions = [
    { value: "Top Reviewed", label: "Top Reviewed" },
    { value: "low-to-high", label: "Price: Low to High" },
    { value: "high-to-low", label: "Price: High to Low" },
    { value: "earliest-to-latest", label: "Date" },
  ];

  const customStyles = {
    control: (base) => ({
      ...base,
      border: "1px solid #E5E7EB",
      borderRadius: "6px",
      padding: "2px 8px",
      cursor: "pointer",
      minHeight: "40px",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#4F46E5",
      },
    }),
    option: (base, { isFocused, isSelected }) => ({
      ...base,
      fontSize: "14px",
      padding: "8px 12px",
      backgroundColor: isSelected
        ? "rgba(209, 165, 84, 0.1)" // Light background for selected option
        : isFocused
        ? "#F3F4F6"
        : "white",
      color: isSelected
        ? "rgba(209, 165, 84, 1)" // Gold color for selected option text
        : "#1F2937",
      "&:active": {
        backgroundColor: "rgba(209, 165, 84, 0.2)",
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#6B7280",
      padding: "0 4px",
      "&:hover": {
        color: "#4F46E5",
      },
    }),
    indicatorSeparator: () => ({}),
    menu: (base) => ({
      ...base,
      borderRadius: "6px",
      boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1)",
      marginTop: "4px",
    }),
    singleValue: (base) => ({
      ...base,
      color: "rgba(209, 165, 84, 1)", // Gold color for selected value in the control
      fontWeight: "500",
    }),
    valueContainer: (base) => ({
      ...base,
      padding: "0 4px",
    }),
  };

  return (
    <div className="flex items-center gap-2">
      <span className="text-sm font-medium text-gray-600">Sort by:</span>
      <div className="w-48">
        <Select
          options={sortOptions}
          value={sortOptions.find(option => option.value === sortOrder)}
          onChange={(selectedOption) => setSortOrder(selectedOption.value)}
          styles={customStyles}
          isSearchable={false}
          components={{
            DropdownIndicator: () => (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-1 text-gray-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            ),
          }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: '#F3F4F6',
              primary: '#4F46E5',
            },
          })}
        />
      </div>
    </div>
  );
};

export default SortDropdownButton;