import React, { useEffect } from "react";
import HeaderFooter from "../HeaderFooter";
import Banner from "./Banner";
import Card from "../Card";
import TaifHolySites from "./TaifHolySites";
import {
  TaifPlacesHolyPlaces,
  TaifPlacesAttractions,
} from "../../../Utilities/placesdata";
const Taif = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const sections = [
    { title: "Holy Sites", data: TaifPlacesHolyPlaces },
    { title: "Attractions", data: TaifPlacesAttractions },
  ];
  return (
    <HeaderFooter>
      <div>
        <Banner />
        <TaifHolySites />
        <Card sections={sections} />
      </div>
    </HeaderFooter>
  );
};

export default Taif;
