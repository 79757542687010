import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  BookingDetail,
  BookingInfo,
  Footer,
  Header,
  PackageHeader,
  BookingDetailsComponent,
  ObjectionComponent,
  Loader,
} from "../../../";
import banner from "../../../assets/Detail-Page/banner.png";
import { useLocation, useParams } from "react-router-dom";
import { Star } from "lucide-react";
import { fetchBookingDetails } from "../../../api/apiService";

const useBookingData = (booking_number, sessionToken) => {
  const [booking, setBooking] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      const bookingData = await fetchBookingDetails(
        sessionToken,
        booking_number
      );
      const parseNumber = (value) =>
        !isNaN(Number(value)) ? Number(value) : 0;

      const roomBreakDown = [
        {
          type: "Sharing Room",
          count: parseNumber(bookingData?.sharing),
          price: parseNumber(bookingData?.cost_for_sharing),
        },
        {
          type: "Quad Room",
          count: parseNumber(bookingData?.quad),
          price: parseNumber(bookingData?.cost_for_quad),
        },
        {
          type: "Triple Room",
          count: parseNumber(bookingData?.triple),
          price: parseNumber(bookingData?.cost_for_triple),
        },
        {
          type: "Double Bed Private Room",
          count: parseNumber(bookingData?.double),
          price: parseNumber(bookingData?.cost_for_double),
        },
      ].filter((room) => room.count > 0);

      setBooking({ ...bookingData, roomBreakDown });
      localStorage.setItem("BookingResponse", JSON.stringify(bookingData));
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [booking_number, sessionToken]);

  useEffect(() => {
    if (sessionToken) fetchData();
  }, [sessionToken, fetchData]);

  return { booking, loading, error, setBooking };
};

const BookingInfoPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { booking_number } = useParams();
  const userProfile = JSON.parse(localStorage.getItem("Login-User-Profile"));
  const sessionToken = userProfile?.session_token;

  const { booking, loading, error, setBooking } = useBookingData(
    booking_number,
    sessionToken
  );
  const { packageDetails } = location.state || {}; // Keep only this declaration


  const shouldShowBookingDetails = ["Active", "Completed", "Closed"].includes(booking?.booking_status);
  const canSubmitReview = ["Completed", "Closed"].includes(booking?.booking_status) && !booking?.booking_rating?.length;
  const canRaiseComplaint = ["Active", "Completed", "Closed"].includes(booking?.booking_status);

  // Function to update booking status dynamically
  const updateBookingStatus = (newStatus) => {
    setBooking((prev) => ({ ...prev, booking_status: newStatus }));
  };

  if (loading)
    return (
      <div className="bg-white flex items-center justify-center h-screen">
        <Loader />
      </div>
    );

  if (error) {
    return (
      <div>
        <Header />
        <div className="flex lg:flex-row flex-col lg:h-full mb-10 py-7">
          <div>Error: {error}</div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Header />
      <div className="w-all-sections-width mx-auto mb-10">
        <img src={banner} alt="banner-image" className="w-full my-10" />
        <div className="flex flex-col lg:flex-row gap-6">
          {/* Left Column */}
          <div className="w-full lg:w-[70%]">
            <PackageHeader data={booking} />


            <div className="bg-white p-6 border-[1px] mb-4 rounded-md">
              {booking.booking_status == "Paid" && (
                <div className="md:flex space-y-2 md:space-y-0 justify-between items-center">
                  <p className="text-[20px] font-700">Booking Confirmation</p>
                  {/* <p className="text-[16px] font-400">
                    Booking Number:{" "}
                    <span className="text-[#22AD5C]">
                      {booking.booking_number}
                    </span>
                  </p> */}
                </div>
              )}
              {!canRaiseComplaint && (
                <p className="text-[16px] font-400 mt-2">
                  Your Booking details have been sent to{" "}
                  <span className="text-brandGold">{booking.user_email}</span>
                </p>
              )}
              {canRaiseComplaint && (
                <p className="text-[16px] font-400 mt-2">
                  Booking Number: <span className="text-brandGold">{booking.booking_number}</span>
                </p>
              )}
              {/* Conditionally render the buttons */}
              <div className="mt-4 flex space-x-4">
                {/* Raise Complaint Button - Visible for Active, Completed & Closed */}
                {canRaiseComplaint && (
                  <button
                    onClick={() =>
                      navigate("/complaint", {
                        state: { booking, packageDetails },
                      })
                    }
                    className="px-6 py-3 bg-brandGold text-white rounded-md hover:bg-yellow-600 transition"
                  >
                    Raise a Complaint ticket
                  </button>
                )}


                {/* Submit Your Review Button - Visible for Completed & Closed */}
                {canSubmitReview && (
                  <button
                    onClick={() =>
                      navigate("/review", {
                        state: { booking, packageDetails },
                      })
                    }
                    className="px-6 py-3 bg-[#22AD5C] text-white rounded-md hover:bg-green-700 transition"
                  >
                    Submit Your Review
                  </button>
                )}

                {/* Submit Your Request Button - Visible for Active, Completed & Closed */}
                {canSubmitReview && (
                  <button
                    onClick={() =>
                      navigate("/request", {
                        state: { booking, packageDetails },
                      })
                    }
                    className="px-6 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition"
                  >
                    Submit Your Review
                  </button>
                )}
              </div>
            </div>


            {booking.booking_rating?.length > 0 && (
              <div className="p-6 border-[1px] mb-10 rounded-lg">
                <h2 className="text-2xl font-bold text-gray-800 mb-4">
                  Your Review
                </h2>
                <div className="flex items-center mb-2">
                  {[...Array(5)].map((_, index) => (
                    <Star
                      key={index}
                      className={
                        index < booking.booking_rating[0].partner_total_stars
                          ? "text-brandGold w-6 h-6 fill-current"
                          : "text-brandGold w-6 h-6"
                      }
                    />
                  ))}
                </div>
                <p className="text-gray-800 text-lg font-semibold">
                  {booking.booking_rating[0].partner_total_stars} Stars
                </p>
                <p className="text-gray-700 italic mt-2 border-l-4 border-yellow-600 pl-3">
                  "{booking.booking_rating[0].partner_comment}"
                </p>
              </div>
            )}

            {booking.booking_status === "Objection" ? (
              <ObjectionComponent
                booking={booking}
                updateBookingStatus={updateBookingStatus}
              />
            ) : shouldShowBookingDetails ? (
              <BookingDetailsComponent bookingResponse={booking} />
            ) : (
              <BookingInfo bookingResponse={booking} />
            )}
          </div>

          {/* Right Column */}
          <div className="w-full lg:w-[30%]">
            <div className="sticky top-6">
              <BookingDetail
                bookingResponse={booking}
                travellers={booking?.adults || 0}
                selectedRooms={packageDetails?.selectedRooms}
                prices={packageDetails?.prices}
                rooms={packageDetails?.rooms}
                totalSelectedRooms={packageDetails?.totalSelectedRooms}
                roomBreakDown={booking?.roomBreakDown || []}
                totalRoomCost={packageDetails?.totalRoomCost}
                total_amount={packageDetails?.total_amount}
                payable_amount={packageDetails?.payable_amount}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BookingInfoPage;
