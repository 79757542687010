// src/Utilities/UtilityFunctions.js
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import makkahHotels from './makkah_hotels.json';
import madinahHotels from './madina_hotels.json';



const getHotelImages = (hotelName, hotelData = []) => {
  if (!hotelData || hotelData.length === 0) return []; // Handle case where no hotel data is passed

  const hotelDataItem = hotelData.find(hotel => hotel.hotel_Name === hotelName);
  return hotelDataItem
    ? [hotelDataItem.image1, hotelDataItem.image2, hotelDataItem.image3, hotelDataItem.image4].filter(Boolean)
    : [];
};

export { getHotelImages, makkahHotels, madinahHotels };

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, [location]);

  return null; // This component does not render anything
};


const useClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) return;
      handler(event);
    };

    document.addEventListener('mousedown', listener);
    return () => document.removeEventListener('mousedown', listener);
  }, [ref, handler]);
};

export { ScrollToTop, useClickOutside };
