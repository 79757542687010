import React, { useEffect } from "react";
import { Footer, Header } from "../../../";

const MissionVision = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  return (
    <div>
      <Header />
      <section className="bg-white text-[#484848] py-16">
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-[20px] font-kd  font-bold text-[#484848] mb-8">
              VISION
            </h2>
            <ol className="list-decimal font-kd text-left space-y-4 text-[15px] leading-7 text-[#484848]">
              <li>
                To become the premier destination for Muslims seeking a
                hassle-free and affordable Umrah and Hajj experience, by
                fostering strong, long-lasting partnerships with travel agencies
                and delivering exceptional customer service.
              </li>
              <li>
                To transform the way Muslims perform Umrah and Hajj, by
                leveraging technology and innovation to offer a seamless,
                personalized experience that meets our customers' evolving
                needs.
              </li>
              <li>
                To expand our reach and establish ourselves as the most trusted
                and recognized brand in the Umrah and Hajj industry in Malaysia
                and beyond.
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section className="bg-green-100 text-[#484848] py-16">
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-[20px] font-kd  font-bold text-[#484848] mb-8">
              MISSION
            </h2>
            <ol className="list-decimal font-kd text-left space-y-4 text-[15px] leading-7 text-[#484848]">
              <li>
                To simplify and enhance the Umrah and Hajj experience for our
                customers by providing affordable, customized packages that
                cater to their unique needs and preferences.
              </li>
              <li>
                To empower Muslims to fulfill their spiritual obligations by
                offering flexible, convenient, and high-quality Umrah and Hajj
                packages throughout the year.
              </li>
              <li>
                To be the leading online platform for Umrah and Hajj services in
                Malaysia, offering a wide range of options that accommodate
                different budgets and preferences.
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section className="bg-white text-[#484848] py-16">
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-[20px] font-kd  font-bold text-[#484848] mb-8">
              OBJECTIVE
            </h2>
            <ol className="list-decimal font-kd text-left space-y-4 text-[15px] leading-7 text-[#484848]">
              <li>
                To lower the barriers to accessing Umrah and Hajj packages.
              </li>
              <li>
                To promote Mutawwifs and Muttawwifahs as recognized and
                respected professions.
              </li>
              <li>
                To streamline the processes involved in performing Umrah and
                Hajj in Makkah.
              </li>
              <li>
                To generate higher returns within the Umrah and Hajj industry.
              </li>
              <li>
                To optimize and automate the procedures for Umrah and Hajj.
              </li>
            </ol>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default MissionVision;
