import React from "react";
import banner from "../../../assets/homepage/MBS/Banner.png";
import logo from "../../../assets/homepage/MBS/logo.png";
import mbs from "../../../assets/homepage/MBS/Mbs.png";

const Vision = () => {
  return (
    <div style={{ backgroundImage: `url(${banner})` }} className="md:relative">
      <div className="w-all-sections-width mx-auto py-8 lg:py-14 flex justify-between items-center">
        <div className="space-y-2.5 lg:space-y-4 w-full md:w-2/3">
          <div className="flex justify-center md:justify-start">
            <img src={logo} alt="Vision 2030 Logo" />
          </div>
          <p className="text-[#4B465C] text-[14px] lg:text-[18px] xl:text-[26px] font-600 text-center md:text-start">
            The great honor that Allah has bestowed on our country is by serving
            the Two Holy Mosques, ensuring the comfort of pilgrims, Umrah
            performers and visitors.
          </p>
          <div className="  flex justify-center md:hidden">
            <img
              src={mbs}
              alt="MBS"
              className="w-auto h-auto rounded-[30px] max-w-[280px] max-h-[280px]"
            />
          </div>
          <div className="text-center md:text-start space-y-2.5">
            <p className=" text-sm lg:text-[18px] font-400 text-secondaryGray">
              His Royal Highness Crown Prince
            </p>
            <p className="text-sm lg:text-[18px] font-400">
              Prince Mohammed bin Salman bin Abdulaziz
            </p>
          </div>
          <div className="text-center md:text-start">
            <a
              href="https://www.vision2030.gov.sa/en/explore/programs/pilgrim-experience-program"
              className="p-1.5 lg:p-2.5 mt-2 lg:mt-4 bg-brandGold rounded-md text-white text-xs lg:text-[16px] font-500"
            >
              Learn about the Pilgrim Experience Program
            </a>
          </div>
        </div>
      </div>

      {/* MBS Image positioned at the top-right */}
      <div className="hidden md:block absolute top-0 lg:top-[-22px] right-0">
        <img
          src={mbs}
          alt="MBS"
          className="w-auto h-auto max-w-[280px] max-h-[280px] lg:max-w-[425px] lg:max-h-[425px] xl:max-w-[475px] xl:max-h-[475px]"
        />
      </div>
    </div>
  );
};

export default Vision;
