import React from "react";
import { TiTick } from "react-icons/ti";

const ZiyarahDetails = ({ data }) => {
  const detailPackageZiyarahs = data?.ziyarah_detail?.[0]?.ziyarah_list || "";
  const ziyarahPlaces = detailPackageZiyarahs
    .split(",")
    .map((ziyarah) => ziyarah.trim())
    .filter(Boolean);

  if (!ziyarahPlaces.length) return null;

  return (
    <div className="w-full mb-4">
      <div className="flex items-center gap-2 mb-4">
        <h2 className="text-2xl font-bold">Ziyarah Places</h2>
      </div>
      <div className="bg-white rounded-xl p-4 border border-lightGray">
        <ul className="pl-4 text-gray-700 text-sm">
          {ziyarahPlaces.map((place, idx) => (
            <div key={idx} className="flex justify-between items-center space-y-2">
              <li className="list-none">{place}</li>
              <div className="text-brandGold text-xl">
                <TiTick />
              </div>
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ZiyarahDetails;
