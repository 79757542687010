import React from "react";
import { Footer, Header } from "../../";
const FraudProtection = () => {
  return (
    <div>
      <Header />
      <div className="w-all-sections-width mx-auto text-secondaryBlue h-screen">
        <p className="h2">Transparency & Fraud Protection</p>
        <div className="h-screen flex items-center justify-center">
          <p className="text-xl font-bold text-center">UnderDevelopment</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FraudProtection;
