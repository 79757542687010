import React from "react";
import Card from "./Card";
import communityImg from "../../../../assets/HelpPage/communitypolicy.png";
import safetyImg from "../../../../assets/HelpPage/Airbnb-Safety-Web.png";
const ExploreMoreCards = () => {
  return (
    <div className="bg-[#484848]">
      {/* explore more */}
      <div className="py-14 space-y-2 w-all-sections-width mx-auto">
        <h3 className="text-3xl font-semibold mb-4 text-white">Explore more</h3>
        <div className="grid md:grid-cols-3 grid-cols-1 gap-4">
          <Card
            title={"Our community policies"}
            imageUrl={communityImg}
            link={"/community-policies"}
            description={"How we build a foundation of trust."}
            styles={"bg-[#222222] shadow-md rounded-b-md p-4 text-white"}
            imagestyles={"rounded-t-md"}
          />
          <Card
            title={"Safety tips and guidelines"}
            imageUrl={safetyImg}
            link={"/explore-more"}
            description={"Resources to help travelers stay safe."}
            styles={"bg-[#222222] shadow-md rounded-b-md p-4 text-white"}
            imagestyles={"rounded-t-md"}
          />
          <div className="text-white p-6 mx-auto">
            <h2 className="text-2xl font-bold mb-4">Need to get in touch?</h2>
            <p className="mb-6">
              We’ll start with some questions and get you to the right place.
            </p>
            <button className="bg-brandGold text-white py-2 px-4 rounded-full font-medium mb-4 hover:bg-gray-200 transition duration-300">
              Contact us
            </button>
            <p>
              You can also{" "}
              <a
                href="#"
                className="text-brandGold underline hover:text-brandGold transition duration-300"
              >
                give us feedback
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreMoreCards;
