import React from "react";
import bgImage from "../../assets/footer/bgImg.png";
import mobileImage from "../../assets/footer/mobileImg.svg";
import playStore from "../../assets/footer/playStore.png";
import appleStore from "../../assets/footer/appleStore.png";
import mobileImageForMob from "../../assets/footer/mobileImgforMob.svg";

const AppDownload = () => {
  return (
    <div>
      <div
        className="pt-s10 md:pt-m12 lg:pt-0"
        style={{
          backgroundImage: `linear-gradient(180deg, rgba(18, 60, 75, 0.7) 0%, rgba(24, 77, 97, 0.9) 50%, rgba(18, 60, 75, 0.7) 100%), 
            linear-gradient(360deg, rgba(18, 60, 75, 0.7) 0%, rgba(24, 77, 97, 0.9) 50%, rgba(18, 60, 75, 0.7) 100%), 
            url(${bgImage})`,
          backgroundBlendMode: "overlay, overlay, normal",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="w-all-sections-width mx-auto flex flex-col lg:flex-row lg:space-x-10 text-white p-4 lg:p-0 lg:py-l14 xl:pt-l14 xl:pb-0">

          {/* Mobile Image Section */}
          <div className="w-auto lg:block hidden xl:hidden">
            <img
              src={mobileImageForMob}
              alt="Mobile Image for LG "
              className="max-w-[170px]"
            />
          </div>

          {/* Mobile Image Section (for XL screens and up) */}
          <div className="w-auto xl:block lg:hidden hidden">
            <img
              src={mobileImage}
              alt="Mobile Image for XL and up"
              className="max-w-[350px]"
            />
          </div>

          {/* Text and Form Section */}
          <div className="w-full flex flex-col xl:p-4">
            <div className="space-y-6 text-center lg:text-left">
              {/* Header Text */}
              <p className="h48 lg:text-5xl font-bold pb-2 lg:pb-4 text-left ">
                Your{" "}
                <span className="text-brandGold">all-in-one</span> Umrah app.
              </p>

              {/* Description */}
              <p className="text-[18px] font-light w-full text-left lg:text-start">
                Book packages that provide visas, flights, hotels, trains & rental cars for your Umrah in seconds. Get real-time Umrah updates, travel info, exclusive deals, and 24/7 Customer support!
              </p>
            </div>

            {/* Email Section */}
            <div className="mt-10 flex flex-col lg:flex-row items-center lg:space-y-0 lg:space-x-10">
              {/* Email Input Section */}
              <div className="flex flex-col space-y-2 w-full lg:w-[60%]">
                <p className="text-2xl font-semibold">Email</p>
                <p className="text-sm font-normal pb-2">
                  Receive a link to download the mobile app, along with our exclusive offers.
                </p>

                <div className="flex items-center bg-darkBlue rounded-full p-2">
                  <input
                    type="search"
                    placeholder="Enter Email"
                    className="flex-grow outline-none px-4 bg-transparent text-sm sm:text-base text-white"
                  />
                  <button className="px-6 py-2 bg-brandGold font-bold text-sm sm:text-base text-secondaryBlue rounded-full hover:bg-opacity-90">
                    Submit
                  </button>
                </div>
              </div>

              {/* Or Text */}
              <p className="text-3xl font-medium self-center py-10">or</p>

              {/* Store Buttons for LG and XL*/}
              <div className="w-auto lg:flex flex-col hidden items-center space-y-8">
                <button className="w-auto">
                  <img
                    src={playStore}
                    alt="Play Store"
                    className="max-w-[150px] xl:max-w-[350px]"
                  />
                </button>
                <button className="w-auto">
                  <img
                    src={appleStore}
                    alt="Apple Store"
                    className="max-w-[150px] xl:max-w-[350px]"
                  />
                </button>
              </div>
            </div>
          </div>

          {/* Mobile Image Section (For Mobile and up to md) */}
          <div className="w-full lg:hidden flex justify-center">
            <img
              src={mobileImageForMob}
              alt="Footer Image"
              className="w-full max-w-[250px] sm:max-w-[300]"
            />
          </div>

          {/* Store Buttons */}
          <div className="my-10 space-x-6 flex lg:hidden items-center justify-center">
            <button className="w-auto">
              <img
                src={playStore}
                alt="Play Store"
                className="w-[150px] sm:w-[180px] md:w-[200px] lg:w-[250px]"
              />
            </button>
            <button className="w-auto">
              <img
                src={appleStore}
                alt="Apple Store"
                className="w-[150px] sm:w-[180px] md:w-[200px] lg:w-[250px]"
              />
            </button>
          </div>

        </div>
      </div>
    </div>
  );
};

export default AppDownload;
