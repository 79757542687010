// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  About,
  AllBookings,
  BookingInfoPage,
  BrowsingTipsPage,
  ConfirmVisa,
  Contactus,
  DocumentUpload,
  HajjGuidance,
  HomePage,
  ListingPage,
  PackageDetailPage,
  PaymentPage,
  TravelEssential,
  TravellerInfoPage,
  UmrahGuidance,
  UnderDevelopment,
  VisaServices,
  // AddressDetails,
  ComplaintPage,
  RequestPage,
  ReviewPage,
  ComplaintsOverviewPage,
  RequestOverviewPage,
  Articles,
  Madinah,
  Jeddah,
  Taif,
  Kabba,
  SafaMarwa,
  Mina,
  Arafah,
  Home,
  Muzdalifah,
  MasjidNimrah,
  GharEsoor,
  MasjidAlKhayf,
  AlHudaybiyah,
  MakkahClockTower,
  MakkahMuseum,
  MasjidJin,
  House,
  Wadijin,
  UhudMountain,
  BirGhars,
  MasjidAbuBakar,
  MasjidIjabah,
  MasjidGhamama,
  MasjidJummah,
  MasjidMiqat,
  MasjidMustarah,
  MasjidQiblatain,
  MasjidQuba,
  SalmanFarsiGarden,
  AlRehmanMosque,
  KhadijahMosque,
  HassanMosque,
  KhalidMosque,
  TurkeyMosque,
  AbuBakarMosque,
  NassifHouseMuseum,
  GlobeRoundAbout,
  SouqAlAlawi,
  TaybatMuseum,
  FlagPole,
  AlBalad,
  MasjidAbdulahBinAbbas,
  HalimaSadiaHouse,
  MasjidAlQantara,
  ALHadaMountain,
  ShafaMountain,
  ArruddafPark,
  ShubraPalace,
  TaifRoseFields,
  SaiysadPark,
  Taifzoo,
  KingFahad,
  MissionVision,
  CoreValues,
  HowHuzWorks,
  HuzPartnerPage,
  Career,
  ApplyForm,
  JobOne,
  FQA,
  TermsServices,
  PrivacyPolicy,
  Legal,
  RefundPolicy,
  CancellationPolicy,
  Concierge,
  Medical,
  Insurance,
  HelpCenter,
  SafetyPage,
  CustomerSupport,
  FraudProtection,
  RewardsWallet,
  CommunityPoliciesPage,
  ExploreMore,
  GetStarted,
  ManageAccount,
  Reservation,
  GettingPaidPage,
  HelpWithHosting,
  AirCoverPage,
  PersonalDetailsPage,
  ListPackagePage,
} from "./"; // Ensure you have imported your pages correctly
import { ScrollToTop } from "./Utilities/UtilityFunctions.js"; // Import ScrollToTop utility
import "./styles/globals.css"; // Ensure global styles are imported
import AuthContextProvider from "./context/AuthContextProvider.js";
import { ModalProvider } from "./context/ModalContext.js";
import { CurrencyProvider } from "./context/CurrencyContext";

const App = () => {
  return (
    <AuthContextProvider>
      <CurrencyProvider>
        <ModalProvider>
          <Router>
            <ScrollToTop /> {/* Ensure this is inside the Router */}
            <div>
              <Routes>
                {/* Home Page Route */}
                <Route path="/" element={<HomePage />} />
                {/* Listing Page Route */}
                <Route path="/listing-page" element={<ListingPage />} />
                <Route
                  path="/package-detail-page"
                  element={<PackageDetailPage />}
                />
                {/* //               <Route path="/address-details" element={<AddressDetails />} /> */}
                <Route
                  path="/traveller-info-page"
                  element={<TravellerInfoPage />}
                />
                <Route path="/payment-page" element={<PaymentPage />} />
                <Route
                  path="/booking-info/:booking_number"
                  element={<BookingInfoPage />}
                />
                <Route path="/review" element={<ReviewPage />} />
                <Route path="/complaint" element={<ComplaintPage />} />
                <Route path="/request" element={<RequestPage />} />
                <Route
                  path="/complaints-list"
                  element={<ComplaintsOverviewPage />}
                />
                <Route
                  path="/requests-list"
                  element={<RequestOverviewPage />}
                />
                <Route path="/document-detail" element={<DocumentUpload />} />
                <Route
                  path="/development-phase"
                  element={<UnderDevelopment />}
                />
                <Route path="/about" element={<About />} />
                <Route path="/mission-vision" element={<MissionVision />} />
                <Route path="/core-values" element={<CoreValues />} />
                <Route path="/how-hajjUmrah-works" element={<HowHuzWorks />} />
                <Route path="/hajjUmrah-partner" element={<HuzPartnerPage />} />
                <Route path="/career" element={<Career />} />
                <Route path="/apply-form" element={<ApplyForm />} />
                <Route path="/job-one" element={<JobOne />} />
                <Route path="/contact" element={<Contactus />} />
                <Route path="/Hajj-Guidance" element={<HajjGuidance />} />
                <Route path="/Umrah-Guidance" element={<UmrahGuidance />} />
                <Route path="/browse-tips" element={<BrowsingTipsPage />} />
                <Route path="/travel-essential" element={<TravelEssential />} />
                <Route path="/visa-services" element={<VisaServices />} />
                <Route path="/frequently-asked-questions" element={<FQA />} />
                <Route path="/legal" element={<Legal />} />
                <Route path="/refund-policy" element={<RefundPolicy />} />
                <Route path="/terms-services" element={<TermsServices />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route
                  path="/cancellation-policy"
                  element={<CancellationPolicy />}
                />
                <Route path="/concierge" element={<Concierge />} />
                <Route path="/medical" element={<Medical />} />
                <Route path="/insurance" element={<Insurance />} />
                <Route path="/help-center" element={<HelpCenter />} />
                <Route
                  path="/evisa-services/confirmation-process"
                  element={<ConfirmVisa />}
                />
                <Route path="/all-bookings" element={<AllBookings />} />
                {/* Services pages */}
                <Route path="/safety-tracking" element={<SafetyPage />} />
                <Route path="/customer-support" element={<CustomerSupport />} />
                <Route path="/fraud-protection" element={<FraudProtection />} />
                {/* Dropdown Pages */}
                <Route
                  path="/account-rewards&wallet"
                  element={<RewardsWallet />}
                />
                <Route
                  path="/community-policies"
                  element={<CommunityPoliciesPage />}
                />
                <Route path="/explore-more" element={<ExploreMore />} />
                <Route path="/get-started" element={<GetStarted />} />
                <Route path="/manage-account" element={<ManageAccount />} />
                <Route path="/reservation" element={<Reservation />} />
                <Route path="/getting-paid" element={<GettingPaidPage />} />
                <Route path="/help-hosting" element={<HelpWithHosting />} />
                <Route path="/aircover" element={<AirCoverPage />} />
                <Route
                  path="/personal-details"
                  element={<PersonalDetailsPage />}
                />
                <Route
                  path="/list-your-packages"
                  element={<ListPackagePage />}
                />
                <Route path="/articles/makkah" element={<Articles />} />
                <Route path="/articles/madinah" element={<Madinah />} />
                <Route path="/articles/jeddah" element={<Jeddah />} />
                <Route path="/articles/taif" element={<Taif />} />
                {/* Routes Makkah Pages */}
                <Route
                  path="/articles/makkah/holysites/kabba"
                  element={<Kabba />}
                />
                <Route
                  path="/articles/makkah/holysites/safa-marwa"
                  element={<SafaMarwa />}
                />
                <Route
                  path="/articles/makkah/holysites/mina"
                  element={<Mina />}
                />
                <Route
                  path="/articles/makkah/holysites/arafah"
                  element={<Arafah />}
                />
                <Route path="/articles/makkah/gar-e-hira" element={<Home />} />
                <Route
                  path="/articles/makkah/holysites/muzdalifah"
                  element={<Muzdalifah />}
                />
                <Route
                  path="/articles/makkah/holysites/namirah-mosque"
                  element={<MasjidNimrah />}
                />
                <Route
                  path="/articles/makkah/ghar-e-soor"
                  element={<GharEsoor />}
                />
                <Route
                  path="/articles/makkah/holysites/masjid-al-Khayf"
                  element={<MasjidAlKhayf />}
                />
                <Route
                  path="/articles/makkah/al-hudaybiyah"
                  element={<AlHudaybiyah />}
                />
                <Route
                  path="/articles/makkah/holysites/makkah-clock-tower"
                  element={<MakkahClockTower />}
                />
                <Route
                  path="/articles/makkah/holysites/makkah-museum"
                  element={<MakkahMuseum />}
                />
                <Route
                  path="/articles/makkah/holysites/masjid-jinn"
                  element={<MasjidJin />}
                />
                <Route
                  path="/articles/makkah/prophet-house"
                  element={<House />}
                />
                {/* Routes Madinah Places */}
                <Route
                  path="/articles/madinah/wadi-jin"
                  element={<Wadijin />}
                />
                <Route
                  path="/articles/madinah/uhud-mountain"
                  element={<UhudMountain />}
                />
                <Route
                  path="/articles/madinah/bir-ghars"
                  element={<BirGhars />}
                />
                <Route
                  path="/articles/madinah/masjid-abu-bakr"
                  element={<MasjidAbuBakar />}
                />
                <Route
                  path="/articles/madinah/masjid-ijabah"
                  element={<MasjidIjabah />}
                />
                <Route
                  path="/articles/madinah/masjid-al-ghammamah"
                  element={<MasjidGhamama />}
                />
                <Route
                  path="/articles/madinah/masjid-jummah"
                  element={<MasjidJummah />}
                />
                <Route
                  path="/articles/madinah/masjid-miqat"
                  element={<MasjidMiqat />}
                />
                <Route
                  path="/articles/madinah/masjid-mustarah"
                  element={<MasjidMustarah />}
                />
                <Route
                  path="/articles/madinah/masjid-qiblatayn"
                  element={<MasjidQiblatain />}
                />{" "}
                <Route
                  path="/articles/madinah/masjid-quba"
                  element={<MasjidQuba />}
                />
                <Route
                  path="/articles/madinah/garden-of-salman-farsi"
                  element={<SalmanFarsiGarden />}
                />
                {/* Routes Jeddah Places */}
                <Route
                  path="/articles/jeddah/holysites/al-rehman-mosque"
                  element={<AlRehmanMosque />}
                />{" "}
                <Route
                  path="/articles/jeddah/holysites/khadijah-mosque"
                  element={<KhadijahMosque />}
                />{" "}
                <Route
                  path="/articles/jeddah/holysites/hassan-mosque"
                  element={<HassanMosque />}
                />{" "}
                <Route
                  path="/articles/jeddah/holysites/khalid-mosque"
                  element={<KhalidMosque />}
                />{" "}
                <Route
                  path="/articles/jeddah/holysites/turkey-mosque"
                  element={<TurkeyMosque />}
                />{" "}
                <Route
                  path="/articles/jeddah/holysites/abu-bakar-mosque"
                  element={<AbuBakarMosque />}
                />
                <Route
                  path="/articles/jeddah/attractions/nasif-house-museum"
                  element={<NassifHouseMuseum />}
                />
                <Route
                  path="/articles/jeddah/attractions/globe-round-about"
                  element={<GlobeRoundAbout />}
                />
                <Route
                  path="/articles/jeddah/attractions/souq-al-alawi"
                  element={<SouqAlAlawi />}
                />
                <Route
                  path="/articles/jeddah/attractions/taybat-museum"
                  element={<TaybatMuseum />}
                />
                <Route
                  path="/articles/jeddah/attractions/flag-pole"
                  element={<FlagPole />}
                />
                <Route
                  path="/articles/jeddah/attractions/al-balad"
                  element={<AlBalad />}
                />
                {/* Routes Taif Places */}
                <Route
                  path="/articles/taif/holysites/masjid-abdullah-bin-abbas"
                  element={<MasjidAbdulahBinAbbas />}
                />
                <Route
                  path="/articles/taif/holysites/halima-sadia-house"
                  element={<HalimaSadiaHouse />}
                />
                <Route
                  path="/articles/taif/holysites/masjid-al-qantara"
                  element={<MasjidAlQantara />}
                />
                <Route
                  path="/articles/taif/holysites/al-hada-mountain"
                  element={<ALHadaMountain />}
                />
                <Route
                  path="/articles/taif/holysites/shafa-mountain"
                  element={<ShafaMountain />}
                />
                <Route
                  path="/articles/taif/attractions/arruddaf-park"
                  element={<ArruddafPark />}
                />
                <Route
                  path="/articles/taif/attractions/shubra-palace"
                  element={<ShubraPalace />}
                />
                <Route
                  path="/articles/taif/attractions/taif-rose-fields"
                  element={<TaifRoseFields />}
                />
                <Route
                  path="/articles/taif/attractions/saiysad-park"
                  element={<SaiysadPark />}
                />
                <Route
                  path="/articles/taif/attractions/taif-zoo"
                  element={<Taifzoo />}
                />
                <Route
                  path="/articles/taif/attractions/king-fahad"
                  element={<KingFahad />}
                />
              </Routes>
            </div>
          </Router>
        </ModalProvider>
      </CurrencyProvider>
    </AuthContextProvider>
  );
};

export default App;
