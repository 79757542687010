import React, { useState, useMemo, useCallback } from "react";
import Select from "react-select";
import countryData from "./country_data.json";
import { checkUserExistence, sendOtp } from "../../api/AuthApi";
import { ButtonsLoader, Otp } from "../..";
import { FiSmartphone, FiChevronDown, FiAlertCircle } from "react-icons/fi";

const countryOptions = countryData.map(({ code, dial_code, name }) => ({
  value: dial_code,
  label: `${name} (${dial_code})`,
  code,
  name,
}));

const Login = ({ closeModal, openSignUpModal }) => {
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [apiError, setApiError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(countryOptions[0]);
  const [showOtp, setShowOtp] = useState(false);
  const [userProfile, setUserProfile] = useState(null);

  const selectStyles = useMemo(
    () => ({
      control: (base) => ({
        ...base,
        borderRadius: "0.5rem",
        padding: "0.25rem 0.75rem",
        borderColor: phoneError ? "#ef4444" : "#e5e7eb",
        "&:hover": { borderColor: "#d1d5db" },
      }),
      singleValue: (base) => ({ ...base, textAlign: "left" }),
      option: (base, { isFocused }) => ({
        ...base,
        textAlign: "left",
        backgroundColor: isFocused ? "#fef6e6" : "#fff",
        color: "#1f2937",
      }),
    }),
    [phoneError]
  );

  const selectTheme = useMemo(
    () => (theme) => ({
      ...theme,
      colors: { ...theme.colors, primary: "#f5b301" },
    }),
    []
  );

  const handleCountryChange = useCallback((option) => setSelectedCountry(option), []);
  const handleChange = useCallback(({ target: { value } }) => {
    if (/^\d{0,10}$/.test(value)) {
      setPhone(value);
      if (value) setPhoneError("");
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPhoneError("");
    setApiError("");
    if (!phone) {
      setPhoneError("Phone Number is required");
      return;
    }
    setIsLoading(true);
    const number = selectedCountry.value + phone;
    try {
      const res = await checkUserExistence(number);
      if (res?.data?.message === "User with this phone number does not exist.") {
        setApiError("User with this phone number does not exist. Please sign up first.");
        return;
      }
      const userData = res.data || res;
      setUserProfile(userData);
      await sendOtp(number);
      sessionStorage.setItem("fromLocation", window.location.pathname + window.location.search);
      setShowOtp(true);
    } catch (err) {
      setApiError(
        err.response?.status === 400
          ? err.response.data.message || "An error occurred"
          : "An unexpected error occurred"
      );
    } finally {
      setIsLoading(false);
    }
  };

  if (showOtp) return <Otp closeModal={closeModal} userProfile={userProfile} />;

  return (
    <div className="w-full z-50">
      <div className="text-center mb-8">
        <div className="mb-5 flex justify-center">
          <div className="bg-brandGold/20 p-4 rounded-full">
            <FiSmartphone className="w-8 h-8 text-brandGold" />
          </div>
        </div>
        <h2 className="text-3xl font-bold text-gray-900 mb-2">Welcome Back</h2>
        <p className="text-gray-500">Enter your phone number to continue</p>
      </div>
      <form onSubmit={handleSubmit} className="space-y-6">
        <Select
          options={countryOptions}
          value={selectedCountry}
          onChange={handleCountryChange}
          formatOptionLabel={(option, { context }) =>
            context === "menu" ? option.label : option.name
          }
          components={{ IndicatorSeparator: null, DropdownIndicator: () => <FiChevronDown className="text-gray-400" /> }}
          styles={selectStyles}
          theme={selectTheme}
        />
        <div className="relative mt-4">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span className="text-gray-500">+{selectedCountry.value.replace(/\D/g, "")}</span>
          </div>
          <input
            type="text"
            name="phone_number"
            value={phone}
            onChange={handleChange}
            placeholder="123 456 7890"
            className={`w-full pl-14 pr-4 py-3 border ${phoneError ? "border-red-500" : "border-gray-200"} rounded-lg focus:ring-2 focus:ring-brandGold focus:border-brandGold/50 transition-colors`}
          />
        </div>
        {(phoneError || apiError) && (
          <div className="mt-2 flex items-center text-red-600">
            <FiAlertCircle className="w-4 h-4 mr-1" />
            <span className="text-sm">{phoneError || apiError}</span>
          </div>
        )}
        <button
          type="submit"
          disabled={isLoading}
          className="w-full bg-gradient-to-r from-brandGold to-amber-500 hover:from-amber-500 hover:to-brandGold text-white font-semibold py-3 px-4 rounded-lg transition-all transform hover:scale-[1.02] active:scale-95 disabled:opacity-70 disabled:hover:scale-100"
        >
          {isLoading ? (
            <div className="flex items-center justify-center space-x-2">
              <ButtonsLoader type="loading" />
              <span>Sending OTP...</span>
            </div>
          ) : (
            "Continue"
          )}
        </button>
        <div className="text-center text-sm text-gray-600 mt-4">
          Don't have an account?{" "}
          <button type="button" onClick={openSignUpModal} className="text-brandGold font-semibold hover:text-amber-600 transition-colors">
            Create new account
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;
