import React from "react";
import { Footer, Header } from "../../../";
const Legal = () => {
  return (
    <div>
      <Header />
      <div className="w-all-sections-width mx-auto h-screen text-4xl font-bold">Legal</div>
      <Footer />
    </div>
  );
};

export default Legal;
