// TravellerInfoPage.jsx
import React, { useState, useCallback } from "react";
import { Footer, Header, PackageHeader, PassportInfo } from "../../";
import banner from "../../assets/Detail-Page/banner.png";
import { useLocation, useNavigate } from "react-router-dom";
import { checkPassportValidity } from "../../api/apiService";
import dayjs from "dayjs";

const TravellerInfoPage = () => {
  const { state } = useLocation();
  const bookingResponse = state?.bookingResponse;
  const navigate = useNavigate();
  const numTravelers = bookingResponse?.adults || 0;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [passports, setPassports] = useState(
    Array.from({ length: numTravelers }, () => ({
      firstName: "",
      middleName: "",
      lastName: "",
      dob: "",
      passportNumber: "",
      nationality: "",
      expiryDate: "",
    }))
  );
  const [errorMessages, setErrorMessages] = useState(
    Array(numTravelers).fill("")
  );

  const passportRegex = /^[A-Z0-9]{6,9}$/;
  const returnDate = bookingResponse?.end_date;
  const isExpiryDateValid = (expiryDate) =>
    returnDate
      ? dayjs(expiryDate).isAfter(dayjs(returnDate).add(6, "month"))
      : false;

  const validateAllTravelers = () => {
    let valid = true;
    const errors = passports.map((p, i) => {
      if (!p.firstName.trim()) {
        valid = false;
        return `First Name for traveler ${i + 1} is required.`;
      }
      if (!p.middleName.trim()) {
        valid = false;
        return `Middle Name for traveler ${i + 1} is required.`;
      }
      if (!p.lastName.trim()) {
        valid = false;
        return `Last Name for traveler ${i + 1} is required.`;
      }
      if (!p.dob) {
        valid = false;
        return `Date of Birth for traveler ${i + 1} is required.`;
      }
      if (!p.passportNumber.trim()) {
        valid = false;
        return `Passport number for traveler ${i + 1} is required.`;
      }
      if (!passportRegex.test(p.passportNumber)) {
        valid = false;
        return `Invalid passport number for traveler ${i + 1}. Use 6-9 alphanumeric characters.`;
      }
      if (!p.nationality.trim()) {
        valid = false;
        return `Country issued for traveler ${i + 1} is required.`;
      }
      if (!p.expiryDate) {
        valid = false;
        return `Passport expiry date for traveler ${i + 1} is required.`;
      }
      if (!isExpiryDateValid(p.expiryDate)) {
        valid = false;
        return `Passport for traveler ${i + 1} must be valid for at least 6 months after the return date.`;
      }
      return "";
    });
    setErrorMessages(errors);
    return valid;
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!validateAllTravelers()) return;
      setIsSubmitting(true);
      try {
        const payloads = passports.map((p) => ({
          session_token: bookingResponse.user_session_token,
          booking_number: bookingResponse.booking_number,
          first_name: p.firstName,
          middle_name: p.middleName,
          last_name: p.lastName,
          passport_number: p.passportNumber,
          passport_country: p.nationality,
          date_of_birth: p.dob,
          expiry_date: p.expiryDate,
        }));
        const results = await Promise.all(payloads.map(checkPassportValidity));
        const errorRes = results.find((res) => res.error);
        if (errorRes) {
          console.error("Passport validation error:", errorRes.message);
          return;
        }
        navigate(
          `/payment-page/?bookingNumber=${encodeURIComponent(
            results[0].booking_number
          )}`
        );
      } catch (err) {
        console.error("Error submitting passports:", err);
      } finally {
        setIsSubmitting(false);
      }
    },
    [passports, bookingResponse, navigate]
  );

  return !bookingResponse ? (
    <p>No package data available</p>
  ) : (
    <div>
      <Header />
      <div className="w-all-sections-width mx-auto mb-10">
        <img src={banner} alt="banner" className="w-full my-10" />
        <div className="flex flex-col lg:flex-row gap-6">
          <div className="w-full">
            <PackageHeader data={bookingResponse} />
            <PassportInfo
              passports={passports}
              setPassports={setPassports}
              errorMessages={errorMessages}
              onSubmit={handleSubmit}
              isSubmitting={isSubmitting}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TravellerInfoPage;
