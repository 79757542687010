import React from "react";
import { Footer } from "../../";
import BlogHeader from "./BlogHeader";

const HeaderFooter = ({ children }) => {
  return (
    <div>
      <BlogHeader />
      <main>{children}</main>
      <div className="mt-10">
        <Footer />
      </div>
    </div>
  );
};

export default HeaderFooter;
