import React, { useEffect } from "react";
import Banner from "../../HeroSection/Banner";
import HeaderFooter from "../../../../ZiyaratArticlesPages/HeaderFooter";
import Slider from "../Wadijin/Slider";
import Page from "./Page";
import MasjidJummahImg1 from "../../../../../assets/ZiyaratPlaces/Madinah/MasjidJummah/Jummah1.jpg";
import MasjidJummahImg2 from "../../../../../assets/ZiyaratPlaces/Madinah/MasjidJummah/Jummah2.jpg";
import MasjidJummahImg3 from "../../../../../assets/ZiyaratPlaces/Madinah/MasjidJummah/Jummah3.jpg";
import MasjidJummahImg4 from "../../../../../assets/ZiyaratPlaces/Madinah/MasjidJummah/Jummah4.jpg";
import bg from "../../../../../assets/ZiyaratPlaces/Madinah/MasjidJummah/Jummah1.jpg";
const MasjidJummah = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const images = [
    MasjidJummahImg1,
    MasjidJummahImg2,
    MasjidJummahImg3,
    MasjidJummahImg4,
  ];

  return (
    <div className="">
      <HeaderFooter>
        <Banner
          image={bg}
          title={"Masjid Al Jummah"}
        />
        <Page />
        <Slider images={images} />
      </HeaderFooter>
    </div>
  );
};

export default MasjidJummah;
