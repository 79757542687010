import React, { useEffect } from "react";
import Banner from "../../HeroSection/Banner";
import HeaderFooter from "../../../../ZiyaratArticlesPages/HeaderFooter";
import Slider from "../../MadinahPlaces/Wadijin/Slider";
import Page from "./Page";
import ShafaMountainImg1 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/shafaMountains/image1.jpg";
import ShafaMountainImg2 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/shafaMountains/image2.jpg";
import ShafaMountainImg3 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/shafaMountains/image3.webp";
import ShafaMountainImg4 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/shafaMountains/image4.jpg";
import ShafaMountainImg5 from "../../../../../assets/ZiyaratPlaces/Taif/holyplace/shafaMountains/image5.jpg";

const ShafaMountain = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const images = [
    ShafaMountainImg1,
    ShafaMountainImg2,
    ShafaMountainImg3,
    ShafaMountainImg4,
    ShafaMountainImg5,
  ];

  return (
    <div>
      <HeaderFooter>
        <Banner image={ShafaMountainImg1} title={"Shafa-Mountain"} />
        <Page />
        <Slider images={images} />
      </HeaderFooter>
    </div>
  );
};

export default ShafaMountain;
