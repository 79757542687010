import React, { useContext, useRef, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { BiErrorAlt, BiLockAlt } from "react-icons/bi";
import { verifyOtp } from "../../api/AuthApi";
import AuthContext from "../../context/AuthContext";
import { ButtonsLoader } from "../..";

const Otp = ({ closeModal, userProfile }) => {
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState("");
  const inputRefs = useRef(otp.map(() => React.createRef()));
  const navigate = useNavigate();
  const location = useLocation();
  const { setLogin } = useContext(AuthContext);

  const userPhoneNumber = userProfile.phone_number || "";
  const countryCode = userProfile.country_code || "";
  const formattedPhoneNumber = `${countryCode} ${userPhoneNumber}`;
  const [fromLocation, setFromLocation] = useState("/");

  useEffect(() => {
    const storedFromLocation = sessionStorage.getItem("fromLocation");
    if (storedFromLocation) {
      setFromLocation(storedFromLocation);
      sessionStorage.removeItem("fromLocation");
    } else if (location.state?.from) {
      setFromLocation(location.state.from);
    }
  }, [location.state]);

  const handleOtpChange = (value, index) => {
    if (/^[0-9]$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value.slice(-1);
      setOtp(newOtp);
      if (value && index < otp.length - 1) inputRefs.current[index + 1].current.focus();
      if (!value && index > 0) inputRefs.current[index - 1].current.focus();
      setErrors("");
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text").slice(0, 6);
    const newOtp = pasteData.split("").map((char, i) => {
      if (inputRefs.current[i]) inputRefs.current[i].current.value = char;
      if (i < otp.length - 1 && inputRefs.current[i + 1]) inputRefs.current[i + 1].current.focus();
      return char;
    });
    setOtp(newOtp);
  };

  const handleOtpSubmit = async () => {
    setIsLoading(true);
    setErrors("");
    const enteredOtp = otp.join("");
    if (enteredOtp.length < 6) {
      setErrors("Please enter all 6 digits of the OTP.");
      setIsLoading(false);
      return;
    }
    try {
      const fullPhoneNumber = `${countryCode}${userPhoneNumber}`;
      await verifyOtp(fullPhoneNumber, enteredOtp);
      setLogin(userProfile);
      closeModal();
      navigate(fromLocation, { replace: true });
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;
        if (status === 400) setErrors(data.message || "Invalid OTP. Please try again.");
        else if (status === 401) setErrors("Unauthorized access. Please log in again.");
        else if (status === 500) setErrors("Server error. Please try again later.");
        else setErrors(data.message || "An unexpected error occurred.");
      } else {
        setErrors("Network error. Please check your connection.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full">
      <div className="">
        <div className="text-center mb-8">
          <div className="mb-5 flex justify-center">
            <div className="bg-brandGold/20 p-4 rounded-full">
              <BiLockAlt className="w-8 h-8 text-brandGold" />
            </div>
          </div>
          <h2 className="text-3xl font-bold text-gray-900 mb-2">OTP Verification</h2>
          <p className="text-gray-500">
            Enter the 6-digit code sent to{" "}
            <span className="font-semibold text-gray-700">{formattedPhoneNumber}</span>
          </p>
        </div>

        <div className="space-y-6">
          <div className="flex justify-center gap-3" onPaste={handlePaste}>
            {otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                maxLength="1"
                value={digit}
                onChange={(e) => handleOtpChange(e.target.value, index)}
                ref={inputRefs.current[index]}
                className="w-10 h-10 md:w-10 md:h-10 text-center text-xl font-bold border-2 border-gray-200 rounded-lg focus:border-brandGold focus:ring-2 focus:ring-brandGold/50 transition-all"
              />
            ))}
          </div>

          {errors && (
            <div className="mt-2 flex items-center justify-center text-red-600">
              <BiErrorAlt className="w-5 h-5 mr-1" />
              <span className="text-sm">{errors}</span>
            </div>
          )}

          <button
            onClick={handleOtpSubmit}
            disabled={isLoading}
            className="w-full bg-gradient-to-r from-brandGold to-amber-500 hover:from-amber-500 hover:to-brandGold text-white font-semibold py-3 px-4 rounded-lg transition-all transform hover:scale-[1.02] active:scale-95 disabled:opacity-70 disabled:hover:scale-100"
          >
            {isLoading ? (
              <div className="flex items-center justify-center space-x-2">
                <ButtonsLoader />
                <span>Verifying...</span>
              </div>
            ) : (
              'Verify OTP'
            )}
          </button>

          <div className="text-center text-sm text-gray-600 mt-4">
            Didn't receive the code?{' '}
            <button
              type="button"
              onClick={() => {}}
              className="text-brandGold font-semibold hover:text-amber-600 transition-colors"
            >
              Resend OTP
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Otp;