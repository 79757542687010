import React from "react";
import bg from "../../../../../assets/ZiyaratPlaces/Madinah/mountainUhud/bannerbg.png";
const Banner = () => {
  return (
    <div
      className="relative text-center h-[364px] bg-[#F0F6F5]"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div className="absolute bottom-10 md:left-28 left-7 text-white">
        <h1 className=" mb-2 font-kd font-bold md:text-[40px] text-[20px]">
          The Mount Uhud
        </h1>
      </div>
    </div>
  );
};

export default Banner;
