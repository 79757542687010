import React from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
  FaPinterestP,
} from "react-icons/fa";
// import googlePlayLogo from "../assets/assets/img/svg/store-google.svg";
// import appStoreLogo from "../assets/assets/img/svg/store-apple.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-secondaryBlue py-10 text-white">
      <div className=" w-all-sections-width mx-auto ">
        <div className="grid grid-cols-1 md:grid-cols-4 w-full gap-8 text-left font-thin text-xs">
          {[
            {
              title: "COMPANY INFORMATION",
              links: [
                { to: "/about", text: "About us" },
                { to: "/core-values", text: "Our core values" },
                { to: "/how-hajjUmrah-works", text: "How we work" },
                { to: "/hajjUmrah-partner", text: "HajjUmrah.co Business" },
                { to: "/career", text: "Careers" },
              ],
            },
            {
              title: "DISCOVER",
              links: [
                { to: "/listing-page?type=Hajj", text: "Hajj Packages" },
                { to: "/listing-page", text: "Umrah Packages" },
                { to: "/articles/makkah", text: "Travel Articles" },
              ],
            },
            {
              title: "RESOURCES",
              links: [
                { to: "/frequently-asked-questions", text: "FAQs" },
                { to: "/terms-services", text: "Terms & Conditions" },
                { to: "/privacy-policy", text: "Privacy & Cookies" },
                { to: "/refund-policy", text: "Refund Policy" },
                { to: "/cancellation-policy", text: "Cancellation Policy" },
              ],
            },
            {
              title: "HELP",
              links: [
                { to: "/contact", text: "Contact Us" },
                { to: "/contact", text: "Help Center" },
                { to: "/Media", text: "Media" },
              ],
            },
          ].map((section, index) => (
            <div key={index} className="flex flex-col w-full space-y-4 text-left">
              <h3 className="font-bold text-sm">{section.title}</h3>
              <ul className="space-y-4 text-[14px] font-[400]">
                {section.links.map((link, idx) => (
                  <li key={idx}>
                    <Link to={link.to}>{link.text}</Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <hr className="my-10 border-gray-300" />
        <div className="lg:flex space-y-7 lg:space-y-0 justify-between items-center">
          <div className="flex items-center xl:space-x-7 lg:space-x-5 space-x-7">
            <a href="/error">
              <FaFacebookF className="size-[20px]" />
            </a>
            <a href="/error">
              <FaTwitter className="size-[20px]" />
            </a>
            <a href="/error">
              <FaInstagram className="size-[20px]" />
            </a>
            <a href="/error">
              <FaLinkedinIn className="size-[20px]" />
            </a>
            <a href="/error">
              <FaYoutube className="size-[20px]" />
            </a>
          </div>
          <div className="text-sm block">
            <div className="w-full">
              <p className="text-[12px]">
                &copy;2024 HajjUmrah.co All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
