import React, { useEffect, useState } from "react";
import PackageCarousel from "./PackageCarousel";
import { getShortPackageDetails } from "../../../api/apiService";
import { MessageDisplay } from "../../../";
import { useNavigate } from "react-router-dom";
const PopularPackages = () => {
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchPackages = async () => {
      setLoading(true);
      setError(null);

      try {
        const data = await getShortPackageDetails({
          package_type: "umrah",
          // page: 1,
          // page_size: 10,
        });

        if (data) {
          setPackages(data);
        } else {
          setError("No packages available at the moment. Please check back later!");
        }
      } catch (err) {
        setError(err.message || "An error occurred while fetching packages.");
      } finally {
        setLoading(false);
      }
    };

    fetchPackages();
  }, []);

  return (
    <section className="bg-white w-all-sections-width mx-auto my-s10 md:my-m12 lg:my-l14 sm:py-0 py-4 text-secondaryBlue">
      <div className="flex items-center justify-between mb-6">
        <h2 className="h2 font-bold text-secondaryBlue">Featured Packages</h2>
        <button
          className="text-secondaryBlue underline"
          onClick={() => navigate("/listing-page?type=Umrah")} // Navigate to the desired URL
        >
          Explore More
        </button>

      </div>

      {loading ? (
        <MessageDisplay
          type="loading"
          message="Fetching the latest packages for you..."
        />
      ) : error ? (
        <MessageDisplay type="error" message={error} />
      ) : (
        <PackageCarousel packages={packages} />
      )}
    </section>
  );
};

export default PopularPackages;
